import gql from 'graphql-tag';

export default gql`
query getAllChildPagesOfProject($projectId: Int!) {
  projects_by_pk(project_id: $projectId) {
    child_pages {
      page_id
      canonical_url
      og_images
      og_title
      og_description
      slug
      order_index
      listed
      published
      description
      name
      keywords
      thumbnails
      latest_page_version {
        page_version_id
        display_data
        editor_data
      }
      template {
        name
      }
      pages_tags {
        tag {
          name
        }
      }
    }
  }
}
`;
