
var editor;
(function($) {




window.p$ = {};




    // Check if button is active.
    var isActive = function (cmd) {
       // console.log("isactive")
      var blocks = this.selection.blocks();

      if (blocks.length) {
        var blk = blocks[0];
        var tag = 'N';
        var default_tag = this.html.defaultTag();
        if (blk.tagName.toLowerCase() != default_tag && blk != this.el) {
          tag = blk.tagName;
        }
      }

      if (['LI', 'TD', 'TH'].indexOf(tag) >= 0) {
        tag = 'N';
      }

      if(tag) return tag.toLowerCase() == cmd;
    }





    // Log function will print log message
    var flog = function() {

        if (typeof(console) !== 'undefined' && KEditor.debug === true) {
            var args = Array.prototype.slice.apply(arguments);
            args.unshift('[KEditor]');

            //console.log(args)

        }
    };

    // Throw error message
    var error = function(msg) {
        throw new Error('[KEditor] ' + msg);
    };

    // Check dependencies




    // KEditor class
    var KEditor = function(target, options) {


        this.element = target;
        this.options = $.extend({}, KEditor.DEFAULTS, options);

        this.init(target);
    };

    // Turn on/off debug mode
    KEditor.debug = true;

    // Version of KEditor
    KEditor.version = '@{version}';


    KEditor.labels = "labels";
    KEditor.language = "de";





    // Default configuration of KEditor
    KEditor.DEFAULTS = {
        content: null,
        css:null,
        js:null,
        cssRaw:null,
        jsRaw:null,
        accessKeditorFirstTime: true,
        isNewPage:false,
        snippets:null,
        locale: null,
        corporateIdentity: null,
        groupSettings: null,
        pageLanguage: null,
        personalizedData: null,
        env: null,
        language: 'en',
        isSysAdmin: false,
        privileges: null,
        allPageSiblings: [],
        prevNextSiblings: [],
        data: {},
        variablesPlugin: [],
        chatGPTPlugin: {},
        niceScrollEnabled: false,
        nestedContainerEnabled: true,
        btnMoveUp: '<i class="fa fa-long-arrow-up"></i>',
        btnMoveDown: '<i class="fa fa-long-arrow-down"></i>',
        btnMoveContainerText: '<i class="fa fa-sort"></i>',
        btnMoveComponentText: '<i class="fa fa-arrows"></i>',
        btnSettingContainerText: '<i class="fa fa-pencil"></i> ',
        btnSettingComponentText: '<i class="fa fa-pencil"></i> '+KEditor.labels.editor_edit_snippet,
        btnDuplicateContainerText: '<i class="fa fa-files-o"></i>',
        btnDuplicateComponentText: '<i class="fa fa-files-o"></i>',
        btnDeleteContainerText: '<i class="fa fa-times"></i>',
        btnDeleteComponentText: '<i class="fa fa-trash-o"></i>',
        btnUpdateComponentText: '<i class="fa fa-refresh"></i>',//+KEditor.labels.editor_update_snippet,
        tabContainersText: 'Layout',
        tabContainersTitle: 'Layout',
        tabComponentsText: 'Snippets',
        tabComponentsTitle: 'Snippets',
        tabTooltipEnabled: false,
        extraTabs: null,
        defaultComponentType: 'blank',
        sidebarContainer: null,
        snippetsUrl: 'snippets/snippets.html',
        snippetsUrl: 'snippets/',
        snippetsTooltipEnabled: false,
        snippetsTooltipPosition: 'left',
        snippetsFilterEnabled: true,
        snippetsCategoriesSeparator: ';',
        snippetsContainer: true,
        snippetsComponents: true,
        previewUrl:null,
        templateSearch: false,
        templateFilter: false,
        snippetSearch: true,
        snippetFilter: true,
        iframeMode: true,
        newSnippetList: true,
        // set also again further at the bottom!!
        //toolbarSet1: "bold,italic,underline,strikeThrough,primary,insertLink,undo,redo",
        toolbarSet1:  "bold,italic,underline,strikeThrough,textColor,insertLink,clearFormatting,inlineClass,fontAwesome,undo,redo",
        toolbarSet2:  "bold,italic,underline,strikeThrough,textColor,clearFormatting,inlineClass,fontAwesome,undo,redo",
        toolbarSet3:  "bold,italic,underline,strikeThrough,insertLink,clearFormatting,inlineClass,fontAwesome,undo,redo",
        toolbarSet4:  "bold,italic,underline,strikeThrough,textColor,insertLink,formatUL,formatOL,inlineClass,fontAwesome,clearFormatting,undo,redo",
        toolbarSet4a: "bold,italic,underline,strikeThrough,textColor,insertLink,formatUL,formatOL,inlineClass,fontAwesome,clearFormatting,undo,redo",
        toolbarSet5:  "bold,italic,underline,strikeThrough,textColor,paragraphFormat,inlineClass,insertLink,formatUL,formatOL,fontAwesome,clearFormatting,undo,redo",
        toolbarSet5a: "bold,italic,underline,strikeThrough,textColor,paragraphFormat,inlineClass,insertLink,formatUL,formatOL,fontAwesome,clearFormatting,undo,redo",
        toolbarSet6:  "bold,italic,subscript,superscript,underline,strikeThrough,textColor,paragraphFormat,fontFamily,inlineClass,insertLink,formatUL,formatOL,alignLeft,alignCenter,alignRight,alignJustify,fontAwesome,clearFormatting,undo,redo",
        toolbarSet7:  {
           indexOf: key => Object.keys(this).indexOf(key),
          'moreText': {
            'buttons': ['bold', 'italic',  'textColor', 'inlineClass', 'underline', 'strikeThrough',  'subscript', 'superscript', 'fontFamily','fontSize', 'backgroundColor',  'clearFormatting'],
            'buttonsVisible': 4
          },
          'moreParagraph': {
            'buttons': ['alignLeft', 'alignCenter', 'paragraphFormat', 'formatULSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'customFormatUL', 'lineHeight', 'outdent', 'indent', 'paragraphStyle'],
            'buttonsVisible': 3
          },
          'moreRich': {
            'buttons': [ 'personalizationBtn', 'insertLink', 'insertImage', 'insertVideo', 'inline_snippets',  'chatGPT', 'insertTable', 'fontAwesome', 'emoticons',  'specialCharacters', 'embedly', /*'insertOembed'*/, 'br','line'],
            'buttonsVisible': 4
          },
          'moreMisc': {
            'buttons': ['undo', 'redo', 'html', 'help'],
            'align': 'right',
            'buttonsVisible': 2
          }
        },
        sortingArray : [
            {
                "icon":"fa-window-minimize",
                "value":"Footers"
            },
            {
                "icon":"fa-th",
                "value":"Others"
            },
            {
                "icon":"fa-bars",
                "value":"Navigations & Indexes"
            },
            {
                "icon":"fa-window-maximize",
                "value":"Modals"
            },
            {
                "icon":"fa-check-square-o",
                "value":"Forms"
            },
            {
                "icon":"fa-share",
                "value":"Sharing"
            },
            {
                "icon":"fa-user",
                "value":"Team & Signatures"
            },
            {
                "icon":"fa-pie-chart",
                "value":"Tables & Charts"
            },
            {
                "icon":"fa-quote-right",
                "value":"Quotes & Testimonials"
            },
            {
                "icon":"fa-bullhorn",
                "value":"Call to actions"
            },
            {
                "icon":"fa-arrows-h",
                "value":"Full width"
            },
            {
                "icon":"fa-list",
                "value":"Features & Lists"
            },
            {
                "icon":"fa-id-card-o",
                "value":"Cards & Boxes"
            },
            {
                "icon":"fa-play",
                "value":"Videos & Audios"
            },
            {
                "icon":"fa-file-image-o",
                "value":"Images & Galleries"
            },
            {
                "icon":"fa-align-justify",
                "value":"Titles & Texts"
            },
            {
                "icon":"fa-header",
                "value":"Headers"
            },
            {
                "icon":"fa-pencil",
                "value":"Custom"
            },
            {
                "icon":"fa-square-o",
                "value":"Basic Elements"
            },
            {
                "icon":"fa-address-card-o",
                "value":"Covers"
            },
            {
                "icon":"fa-columns",
                "value":"Layouts"
            },
            {
                "icon":"fa-star",
                "value":"All"
            },
            {
                "icon":"fa-star",
                "value":"Telefonica B2C",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"Telefonica B2B",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"ABG",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"ASD|Portfolios",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"ASD|Snippets",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"Haufe Akademie",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"Webmag",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"Tennet",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"Telefonica",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"Universal",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"Fokus Layout",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"CNHI",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"CNHI - Case IH",
                "style": true,
            },

            {
                "icon":"fa-star",
                "value":"CNHI - New Holland",
                "style": true,
            },
            {
                "icon":"fa-star",
                "value":"CNHI - Steyr",
                "style": true,
            }
        ],
        contentStyles: [],
        contentAreasSelector: null,
        contentAreasWrapper: '<div class="keditor-ui keditor-content-areas-wrapper"></div>',
        containerSettingEnabled: true,
        containerSettingInitFunction: null,
        containerSettingShowFunction: null,
        containerSettingHideFunction: null,
        onReady: function() {},
        onInitFrame: function(frame, frameHead, frameBody) {},
        onSidebarToggled: function(isOpened) {},
        onContentChanged: function(event, contentArea) {},

        onBeforeInitContentArea: function(contentArea) {},
        onInitContentArea: function(contentArea) {},

        onBeforeInitContainer: function(container, contentArea) {},
        onInitContainer: function(container, contentArea) {},
        onBeforeContainerDeleted: function(event, selectedContainer, contentArea) {},
        onContainerDeleted: function(event, selectedContainer, contentArea) {},
        onContainerChanged: function(event, changedContainer, contentArea) {},
        onContainerDuplicated: function(event, originalContainer, newContainer, contentArea) {},
        onContainerSelected: function(event, selectedContainer, contentArea) {},
        onContainerSnippetDropped: function(event, newContainer, droppedSnippet, contentArea) {},

        onComponentReady: function(component) {},
        onBeforeInitComponent: function(component, contentArea) {},
        onInitComponent: function(component, contentArea) {},
        onBeforeComponentDeleted: function(event, selectedComponent, contentArea) {},
        onComponentDeleted: function(event, selectedComponent, contentArea) {},
        onBeforeComponentUpdated: function(event, selectedComponent, contentArea) {},
        onComponentUpdated: function(event, selectedComponent, contentArea) {},
        onComponentChanged: function(event, changedComponent, contentArea) {},
        onComponentDuplicated: function(event, originalComponent, newComponent, contentArea) {},
        onComponentSelected: function(event, selectedComponent, contentArea) {},
        onComponentSnippetDropped: function(event, newComponent, droppedSnippet, contentArea) {},

        onBeforeDynamicContentLoad: function(dynamicElement, component, contentArea) {},
        onDynamicContentLoaded: function(dynamicElement, response, status, xhr, contentArea) {},
        onDynamicContentError: function(dynamicElement, response, status, xhr, contentArea) {}
    };

    // Component types
    KEditor.components = {
        blank: {
            settingEnabled: false
        }
    };

    // Export log methods;
    KEditor.log = flog;
    KEditor.error = error;



    KEditor.prototype.init = function(target) {


        var self = this;
        var options = self.options;

        self.labels = self.options.locale.localeObject.editor;
        self.corporateIdentity = self.options.corporateIdentity;
        self.groupSettings = self.options.groupSettings;
        self.privileges = self.options.privileges;
        self.isSysAdmin = self.options.isSysAdmin;
        self.options.btnSettingComponentText = '<i class="fa fa-pencil"></i> '+self.labels.edit_snippet;
        self.options.btnUpdateComponentText = '<i class="fa fa-refresh"></i> ';
        //self.cloudimage = "https://axyqwmwryo.cloudimg.io/v7/";
        self.target = target;

        //test privileges
        /*var value = "can_edit_page"
        self.privileges = self.privileges.filter(function(item) {
            return item !== value
        })*/


        // use the cloudimage functions from vue
        self.cloudImageFunction = self.options.cloudImageFunction;
        self.checkForCloudImage = self.options.checkForCloudImage;
        //self.cloudImageWrapperFunction = self.options.cloudImageWrapperFunction;


        self.body = $(target.closest("body"));


        self.initFrame(target, function(target){


            var body = self.body;
            if (body.hasClass('initialized-snippets-list')) {
                flog('Snippets list is already initialized!');
            } else {

                ajaxRequest = self.initSidebar();
                body.addClass('initialized-snippets-list');
            }

            if (body.hasClass('initialized-click-event-handlers')) {
                flog('CLick event handlers is already initialized!');
            } else {
                self.initKEditorClicks();
                body.addClass('initialized-click-event-handlers');
            }

          self.window.imgCover.setup({
              ready: true,
              doc:self.body[0].ownerDocument
          }); //make READY=true

          self.window.KEditor = self;

          self.initContentAreas(target);
          var ajaxRequest;
          if (!ajaxRequest && typeof options.onReady === 'function') {
              options.onReady.call(self);
          }



          self.getdisplaydata = function(){
              var deferred = $.Deferred();
              self.getContent(false, true).then(function(content) {
                  var newData_min = content.replace(/\n|\t/g, ' ').replace(/&amp;shy;/g, '&shy;');
                  deferred.resolve(newData_min);
              })
              return deferred.promise();
          }




          //if(self.options.newArticle)

          self.save();


          // firefox drop fix https://github.com/SortableJS/Sortable/issues/985
           if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {

              document.body.ondragover = function(event) {
                  if (event.dataTransfer) {
                      event.stopPropagation();
                      event.preventDefault();
                  }
              };
              document.body.ondrop = function(event) {
                  if (event.dataTransfer) {
                      event.stopPropagation();
                      event.preventDefault();
                  }
              };

              self.body[0].ondragover = function(event) {
                  if (event.dataTransfer) {
                      event.stopPropagation();
                      event.preventDefault();
                  }
              };
              self.body[0].ondrop = function(event) {
                  if (event.dataTransfer) {
                      event.stopPropagation();
                      event.preventDefault();
                  }
              };
          }

          // wrap sidebar and keditor-content-area
          if(self.privileges.includes('can_edit_page') || self.options.isSysAdmin){
            if(self.privileges.includes('can_edit_page_layouts') || self.privileges.includes('can_edit_page_snippets') || self.options.isSysAdmin){
                self.body.find( "#keditor-sidebar, .keditor-content-area" ).wrapAll( "<div class='d-flex flex-row justify-content-center' />");
            }
          }

          $(self.body).idle({
            onActive: function(){
                if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("onActive", null, function(){})
            },
            onShow: function(){
                if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("onActive", null, function(){})
            },
            idle: 2000
          })

          if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("loaded", null, function(){})

          if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("stopEditorLoader", null, function(){})


        });




        console.log("keditor", this);








    };




    //prototype of save Data
    KEditor.prototype.save = function() {

        if (
          (this.options.accessKeditorFirstTime && this.options.isNewPage)
            ||  (!this.options.accessKeditorFirstTime && !this.options.isNewPage)
        ) {
            this.options.isNewPage = false;
            this.options.accessKeditorFirstTime = false;
            //console.error("save", this.options.data, this);






            var self = this;

            // Fucking ugly: if no timeout, froala text is not initiated
            // need that because of froala Bug https://jsfiddle.net/ac3r4f80/3/
            setTimeout(function() {


                //prevent autosave!
                //if(window.parent._AMS.noAutoSave) return;
                self.getContent().then(function(content) {
                    var newData = content.replace(/\n|\t/g, ' ');
                    if(typeof self.options.autosave === 'function' ) self.options.autosave(newData);
                })

            }, 100);

            //console.error("SAFE", newData_min)
        } else {
            this.options.accessKeditorFirstTime = false;
        }
        this.options.isNewPage = false;
    }


    KEditor.prototype.generateId = function(type) {
        var timestamp = (new Date()).getTime();
        return 'keditor-' + type + '-' + timestamp;
    };

    KEditor.prototype.initNiceScroll = function(target) {
        flog('initNiceScroll', target);

        if ($.fn.niceScroll && this.options.niceScrollEnabled) {
            flog('Initialize $.fn.niceScroll');
            target.niceScroll({
                cursorcolor: '#999',
                cursorwidth: 6,
                railpadding: {
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                },
                cursorborder: '',
                disablemutationobserver: true
            });
        } else {
            flog('$.fn.niceScroll does not exist. Use default sidebar.');
        }
    };

    KEditor.prototype.initFrame = function(target, callback) {
        flog('initFrame', target);


        var self = this;
        var options = self.options;
        var originalContent;
        var content = options.content;
        var _templateVersion,
            _currentVersion;




        self.template = target.html();


        // compile content with handlebars
        //originalContent = self.compileHandlebars(originalContent)

        var iframe = self.iframe = $('<iframe style="display:none" />');


        //console.log("iframe",iframe)
        //var iframe = document.createElement('iframe'),
        iframe.src = "about:blank";
        var iframeId = self.generateId('frame');


        // Why do i have to insert that?
        target.append(iframe);


        iframe.attr({
            'id': iframeId,
            'class': 'keditor-ui keditor-frame',
            "src": "about:blank"
        });



        //target.css('display', 'none');
        target.attr('data-keditor-frame', '#' + iframeId);

        var iframeDoc = self.iframeDoc = iframe.contents();
        // Fix issue Firefox can't render content inside iframe
        // ======================================================
        iframeDoc.get(0).open();
        iframeDoc.get(0).close();
        // ======================================================
        self.window = iframe[0].contentWindow ? iframe[0].contentWindow : iframe[0].contentDocument.defaultView;
        var iframeHead = self.iframeHead = iframeDoc.find('head');
        var iframeBody = self.iframeBody = iframeDoc.find('body');

        var newDoctype = document.implementation.createDocumentType(
                'html',
                '',
                ''
             );
        iframe[0].contentDocument.insertBefore(newDoctype,iframe[0].contentDocument.childNodes[0]);

        // inacticityCheck
        $(iframeDoc).mousemove(function (e) {
            //window.parent._AMS.idleTime = 0;
        });
        $(iframeDoc).keypress(function (e) {
            //window.parent._AMS.idleTime = 0;
        });

        self.window.jQuery = function (selector) { return window.jQuery(selector, document); };
        jQuery = window.$.extend(jQuery, parent.$);
        self.window.$ = jQuery;
        self.window.jQuery = jQuery;
        //self.window.FroalaEditor = FroalaEditor;
        //delete window.FroalaEditor;



        self.window.p$={};

        self.window.p$ = {};
        self.window.p$.snippet = {};
        self.window.p$.module  = {};

        flog('Adding styles to iframe...');
        var styles = '',
            scripts = '';

        //setWebFonts
        var customFontsOfCI = (self.corporateIdentity && self.corporateIdentity._customFonts) ? self.corporateIdentity._customFonts : null;
        if(self.options.groupSettings.customFonts && self.options.groupSettings.customFonts.length>0){
            this.setCustomUploadedFont(self.options.groupSettings.customFonts)
        }
        this.setCustomFont(customFontsOfCI);









        var style;

        $.each(options.cssRaw, function( index, value ) {
            styles += '<style type="text/css">' + value + '</style>\n';
        })

        $.each(options.jsRaw, function( index, value ) {
            scripts += '<script type="text/javascript" >' + value + '<\/script>\n';
        })


        // console.log("self", self);

        if(options.pageLanguage){
            iframeDoc.find('html').attr("lang",options.pageLanguage);
        }
        // no translation for google tranlate
        iframeDoc.find('html').attr("translate","no");


        iframeHead.append(styles);
        iframeHead.append(scripts);


        var baseTag = document.createElement('base');
        iframeHead.append(baseTag);



        $.when(self.loadCSS(options.css, iframeHead))
        $.when(self.loadJS(options.js, iframeHead))
        .always(function() {

            var customColorOfCI = (self.corporateIdentity && self.corporateIdentity._customColor) ? self.corporateIdentity._customColor : null;
            var _colorString = self.setBaseColors(customColorOfCI);
            if(_colorString) var styles = '<style class="customColorOfCI" type="text/css">' + _colorString + '</style>\n';
            self.iframeHead.append(styles);

            if(self.corporateIdentity && self.corporateIdentity.enableExtendTypo && self.corporateIdentity.extendTypo) self.setCustomTypo(self.corporateIdentity.extendTypo);
            if(self.corporateIdentity && self.corporateIdentity.buttons) self.setCustomButtons(self.corporateIdentity.buttons);

            // add custom CSS
            iframeHead.prepend('<style class="customCss" type="text/css">' + options.customCss + '</style>\n');

            // init template
            self.options.template =  new self.window.p$.module[self.options.templateName];

            flog('All styles are added');

            flog('Adding original content to iframe...');
            var contentAreasWrapper;

            if (content) {

                var _dom = self.window.document.createElement("div");
                _dom.innerHTML = content.trim();
                _dom = _dom.children[0];



                var isSection = $(_dom).is('section');
                if (!isSection) {
                    content = "<section>" + $(_dom).prop('outerHTML') + "</section>";
                }
                originalContent = content;

                target.find("pgs-article").remove();
            } else {

                originalContent = target.is('textarea') ? target.val() : target.html();

                originalContent = "<section>" + originalContent + "</section>";

                target.find("pgs-article").remove();

            }


            // compile content with handlebars
            //self.window = iframe[0];
            originalContent = self.compileHandlebars(originalContent)

            if (options.contentAreasWrapper) {
                contentAreasWrapper = $(options.contentAreasWrapper);
                contentAreasWrapper.html(originalContent);
                iframeBody.append(contentAreasWrapper);
            } else {
                iframeBody.html(originalContent);
            }

            // delete helper iframe again (dont know why it is neccesary...?)
            $(iframeBody).find("iframe.keditor-ui").remove();

            self.body = iframeBody;

            $.each(options.css, function( index, value ) {
                //styles += '<link rel="stylesheet" type="text/css" href="' + value + '" />\n';
            })

            $.each(options.js, function( index, value ) {
                //scripts += '<script type="text/javascript" src="' + value + '" ><\/script>\n';
            })

            // add mp4 video provider
            self.window.FroalaEditor.VIDEO_PROVIDERS.push(
                {
                    test_regex: /\.(mp4)$/,
                    url_regex: /(https?:\/\/[a-zA-Z0-9_\-\/\.]+\.(mp4))/,
                    url_text: "$1",
                    html: '<video controls><source src="{url}" type="video/mp4">Your browser does not support the video tag.</video>',
                    provider: "mp4"
                  }
            );

            self.iframe.show();

            if (typeof options.onInitFrame === 'function') {
                options.onInitFrame.call(self, iframe, iframeHead, iframeBody);
            }

             // Initialize the selection widget when KEditor starts
            self.initSelectionWidget();

            callback(contentAreasWrapper || iframeBody);
        });


    };


    KEditor.prototype.loadJS = function(_loaded, iframeHead) {
        var promise,
          deferred = $.Deferred(),
          dependencies = [];



        for (var n = 0; n < _loaded.length; n++) {
          dependencies.push(
            promise = $.ajax({
              url: _loaded[n]+ "?v=" + new Date().getTime(),
              template: {
                type: "js",
                index: n
              },
              dataType: 'text',
              type: 'GET'
            }).always(function(data, status, xhr) {
              //hack: htaccess rewrites to html file, so if js not available it loads the html. If it is a html (containes "<!doctype"), insert blank text
              if (xhr.responseText == undefined || xhr.responseText.indexOf('<!doctype') == 0) {
                xhr.responseText = "";
              }
              iframeHead.append("<script class='fromTemplate'>" + xhr.responseText + "</script>")
            }).then(function(data) {

              return data;
            }, function(err) {
              // convert failure into success so $.when() doesn't stop
              return $.Deferred().resolve('err');
            })
          )
        }

        // when all dependencies (all connected JS and CSS) files are loaded - we're done!
        $.when.apply($, dependencies).done(function() {
          // all loaded - callback
          deferred.resolve(_loaded);
        })

        return deferred.promise();
    }

    KEditor.prototype.loadCSS = function(_loaded, iframeHead) {
    var promise,
        deferred = $.Deferred(),
        dependencies = [];



    for (var n = 0; n < _loaded.length; n++) {
        dependencies.push(
        promise = $.ajax({
            url: _loaded[n]+ "?v=" + new Date().getTime(),
            template: {
            type: "css",
            index: n
            },
            dataType: 'text',
            type: 'GET'
        }).always(function(data, status, xhr) {
            if (xhr.responseText == undefined || xhr.responseText.indexOf('<!doctype') == 0) {
                xhr.responseText = "";
            }
            // append before style tag with classname customColorOfCI if existing
            if(iframeHead.find(".customColorOfCI").length>0){
                iframeHead.find(".customColorOfCI").before("<style class='custom'>" + xhr.responseText + "</style>")
            } else {
                iframeHead.append("<style class='custom'>" + xhr.responseText + "</style>")
            }

        }).then(function(data) {
            return data;
        }, function(err) {
            // convert failure into success so $.when() doesn't stop
            return $.Deferred().resolve('err');
        })
        )
    }

    // when all dependencies (all connected JS and CSS) files are loaded - we're done!
    $.when.apply($, dependencies).done(function() {

        // all loaded - callback
        deferred.resolve(_loaded);
    })

    return deferred.promise();
    }



    KEditor.prototype.setBaseColors = function(_colors){
  		// Retrieve the object from storage

  		//----------------------------------------
  		// LIVE GENERATOR
  		//----------------------------------------
  		//var s = document.styleSheets[5];
  		// createStyles("body", "background-color", "red");

  		var colorString ="";


  		/**
  		 * Base scheme for the bs4 primary color.
  		 */
  		var isDark = true;
  		var t1 = "007bff"; // hsl(211, 100%, 50%)
  		var t2 = "0062cc"; // hsl(211, 100%, 40%)
  		var t3 = "9fcdff"; // hsl(211, 100%, 81%)
  		var t4 = "b8daff"; // hsl(211, 100%, 86%)
  		var t5 = "004085"; // hsl(211, 100%, 26%)
  		var t6 = "005cbf"; // hsl(211, 100%, 37%)
  		var t7 = "0069d9"; // hsl(211, 100%, 43%)
  		var t8 = "002752"; // hsl(211, 100%, 16%)
  		var t9 = "cce5ff"; // hsl(211, 100%, 90%)
  		var shadow = 'rgba(0, 123, 255, 0.5)'; // hsl(211, 100%, 50%)
      var _suffix = ["primary","secondary","tertiary","quaternary"]

      if (_colors !== null) {
          $.each(_colors, function(key, value) {
              if (!value) value = t1;
              colorString+=updateColor(value,_suffix[key])
          });
      } else {
          colorString = updateColor(t1, _suffix[0])
      }

  		//if(!primary) primary=t1;

  		/*colorString+=updateColor(primary, "primary");
  		if(secondary) colorString+=updateColor(secondary,"secondary");
  		if(tertiary) colorString+=updateColor(tertiary,"tertiary");
  		if(quaternary) colorString+=updateColor(quaternary,"quaternary");*/

  		return colorString;



  		function updateColor(color, suffix) {

  		    var hsl = hexToHSL(color);
  		    var _rgb = hexToRGB(color);
  		    var rgb = "rgb("+ +_rgb.r+","+ +_rgb.g+","+ +_rgb.b+")";

  		    var hsl_h = hsl.h;
  		    var hsl_s = hsl.s;
  		    var hsl_l = hsl.l;

  		    t1 = HSLToHex(hsl_h, hsl_s, hsl_l);
  		    t2 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, -10));
  		    t3 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, 31));
  		    t4 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, 36));
  		    t5 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, -24));
  		    t6 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, -13));
  		    t7 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, -7));
  		    t8 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, -34));
  		    t9 = HSLToHex(hsl_h, hsl_s, hslAddition(hsl_l, 40));
  		    shadow = '0 0 0 0.2rem rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.5)';
  		    isDark = getBrightness(_rgb) < 145;

  		    var _string = "";

  		    //----------------------------------------
  		    // Special
  		    //----------------------------------------
  		    if(suffix=="primary"){


  		      _string+=createStyles("a", "color", t1, true);
  		      _string+=createStyles("a:hover", "color", t2, true);
  		      _string+=createStyles(".btn-link", "color", t1);
  		      _string+=createStyles(".btn-link:hover", "color", t2);
  		      _string+=createStyles(".btn-light", "color", t1);
  		      _string+=createStyles(".btn-light:hover", "color", t2);

  		      _string+=createStyles(" .ct-series-a .ct-area, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-slice-pie", "fill", t1);
                _string+=createStyles(" .ct-series-b .ct-area, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-slice-pie", "fill", t2);
                _string+=createStyles(" .ct-series-c .ct-area, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-slice-pie", "fill", t3);
                _string+=createStyles(" .ct-series-d .ct-area, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-slice-pie", "fill", t4);
                _string+=createStyles(" .ct-series-e .ct-area, .ct-series-e .ct-slice-donut-solid, .ct-series-e .ct-slice-pie", "fill", t5);
                _string+=createStyles(" .ct-series-f .ct-area, .ct-series-f .ct-slice-donut-solid, .ct-series-f .ct-slice-pie", "fill", t6);
                _string+=createStyles(" .ct-series-g .ct-area, .ct-series-g .ct-slice-donut-solid, .ct-series-g .ct-slice-pie", "fill", t7);
                _string+=createStyles(" .ct-series-h .ct-area, .ct-series-h .ct-slice-donut-solid, .ct-series-h .ct-slice-pie", "fill", t8);
                _string+=createStyles(" .ct-series-i .ct-area, .ct-series-i .ct-slice-donut-solid, .ct-series-i .ct-slice-pie", "fill", t9);

                _string+=createStyles(".nav-pills .nav-link.active, .nav-pills .show>.nav-link","color", t1, true)
                _string+=createStyles(".nav-link:hover","color", t1, true)
  		    }

  		    //----------------------------------------
  		    // t1
  		    //----------------------------------------
            _string+=createStyles("."+suffix + "-hover:hover", "color", t1);
  		    _string+=createStyles(".badge-"+suffix+"", "background-color", t1);
  		    _string+=createStyles(".bg-"+suffix+"", "background-color", t1);
  		    _string+=createStyles(".border-"+suffix+"", "border-color", t1);
  		    _string+=createStyles(".btn-"+suffix+"", "background-color", t1);
  		    _string+=createStyles(".btn-"+suffix+"", "border-color", t1);
  		    _string+=createStyles(".btn-"+suffix+".disabled, .btn-"+suffix+":disabled", "background-color", t1);
  		    _string+=createStyles(".btn-"+suffix+".disabled, .btn-"+suffix+":disabled", "border-color", t1);

  		    _string+=createStyles(".btn-outline-"+suffix+"", "color", t1);
  		    _string+=createStyles(".btn-outline-"+suffix+"", "border-color", t1);
  		    _string+=createStyles(".btn-outline-"+suffix+":hover", "background-color", t1);
  		    _string+=createStyles(".btn-outline-"+suffix+":hover", "border-color", t1);
  		    _string+=createStyles(".btn-outline-"+suffix+".disabled, .btn-outline-"+suffix+":disabled", "color", t1);
  		    _string+=createStyles(".btn-outline-"+suffix+":not(:disabled):not(.disabled):active, .btn-outline-"+suffix+":not(:disabled):not(.disabled).active, .show > .btn-outline-"+suffix+".dropdown-toggle", "background-color", t1);
  		    _string+=createStyles(".btn-outline-"+suffix+":not(:disabled):not(.disabled):active, .btn-outline-"+suffix+":not(:disabled):not(.disabled).active, .show > .btn-outline-"+suffix+".dropdown-toggle", "border-color", t1);
  		    _string+=createStyles(".text-"+suffix+"", "color", t1);
  		    _string+=createStyles(".before-bg-"+suffix+":before", "background-color", t1);
  		    _string+=createStyles(".after-bg-"+suffix+":after", "background-color", t1);
  		    _string+=createStyles(".before-"+suffix+":before", "color", t1);
  		    _string+=createStyles(".after-"+suffix+":after", "color", t1);


  				_string+=createStyles(".fill-"+suffix+"", "fill", t1);






  		    //----------------------------------------
  		    // t2
  		    //----------------------------------------
  		    _string+=createStyles(".badge-"+suffix+"[href]:hover, .badge-"+suffix+"[href]:focus", "background-color", t2);
  		    _string+=createStyles("a.bg-"+suffix+":hover, a.bg-"+suffix+":focus, button.bg-"+suffix+":hover, button.bg-"+suffix+":focus", "background-color", t2);
  		    _string+=createStyles(".btn-"+suffix+":hover", "border-color", t2);
  		    _string+=createStyles(".btn-"+suffix+":not(:disabled):not(.disabled):active, .btn-"+suffix+":not(:disabled):not(.disabled).active, .show > .btn-"+suffix+".dropdown-toggle", "background-color", t2);
  		    _string+=createStyles("a.text-"+suffix+":hover, a.text-"+suffix+":focus", "color", t2);


  		    //----------------------------------------
  		    // t3
  		    //----------------------------------------
  		    _string+=createStyles(".alert-"+suffix+" hr", "border-top-color", t3);
  		    _string+=createStyles(".list-group-item-"+suffix+".list-group-item-action:hover, .list-group-item-"+suffix+".list-group-item-action:focus", "background-color", t3);
  		    _string+=createStyles(".table-hover .table-"+suffix+":hover", "background-color", t3);
  		    _string+=createStyles(".table-hover .table-"+suffix+":hover > td, .table-hover .table-"+suffix+":hover > th", "background-color", t3);


  		    //----------------------------------------
  		    // t4
  		    //----------------------------------------
  		    _string+=createStyles(".alert-"+suffix+"", "border-color", t4);
  		    _string+=createStyles(".list-group-item-"+suffix+"", "background-color", t4);
  		    _string+=createStyles(".table-"+suffix+", .table-"+suffix+" > th, .table-"+suffix+" > td", "background-color", t4);


  		    //----------------------------------------
  		    // t5
  		    //----------------------------------------
  		    _string+=createStyles(".alert-"+suffix+"", "color", t5);
  		    _string+=createStyles(".list-group-item-"+suffix+"", "color", t5);
  		    _string+=createStyles(".list-group-item-"+suffix+".list-group-item-action:hover, .list-group-item-"+suffix+".list-group-item-action:focus", "color", t5);
  		    _string+=createStyles(".list-group-item-"+suffix+".list-group-item-action.active", "background-color", t5);
  		    _string+=createStyles(".list-group-item-"+suffix+".list-group-item-action.active", "border-color", t5);

  		    //----------------------------------------
  		    // t6
  		    //----------------------------------------
  		    _string+=createStyles(".btn-"+suffix+":not(:disabled):not(.disabled):active, .btn-"+suffix+":not(:disabled):not(.disabled).active, .show > .btn-"+suffix+".dropdown-toggle", "border-color", t6);


  		    //----------------------------------------
  		    // t7
  		    //----------------------------------------
  		    _string+=createStyles(".btn-"+suffix+":hover", "background-color", t7);

  		    //----------------------------------------
  		    // t8
  		    //----------------------------------------
  		    _string+=createStyles(".alert-"+suffix+" .alert-link", "color", t8);

  		    //----------------------------------------
  		    // t9
  		    //----------------------------------------
  		    _string+=createStyles(".alert-"+suffix+"", "background-color", t9);

  		    //----------------------------------------
  		    // shadow
  		    //----------------------------------------
  		    _string+=createStyles(".btn-"+suffix+":focus, .btn-"+suffix+".focus", "box-shadow", shadow);
  		    _string+=createStyles(".btn-"+suffix+":not(:disabled):not(.disabled):active:focus, .btn-"+suffix+":not(:disabled):not(.disabled).active:focus, .show > .btn-"+suffix+".dropdown-toggle:focus", "box-shadow", shadow);
  		    _string+=createStyles(".btn-outline-"+suffix+":focus, .btn-outline-"+suffix+".focus", "box-shadow", shadow);
  		    _string+=createStyles(".btn-outline-"+suffix+":not(:disabled):not(.disabled):active:focus, .btn-outline-"+suffix+":not(:disabled):not(.disabled).active:focus, .show > .btn-outline-"+suffix+".dropdown-toggle:focus", "box-shadow", shadow);


  		    //----------------------------------------
  		    // dark/light
  		    //----------------------------------------
  		    var textColor = '#fff';
  		    if (false === isDark) {
  		        textColor = '#212529';
  		    }
  		    _string+=createStyles(".badge-"+suffix+"", "color", textColor);
  		    _string+=createStyles(".badge-"+suffix+"[href]:hover, .badge-"+suffix+"[href]:focus", "color", textColor);
  		    _string+=createStyles(".btn-"+suffix+"", "color", textColor, true);
  		    _string+=createStyles(".btn-"+suffix+":hover", "color", textColor);
  		    _string+=createStyles(".btn-"+suffix+".disabled, .btn-"+suffix+":disabled", "color", textColor);
  		    _string+=createStyles(".btn-"+suffix+":not(:disabled):not(.disabled):active, .btn-"+suffix+":not(:disabled):not(.disabled).active, .show > .btn-"+suffix+".dropdown-toggle", "color", textColor);
  		    _string+=createStyles(".btn-outline-"+suffix+":hover", "color", textColor);
  		    _string+=createStyles(".btn-outline-"+suffix+":not(:disabled):not(.disabled):active, .btn-outline-"+suffix+":not(:disabled):not(.disabled).active, .show > .btn-outline-"+suffix+".dropdown-toggle", "color", textColor);
  		    _string+=createStyles(".list-group-item-"+suffix+".list-group-item-action.active", "color", textColor);

  		    return _string;
  		   // console.log(_string);


  		}

    	function hslAddition(hslValue, number) {
    	    var newVal = hslValue + number;
    	    if (newVal < 0) {
    	        newVal = 0;
    	    }
    	    if (newVal > 100) {
    	        newVal = 100;
    	    }
    	    return newVal;
    	}

      // https://stackoverflow.com/questions/6620393/is-it-possible-to-alter-a-css-stylesheet-using-javascript-not-the-style-of-an/38133146#38133146
    	function createStyles(selector, property, value, noImportant) {
    	    // Make the strings lowercase
    	    selector = selector.toLowerCase();
    	    property = property.toLowerCase();
    	    value = value.toLowerCase();

    	    if(noImportant) return (selector + " { " + property + ": " + value + "; }");

    	    return (selector + " { " + property + ": " + value + "!important; }");
    	    // Add it if it does not
    	    //stylesheet.insertRule(selector + " { " + property + ": " + value + "; }", 0);
    	}

    	function hexToRGB(h) {
    	  var r = 0, g = 0, b = 0;

    	  // 3 digits
    	  if (h.length == 4) {
    	    r = "0x" + h[1] + h[1];
    	    g = "0x" + h[2] + h[2];
    	    b = "0x" + h[3] + h[3];

    	  // 6 digits
    	  } else if (h.length == 7) {
    	    r = "0x" + h[1] + h[2];
    	    g = "0x" + h[3] + h[4];
    	    b = "0x" + h[5] + h[6];
    	  }

    	  //return "rgb("+ +r + "," + +g + "," + +b + ")";

    	  return {
    	        "r":r,
    	        "g":g,
    	        "b":b,
    	    }
    	}

    	function hexToHSL(H) {
    	  // Convert hex to RGB first
    	  var r = 0, g = 0, b = 0;
    	  if (H.length == 4) {
    	    r = "0x" + H[1] + H[1];
    	    g = "0x" + H[2] + H[2];
    	    b = "0x" + H[3] + H[3];
    	  } else if (H.length == 7) {
    	    r = "0x" + H[1] + H[2];
    	    g = "0x" + H[3] + H[4];
    	    b = "0x" + H[5] + H[6];
    	  }
    	  // Then to HSL
    	  r /= 255;
    	  g /= 255;
    	  b /= 255;
    	  var cmin = Math.min(r,g,b),
    	      cmax = Math.max(r,g,b),
    	      delta = cmax - cmin,
    	      h = 0,
    	      s = 0,
    	      l = 0;

    	  if (delta == 0)
    	    h = 0;
    	  else if (cmax == r)
    	    h = ((g - b) / delta) % 6;
    	  else if (cmax == g)
    	    h = (b - r) / delta + 2;
    	  else
    	    h = (r - g) / delta + 4;

    	  h = Math.round(h * 60);

    	  if (h < 0)
    	    h += 360;

    	  l = (cmax + cmin) / 2;
    	  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    	  s = +(s * 100).toFixed(1);
    	  l = +(l * 100).toFixed(1);

    	  return {
    	        "h":h,
    	        "s":s,
    	        "l":l,
    	    }
    	  //return "hsl(" + h + "," + s + "%," + l + "%)";
    	}

    	function HSLToHex(h,s,l) {
    	  s /= 100;
    	  l /= 100;

    	  var c = (1 - Math.abs(2 * l - 1)) * s,
    	      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
    	      m = l - c/2,
    	      r = 0,
    	      g = 0,
    	      b = 0;

    	  if (0 <= h && h < 60) {
    	    r = c; g = x; b = 0;
    	  } else if (60 <= h && h < 120) {
    	    r = x; g = c; b = 0;
    	  } else if (120 <= h && h < 180) {
    	    r = 0; g = c; b = x;
    	  } else if (180 <= h && h < 240) {
    	    r = 0; g = x; b = c;
    	  } else if (240 <= h && h < 300) {
    	    r = x; g = 0; b = c;
    	  } else if (300 <= h && h < 360) {
    	    r = c; g = 0; b = x;
    	  }
    	  // Having obtained RGB, convert channels to hex
    	  r = Math.round((r + m) * 255).toString(16);
    	  g = Math.round((g + m) * 255).toString(16);
    	  b = Math.round((b + m) * 255).toString(16);

    	  // Prepend 0s, if necessary
    	  if (r.length == 1)
    	    r = "0" + r;
    	  if (g.length == 1)
    	    g = "0" + g;
    	  if (b.length == 1)
    	    b = "0" + b;

    	  return "#" + r + g + b;
    	}

    	function getBrightness(rgb) {
    	        return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    	}


  	}

    KEditor.prototype.setCustomFont = function(_customFonts){

      var self = this;
      var fonts=[];

      setTimeout(function () {

        if(self.corporateIdentity && self.corporateIdentity.defaultFonts){
            var _defaultFonts = self.corporateIdentity.defaultFonts;
            $.each(_defaultFonts, function(key, value) {
              var keyNumber = key.match(/\d+/)[0];
              var googleFonts = [];
              // Check the type of each font
              if (value.type === 'google') {
                  console.log(value.font+":300,400,700" + ' is a Google font.');
                  googleFonts.push(value.font+":300,400,700")
              } else if (value.type === 'custom') {
                  console.log(value.font + ' is a custom font.');
                  // add system font
                  var _baseFontCss = ".wps-font" + (keyNumber)  + "{font-family:'" + value.font + "', sans-serif}";
                  // find keyNumber from _defaultFonts of loaded font
                  var style_theme = document.createElement("style");
                  style_theme.className = "wps-custom font-styles";
                  style_theme.innerHTML = _baseFontCss;
                  self.window.document.head.appendChild(style_theme);
              } else if (value.type === 'system') {
                console.log(value.font + ' is a system font.', self.window.document);
                // add system font
                var _baseFontCss = ".wps-font" + (keyNumber)  + "{font-family:'" + value.font + "', sans-serif}";
                // find keyNumber from _defaultFonts of loaded font
                var style_theme = document.createElement("style");
                style_theme.className = "wps-systemfont-styles";
                style_theme.innerHTML = _baseFontCss;
                self.window.document.head.appendChild(style_theme);
              }

              if(googleFonts.length>0){
                self.window.WebFont.load({
                  google: {
                    families: googleFonts
                  },
                  active: function() {},
                  fontloading: function(familyName, fvd) {
                    console.log("fontloading", familyName, fvd);
                    var _found = (fonts.indexOf(familyName) > -1);
                    if (!_found) fonts.push(familyName);
                  },
                  context: self.window,
                  loading: function() {},
                  inactive: function() {},
                  fontactive: function(familyName, fvd) {
                    //console.log("fontactive", fonts);
                    var _baseFontCss = "";
                    for (var i = 0; i < fonts.length; i++) {
                      // find keyNumber from _defaultFonts of loaded font
                      if(_defaultFonts.font1.type==="google" && _defaultFonts.font1.font===familyName) keyNumber = 1;
                      if(_defaultFonts.font2 && _defaultFonts.font2.type==="google" && _defaultFonts.font2.font===familyName) keyNumber = 2;
                      _baseFontCss +=
                        ".wps-font" + (keyNumber)  + "{font-family:'" + fonts[i] + "', sans-serif}";
                    }
                    var style_theme = this.context.document.createElement("style");
                    style_theme.className = "wps-webfont-styles";
                    style_theme.innerHTML = _baseFontCss;
                    //fix, because active is just started once on multiple font loadings
                    if ($(this.context.document).find(".wps-webfont-styles").length == 0) this.context.document.head.appendChild(style_theme);
                  },
                  fontinactive: function(familyName, fvd) {}
                });
              }


          });

          }else{



            if(self.corporateIdentity.disableGoogleFont){
                // var style_theme = document.createElement("style");
                // style_theme.className = "wps-font-styles";
                // style_theme.innerHTML = ".wps-font1,.wps-font2 {font-family: Verdana, sans-serif};";
                // console.error(style_theme, style_theme, self)
                // self.iframeHead.append(style_theme);
            }else{
                let googleFonts = [];
                //prepare fonts for google
                if (_customFonts && _customFonts.google && _customFonts.google.length > 0) {
                    $.each(_customFonts.google, function( index, value ) {
                        if(_customFonts.google[index] && _customFonts.google[index]!="") googleFonts[index] = _customFonts.google[index]+ ":300,400,700";
                    });
                } else {
                    googleFonts = ['Open+Sans:300,400,700'];
                }

                console.error("googleFonts", googleFonts);

                if(googleFonts.length==0) googleFonts = ['Open+Sans:300,400,700'];
                if(self.window.WebFont && googleFonts.length!=0) self.window.WebFont.load({
                    google: {
                    families: googleFonts
                    },
                    context: self.window,
                    active: function() {},
                    fontloading: function(familyName, fvd) {
                    var _found = (fonts.indexOf(familyName) > -1);
                        if(!_found) fonts.push(familyName);
                    },
                    loading: function() {},
                    inactive: function() {},
                    fontactive: function(familyName, fvd) {
                        var _baseFontCss = "";
                        for (var i = 0; i < fonts.length; i++) {
                            _baseFontCss+=
                                ".wps-font"+(i+1)+"{font-family:'"+fonts[i]+"', sans-serif}";
                        }
                        var style_theme = this.context.document.createElement("style");
                            style_theme.className = "wps-font-styles";
                            style_theme.innerHTML = _baseFontCss;
                            //fix, because active is just started once on multiple font loadings
                            if($(this.context.document).find(".wps-font-styles").length==0) this.context.document.head.appendChild(style_theme);
                    },
                    fontinactive: function(familyName, fvd) {}
                });
            }
          }

      }, 10);
    }

    KEditor.prototype.setCustomUploadedFont = function(fontsArray){
        var self = this;
        //if a custom font was uploaded
        fontsArray.forEach(function(font) {
            var fontFaceRule = "@font-face { font-family: '" + font.fontFamily + "'; font-style: " + font.style + "; font-weight: " + font.fontWeight + "; font-display: " + font.fontDisplay + "; src: ";

            font.fontFiles.forEach(function(file, index) {
                fontFaceRule += "url('" + file.url + "') format('" + file.filetype.split('/')[1] + "')";
                if (index < font.fontFiles.length - 1) {
                    fontFaceRule += ", ";
                }
            });
            fontFaceRule += "; }";
            // Inject the @font-face rule into the page
            //console.log("fontFaceRule", fontFaceRule, self.iframeHead);
            $("<style>").prop("type", "text/css").prop("class", "customFont").html(fontFaceRule).appendTo(self.iframeHead);
        });
    }

    KEditor.prototype.setCustomTypo = function(_customTypo){

        var self = this;


        setTimeout(function () {

            function changeVar(val){
             return val.replace(/([A-Z])/g, "-$1").toLowerCase();
            }
            if (_customTypo) {
                var _customCss = "";
                $.each(_customTypo, function (indexInArray, tag) {
                    if(indexInArray == "p") indexInArray = ".pgs-article p,.pgs-article td,.pgs-article li"; // also add typo to list and table
                    else if(indexInArray == "customTypo1") indexInArray = ".pgs-article ."+indexInArray; // also add typo to list and table
                    else if(indexInArray == "customTypo2") indexInArray = ".pgs-article ."+indexInArray; // also add typo to list and table
                    else if(indexInArray == "customTypo3") indexInArray = ".pgs-article ."+indexInArray; // also add typo to list and table
                    else indexInArray = ".pgs-article "+indexInArray;
                    _customCss +=  indexInArray+"{font-family:"+tag.fontFamily+";}";
                    _customCss +=  indexInArray+"{"
                    $.each(tag.screen, function (indexInArray, value) {
                        _customCss +=  changeVar(indexInArray)+":"+value+";";
                    });
                    _customCss +=  "} ";

                    _customCss += "@media (max-width: 992px) { " + indexInArray+"{"
                    $.each(tag.tablet, function (indexInArray, value) {
                        _customCss +=  changeVar(indexInArray)+":"+value+";";
                    });
                    _customCss +=  "} }";

                    _customCss += "@media (max-width: 576px) { " + indexInArray+"{"
                    $.each(tag.mobile, function (indexInArray, value) {
                        _customCss +=  changeVar(indexInArray)+":"+value+";";
                    });
                    _customCss +=  "} }";

                });
                self.iframeHead.append("<style class='_customTypo'>" + _customCss + "</style>")
            }
        }, 10);
    }

    KEditor.prototype.setCustomButtons = function(_customButton){

        var self = this;


        setTimeout(function () {

            function changeVar(val){
                return val.replace(/([A-Z])/g, "-$1").toLowerCase();
            }
            if (_customButton) {
                var _customCss = "";
                _customCss += ".btn{"
                $.each(_customButton.styleObject, function (indexInArray, value) {
                    _customCss +=  changeVar(indexInArray)+":"+value+";";
                });
                _customCss += "}"
                self.iframeHead.append("<style class='_customButton'>" + _customCss + "</style>")
            }
        }, 10);
    }




    KEditor.prototype.initSidebar = function() {
        flog('initSidebar');


        var self = this;

        // check priviledge for user
        if(!self.privileges.includes('can_edit_page') && !self.options.isSysAdmin) return;
        if( (!self.privileges.includes('can_edit_page_layouts') && !self.privileges.includes('can_edit_page_snippets')) && !self.options.isSysAdmin ) return;

        var options = self.options;
        var body = self.body;

        self.body.addClass('opened-keditor-sidebar');
        var sidebarContainer = self.body;

        if (options.sidebarContainer) {
            sidebarContainer = options.sidebarContainer;
        }

        flog('Render Keditor sidebar');

        sidebarContainer.append(
            //'<div id="keditor-toolbarContainer" class="keditor-toolbarContainer">' +
            //'</div>'+
            '<div id="keditor-sidebar" class="keditor-ui">' +
            '   <a id="keditor-sidebar-toggler" class="keditor-ui"><i class="fa fa-chevron-right"></i></a>' +
            '   <div id="keditor-snippets-list" class="keditor-ui"></div>' +
            '   <div id="keditor-snippets-content" class="keditor-ui" style="display: none"></div>' +
            /*'   <div id="keditor-setting-panel" class="keditor-ui">' +
            '       <div id="keditor-setting-header" class="keditor-ui">' +
            '           <span id="keditor-setting-title" class="keditor-ui"></span>' +
            '           <a  id="keditor-setting-closer" class="keditor-ui"><i class="fa fa-arrow-right"></i></a>' +
            '       </div>' +
            '       <div id="keditor-setting-body" class="keditor-ui">' +
            '           <div id="keditor-setting-forms" class="keditor-ui"></div>' +
            '       </div>' +
            '   </div>' +*/
            '</div>'
        );

        sidebarContainer.append(
            '<div id="keditor-sidebar-overlay"></div>' +
            '<div id="keditor-sidebar-settings-editor"></div>' +
            '<div id="keditor-sidebar-settings" class="keditor-ui">' +
            '   <a id="keditor-sidebar-toggler" class="keditor-ui"><i class="fa fa-chevron-right"></i></a>' +
          /*  '   <div id="keditor-snippets-list" class="keditor-ui"></div>' +
            '   <div id="keditor-snippets-content" class="keditor-ui" style="display: none"></div>' +*/
            '   <div id="keditor-setting-panel" class="keditor-ui">' +

            '       <div id="keditor-setting-body" class="keditor-ui">' +
            '           <div id="keditor-setting-forms" class="keditor-ui">'+
            '               <div id="keditor-setting-header" class="keditor-ui">' +
            '                   <span id="keditor-setting-title" class="keditor-ui"></span>' +
            '                   <a  id="keditor-setting-closer" class="keditor-ui"><i class="fa fa-close"></i></a>' +
            '               </div>' +
            '           </div>' +
            '       </div>' +
            '   </div>' +
            '</div>'
        );




        self.initSidebarToggler();

        if (typeof options.snippetsUrl === 'string' && options.snippetsUrl.length > 0) {
            flog('Getting snippets form "' + options.snippetsUrl + '"...');


            //var _snippetsURL =  window.parent._AMS.defaults.templates.templates[window.parent._AMS.active.template].snippets,
              //  _rootTemplate= module.baseTemplate+"_ams/";

            //console.error("_snippetsURL", _snippetsURL)
            var _snippetsURL =self.options.snippets;

            var snippetArr = _snippetsURL//_snippetsURL.split(',');




            var promises = [],
                results="";




            for (var i = 0; i < snippetArr.length; i++){
               /* $.ajax returns a promise*/
               var request = $.ajax({
                    type: 'get',
                    dataType: 'html',
                    //url: snippet_href,
                    url: snippetArr[i]+ "?v=" + new Date().getTime(),
                    success: function(resp) {


                        results += resp;
                    },
                    error: function(jqXHR) {
                        console.error('Error when getting snippets', jqXHR);
                    }
                })

               promises.push(request);
            }

            $.when.apply(null, promises).done(function(){

               buildSnippets(results)
            })



            function buildSnippets(results){


                self.renderSnippets(results);

                self.initSnippets();
                self.initTabs();
                self.initTabsSwitcher();
                self.initSettingPanel();

                if (options.snippetsFilterEnabled) {
                    self.initSnippetsFilter('Container');
                    self.initSnippetsFilter('Component');
                }

                if (options.snippetsTooltipEnabled || options.tabTooltipEnabled) {
                    flog('Initialize Bootstrap tooltip plugin');
                    body.find('#keditor-snippets-list').find('[data-toggle="tooltip"]').tooltip();
                }

                if (typeof options.onReady === 'function') {
                    options.onReady.call(self);
                }

                self.checkContentArea($(self.body).find(".keditor-content-area"))

            }





            /*return $.ajax({
                type: 'get',
                dataType: 'html',
                //url: snippet_href,
                url: _rootTemplate+options.snippetsUrl+_snippetsURL+"?v="+window.parent._AMS.defaults.version,
                success: function(resp) {
                    flog('Success in getting snippets');
                    //console.log(resp)
                    var results = resp;

                    // load the collection snippet list
                    $.ajax({
                        type: 'get',
                        dataType: 'html',
                        //url: snippet_href,
                        url: _rootTemplate+"../../"+options.snippetsUrl+"snippets.html"+"?v="+window.parent._AMS.defaults.version,
                        success: function(resp) {
                                                        flog('Success in getting snippets');
                            var reference = resp;

                            // replace snippet with reference
                            results = self.referenceSnippets(results, reference);
                            loadGlobalSnippets();
                        },
                        error: function(e){
                            console.error("error", e)

                            loadGlobalSnippets();
                        }
                    })


                    function loadGlobalSnippets(){
                        // load the collection snippet list
                        $.ajax({
                            type: 'get',
                            dataType: 'html',
                            //url: snippet_href,
                            url: _rootTemplate+"../../../global_snippets/snippets.html"+"?v="+window.parent._AMS.defaults.version,
                            success: function(resp) {
                                                                flog('Success in getting snippets');
                                var reference = resp;

                                // replace snippet with reference
                                buildSnippets(self.referenceGlobalSnippets(results, reference));
                            },
                            error: function(e){
                                 console.error("error1", e)

                                buildSnippets(results);
                            }
                        })
                    }








                },
                error: function(jqXHR) {
                    flog('Error when getting snippets', jqXHR);
                }
            });*/
        } else {
            error('"snippetsUrl" must be not null!');
        }



    };

    // replace snippet with reference
    KEditor.prototype.referenceSnippets = function(results, reference) {

        var self = this;
        var options = self.options;
        var body = self.body;

        // get dom with vanilla JS so it shows no error (scope has to be in iframe!)
        var _dom = self.window.document.createElement("div"),
            _dom_reference = self.window.document.createElement("div");
        _dom.innerHTML = results.trim();
        _dom_reference.innerHTML = reference.trim();

        // iterate snippets
        $(_dom).find('> section').each(function(i) {
             var snippet = $(this),
                 _refAttr = snippet.attr('data-wps-snippet-reference');

             if(_refAttr){
                // find reference and replace
                var _ref = $(_dom_reference).find("[data-wps-name='"+_refAttr+"']");
                snippet.replaceWith(_ref);
             }
        })

        return _dom.innerHTML;
    }


    // replace snippet with reference
    KEditor.prototype.referenceGlobalSnippets = function(results, reference) {




        var self = this;
        var options = self.options;
        var body = self.body;

        // get dom with vanilla JS so it shows no error (scope has to be in iframe!)
        var _dom = self.window.document.createElement("div"),
            _dom_reference = self.window.document.createElement("div");
        _dom.innerHTML = results.trim();
        _dom_reference.innerHTML = reference.trim();



        // iterate snippets
        $(_dom).find('[data-wps-global-snippet-reference]').each(function(i) {
             var snippet = $(this),
                 _refAttr = snippet.attr('data-wps-global-snippet-reference');


             if(_refAttr){
                // find reference and replace
                var _ref = $(_dom_reference).find("[data-wps-name='"+_refAttr+"']");
                snippet.replaceWith(_ref.html());
             }
        })

        return _dom.innerHTML;
    }




    KEditor.prototype.initSnippetsFilter = function(type) {
        flog('initSnippetsFilter for ' + type);

        var self = this;
        var options = self.options;
        var body = self.body;
        var lowerCaseType = type.toLowerCase();
        var categories = self['snippets' + type + 'Categories'];

        var filterHtml = '';

        var hideClass='';



        if( (type=="Container" && !options.templateSearch) || (type=="Component" && !options.snippetSearch) ) hideClass+="hideSearch ";
        if( (type=="Container" && !options.templateFilter) || (type=="Component" && !options.snippetFilter) ) hideClass+="hideFilter ";


        filterHtml += '<div id="keditor-' + lowerCaseType + '-snippets-filter-wrapper" class="keditor-ui keditor-snippets-filter-wrapper">';
        filterHtml += '     <input type="text" id="keditor-' + lowerCaseType + '-snippets-search" class="keditor-ui keditor-snippets-search" value="" placeholder="'+self.labels.snippet_search+'..." />';

        if(!options.newSnippetList){
            filterHtml += '     <select id="keditor-' + lowerCaseType + '-snippets-filter" class="keditor-ui keditor-snippets-filter">';
            filterHtml += '         <option value="" selected="selected">'+self.labels.all_snippets+'</option>';
            for (var i = 0; i < categories.length; i++) {
                filterHtml += '     <option value="' + categories[i] + '">' + categories[i] + '</option>';
            }
            filterHtml += '     </select>';
        }

        if(categories.length==1){
            body.find("#snippet-menu").addClass('snippetSearch');
        }

        filterHtml += '</div>';

        var tab = body.find('#keditor-' + lowerCaseType + '-snippets-tab');

         tab.addClass(hideClass)
        var snippets = tab.find('.keditor-snippet');
        tab.prepend(filterHtml);

        snippets.each(function() {
            var snippet = $(this);
            var categories = snippet.attr('data-wps-categories') || '';
            var filterCategories = categories.toLowerCase();
            categories = categories.split(options.snippetsCategoriesSeparator);
            filterCategories = filterCategories.split(options.snippetsCategoriesSeparator);

            snippet.data('categories', categories);
            snippet.data('categoriesFilter', filterCategories);
        });

        var txtSearch = tab.find('.keditor-snippets-search');
        var cbbFilter = tab.find('.keditor-snippets-filter');

        var doFilter = function() {
            var selectedCategory = (cbbFilter.val() || '').toLowerCase();
            var searchText = (txtSearch.val() || '').toLowerCase();

            flog('Do filter with selected category is "' + selectedCategory + '" and search text is "' + searchText + '"');
            //console.log('Do filter with selected category is "' + selectedCategory + '" and search text is "' + searchText + '"');

            if (selectedCategory || searchText) {
                flog('Filtering snippets');
                body.find("#snippet-menu").addClass('snippetSearch');
                snippets = tab.find('.keditor-snippet');

                snippets.each(function() {
                    var snippet = $(this);
                    var dataCategories = snippet.data('categoriesFilter');
                    if(!dataCategories) return;
                    var dataCategoriesString = dataCategories.join(';');
                    var error = 0;

                    if (selectedCategory) {
                        if ($.inArray(selectedCategory, dataCategories) === -1) {
                            error++;
                        }
                    }

                    if (searchText) {
                        var title = snippet.attr('title').toLowerCase();
                        var tags = snippet.attr('data-wps-tags').toLowerCase() || "";
                        var name = snippet.attr('data-wps-name').toLowerCase() || "";

                        if ( (title.indexOf(searchText) === -1 && tags.indexOf(searchText) === -1 && name.indexOf(searchText) === -1) && dataCategoriesString.indexOf(searchText) === -1) {
                            error++;
                        }
                    }

                    snippet[error === 0 ? 'removeClass' : 'addClass']('not-matched');
                });


                //hide category where no snippets where found
                var _containers = tab.find('.snippetContainer');
                body.find("#sideMenu li").show();
                _containers.each(function() {
                    if($(this).children('.keditor-snippet').length === $(this).children('.keditor-snippet.not-matched').length){
                        body.find("#sideMenu li[data-menu-item='"+$(this).attr('id')+"']").hide();
                    }
                })
                // load Images
                body.find(".snippetContainer section:not(.not-matched) img:not([src])").each(function(index, el) {
                    $(el).attr("src", $(el).attr("data-src"))
                  });



            } else {
                flog('Show all snippets');
                body.find("#snippet-menu").removeClass('snippetSearch');
                body.find("#sideMenu li").show();
                snippets.removeClass('not-matched');
            }
        };

        cbbFilter.on('change', function() {
            doFilter();
        });

        var timer;
        txtSearch.on('keydown', function() {
            clearTimeout(timer);
            timer = setTimeout(function() {
                doFilter();
            }, 200);
        });
    };

    KEditor.prototype.toggleSidebar = function(showSidebar) {
        flog('toggleSidebar', showSidebar);

        var self = this;
        var options = self.options;
        var body = self.body;
        var icon = body.find('#keditor-sidebar-toggler i');

        if (showSidebar) {
            body.addClass('opened-keditor-sidebar');
            icon.attr('class', 'fa fa-chevron-right')
        } else {
            body.removeClass('opened-keditor-sidebar');
            icon.attr('class', 'fa fa-chevron-left')
        }

        if (typeof options.onSidebarToggled === 'function') {
            options.onSidebarToggled.call(self, showSidebar);
        }
    };

    KEditor.prototype.initSidebarToggler = function() {
        flog('initSidebarToggler');

        var self = this;
        var body = self.body;

        body.find('#keditor-sidebar-toggler').on('click', function(e) {
            e.preventDefault();

            self.toggleSidebar(!body.hasClass('opened-keditor-sidebar'));
        });
    };

    KEditor.prototype.renderSnippets = function(resp) {



        var self = this;
        var options = self.options;
        var body = self.body;

        var snippetsContainerHtml = '';
        var snippetsComponentHtml = '';
        var snippetsContentHtml = $('<span></span>');
        var snippetWhitelist = (self.groupSettings.editorSettings && self.groupSettings.editorSettings.snippetWhitelist) ? self.groupSettings.editorSettings.snippetWhitelist : [];
        var snippetsTemplate = [];
        //console.log("self.groupSettings", self.groupSettings);
        self.snippetsContainerCategories = [];
        self.snippetsComponentCategories = [];


        // change img and source tag so it not loads the files in the background
        var html = resp.trim().replace(/<img /g,"<img_wbmg ");
        html = html.replace(/<source/g,"<source_wbmg");
        html = html.replace(/poster="/g,'poster_wbmg="');
        // html = html.replace(/<video/g,"<video_wbmg");
         var _dom = self.window.document.createElement("div");
         _dom.innerHTML = html;

        // var newHTMLDocument = document.implementation.createHTMLDocument('preview');
        // var _dom = newHTMLDocument.createElement('div').innerHTML = resp.trim();



        console.log("newHTMLDocument", _dom)

        $(_dom).find('section.section:not(.section .section)').each(function(i) {
            //$('<section />').html(resp).find('> section').each(function(i) {

            // var newHTMLDocument = document.implementation.createHTMLDocument('preview');
            // var snippet = newHTMLDocument.createElement('div').innerHTML = this.innerHTML;
            //snippet= $(snippet);



            var snippet = $(this);
            var version,
                name;
            var content = snippet.html().trim();

            var show = snippet.attr('data-show');






            var previewUrl = self.options.previewUrl+"1/"+snippet.attr('data-wps-preview');
            previewUrl = self.absolutePath(previewUrl); // change to absolute path so it works in iframe
            //previewUrl = "https://webmag.dev"+previewUrl;



            var type = snippet.attr('data-wps-type');
            var version = snippet.attr('data-wps-version');
            var name = snippet.attr('data-wps-name');
            var isContainer = (snippet.attr('data-wps-container')) ? "data-wps-container='true'" : "";
            var title = snippet.attr('data-wps-title_'+KEditor.language) || "";
            var style = snippet.attr('class');
            var style = snippet.attr('data-style');
            var tags = snippet.attr('data-wps-tags') || "";
            var _class = snippet.attr('class');
            var _classID =""// snippet.attr('data-wps-name');

            // if there is a whitelist, delete snippet that are not in the whitelist
            if(snippetWhitelist.length>0){
                if($.inArray(snippet.attr('data-wps-name'), snippetWhitelist) == -1){
                    snippet.remove();
                    return;
                }
            }

            //console.log(_class)
            //console.log(_classID)

            var snippetHtml = '';
            var categories = snippet.attr('data-wps-categories') || '';

            //console.error("NEW CHANGE: make cloudimage from URL")
            //previewUrl = self.makeCloudImage(previewUrl,300,300);

            previewUrl = self.cloudImageFunction(previewUrl, { width: 325, height: 400}, { width: 700, height: 700 }, 80, true);


            snippetHtml += '';
            snippetHtml += '<section class="keditor-ui keditor-snippet '+_class+" "+_classID+'" data-wps-tags="' + tags + '" data-snippet="#keditor-snippet-' + i + '" data-style="' + style + '" ' + '" data-wps-version="' + version + '" ' + isContainer + ' " data-wps-type="' + type + '" ' + (options.snippetsTooltipEnabled ? 'data-toggle="tooltip" data-placement="' + options.snippetsTooltipPosition + '"' : '') + ' title="' + title + '" data-wps-categories="' + categories + '">';
            snippetHtml += '<span>' + title + '</span><img class="keditor-ui keditor-snippet-preview" src="' + previewUrl + '" />';
            snippetHtml += '</section>';

            categories = categories.split(options.snippetsCategoriesSeparator);

            if (type === 'container') {

                snippetsContainerHtml += snippetHtml;
                self.snippetsContainerCategories = self.snippetsContainerCategories.concat(categories);
            } else if (type.indexOf('component') !== -1) {

                // dont show snippets with data-show="ignore"
                // but save them, so they can be updateable
                if (show != "ignore") {
                    snippetsComponentHtml += snippetHtml;
                    self.snippetsComponentCategories = self.snippetsComponentCategories.concat(categories);
                }

                version = snippet.attr('data-wps-version');
                name = snippet.attr('data-wps-name');
                style = snippet[0].classList;



                //compile snippet with handlebars and add it
                var compiledSnippet = self.compileHandlebars(snippet[0].innerHTML);





                // so no image errors in console (scope in iframe window)
                var _dom = self.window.document.createElement("div");
                _dom.innerHTML = compiledSnippet.trim();
                compiledSnippet = _dom.children[0];

                snippetsTemplate.push({
                    "name": name,
                    "version": version,
                    "element": compiledSnippet
                })
            }



            // add custom editor default classes from editorsettings
            var $html = $('<div />',{html:content});
            $.each($html.find("[data-wps-custom], [data-wps-custom2], [data-wps-custom3]"), function (index, el) {
                _custom = $(el).attr('data-wps-custom') || $(el).attr('data-wps-custom2') || $(el).attr('data-wps-custom3') ||""; // media, video, ...
                var _customEditorSettings = self.groupSettings?.editorSettings?.custom || false;
                if(_custom && _customEditorSettings){
                    if(_customEditorSettings[_custom]){
                        if(_customEditorSettings[_custom].default){
                            $(el).addClass(_customEditorSettings[_custom].default)
                        }
                    }
                }
            })

            var dataAttributes = self.getDataAttributes(snippet, ['data-wps-preview', 'data-wps-type', 'data-wps-categories'], true);

           // console.log("dataAttributes", dataAttributes);

            if (show != "ignore") {
                var $section = $('<script />'); // Replace with your div's ID or another selector

                // Iterate over the object and add each attribute to the div
                $.each(dataAttributes, function(key, value) {
                    $section.attr(key, value);
                });
                $section.addClass(style);
                // add data-wps-type
                $section.attr('type', "text/html");
                $section.attr('id', 'keditor-snippet-' + i );
                // add html
                $section.html($html[0].outerHTML);
                snippetsContentHtml.append( $section);
                //console.log($html[0].outerHTML);
                //snippetsContentHtml += $section;

                //snippetsContentHtml += '<script class="'+style+'" id="keditor-snippet-' + i + '" type="text/html" ' + dataAttributes.join(' ') + '>' + $html[0].outerHTML + '</script>';
            }




        });


        if(self.options.newSnippetList){
          snippetsComponentHtml2 = "";

          snippetsComponentHtml2 +=
          '<div id="snippet-menu" class="SFG hidden">'+
              '<div id="sideMenu"  class="ui-droppable">'+
                  '<ul class="sideMenu" data-simplebar >';

          var categories = self.beautifyCategories(self.snippetsComponentCategories);
          var _icon="",
            _style="";
          for (var i = 0; i < categories.length; i++) {


            var result = $.grep(self.options.sortingArray, function(e){ return e.value == categories[i]; });

            if (result.length === 1) {
              // property found, access the foo property using result[0].foo
                _icon = result[0].icon;
                if(result[0].style){
                  _style = "border-primary border-1";
                }else{
                  _style="";
                }
            }
            var labelCategory = categories[i].toLowerCase().replace(/\s/g, '_');
            labelCategory = (self.labels.categories[labelCategory]) ? self.labels.categories[labelCategory] : categories[i];

            //self.labels[

            snippetsComponentHtml2 += '<li data-menu-item="'+categories[i]+'" class="'+_style+'"><i class="fa '+_icon+'" aria-hidden="true"></i><span>'+labelCategory+'</span></li>';
          }
          var _snippets = $(_dom).find('section.section:not(.section .section)');
          snippetsComponentHtml2 +=
                  '</ul>'+
              '<div id="subMenu" class="generator-nano has-scrollbar hidden hide">'+
                  '<div data-simplebar >';
                        for (var i = 0; i < categories.length; i++) {
                          snippetsComponentHtml2 += '<ul class="" data-id="'+categories[i]+'" id="'+categories[i]+'">';

                          snippetsComponentHtml2 += '</ul>';
                        }


                        snippetsComponentHtml2 += '<div class="snippetContainer">';
                        _snippets.each(function(a) {
                              var snippet = $(this);


                              var _cat = snippet.attr('data-wps-categories') || '';
                              _cat = _cat.split(options.snippetsCategoriesSeparator);
                              var _found = (_cat.indexOf(categories[i]) > -1);
                              if(_found || true){
                                  var version,
                                      name;
                                  var content = snippet.html().trim();
                                  var show = snippet.attr('data-show') || "";
                                  var version = snippet.attr('data-wps-version') || "";
                                  var type = snippet.attr('data-wps-type') || "";
                                  var previewUrl = self.options.previewUrl+"1/"+snippet.attr('data-wps-preview');
                                  previewUrl = self.absolutePath(previewUrl); // change to absolute path so it works in iframe
                                  //previewUrl = "https://webmag.dev"+previewUrl;
                                  //console.error("NEW CHANGE: make cloudimage from URL")
                                  previewUrl = self.cloudImageFunction(previewUrl, { width: 325, height: 400}, { width: 700, height: 700 }, 80, true);
                                  var title = snippet.attr('data-wps-title_'+KEditor.language) || "";
                                  var name = (snippet.attr('data-wps-name')) ? snippet.attr('data-wps-name').split('-')[1] : "";
                                  var isContainer = (snippet.attr('data-wps-container')) ? "data-wps-container='true'" : "";
                                  var _categories = snippet.attr('data-wps-categories') || '';
                                  var style = snippet.attr('data-style') || "";
                                  var _class = snippet.attr('class') || "";
                                  var tags = snippet.attr('data-wps-tags') || "";
                                  snippetsComponentHtml2 += '<section class="keditor-ui keditor-snippet '+_class+'" data-wps-name="' + name + '" data-wps-tags="' + tags + '" data-snippet="#keditor-snippet-' + a + '" data-style="' + style + '" ' + '" data-wps-version="' + version + '" ' +isContainer+ ' " data-wps-type="' + type + '" ' + (options.snippetsTooltipEnabled ? 'data-toggle="tooltip" data-placement="' + options.snippetsTooltipPosition + '"' : '') + ' title="' + title + '" data-wps-categories="' + _categories + '">';
                                  snippetsComponentHtml2 += '<img class="keditor-ui keditor-snippet-preview" data-src="' + previewUrl + '" /><span class="snippet_title">' + title + '</span><span class="snippet_name">(' +name+ ')</span>';
                                  snippetsComponentHtml2 += '</section>';
                                  /*snippetsComponentHtml2 +=
                                      '<li>'+
                                          '<span>'+title+'</span>'+
                                          '<img  src="'+previewUrl+'">'+
                                      '</li>';*/
                              }

                        })
                        snippetsComponentHtml2 += '</div>';




          snippetsComponentHtml2 +=
                  '</div>'+
              '</div>'+
          '</div>';
        }









        self.snippetsTemplate = snippetsTemplate;

        self.snippetsContainerCategories = self.beautifyCategories(self.snippetsContainerCategories);
        self.snippetsComponentCategories = self.beautifyCategories(self.snippetsComponentCategories);

        /**/

        console.log("self", self);

        if(self.options.newSnippetList){

            body.find('#keditor-snippets-list').html("").addClass('loaded-snippets');



            var _content = '<ul id="keditor-snippets-type-switcher" class="keditor-ui keditor-tabs">',
            _active = "";

            if( (self.privileges.includes('can_edit_page_layouts') && !self.privileges.includes('can_edit_page_snippets')) ) _active ="active";

            if(self.privileges.includes('can_edit_page_snippets') || self.options.isSysAdmin){
                _content+='    <li class="keditor-ui keditor-tab active keditor-component-snippets-tab"><a class="keditor-ui" href="#keditor-component-snippets-tab"' + (options.tabTooltipEnabled ? 'data-toggle="tooltip" data-placement="bottom"' : '') + ' title="' + self.labels.snippets_tab + '">' + self.labels.snippets_tab + '</a></li>';
            }
            var _hideLayout = (self.groupSettings.editorSettings && self.groupSettings.editorSettings.hideLayout) ? self.groupSettings.editorSettings.hideLayout : false;
            if( (self.privileges.includes('can_edit_page_layouts') && !_hideLayout) || self.options.isSysAdmin){
                _content+='    <li class="keditor-ui keditor-tab '+_active+' keditor-container-snippets-tab"><a class="keditor-ui" href="#keditor-container-snippets-tab"' + (options.tabTooltipEnabled ? 'data-toggle="tooltip" data-placement="bottom"' : '') + ' title="' + self.labels.layout_tab + '">' + self.labels.layout_tab + '</a></li>';
            }
            _content+='</ul><div id="keditor-snippets-container" class="keditor-ui keditor-tabs-content">';

            if(self.privileges.includes('can_edit_page_layouts') || self.options.isSysAdmin){
                _content+='   <div class="keditor-ui keditor-tab-content '+_active+' " id="keditor-container-snippets-tab"><div class="keditor-ui keditor-tab-content-inner" data-simplebar >' + snippetsContainerHtml + '</div></div>';
            }

            if(self.privileges.includes('can_edit_page_snippets') || self.options.isSysAdmin){
                _content+='   <div class="keditor-ui keditor-tab-content active keditor-component-snippets-tab" id="keditor-component-snippets-tab"><div class="keditor-ui keditor-tab-content-inner">' + snippetsComponentHtml2 + '</div></div>';

            }


            _content+='</div>';


            body.find('#keditor-snippets-list').append(_content);



            self.addSnippetListerners()
        }








        if (snippetsContainerHtml == '') {
            console.warn("delete1")
            self.options.snippetsContainer = false;
            body.find('#keditor-container-snippets-tab, .keditor-container-snippets-tab').remove()
            body.find('#keditor-component-snippets-tab, .keditor-component-snippets-tab').addClass('active');
        }else{
            self.options.snippetsContainer = true;
        }

        //layout
        if (snippetsComponentHtml == '') {
            console.warn("delete2")
            self.options.snippetsComponent = false;
            body.find('#keditor-component-snippets-tab, .keditor-component-snippets-tab').remove()
            body.find('#keditor-container-snippets-tab, .keditor-container-snippets-tab').addClass('active');
        }else{
             self.options.snippetsComponent = true;
        }

        if (snippetsContainerHtml == '' && snippetsComponentHtml == '') {
            self.body.removeClass("opened-keditor-sidebar");
            self.body.addClass("hide-keditor-sidebar");
        }else{
            self.body.removeClass("hide-keditor-sidebar");
        }

        body.find('#keditor-snippets-content').html(snippetsContentHtml);

        body.find('[data-simplebar]').each(function( index, element ) {
          new SimpleBar(element);
        })
    };


    KEditor.prototype.addSnippetListerners = function(categories) {
                var _timeout1;
                var _timeout2;
                var self = this;
                var body = self.body;

              body.find("#sideMenu .sideMenu li").mouseenter(function(){
                    var that = this;
                      clearTimeout(_timeout1);
                    _timeout1 = setTimeout(function() {
                        //your code here
                        body.find("#subMenu").addClass('hide');
                         body.find("#sideMenu .sideMenu li").removeClass("selected");

                        var _attr = $(that).attr('data-menu-item');



                         _timeout2 = setTimeout(function() {
                            doFilter(_attr);
                            body.find("#subMenu ul").removeClass('visible')
                            body.find(that).addClass('selected');
                            body.find("#subMenu").removeClass('hidden');
                            body.find("#subMenu").removeClass('hide');
                            body.find("#subMenu ul[data-id='"+_attr+"']").addClass('visible');

                            // load Images
                            body.find(".snippetContainer section:not(.not-matched) img:not([src])").each(function(index, el) {
                              $(el).attr("src", $(el).attr("data-src"))
                            });

                        }, 150);

                    }, 150);

              });
              body.find("#sideMenu ").mouseleave(function(){
                  //your code here
                    clearTimeout(_timeout1);
                    clearTimeout(_timeout2);
                    body.find("#sideMenu .sideMenu li").removeClass("selected");
                    body.find("#subMenu ul").removeClass('visible')
                    body.find("#subMenu").addClass('hide')

                    setTimeout(function() {
                        body.find("#subMenu").addClass('hidden')
                    }, 300);
              });

              body.find("#subMenu").mouseenter(function(){
                  //your code here
                  clearTimeout(_timeout1);
                  clearTimeout(_timeout2);

              });


              // filter snippets
              var doFilter = function(searchText) {

                    var searchText = searchText.toLowerCase();

                  //console.log('Do filter with search text is "' + searchText + '"');

                  if (searchText) {
                      snippets = body.find(".snippetContainer .keditor-snippet");



                      snippets.each(function() {
                          var snippet = $(this);
                          var dataCategories = snippet.data('categoriesFilter');
                          if(!dataCategories) return;
                          var dataCategoriesString = dataCategories.join(';');
                          var error = 0;


                          if (searchText) {
                              var title = snippet.attr('title').toLowerCase();
                              var tags = snippet.attr('data-wps-tags').toLowerCase() || "";
                              var name = snippet.attr('data-wps-name').toLowerCase() || "";
                              if ( dataCategoriesString.indexOf(searchText) === -1) {
                                  error++;
                              }

                          }

                          snippet[error === 0 ? 'removeClass' : 'addClass']('not-matched');
                      });

                  } else {
                      flog('Show all snippets');
                      snippets.removeClass('not-matched');
                  }
              };
    }






    KEditor.prototype.beautifyCategories = function(categories) {
        var newArray = [];
        for (var i = 0; i < categories.length; i++) {
            var category = categories[i] || '';

            if (category !== '' && $.inArray(category, newArray) === -1) {
                newArray.push(category);
            }
        }



        var sortingArray = ['Footers','Others','','Navigations & Indexes','Modals','Forms','Sharing','Team & Signatures','Tables & Charts', 'Quotes & Testimonials','Call to actions', 'Features & Lists','Cards & Boxes','Videos','Images & Galleries','Titles & Texts','Headers','Covers','Layouts','All','Webmag','Haufe Akademie','Telefonica','Tennet','Universal','Fokus Layout','CNHI','CNHI - Case IH','CNHI - New Holland','CNHI - Steyr'];


        sortingArray = this.options.sortingArray;

        var index_a,
            index_b;

        newArray.sort(function(a, b) {

            index_a = sortingArray.map(function(x) {return x.value }).indexOf(a);
            index_b = sortingArray.map(function(x) {return x.value }).indexOf(b);


            return index_a - index_b;


          //return sortingArray.indexOf(a) - sortingArray.indexOf(b) ;
        });



        return newArray.reverse();
    };

    KEditor.prototype.getConnectedForContainerSnippets = function() {
        var self = this;
        var options = self.options;
        var body = self.body;

        var selector = '.keditor-content-area';
        if (options.nestedContainerEnabled) {
            selector += ',.keditor-container-content:not(.keditor-sub-container-content)';
        }




        return body.find(selector);
    };

    KEditor.prototype.initSnippets = function() {



        var self = this;
        var options = self.options;
        var body = self.body;
        var snippetsList = self.body.find('#keditor-snippets-list');
        var containerSnippets = self.containerSnippets = snippetsList.find('.keditor-snippet[data-wps-type=container]');
        var componentSnippets = self.componentSnippets = snippetsList.find('.keditor-snippet[data-wps-type^=component]');






        self.getContent().then(function(content) {
            // so no image errors in console (scope in iframe window)
            var _dom = self.window.document.createElement("div");
            _dom.innerHTML = content.trim();


            var _style = $(_dom).find("div[class^='mgz_'],div[class*=' mgz_']").addBack("div[class^='mgz_'],div[class*=' mgz_']"),
                _styleClass="";

            containerSnippets.off('click').on('click', function(e) {

                // console.error("Style change")

                if ($(this).hasClass("active")) return;


                $(this.parentNode).find("section").removeClass("active");
                $(this).addClass("active")

                var style = $(this).attr("data-style");


                self.setContentTemplate(style);

                //self.save();
            })




            if(self.options.newSnippetList){
                var _snippetContainers = snippetsList.find(".snippetContainer")

                _snippetContainers.each(function(index, el) {
                    var _scrollable = ($(self.iframeBody).find(".scrollable").length>0) ? $(self.iframeBody).find(".scrollable")[0] : true;


                    //SnippetList sortable

                    var _connectWith = $(self.iframeBody).find("#sortableSnippets");

                    //console.log("_connectWith", _connectWith)

                    /* $(el).sortable({
                        appendTo: self.iframeBody,
                        placeholder: "drag_ghost",
                        connectWith: "#sortableSnippets",
                        helper: "clone",
                        items: 'section.keditor-snippet',
                        scroll: true,
                        //axis: "y",
                        //scrollSensitivity: 300,
                        //scrollSpeed: 50,
                        forcePlaceholderSize:true,
                        forceHelperSize:true,
                        sort: function(event, ui) {

                        }
                    });*/

                    var sort = Sortable.create(el, {
                        group: {
                            name: 'componentSnippets',
                            revertClone: true,
                            pull: 'clone'
                        },
                        ghostClass: 'drag_chosen',
                        sort: false,
                        //forceFallback: true,
                        scroll: _scrollable, // or HTMLElement
                        scrollSensitivity: 300, // px, how near the mouse must be to an edge to start scrolling.
                        scrollSpeed: 50, // px
                        ////forceFallback: true,
                        //delay: 100,
                        //animation: 150,
                        animation:0,
                        //handle: '.btn-component-reposition', // Restricts sort start click/touch to the specified element
                        draggable: 'section.keditor-snippet', // Specifies which items inside the element should be sortable
                        // Called by any change to the list (add / update / remove)
                        onSort: function(evt) {
                            // same properties as onEnd
                            evt.preventDefault();
                            evt.stopPropagation();
                            //fix for insert dragged text on dropping hover other snippet
                            setTimeout(function() {
                                body.find(".fr-element").attr('contenteditable', 'true').css('pointerEvents', 'all');
                            }, 200);
                            var itemEl = evt.item;

                        },
                        // Element is dropped into the list from another list (receive)
                        onAdd: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();


                        },
                        onMove: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();
                            // console.log("onMove2", evt)
                            // dont drop container in container
                            if($(evt.dragged).attr("data-wps-container")=="true" && $(evt.to).hasClass("keditor-sub-container-content")) return false;
                        },
                        // Element dragging started
                        onStart: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();

                            $("#subMenu").addClass('hide')

                            setTimeout(function() {
                                $("#subMenu").addClass('hidden')
                            }, 300);
                            //console.log("start2", evt)

                            body.find(".fr-element").attr('contenteditable', 'false').css('pointerEvents', 'none');
                            //body.find("[data-wps-edit]").attr("contentEditable", false) //set to false, or it will try to insert it as plain text in hover snippet
                            body.find('[contenteditable]').blur();
                            //body.find("[data-wps-edit]").remove();
                            body.find('.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                            body.addClass('highlighted-container-content');
                        },
                        // Element dragging ended
                        onEnd: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();
                            //fix for insert dragged text on dropping hover other snippet
                            setTimeout(function() {
                                body.find(".fr-element").attr('contenteditable', 'true').css('pointerEvents', 'all');
                            }, 200);

                            body.removeClass('highlighted-container-content');

                            //self.save();
                        },
                    });


                });
            }else{
            //needed?
                if (componentSnippets[0]) {

                    return;
                    //console.log("componentSnippets",componentSnippets);



                    var _scrollable = ($(self.iframeBody).find(".scrollable").length>0) ? $(self.iframeBody).find(".scrollable")[0] : true;
                    var _connectWith = $(self.iframeBody).find("#sortableSnippets");

                    console.log("_connectWith", _connectWith)

                    /*$(componentSnippets[0]).sortable({
                        appendTo: "parent",
                        placeholder: "drag_ghost",
                        connectWith: _connectWith[0],
                        helper: "clone",
                        items: 'section.keditor-snippet',
                        scroll: true,
                        axis: "y",
                        scrollSensitivity: 300,
                        scrollSpeed: 50,
                        forcePlaceholderSize:true,
                        forceHelperSize:true,
                        sort: function(event, ui) {

                        }
                    });*/

                    //not used?

                    console.log("componentSnippets[0].parentNode",componentSnippets[0].parentNode)

                    var sort = Sortable.create(componentSnippets[0].parentNode, {
                        group: {
                            name: 'componentSnippets',
                            revertClone: true,
                            pull: 'clone'
                        },
                        ghostClass: 'drag_chosen',
                        sort: false,
                        //forceFallback: true,
                        scroll: _scrollable, // or HTMLElement
                        scrollSensitivity: 300, // px, how near the mouse must be to an edge to start scrolling.
                        scrollSpeed: 50, // px
                        //delay: 100,
                        animation: 150,
                        //handle: '.btn-component-reposition', // Restricts sort start click/touch to the specified element
                        draggable: 'section.keditor-snippet', // Specifies which items inside the element should be sortable
                        // Called by any change to the list (add / update / remove)
                        onSort: function(evt) {
                            // same properties as onEnd
                            evt.preventDefault();
                            evt.stopPropagation();
                            //fix for insert dragged text on dropping hover other snippet
                            setTimeout(function() {
                                body.find(".fr-element").attr('contenteditable', 'true').css('pointerEvents', 'all');
                            }, 200);
                            var itemEl = evt.item;

                        },
                        // Element is dropped into the list from another list (receive)
                        onAdd: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();


                        },
                        onMove: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();
                        // console.log("onMove2", evt)

                            //console.log(evt.to)


                        },
                        // Element dragging started
                        onStart: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();
                        //console.log("start2", evt)

                        body.find(".fr-element").attr('contenteditable', 'false').css('pointerEvents', 'none');
                            //body.find("[data-wps-edit]").attr("contentEditable", false) //set to false, or it will try to insert it as plain text in hover snippet
                            body.find('[contenteditable]').blur();
                            //body.find("[data-wps-edit]").remove();
                            body.find('.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                            body.addClass('highlighted-container-content');
                        },
                        // Element dragging ended
                        onEnd: function(  evt) {
                            evt.preventDefault();
                            evt.stopPropagation();
                            //fix for insert dragged text on dropping hover other snippet
                            setTimeout(function() {
                                body.find(".fr-element").attr('contenteditable', 'true').css('pointerEvents', 'all');
                            }, 200);

                            body.removeClass('highlighted-container-content');
                        },
                    });






                }
            }


            // set first template style on load
            if (self.options.snippetsContainer && _style[0]) {
                for (var i = 0; i < _style[0].classList.length; i++) {
                    if (_style[0].classList[i].indexOf('mgz_') == 0){
                        if(_styleClass!=""){
                            _styleClass +=" "+_style[0].classList[i];
                        }else{
                        _styleClass +=_style[0].classList[i];
                        }
                    }
                }

                //set active thumbnail
                containerSnippets.find("section").addBack("section").removeClass("active");
                containerSnippets.find("section[data-style='" + _styleClass + "']").addBack("section[data-style='" + _styleClass + "']").addClass("active");
            }
        })


    };

    KEditor.prototype.setContentTemplate = function(style) {
        var self = this;
        self.getContent().then(function(content) {
            content = self.options.template.update(content, style, self);
            // add section wrapper
            content = $(content).wrap("<section></section>").parent();
            self.setContent(content[0].outerHTML);
            if(typeof self.options.template.afterUpdate == 'function') self.options.template.afterUpdate(self);
        })
    }

    KEditor.prototype.initTabs = function() {
        flog('initTabs');

        var self = this;
        var body = self.body;
        var options = self.options;
        var switcherWrapper = body.find('#keditor-snippets-type-switcher');
        var tabPaneWrapper = body.find('#keditor-snippets-container');

        if (options.extraTabs && $.isPlainObject(options.extraTabs)) {
            flog('Add extra tabs', options.extraTabs);

            for (var tabName in options.extraTabs) {
                var tabData = options.extraTabs[tabName];

                switcherWrapper.append('<li class="keditor-ui keditor-tab"><a class="keditor-ui" href="#keditor-extra-tab-' + tabName + '"' + (options.tabTooltipEnabled ? 'data-toggle="tooltip" data-placement="bottom" title="' + tabData.title + '"' : '') + '>' + tabData.text + '</a></li>');
                tabPaneWrapper.append('<div class="keditor-ui keditor-tab-content" id="keditor-extra-tab-' + tabName + '"><div class="keditor-tab-content-inner">' + tabData.content + '</div></div>');
            }
        }

       // self.initNiceScroll(tabPaneWrapper.find('.keditor-tab-content-inner'));
    };

    KEditor.prototype.initTabsSwitcher = function() {
        flog('initTabsSwitcher');

        var self = this;
        var body = self.body;
        var switcherLis = body.find('#keditor-snippets-type-switcher li');
        var tabContent = body.find('#keditor-snippets-container .keditor-tab-content');

        switcherLis.find('a').on('click', function(e) {
            e.preventDefault();

            var a = $(this);
            var li = a.parent();
            var href = a.attr('href');

            if (!li.hasClass('active')) {
                var activatedLi = switcherLis.filter('.active');
                var activatedPane = tabContent.filter('.active');
                var targetDiv = body.find(href);

                activatedLi.removeClass('active');
                activatedPane.removeClass('active');

                li.addClass('active');
                targetDiv.addClass('active');

                if ($.fn.niceScroll && self.options.niceScrollEnabled) {
                    activatedPane.getNiceScroll().hide();

                    var targetNiceScroll = targetDiv.getNiceScroll();
                    targetNiceScroll.show();
                    targetNiceScroll.resize();
                }
            }
        });
    };

    KEditor.prototype.initSettingPanel = function() {
        flog('initSettingPanel');

        var self = this;
        var options = self.options;
        var body = self.body;

        body.find('#keditor-setting-closer').on('click', function(e) {
            e.preventDefault();
            //delete iconpicker modal
            //console.log(body.find('.iconpicker-popover'));
            body.find('.iconpicker-popover').remove();
            self.hideSettingPanel();
        });

        // click on sidebar overlay closes settings sidebar
        body.find('#keditor-sidebar-overlay').on('click', function(e) {

            body.find('#keditor-setting-closer').trigger('click');

        });

        var settingForms = body.find('#keditor-setting-forms');
        self.initNiceScroll(settingForms);
        settingForms.on('submit', 'form', function(e) {
            e.preventDefault();
            return false;
        });

        if (options.containerSettingEnabled === true) {
            // if (typeof options.containerSettingInitFunction === 'function') {

            //     var form = $('<div id="keditor-container-setting" class="keditor-ui keditor-setting-form clearfix"></div>');
            //     settingForms.append(form);

            //     flog('Initialize container setting panel');
            //     options.containerSettingInitFunction.call(self, form, self);
            // } else {
            //     error('"containerSettingInitFunction" is not function!');
            // }
        }
    };

    KEditor.prototype.setSettingContainer = function(container) {
        flog('setSettingContainer', container);

        var self = this;
        var body = self.body;

        if (container) {
            var idSettingContainer = container.attr('id');
            body.attr('data-setting-container', idSettingContainer);
        } else {
            body.removeAttr('data-setting-container');
        }
    };

    KEditor.prototype.getSettingContainer = function() {
        flog('getSettingContainer');

        var self = this;
        var body = self.body;
        var idSettingContainer = body.attr('data-setting-container');

        return body.find('#' + idSettingContainer);
    };

    KEditor.prototype.setSettingComponent = function(component) {
        flog('setSettingComponent', component);

        var self = this;
        var body = self.body;

        if (component) {
            var idSettingComponent = component.attr('id');
            body.attr('data-setting-component', idSettingComponent);
        } else {
            body.removeAttr('data-setting-component');
        }
    };

    KEditor.prototype.getSettingComponent = function() {
        flog('getSettingComponent');

        var self = this;
        var body = self.body;
        var idSettingComponent = body.attr('data-setting-component');

        return body.find('#' + idSettingComponent);
    };

    KEditor.prototype.showSettingPanel = function(target,event) {
        //console.log('showSettingPanel', target);
       // console.log("event",event)



        var self = this;
        var options = self.options;
        var body = self.body;
        var isComponent = target.is('.keditor-component');

        self.clickedSnippetTop = target.offset().top;

        var activeForm = body.find('#keditor-setting-forms').children('.active');
        activeForm.removeClass('active');

        if (isComponent) {
            self.setSettingComponent(target);
            self.setSettingContainer(null);

            var componentType = self.getComponentType(target);
            var componentData;
            if (componentType != "blank") {
                componentData = KEditor.components["text"];
            } else {
                componentData = KEditor.components[componentType];
            }
            //componentType = "component-text";
            //var componentData = KEditor.components[componentType];

            var settingTitle = target.attr("data-wps-title_"+"de") || "";
            var settingName = target.attr("data-wps-name").split('-')[1] || "";
            body.find('#keditor-setting-title').html(settingTitle+" <span class='snippet_name'>("+settingName+")</span>");

            var settingForms = body.find('#keditor-setting-forms');
            var settingForm = body.find('#keditor-setting-' + settingName);

            if (settingForm.length === 0 || true) {

                if (typeof componentData.initSettingForm === 'function') {
                    settingForm = $('<div id="keditor-setting-' + settingName + '" data-wps-type="' + componentType + '" class="keditor-ui keditor-setting-form clearfix active"></div>');
                    var loadingText = $('<span />').html('Loading...');
                    settingForms.append(settingForm);
                    settingForm.append(loadingText);

                    flog('Initializing setting form for component type "' + componentType + '"');

                    var initFunction = componentData.initSettingForm.call(componentData, target, settingForm, self); // added target
                    $.when(initFunction).done(function() {
                        flog('Initialized setting form for component type "' + componentType + '"');

                        setTimeout(function() {
                            loadingText.remove();

                            if (typeof componentData.showSettingForm === 'function') {
                                flog('Show setting form of component type "' + componentType + '"');
                                componentData.showSettingForm.call(componentData, settingForm, target, self);
                            } else {
                                flog('"showSettingForm" function of component type "' + componentType + '" does not exist');
                            }
                        }, 100);
                    });
                } else {
                    flog('"initSettingForm" function of component type "' + componentType + '" does not exist');
                }
            } else {
                if (typeof componentData.showSettingForm === 'function') {
                    flog('Show setting form of component type "' + componentType + '"');
                    componentData.showSettingForm.call(componentData, settingForm, target, self);
                } else {
                    flog('"showSettingForm" function of component type "' + componentType + '" does not exist');
                }
                settingForm.addClass('active');
            }
        } else {
            self.setSettingContainer(target);
            self.setSettingComponent(null);
            //console.log("KEditor", KEditor);
            var componentData = KEditor.components["text"];

            body.find('#keditor-setting-title').html("Container Settings");

            var settingForms = body.find('#keditor-setting-forms');
           // var settingName = target.attr("data-wps-name").split('-')[1] || "";
            settingForm = $('<div id="keditor-container-settings' + '" class="keditor-ui keditor-setting-form clearfix active"></div>');
            settingForms.append(settingForm);


            var initFunction = componentData.initContainerForm.call(componentData, target, settingForm, self); // added target
            $.when(initFunction).done(function() {
                console.log('Initialized setting form for container');
            })

            // if (typeof options.containerSettingShowFunction === 'function') {
            //     flog('Show setting form of container');
            //     options.containerSettingShowFunction.call(self, settingForm, target, self);
            // } else {
            //     flog('"containerSettingShowFunction" does not exist');
            // }
            //settingForm.addClass('active');
        }

        self.toggleSidebar(true);
        self.toggleSettings(true, target);
        //
    };


    KEditor.prototype.toggleSettings= function(showSettings, target) {

        var self = this;
        var options = self.options;
        var body = self.body;
        //KEditor.scrollTarget = target;


        setTimeout(function () {
          var _a = self.iframeBody.width(),
            _b = body.find("#keditor-sidebar-settings").width();
            //_c = body.find("#keditor-snippets-list").width();

          var scrollWidth= getScrollbarWidth();

          //scrollbar fix
          _a = _a-(2*scrollWidth);




          var _scale = (((_a -_b)*100)/_a)/100;



           _scrollTop = (self.clickedSnippetTop/100)*(_scale*100);



          if (showSettings) {
              body.addClass('opened-keditor-setting');
              //self.iframeBody.closest("html").css('cursor', 'pointer');
              self.iframeBody.find(".keditor-ui.keditor-content-areas-wrapper").css({
                //   "transform": 'scale('+_scale+')',
                //   "-webkit-transform": 'scale('+_scale+')',
                //   "transform-origin": 'left top',
                  "height": '100%',
                  "pointer-events": "none",
                  "cursor": "pointer",
              });

              //self.iframeBody.scrollTop(_scrollTop)

              setTimeout(function() {
                   //self.iframeDoc.on('click.mynamespace', function(e) { e.preventDefault();self.toggleSettings(false); });

                   /*self.iframeBody.animate({
                      scrollTop: _scrollTop
                   })*/

              }, 210);






          } else {


              body.removeClass('opened-keditor-setting');
              if(!self.options.snippetsContainer && !self.options.snippetsComponent) body.removeClass('opened-keditor-sidebar');
              self.iframeBody.closest("html").css('cursor', 'auto');
              self.iframeBody.find(".keditor-ui.keditor-content-areas-wrapper").css({
                //   "transform": 'none',
                //   "-webkit-transform": 'none',
                //   "transform-origin": 'left top',
                  "height": '100%',
                  "pointer-events": "all",
                   "cursor": "auto",
              });
              self.iframeDoc.off('click.mynamespace');

              //self.iframeBody.scrollTop(self.clickedSnippetTop)


          }
        }, 10);





    };

    KEditor.prototype.hideSettingPanel = function() {
        console.log('hideSettingPanel');


        var self = this;
        var options = self.options;
        var body = self.body;

        self.toggleSettings(false);

        var activeForm = body.find('#keditor-setting-forms').children('.active');
        if (activeForm.length > 0) {
            if (activeForm.is('#keditor-container-setting')) {
                if (typeof options.containerSettingHideFunction === 'function') {
                    flog('Hide setting form of container');
                    options.containerSettingHideFunction.call(self, activeForm, self);
                } else {
                    flog('"containerSettingHideFunction" does not exist');
                }
            } else {
                var activeType = activeForm.attr('data-wps-type');
                var activeType;
                if (activeType != "blank") {
                    componentData = KEditor.components["text"];
                } else {
                    componentData = KEditor.components[activeType];
                }
                //var componentData = KEditor.components[activeType];

                if (typeof componentData.hideSettingForm === 'function') {
                    flog('Hide setting form of component type "' + activeType + '"');
                    componentData.hideSettingForm.call(componentData, activeForm, self);
                } else {
                    flog('"hideSettingForm" function of component type "' + activeType + '" does not exist');
                }
            }

            activeForm.removeClass('active');
            activeForm.remove();

        }


        if(!self.options.snippetsContainer && !self.options.snippetsComponent) body.removeClass('opened-keditor-sidebar');
        body.removeClass('opened-keditor-setting');
        self.setSettingComponent(null);
        self.setSettingContainer(null);
    };



    KEditor.prototype.getContentAreas = function(target) {
        flog('getContentAreas', target);

        var self = this;
        var options = self.options;
        var contentAreas;
        if (options.contentAreasSelector) {
            contentAreas = target.find(options.contentAreasSelector);
        }

        if (!contentAreas || contentAreas.length === 0) {
            flog('Do not find any content area. Creating default content area...');
            contentAreas = $('<div />');

            var originalContent = target.html();

            contentAreas.html(originalContent);
            target.empty().append(contentAreas);
        }

        return contentAreas;
    };

    KEditor.prototype.initContentAreas = function(target) {
        flog('initContentAreas', target);

        var self = this;
        var contentAreas = self.getContentAreas(target);


        contentAreas.each(function() {

            var contentArea = $(this);
            if (!contentArea.attr('id')) {
                contentArea.attr('id', self.generateId('content-area'));
            }

            self.initContentArea(contentArea);
            contentArea.data('keditor', self);
        });
    };

    KEditor.prototype.initContentArea = function(contentArea) {
        flog('initContentArea', contentArea);


        var self = this;
        var options = self.options;
        var body = self.body;






        contentArea.addClass('keditor-content-area');

        if (typeof options.onBeforeInitContentArea === 'function') {
            options.onBeforeInitContentArea.call(self, contentArea);
        }



        flog('Initialize existing containers in content area');
        contentArea.children('section').each(function() {
            self.convertToContainer(contentArea, $(this));
        });

        if (typeof options.onInitContentArea === 'function') {
            var contentData = options.onInitContentArea.call(self, contentArea);
            if (contentData && contentData.length > 0) {
                $.each(contentData, function() {
                    self.convertToContainer(contentArea, $(this));
                });
            }
        }



        self.window.imgCover.update(self.body[0], true);
        function observeResizes() {
          // Only run if ResizeObserver is supported.
          if ('ResizeObserver' in window) {
            // Create a single ResizeObserver instance to handle all
            // container elements. The instance is created with a callback,
            // which is invoked as soon as an element is observed as well
            // as any time that element's size changes.
            var ro = new ResizeObserver(function(entries) {
              // Default breakpoints that should apply to all observed
              // elements that don't define their own custom breakpoints.

              var defaultBreakpoints = {ro_xs: 0, ro_sm: 576, ro_md: 768, ro_lg: 992, ro_xl:1200};


              entries.forEach(function(entry) {
                // If breakpoints are defined on the observed element,
                // use them. Otherwise use the defaults.
                var breakpoints = entry.target.dataset.breakpoints ?
                    JSON.parse(entry.target.dataset.breakpoints) :
                    defaultBreakpoints;

                if (entry.contentRect.width === 0) {
                  entry.target.dataset.observing = false;
                } else {
                  entry.target.dataset.observing = true;
                }

                // Update the matching breakpoints on the target element.
                Object.keys(breakpoints).forEach(function(breakpoint) {
                  var minWidth = breakpoints[breakpoint];
                  if (entry.contentRect.width >= minWidth) {
                    entry.target.classList.add(breakpoint);
                  } else {
                    entry.target.classList.remove(breakpoint);
                  }
                });
              });
            });

            // Find all elements with the `data-observe-resizes` attribute
              // and start observing them.
            var elements = self.body.find('[data-observe-resizes]');
            for (var element, i = 0; element = elements[i]; i++) {
              ro.observe(element);
            }

            // Iterates through the subtree
            var eachObserveableElement = function(nodes, fn) {
              if (nodes) {
                [].slice.call(nodes).forEach(function(node) {
                  if (node.nodeType === 1) {
                    var containers = [].slice.call(
                        node.querySelectorAll('[data-observe-resizes]'));
                    if (node.hasAttribute('data-observe-resizes')) {
                      containers.push(node);
                    }
                    for (var container, i = 0; container = containers[i]; i++) {
                      fn(container);
                    }
                  }
                });
              }
            };

            // Monitor the DOM for changes
            var mo = new MutationObserver(function(entries) {
              entries.forEach(function(entry) {
                eachObserveableElement(entry.addedNodes, ro.observe.bind(ro));
              });
            });
            mo.observe(document.body, {childList: true, subtree: true});
          }
        }

        observeResizes();

        if(window.universalParallax){
           new universalParallax().init({
               speed: 100,
               container: self.body[0]
           });
        }

        self.checkTemplateUpdate();
    };

    KEditor.prototype.checkContentArea = function(contentArea) {


        var self = this;
        var options = self.options;
        var body = self.body;

        console.log("contentArea", contentArea);

        flog('Check snippet versions in content area');


        var sectionsArticle = contentArea.find("section[data-wps-name]"),
            version,
            name,
            snippetsArticle = [];



        sectionsArticle.each(function(index, val) {

            version = $(val).attr("data-wps-version");
            name = $(val).attr("data-wps-name");
            snippetsArticle.push({
                "name": name,
                "version": version,
                "element": val
            })



            if (self.snippetsTemplate) {
                var result = self.snippetsTemplate.filter(function(obj) {
                    return obj.name == name;
                });


                if (result.length > 0) {
                    if (result[0].version > version) {
                        // show update button in component
                        $(val).find(".keditor-toolbar .btn-component-update").css("display", "inline-block");
                    }
                }
            }
        });


        //console.error("checkContentArea", contentArea, contentArea.find("a"))

        //catch all a:href (no internal linkin in AMS Editor)
        contentArea.find("a").click(function(e) {
            href = $(this).attr("href");
            e.preventDefault();

            //alert("fix that")
            var result = [0]; // $.grep(window.parent._AMS.project.modules.content, function(e){ return e.path == href && e.status==1  && $.inArray('#hidden', e.category)== -1 ; });
            if (result.length > 0) {

            }else{
                if(parent.p$) parent.p$.utils.openUrl(href)
            }
              //if(parent.p$) parent.p$.utils.openUrl(href)
        });


        self.addImageHandler(contentArea);

    };



    KEditor.prototype.convertToContainer = function(contentArea, target) {

        flog('convertToContainer', contentArea, target);

        var self = this;
        var isSection = target.is('section');
        var container;

        if (isSection) {
            target.addClass('keditor-container');
            target.removeClass("keditor-ui keditor-component keditor-initialized-component")
            if(target.find(".keditor-ui.keditor-container-inner").length==0) target.wrapInner('<div class="keditor-ui keditor-container-inner"></div>');
            container = target;
        } else {
            //target.wrap('<section class="keditor-ui keditor-container"><pgs-article class="' + self.options.data.template + ' keditor-ui keditor-container-inner"></pgs-article></section>');
            target.wrap('<section class="keditor-ui keditor-container"><div class="' + self.options.data.template + ' keditor-ui keditor-container-inner"></div></section>');
            container = target.parent().parent();
        }
        self.initContainer(contentArea, container);
    };

    KEditor.prototype.initContainer = function(contentArea, container) {


        var self = this;
        var options = self.options;
        var isNested = options.nestedContainerEnabled && container.closest('[data-wps-type="container-content"]').length > 0;

        flog('initContainer - isNested=' + isNested, contentArea, container);

        if (!container.hasClass('keditor-initialized-container') || !container.hasClass('keditor-initializing-container')) {
            container.addClass('keditor-initializing-container');

            if (typeof options.onBeforeInitContainer === 'function') {
                options.onBeforeInitContainer.call(self, container, contentArea);
            }

            container.addClass('section');

            if (isNested) {
                container.addClass('keditor-sub-container keditor-component-sortable');
            }

            var settingBtn = '';
            if (options.containerSettingEnabled === true) {
                settingBtn = '<a href="javascript:void(0);" class="keditor-ui btn-container-setting">' + options.btnSettingContainerText + '</a>';
            }

            if(isNested && (self.privileges.includes('can_edit_page') || self.options.isSysAdmin)){

                console.warn(container.find('[data-wps-edit-name2="spacing"]'));


                var _paddingTopIcon = "fa-arrow-down";
                var _paddingBottomIcon = "fa-arrow-up";
                var _spacing = container.find('[data-wps-edit-name2="spacing"]')
                if(_spacing.length>0 && _spacing.hasClass("pt-0i")){
                    _paddingTopIcon = "fa-arrow-up";
                }
                if(_spacing.length>0 && _spacing.hasClass("pb-0i")){
                    _paddingBottomIcon = "fa-arrow-down";
                }

                var _lock = '';
                if(self.privileges.includes('can_lock_snippets') || self.options.isSysAdmin) {
                    self.body.addClass("webmag_lock_admin");
                    _lock='<button class="dropdown-item btn-component-lock" type="button"><i class="fa fa-lock" aria-hidden="true"></i> '+self.labels.lock+'</button>';
                }

                var _resize = '';
                if(container.find("[data-wps-resize-h]").length>0){
                    _resize = '<button type="button" class="component-btn btn-component-resize"><i class="fa fa-arrows-h"></i></button>';
                }

                // var _paddingLeftIcon = "fa-arrows-h";
                // var _paddingRightIcon = "fa-arrows-h";

                container.append(
                '<button type="button" class="component-btn btn-component-paddingTop"><i class="fa '+_paddingTopIcon+'"></i></button>' +
                '<button type="button" class="component-btn btn-component-paddingBottom"><i class="fa '+_paddingBottomIcon+'"></i></button>' +
                // '<button type="button" class="component-btn btn-component-paddingLeft"><i class="fa '+_paddingLeftIcon+'"></i></button>' +
                // '<button type="button" class="component-btn btn-component-paddingRight"><i class="fa '+_paddingRightIcon+'"></i></button>' +
                '<div class="keditor-toolbar keditor-toolbar-component">' +

                '<div class="btn-group" role="group" aria-label="Button group with nested dropdown">' +
                    '<button title="New snippet update available" href="javascript:void(0);" class="component-btn btn-component-update">' + options.btnUpdateComponentText+  '</button>' +
                    _resize +
                    '<button type="button" class="component-btn btn-component-reposition"><i class="fa fa-arrows"></i></button>' +
                    '<button type="button" class="component-btn btn-component-moveup"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>' +
                    '<button type="button" class="component-btn btn-component-movedown"><i class="fa fa-long-arrow-down" aria-hidden="true"></i></button>' +


                    '<div class="btn-group dropdown" role="group">' +
                    '<button id="btnGroupDrop2" type="button" class="component-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa fa-sort-desc"></i></button>' +
                    '<div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop2">' +
                        '<button class="dropdown-item btn-container-edit" type="button"><i class="fa fa-pencil" aria-hidden="true"></i> '+self.labels.edit+'</button>' +
                        '<button class="dropdown-item btn-container-select" type="button"><i class="fa fa-check-square-o" aria-hidden="true"></i> '+self.labels.select+'</button>' +
                        '<button class="dropdown-item btn-container-duplicate" type="button"><i class="fa fa-clone" aria-hidden="true"></i> '+self.labels.duplicate+'</button>' +
                        '<button class="dropdown-item btn-component-copy" type="button"><i class="fa fa-files-o" aria-hidden="true"></i> '+self.labels.copy+'</button>' +
                        '<button class="dropdown-item btn-component-paste" type="button"><i class="fa fa-clipboard" aria-hidden="true"></i> '+self.labels.paste+'</button>' +
                        '<button class="dropdown-item btn-component-hide" type="button"><i class="fa fa-eye-slash" aria-hidden="true"></i> '+self.labels.showhide+'</button>' +
                        _lock +
                        '<div class="dropdown-divider"></div>' +
                        '<button class="dropdown-item btn-component-delete" type="button"><i class="fa fa-trash" aria-hidden="true"></i> '+self.labels.delete+'</button>' +
                    '</div>' +
                    '</div>' +
                '</div>' +
                '</div>'
                )

                //toggle dropdown
                container.find('.dropdown-toggle').click(function(){

                    var _clone = JSON.parse(window.localStorage.getItem('clonedElements'));
                    if(!_clone){
                        $(this).parent().find(".dropdown-menu .btn-component-paste").hide();
                    }else{
                        $(this).parent().find(".dropdown-menu .btn-component-paste").show();
                    }
                    //$(this).parent().find(".dropdown-menu").toggleClass("show")
                })



                container.append(
                    '<div title="Snippet Settings" class="keditor-toolbar keditor-toolbar-component-settings">' +
                    settingBtn +
                    //'   <a title="Update Snippet" href="javascript:void(0);" class="keditor-ui btn-component-update">' + options.btnUpdateComponentText + '</a>' +
                    '</div>'
                );

                container.find('[data-dynamic-href]').each(function() {
                    var dynamicElement = $(this);

                    self.initDynamicContent(dynamicElement);
                });

                // init editables in container too
                var componentData = KEditor.components["text"];
                if (typeof componentData.init === 'function') {
                    componentData.init.call(componentData, contentArea, container, container, self);
                }



            }

            // flog('Render KEditor toolbar for container', container);
            // container.append(
            //     '<div class="keditor-toolbar keditor-toolbar-container ' + (isNested ? 'keditor-toolbar-sub-container' : '') + '">' +
            //     '   <a href="javascript:void(0);" class="keditor-ui btn-container-moveup">' + options.btnMoveUp + '</a>' +
            //     '   <a href="javascript:void(0);" class="keditor-ui btn-container-movedown">' + options.btnMoveDown + '</a>' +
            //     '   <a href="javascript:void(0);" class="keditor-ui btn-container-reposition">' + options.btnMoveContainerText + '</a>' + settingBtn +
            //     '   <a href="javascript:void(0);" class="keditor-ui btn-container-duplicate">' + options.btnDuplicateContainerText + '</a>' +
            //     '   <a href="javascript:void(0);" class="keditor-ui btn-container-delete">' + options.btnDeleteContainerText + '</a>' +
            //     '</div>'
            // );



            container.attr('id', self.generateId(isNested ? 'sub-container' : 'container'));

            var containerContents = container.find('[data-wps-type="container-content"]');

            //console.log('Initialize ' + containerContents.length + ' container content(s)', containerContents);
            containerContents.each(function() {
                var containerContent = $(this);

                if (options.nestedContainerEnabled) {
                    if (isNested) {
                        self.initContainerContent(contentArea, container, containerContent, isNested);
                    } else {
                        if (containerContent.parents('[data-wps-type="container-content"]').length === 0) {
                            self.initContainerContent(contentArea, container, containerContent, isNested);
                        }
                    }
                } else {
                    self.initContainerContent(contentArea, container, containerContent, isNested);
                }
            });



            if (typeof options.onInitContainer === 'function') {
                options.onInitContainer.call(self, container, contentArea);
            }

            container.addClass('keditor-initialized-container');
            container.removeClass('keditor-initializing-container');
            // init drag of Layout on start
            if(typeof self.options.template.afterUpdate == 'function') self.options.template.afterUpdate(self);
        } else {
            if (container.hasClass('keditor-initialized-container')) {
                flog('Container is already initialized!');
            } else {
                flog('Container is initializing...');
            }
        }
    };

    KEditor.prototype.initContainerContent = function(contentArea, container, containerContent, isNested) {
        flog('initContainerContent - isNested=' + isNested, contentArea, container, containerContent);



        var self = this;
        var options = self.options;
        var body = self.body;

        containerContent.addClass('keditor-container-content');
        if (isNested) {
            containerContent.addClass('keditor-sub-container-content');
        }
        containerContent.attr('id', self.generateId('container-content'));






        flog('Initialize existing components inside container content');
        containerContent.children().each(function() {

            var child = $(this);
            if (child.find('[data-wps-type="container-content"]').length > 0) {
                self.convertToContainer(contentArea, child);
            } else {
                //console.log("!isInitialised", child);
                // console.log all classnames of child element  (for debugging)
                //console.log("child", child[0].className.split(/\s+/));


                //if(child.hasClass("keditor-initialized-component"))
                self.convertToComponent(contentArea, container, child, true);
            }
        });

        /*containerContent.attr('id', 'sortableSnippets');
        $(containerContent[0]).sortable({
              appendTo: "parent",
              placeholder: "drag_ghost",
              handle:'.btn-component-reposition',
              items: 'section.keditor-component:not([data-wps-mode="fixed"])',
              scroll: true,
              axis: "y",
              scrollSensitivity: 100,
              scrollSpeed: 30,
              forcePlaceholderSize:true,
              forceHelperSize:true,
              sort: function(event, ui) {

              }
        });*/


          // sort snippets in editor
          if(containerContent.hasClass("keditor-no-drop")) return;
            //snippets in editor view
            //console.log("containerContent[0]", containerContent[0]);
            //containerContent = $("main.keditor-container-content");
            //console.log("TAG NAME",containerContent.prop("tagName"))
            if(containerContent.prop("tagName")=="MAIN"){
                //console.log("containerContent[0]", containerContent[0]);
                var sort = Sortable.create(containerContent[0], {
                    group: {
                        name: 'component',
                        put: true
                    },
                    ghostClass: 'drag_ghost',
                    animation: 150,
                    //forceFallback: true,
                    //chosenClass: 'drag_chosen',
                    handle: '.btn-component-reposition', // Restricts sort start click/touch to the specified element
                    draggable: 'section.keditor-component-sortable:not([data-wps-mode="fixed"])', // Specifies which items inside the element should be sortable
                    // Called by any change to the list (add / update / remove)
                    onSort: function(evt){ return _onSort(evt) },
                    // Element is dropped into the list from another list (receive)
                    onAdd: function(evt){ return _onAdd(evt) },
                    // Element dragging started
                    onStart: function(evt){ return _onStart(evt) },
                    onMove: function(evt){ return _onMove(evt) },
                    // Element dragging ended
                    onEnd: function(evt){ return _onEnd(evt) },
                    setData: function(dataTransfer,dragEl){ _setData(dataTransfer,dragEl) }

                });
            }else{
                var sort = Sortable.create(containerContent[0], {
                    group: {
                        name: 'component2',
                        put: true
                    },
                    ghostClass: 'drag_ghost',
                    animation: 150,
                    //forceFallback: true,
                    //chosenClass: 'drag_chosen',
                    handle: '.btn-component-reposition', // Restricts sort start click/touch to the specified element
                    draggable: 'section.keditor-component-sortable:not([data-wps-mode="fixed"])', // Specifies which items inside the element should be sortable
                    // Called by any change to the list (add / update / remove)
                    onSort: function(evt){ return _onSort(evt) },
                    // Element is dropped into the list from another list (receive)
                    onAdd: function(evt){ return _onAdd(evt) },
                    // Element dragging started
                    onStart: function(evt){ return _onStart(evt) },
                    onMove: function(evt){ return  _onMove(evt) },
                    // Element dragging ended
                    onEnd: function(evt){ return _onEnd(evt) }
                });
            }

            function _setData(dataTransfer, dragEl) {
                //dataTransfer.setData('Text', dragEl.textContent); // `dataTransfer` object of HTML5 DragEvent
                // Drag Image
            };

            function _onSort(evt) {

                // same properties as onEnd
                evt.preventDefault();
                evt.stopPropagation();
                var itemEl = evt.item;
                $(itemEl).removeClass('ui-state-default');
                //fix for insert dragged text on dropping hover other snippet
                setTimeout(function() {
                    body.find(".fr-element").attr('contenteditable', 'true').css('pointerEvents', 'all');
                }, 200);
            }
            // Element is dropped into the list from another list (receive)
            function _onAdd(  evt) {

                evt.preventDefault();
                evt.stopPropagation();
                // same properties as onEnd
                //console.log('On received snippet', evt);

                var helper = $(evt.item);
                var item = $(evt.item);
                var container;
                var isInitialised = false;

                //console.log("item", item)

                var isSnippet = item.is('.keditor-snippet');
                var isContainer = item.attr('data-wps-container');



                if (isSnippet) {

                    var snippetContentElement = self.body.find(item.attr('data-snippet'));

                    var snippetContent = snippetContentElement.html();

                    // change to img and src again
                    snippetContent = snippetContent.replace(/<img_wbmg/g,"<img").replace(/<source_wbmg/g,"<source").replace(/<video_wbmg/g,"<video").replace(/poster_wbmg="/g,'poster="');

                    var snippetType = item.attr('data-wps-type');

                    snippetContent = self.compileHandlebars(snippetContent)
                    var dataAttributes = self.getDataAttributes(snippetContentElement, null, true);
                    var classNames = snippetContentElement[0].classList;
                    var classNames2 = snippetContentElement.attr('data-wps-name');

                    var $section = $('<section />'); // Replace with your div's ID or another selector

                    // Iterate over the object and add each attribute to the div
                    $.each(dataAttributes, function(key, value) {
                        $section.attr(key, value);
                    });
                    $section.addClass('keditor-ui keditor-component keditor-component-sortable '+classNames+' '+classNames2);
                    // add data-wps-type
                    $section.attr('data-wps-type', snippetType);
                    // add html
                    $section.html('<div class="keditor-ui keditor-component-content">' + snippetContent + '</div>');

                    var component =  $section;

                    // var component = $(
                    //     '<section class="keditor-ui keditor-component keditor-component-sortable '+classNames+" "+classNames2+'" data-wps-type="' + snippetType + '" ' + dataAttributes.join(' ') + '>' +
                    //     '   <section class="keditor-ui keditor-component-content">' + snippetContent + '</section>' +
                    //     '</section>'
                    // );
                    isInitialised = component.hasClass("keditor-initialized-container") || component.hasClass("keditor-initialized-component");
                    isInitialisedContainer = component.hasClass("keditor-initialized-container");
                    if(isInitialised) {
                        component = self.cleanFroalaContent(component);
                        console.error("keditor-initialized-container", component.html());
                    };

                    helper.replaceWith(component);

                    container = component.closest('.keditor-container');

                    if (typeof options.onComponentSnippetDropped === 'function') {
                        options.onComponentSnippetDropped.call(self, evt, component, item, contentArea);
                    }

                    container = $(self.cleanContainerContent(container, true));
                    if(!isInitialisedContainer) self.initComponent(contentArea, container, component);

                } else {
                    // if (helper) {
                    //     helper.remove();
                    // }
                    component = item;
                    helper.replaceWith(component);
                    container = item.closest('.keditor-container');
                    self.initComponent(contentArea, container, component);

                }

                if (!container.hasClass('showed-keditor-toolbar')) {
                    $('.keditor-container.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                    container.addClass('showed-keditor-toolbar');
                }

                if (typeof options.onContainerChanged === 'function') {
                    options.onContainerChanged.call(self, evt, container, contentArea);
                }

                if (typeof options.onContentChanged === 'function') {
                    options.onContentChanged.call(self, evt, contentArea);
                }

                item.removeClass('keditor-ui-dragging');
                contentArea.removeClass('keditor-highlighted-dropzone');

                //console.log("component", component)
                self.checkContentArea(component);

                if(window.universalParallax){
                new universalParallax().init({
                    speed: 100,
                    container: component[0]
                });
                }
                var attr = item.attr('data-wps-container');
                // check if it is a container snippet
                if (typeof attr !== 'undefined' && attr !== false) {
                    self.initContainerContent(contentArea, container, containerContent, false);
                }

                self.window.imgCover.update(self.body[0]);
                //
                self.save();

            }
            // Element dragging started
            function _onStart(  evt) {

                evt.preventDefault();
                evt.stopPropagation();

                //fix for insert dragged text on dropping hover other snippet
                body.find(".fr-element").css('pointerEvents', 'none').attr('contenteditable', 'false');
                body.addClass('highlighted-container-content');
            };
            function _onMove(evt){

                //console.log("onMove", evt)
                evt.preventDefault();
                evt.stopPropagation();

                // dont drop container in container
                if($(evt.dragged).hasClass("keditor-sub-container") && $(evt.to).hasClass("keditor-sub-container-content")) return false;

            }
            // Element dragging ended
            function _onEnd(  evt) {

                evt.preventDefault();
                evt.stopPropagation();

                var itemEl = evt.item; // dragged HTMLElement
                body.removeClass('highlighted-container-content');
                containerContent.removeClass('keditor-highlighted-dropzone');

                //fix for insert dragged text on dropping hover other snippet
                setTimeout(function() {
                    body.find(".fr-element").attr('contenteditable', 'true').css('pointerEvents', 'all');
                }, 200);

                $(itemEl).removeClass('keditor-ui-dragging');
                self.save();

            }




    };

    KEditor.prototype.convertToComponent = function(contentArea, container, target, isExisting) {
        flog('convertToComponent', contentArea, container, target, isExisting);


        var self = this;
        var isSection = target.is('section');
        var component;

        //console.error("target", target)

        if (isSection) {

            if (target.attr("data-class")) {
                target.addClass(target.attr("data-class"));
            }
            target.addClass('keditor-component keditor-component-sortable');

            if (!target.children().is('div.keditor-component-content')) target.wrapInner('<div class="keditor-ui keditor-component-content"></div>');

            component = target;
        } else {
            target.wrap('<div class="keditor-ui keditor-component keditor-component-sortable"><div class="keditor-ui keditor-component-content"></div></div>');
            if (target.attr("data-class")) {
                target.addClass(target.attr("data-class"));
            }
            component = target.parent().parent();
        }

        if (isExisting) {
            component.addClass('existing-component');
        }



        self.initComponent(contentArea, container, component);
    };

    KEditor.prototype.getDataAttributes = function(target, ignoreAttributes, isArray) {
        var dataAttributes = isArray ? [] : {};
        if (!ignoreAttributes) {
            ignoreAttributes = [];
        }
        //console.log("target", target)




        var attributes = target.get(0).attributes;

        var allAttributes = {};


        for (var i = 0; i < attributes.length; i++) {
            //console.log("attributes[i].value", attributes[i].value);
            var attrName = attributes[i].name;
            var attrValue = attributes[i].value;

            // Store attribute and its value
            allAttributes[attrName] = attrValue;

            // If you need to handle specific JSON-like values, add logic here
            if (attrValue.startsWith('{') && attrValue.endsWith('}')) {
                try {
                    allAttributes[attrName] = JSON.stringify(JSON.parse(attrValue));
                } catch (e) {
                    console.error('Error parsing JSON from attribute ' + attrName + ':', e);
                }
            }
        }
        //console.log("allAttributes",allAttributes);


        // $.each(target.get(0).attributes, function(i, attr) {
        //     if (attr.name.indexOf('data-') === 0 && $.inArray(attr.name, ignoreAttributes) === -1) {
        //         console.log("attr.value", attr.value);
        //         if (attr.value.startsWith('{') && attr.value.endsWith('}')) {
        //             try {
        //                 attr.value = JSON.stringify(JSON.parse(attr.value));
        //             } catch (e) {
        //                 console.error('Error parsing JSON from attribute ' + attrName + ':', e);
        //             }
        //         }
        //         console.log("attr.value", attr.value);
        //         if (isArray) {
        //             dataAttributes.push(attr.name + '="' + attr.value + '"');
        //         } else {
        //             dataAttributes[attr.name] = attr.value;
        //         }
        //     }
        // });
        //console.log("dataAttributes",allAttributes);
        return allAttributes;
    };

    KEditor.prototype.getComponentType = function(component) {
        flog('getComponentType', component);

        var self = this;
        var options = self.options;


        var dataType = component.attr('data-wps-type');
        var componentType = dataType ? dataType.replace(/component-/g, '') : '';
        if (componentType) {
            return componentType;
        } else {
            if (componentType) {
                flog('Component type "' + componentType + '" does not exist');
            } else {
                flog('This component does not contain data-wps-type attribute');
            }

            if (typeof options.defaultComponentType === 'string') {
                componentType = options.defaultComponentType;
            } else if (typeof options.defaultComponentType === 'function') {

                componentType = options.defaultComponentType.call(self, component);
            }

            if (!componentType) {
                error('Component type is undefined!');
            }

            flog('Fallback to defaultComponentType: ' + componentType);

            return componentType;
        }
    };



    KEditor.prototype.initComponent = function(contentArea, container, component) {
        flog('initComponent', contentArea, container, component);

       // console.log('initComponent', contentArea, container, component);



        var self = this;
        var options = self.options;
        var body = self.body;



        if (!component.hasClass('keditor-initialized-component') || !component.hasClass('keditor-initializing-component')) {

            component.addClass('keditor-initializing-component');
            component.attr('id', self.generateId('component'));

            if (typeof options.onBeforeInitComponent === 'function') {
                options.onBeforeInitComponent.call(self, component, contentArea);
            }

            var componentContent = component.children('.keditor-component-content');
            componentContent.attr('id', self.generateId('component-content'));

            var componentType = self.getComponentType(component);
            flog('Component type: ' + componentType);




            var componentData;

            if (componentType != "blank") {
                componentData = KEditor.components["text"];
            } else {
                componentData = KEditor.components[componentType];
            }




            var isSettingEnabled = componentData.settingEnabled;
            var settingBtn = '';
            if (isSettingEnabled) {
                settingBtn = '<a href="javascript:void(0);" class="keditor-ui btn-component-setting">' + options.btnSettingComponentText + '</a>';
            }

            flog('Render KEditor toolbar for component', component);

            /*component.append(
                '<div class="keditor-toolbar keditor-toolbar-component">' +
                '   <a title="Reposition" href="javascript:void(0);" class="keditor-ui btn-component-reposition">' + options.btnMoveComponentText + '</a>' +
                '   <a title="Move up" href="javascript:void(0);" class="keditor-ui btn-component-moveup">' + options.btnMoveUp + '</a>' +
                '   <a title="Move down" href="javascript:void(0);" class="keditor-ui btn-component-movedown">' + options.btnMoveDown + '</a>' +

                //settingBtn +
                '   <a title="Duplicate" href="javascript:void(0);" class="keditor-ui btn-component-duplicate">' + options.btnDuplicateComponentText + '</a>' +
                '   <a title="Delete" href="javascript:void(0);" class="keditor-ui btn-component-delete">' + options.btnDeleteComponentText + '</a>' +
                //'   <a href="javascript:void(0);" class="keditor-ui btn-component-update">' + options.btnUpdateComponentText + '</a>' +
                '</div>'
            );*/




            if(self.privileges.includes('can_edit_page') || self.options.isSysAdmin){

                console.warn(component.find('[data-wps-edit-name2="spacing"]'));


                var _paddingTopIcon = "fa-arrow-down";
                var _paddingBottomIcon = "fa-arrow-up";
                var _spacing = component.find('[data-wps-edit-name2="spacing"]')
                if(_spacing.length>0 && _spacing.hasClass("pt-0i")){
                    _paddingTopIcon = "fa-arrow-up";
                }
                if(_spacing.length>0 && _spacing.hasClass("pb-0i")){
                    _paddingBottomIcon = "fa-arrow-down";
                }
                var _lock = '';
                if(self.privileges.includes('can_lock_snippets') || self.options.isSysAdmin) {
                    self.body.addClass("webmag_lock_admin");
                    _lock='<button class="dropdown-item btn-component-lock" type="button"><i class="fa fa-lock" aria-hidden="true"></i> '+self.labels.lock+'</button>';
                }

                // var _paddingLeftIcon = "fa-arrows-h";
                // var _paddingRightIcon = "fa-arrows-h";
                var _resize = '';
                if(component.find("[data-wps-resize-h]").length>0){
                    _resize = '<button type="button" class="component-btn btn-component-resize"><i class="fa fa-arrows-h"></i></button>';
                }

                component.append(
                '<button type="button" class="component-btn btn-component-paddingTop"><i class="fa '+_paddingTopIcon+'"></i></button>' +
                '<button type="button" class="component-btn btn-component-paddingBottom"><i class="fa '+_paddingBottomIcon+'"></i></button>' +
                // '<button type="button" class="component-btn btn-component-paddingLeft"><i class="fa '+_paddingLeftIcon+'"></i></button>' +
                // '<button type="button" class="component-btn btn-component-paddingRight"><i class="fa '+_paddingRightIcon+'"></i></button>' +
                '<div class="keditor-toolbar keditor-toolbar-component">' +

                '<div class="btn-group" role="group" aria-label="Button group with nested dropdown">' +
                    '<button title="New snippet update available" href="javascript:void(0);" class="component-btn btn-component-update">' + options.btnUpdateComponentText+  '</button>' +
                    _resize +
                    '<button type="button" class="component-btn btn-component-reposition"><i class="fa fa-arrows"></i></button>' +
                    '<button type="button" class="component-btn btn-component-moveup"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>' +
                    '<button type="button" class="component-btn btn-component-movedown"><i class="fa fa-long-arrow-down" aria-hidden="true"></i></button>' +


                    '<div class="btn-group dropdown" role="group">' +
                    '<button id="btnGroupDrop1" type="button" class="component-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa fa-sort-desc"></i></button>' +
                    '<div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">' +
                        '<button class="dropdown-item btn-component-edit" type="button"><i class="fa fa-pencil" aria-hidden="true"></i> '+self.labels.edit+'</button>' +
                        '<button class="dropdown-item btn-component-select" type="button"><i class="fa fa-check-square-o" aria-hidden="true"></i> '+self.labels.select+'</button>' +
                        '<button class="dropdown-item btn-component-duplicate" type="button"><i class="fa fa-clone" aria-hidden="true"></i> '+self.labels.duplicate+'</button>' +
                        '<button class="dropdown-item btn-component-copy" type="button"><i class="fa fa-files-o" aria-hidden="true"></i> '+self.labels.copy+'</button>' +
                        '<button class="dropdown-item btn-component-paste" type="button"><i class="fa fa-clipboard" aria-hidden="true"></i> '+self.labels.paste+'</button>' +
                        '<button class="dropdown-item btn-component-hide" type="button"><i class="fa fa-eye-slash" aria-hidden="true"></i> '+self.labels.showhide+'</button>' +
                        _lock +
                        '<div class="dropdown-divider"></div>' +
                        '<button class="dropdown-item btn-component-delete" type="button"><i class="fa fa-trash" aria-hidden="true"></i> '+self.labels.delete+'</button>' +
                    '</div>' +
                    '</div>' +
                '</div>' +
                '</div>'
                )

                //toggle dropdown
                component.find('.dropdown-toggle').click(function(){

                    var _clone = JSON.parse(window.localStorage.getItem('clonedElements'));
                    if(!_clone){
                        $(this).parent().find(".dropdown-menu .btn-component-paste").hide();
                    }else{
                        $(this).parent().find(".dropdown-menu .btn-component-paste").show();
                    }

                })



                component.append(
                    '<div title="Snippet Settings" class="keditor-toolbar keditor-toolbar-component-settings">' +
                    settingBtn +
                    //'   <a title="Update Snippet" href="javascript:void(0);" class="keditor-ui btn-component-update">' + options.btnUpdateComponentText + '</a>' +
                    '</div>'
                );

                component.find('[data-dynamic-href]').each(function() {
                    var dynamicElement = $(this);

                    self.initDynamicContent(dynamicElement);
                });


            }

            var snip;



            //self.options.template.init(false, component);

            //console.error("componentType", componentType)
            var multipleTypes = componentType.split(',');

            $(multipleTypes).each(function(index, el) {
                //console.log("el",el);
                //console.log(p$.snippet[el])
                if (self.window.p$.snippet && self.window.p$.snippet[el]) { //if this snippet has a js file

                    snip = new self.window.p$.snippet[el](); // create snippet
                    if(typeof snip.clear === 'function') snip.clear(component);
                    snip.init(component); // init snippet
                }
            });

            if(typeof self.options.template.afterUpdate == 'function') self.options.template.afterUpdate(self);



            if (typeof componentData.init === 'function') {
                componentData.init.call(componentData, contentArea, container, component, self);

            } else {
                body.removeClass('highlighted-container-content');
                flog('"init" function of component type "' + componentType + '" does not exist');
            }

            if (typeof options.onInitComponent === 'function') {
                options.onInitComponent.call(self, component, contentArea);
            }

            component.addClass('keditor-initialized-component');
            component.removeClass('keditor-initializing-component');
        } else {
            if (component.hasClass('keditor-initialized-component')) {
                flog('Component is already initialized!');
            } else {
                flog('Component is initializing...');
            }
        }


    };

    KEditor.prototype.reinitComponent = function(contentArea, container, component) {
        flog('initComponent', contentArea, container, component);

        //console.log('reinitComponent', contentArea, container, component);

        var self = this;
        var options = self.options;
        var body = self.body;


        if (!component.hasClass('keditor-initialized-component') || !component.hasClass('keditor-initializing-component')) {
            component.addClass('keditor-initializing-component');
            component.attr('id', self.generateId('component'));

            if (typeof options.onBeforeInitComponent === 'function') {
                options.onBeforeInitComponent.call(self, component, contentArea);
            }

            var componentContent = component.children('.keditor-component-content');
            componentContent.attr('id', self.generateId('component-content'));

            var componentType = self.getComponentType(component);
            flog('Component type: ' + componentType);


            var componentData;

            if (componentType != "blank") {
                componentData = KEditor.components["text"];
            } else {
                componentData = KEditor.components[componentType];
            }

            var isSettingEnabled = componentData.settingEnabled;
            var settingBtn = '';
            if (isSettingEnabled) {
                settingBtn = '<a href="javascript:void(0);" class="keditor-ui btn-component-setting">' + options.btnSettingComponentText + '</a>';
            }

            flog('Render KEditor toolbar for component', component);

            var toolbar = component.find(".keditor-toolbar");
            if (toolbar.length > 0) {
                toolbar.remove();
            }

            var _lock = '';
            if(self.privileges.includes('can_lock_snippets') || self.options.isSysAdmin) {
                self.body.addClass("webmag_lock_admin");
                _lock='<button class="dropdown-item btn-component-lock" type="button"><i class="fa fa-lock" aria-hidden="true"></i> '+self.labels.lock+'</button>';
            }


            /*component.append(
                '<div class="keditor-toolbar keditor-toolbar-component">' +
                '   <a href="javascript:void(0);" class="keditor-ui btn-component-reposition">' + options.btnMoveComponentText + '</a>' +
                settingBtn +
                '   <a href="javascript:void(0);" class="keditor-ui btn-component-duplicate">' + options.btnDuplicateComponentText + '</a>' +
                '   <a href="javascript:void(0);" class="keditor-ui btn-component-delete">' + options.btnDeleteComponentText + '</a>' +
                '   <a href="javascript:void(0);" class="keditor-ui btn-component-update">' + options.btnUpdateComponentText + '</a>' +
                '</div>'
            );*/
            component.append(
            '<div class="keditor-toolbar keditor-toolbar-component">' +
              '<div class="btn-group" role="group" aria-label="Button group with nested dropdown">' +
                '<button type="button" title="New snippet update available"  href="javascript:void(0);" class="component-btn btn-component-update">' + options.btnUpdateComponentText + '</button>' +
                '<button type="button" class="component-btn btn-component-resize"><i class="fa fa-arrows-h"></i></button>' +
                '<button type="button" class="component-btn btn-component-reposition"><i class="fa fa-arrows"></i></button>' +
                '<button type="button" class="component-btn btn-component-moveup"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>' +
                '<button type="button" class="component-btn btn-component-movedown"><i class="fa fa-long-arrow-down" aria-hidden="true"></i></button>' +


                '<div class="btn-group dropdown" role="group">' +
                  '<button id="btnGroupDrop1" type="button" class="component-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa fa-sort-desc"></i></button>' +
                  '<div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">' +
                    '<button class="dropdown-item btn-component-edit" type="button"><i class="fa fa-pencil" aria-hidden="true"></i> '+self.labels.edit+'</button>' +
                    '<button class="dropdown-item btn-component-select" type="button"><i class="fa fa-check-square-o" aria-hidden="true"></i> '+self.labels.select+'</button>' +
                    '<button class="dropdown-item btn-component-duplicate" type="button"><i class="fa fa-clone" aria-hidden="true"></i> '+self.labels.duplicate+'</button>' +
                    '<button class="dropdown-item btn-component-copy" type="button"><i class="fa fa-files-o" aria-hidden="true"></i> '+self.labels.copy+'</button>' +
                    '<button class="dropdown-item btn-component-paste" type="button"><i class="fa fa-clipboard" aria-hidden="true"></i> '+self.labels.paste+'</button>' +
                    '<button class="dropdown-item btn-component-hide" type="button"><i class="fa fa-eye-slash" aria-hidden="true"></i> '+self.labels.showhide+'</button>' +
                    _lock +
                    '<div class="dropdown-divider"></div>' +
                    '<button class="dropdown-item btn-component-delete" type="button"><i class="fa fa-trash" aria-hidden="true"></i> '+self.labels.delete+'</button>' +
                  '</div>' +
                '</div>' +
              '</div>' +
            '</div>');
            //toggle dropdown
            component.find('.dropdown-toggle').click(function(){
                return;
              var _clone = window.parent._AMS.clonedElements;

              if(!_clone){
                $(this).parent().find(".dropdown-menu .btn-component-paste").hide();
              }else{
                $(this).parent().find(".dropdown-menu .btn-component-paste").show();
              }
              $(this).parent().find(".dropdown-menu").toggleClass("show")
            })

            /*component.append(
                '<div class="keditor-toolbar keditor-toolbar-component">' +
                '   <a title="Reposition" href="javascript:void(0);" class="keditor-ui btn-component-reposition">' + options.btnMoveComponentText + '</a>' +
                '   <a title="Move up" href="javascript:void(0);" class="keditor-ui btn-component-moveup">' + options.btnMoveUp + '</a>' +
                '   <a title="Move down" href="javascript:void(0);" class="keditor-ui btn-component-movedown">' + options.btnMoveDown + '</a>' +

                //settingBtn +
                '   <a title="Duplicate" href="javascript:void(0);" class="keditor-ui btn-component-duplicate">' + options.btnDuplicateComponentText + '</a>' +
                '   <a title="Delete" href="javascript:void(0);" class="keditor-ui btn-component-delete">' + options.btnDeleteComponentText + '</a>' +
                //'   <a href="javascript:void(0);" class="keditor-ui btn-component-update">' + options.btnUpdateComponentText + '</a>' +
                '</div>'
            );*/

            component.append(
                '<div title="Snippet Settings" class="keditor-toolbar keditor-toolbar-component-settings">' +
                settingBtn +
                //'   <a title="Update Snippet" href="javascript:void(0);" class="keditor-ui btn-component-update">' + options.btnUpdateComponentText + '</a>' +
                '</div>'
            );

            component.find('[data-dynamic-href]').each(function() {
                var dynamicElement = $(this);

                self.initDynamicContent(dynamicElement);
            });

            var snip;


            var multipleTypes = componentType.split(',');

            $(multipleTypes).each(function(index, el) {

                if (self.window.p$.snippet && self.window.p$.snippet[el]) { //if this snippet has a js file
                    snip = new self.window.p$.snippet[el](); // create snippet
                    if(typeof snip.clear === 'function') snip.clear(component);
                    snip.init(component) // init snippet
                }
            });






            if (typeof componentData.init === 'function') {
                componentData.init.call(componentData, contentArea, container, component, self);
            } else {
                body.removeClass('highlighted-container-content');
                flog('"init" function of component type "' + componentType + '" does not exist');
            }

            if (typeof options.onInitComponent === 'function') {
                options.onInitComponent.call(self, component, contentArea);
            }

            component.addClass('keditor-initialized-component');
            component.removeClass('keditor-initializing-component');
        } else {
            if (component.hasClass('keditor-initialized-component')) {
                flog('Component is already initialized!');

            } else {
                flog('Component is initializing...');
            }
        }

        self.checkContentArea(contentArea);

        //console.log(self.body[0])
        self.window.imgCover.update(self.body[0]);


        if(window.universalParallax){
           new universalParallax().init({
               speed: 100,
               container: self.body[0]
           });
        }

        self.save();

    };



    KEditor.prototype.getClickedElement = function(event, selector) {
        var target = $(event.target);
        var closest = target.closest(selector);

        if (target.is(selector)) {
            return target;
        } else if (closest.length > 0) {
            return closest;
        } else {
            return null;
        }
    };

    KEditor.prototype.initKEditorClicks = function() {
        flog('initKEditorClicks');

        var self = this;
        var options = self.options;
        var body = self.body;

        body.on('click', function(e) {
            var sidebar = self.getClickedElement(e, '#keditor-sidebar');

            var container = self.getClickedElement(e, '.keditor-container');
            if (container) {
                if (!container.hasClass('showed-keditor-toolbar')) {
                    body.find('.keditor-container.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                    body.find('.keditor-component.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                    container.addClass('showed-keditor-toolbar');

                    var contentArea = container.parent();
                    if (typeof options.onContainerSelected === 'function') {
                        options.onContainerSelected.call(self, e, container, contentArea);
                    }
                }
            } else {
                if (!sidebar) {
                    body.find('.keditor-container.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                    body.find('.keditor-component.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                }
            }

            var component = self.getClickedElement(e, '.keditor-component');
            if (component) {
                if (!component.hasClass('showed-keditor-toolbar')) {
                    body.find('.keditor-component.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                    component.addClass('showed-keditor-toolbar');

                    var contentArea = component.parent();
                    if (typeof options.onComponentSelected === 'function') {
                        options.onComponentSelected.call(self, e, component, contentArea);
                    }
                }
            } else {
                if (!sidebar) {
                    body.find('.keditor-component.showed-keditor-toolbar').removeClass('showed-keditor-toolbar');
                }
            }
        });

        body.on('click', '.btn-container-setting', function(e) {
            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-container-setting', btn);

            var container = btn.closest('.keditor-container');
            if (body.hasClass('opened-keditor-setting') && body.hasClass('opened-keditor-sidebar')) {
                if (!container.is(self.getSettingContainer())) {
                    self.hideSettingPanel();
                    setTimeout(function() {
                        self.showSettingPanel(component,e);
                    }, 300);
                } else {
                    self.hideSettingPanel();
                }
            } else {
                self.showSettingPanel(container, e);
            }
        });

        // body.on('click', '.btn-container-duplicate2', function(e) {
        //     e.preventDefault();


        //     var btn = $(this);
        //     flog('Click on .btn-container-duplicate', btn);

        //     var container = btn.closest('.keditor-container');
        //     var contentArea = container.parent();
        //     var newContainer = $(self.getContainerContent(container, btn.parent().hasClass('keditor-toolbar-sub-container')));
        //     container.after(newContainer);
        //     self.convertToContainer(contentArea, newContainer);

        //     var snippetsList = body.find('#keditor-snippets-list');
        //     var componentSnippets = snippetsList.find('.keditor-snippet[data-wps-type^=component]');
        //     //var currentLinkedContainerContents = componentSnippets.draggable('option', 'connectToSortable');
        //    // componentSnippets.draggable('option', 'connectToSortable', currentLinkedContainerContents.add(newContainer.find('.keditor-container-content')));

        //     flog('Container is duplicated');

        //     if (typeof options.onContainerDuplicated === 'function') {
        //         options.onContainerDuplicated.call(self, container, newContainer, contentArea);
        //     }

        //     if (typeof options.onContentChanged === 'function') {
        //         options.onContentChanged.call(self, e, contentArea);
        //     }
        // });
         // Modify the existing click handler for select button
         body.on('click', '.btn-container-select', function(e) {
            e.preventDefault();
            var btn = $(this);
            var component = btn.closest('.keditor-container');

            if(component.hasClass("webmag_selected")) {
                component.removeClass("webmag_selected");
            } else {
                component.addClass("webmag_selected");
            }

            //hide dropdown
            component.find('.dropdown-toggle').trigger("click");

            // Update selection widget
            self.updateSelectionWidget();

        });
        body.on('click', '.btn-container-edit', function(e) {
            e.preventDefault();
            var btn = $(this);
            var container = btn.closest('.keditor-container');
            $(container).find(".btn-container-setting").trigger("click");

        })

        body.on('click', '.btn-container-duplicate', function(e) {
            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-container-duplicate', btn);

            var container = btn.closest('.keditor-container');
            var contentArea = container.parent();
            var _clone = container.clone(true, true);

            _clone = self.cleanFroalaContent(_clone);

           // var newContainer = container.clone(true, true);

            var newContainer = $(self.cleanContainerContent(container, true));
            var snippetContent = newContainer.html();
            if(newContainer.find("> .keditor-component-content").length==0){
                newContainer.html('<div class="keditor-ui keditor-component-content">' + snippetContent + '</div>');
            }

            container.after(newContainer);
            // container = newContainer.closest('.keditor-container');
            // self.initContainerContent(contentArea, container, newContainer, false);

            self.addImageHandler(newContainer);

            self.convertToContainer(contentArea, newContainer);

            self.checkContentArea(contentArea);

            flog('Container is duplicated');


            //hide dropdown
            container.find('.dropdown-toggle').trigger("click");

            self.save();
        });
        body.on('click', '.btn-container-paste', function(e) {
            e.preventDefault();
            var btn = $(this);

            self.save();
        });

        body.on('click', '.btn-container-delete', function(e) {
            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-container-delete', btn);

            if (confirm('Wollen Sie das Snippet wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!')) {
                var container = btn.closest('.keditor-container');
                var components = container.find('.keditor-component');
                var contentArea = container.parent();

                if (typeof options.onBeforeContainerDeleted === 'function') {
                    options.onBeforeContainerDeleted.call(self, e, container, contentArea);
                }

                var settingComponent = self.getSettingComponent();
                if (settingComponent) {
                    var settingComponentParent = settingComponent.closest('.keditor-container');
                    if (settingComponentParent.is(container)) {
                        flog('Deleting container is container of setting container. Close setting panel for this setting component', settingComponent);
                        self.hideSettingPanel();
                    }
                } else if (self.getSettingContainer().is(container)) {
                    flog('Deleting container is setting container. Close setting panel for this container', container);
                    self.hideSettingPanel();
                }

                if (components.length > 0) {
                    components.each(function() {
                        self.deleteComponent($(this));
                    });
                }




                container.remove();


                if (typeof options.onContainerDeleted === 'function') {
                    options.onContainerDeleted.call(self, e, container, contentArea);
                }

                if (typeof options.onContentChanged === 'function') {
                    options.onContentChanged.call(self, e, contentArea);
                }
            }
        });

        body.on('click', '.btn-component-setting', function(e) {
            e.preventDefault();

            var body = self.body;

            var btn = $(this);
            flog('Click on .btn-component-setting', btn);

            var component = btn.closest('.keditor-component-sortable');
            if (body.hasClass('opened-keditor-setting') && body.hasClass('opened-keditor-sidebar')) {
               if (!component.is(self.getSettingComponent())) {

                    self.hideSettingPanel();
                    setTimeout(function() {
                        self.showSettingPanel(component,e);
                    }, 300);

                } else {

                    self.hideSettingPanel();
                }
                  self.hideSettingPanel();
            } else {
                self.showSettingPanel(component,e);
            }
        });


        body.on('click', '.btn-component-moveup', function(e) {

            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-component-moveup', btn);

            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();

            component.prev().insertAfter(component);


            self.iframeBody.scrollTop(component.offset().top)


            flog('Component is moved up');


            self.save();

        });

        body.on('click', '.btn-component-movedown', function(e) {

            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-component-moveup', btn);

            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();

            component.next().insertBefore(component);
            self.iframeBody.scrollTop(component.offset().top)

            flog('Component is moved down');


            self.save();

        });

        body.on('click', '.btn-component-resize', function(e) {

            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-component-resize', btn);

            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();
            var _rh, _cc, _options, _start,_classArray=[];


            //console.log(component, container, contentArea);

            _rh = component.find("[data-wps-resize-h]");
           // console.log("_rh",_rh);
            if(_rh.length>0){
                //console.log("_rh[0]",_rh[0]);
                _rh = _rh.eq(0);

                _cc = _rh.attr("data-wps-class-change");
                //console.log("_cc",_cc);
                if(_cc){
                    _options = _cc.split('|');
                    //console.log("_options",_options);
                    $.each(_options, function (index, val) {
                        _classArray.push(val.split('=')[1]);
                    });
                    //console.log("_classArray", _classArray);
                    $.each(_classArray, function (index, val) {
                        if (_rh.hasClass(val)) {
                            _start = val;
                            return;
                        }
                    });
                    //console.log("_start", _start);
                    var next = _classArray[($.inArray(_start, _classArray) + 1) % _classArray.length];
                    _rh.removeClass(_start).addClass(next);
                    //console.log("next", next);
                }
            }





            self.save();

        });



        // Modify the existing click handler for select button
        body.on('click', '.btn-component-select', function(e) {
            e.preventDefault();
            var btn = $(this);
            var component = btn.closest('.keditor-component-sortable');

            if(component.hasClass("webmag_selected")) {
                component.removeClass("webmag_selected");
            } else {
                component.addClass("webmag_selected");
            }

            //hide dropdown
            component.find('.dropdown-toggle').trigger("click");

            // Update selection widget
            self.updateSelectionWidget();

        });


        body.on('click', '.btn-component-edit', function(e) {
            e.preventDefault();
            var btn = $(this);
            var container = btn.closest('section.section');
            $(container).find(".btn-component-setting").trigger("click");

        })
        body.on('click', '.btn-component-duplicate', function(e) {
            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-component-duplicate', btn);

            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();
            var _clone = component.clone(true, true);

            _clone = self.cleanFroalaContent(_clone);

            var newComponent = $(self.getComponentContent(_clone));

            component.after(newComponent);

            self.addImageHandler(newComponent);


            self.convertToComponent(contentArea, container, newComponent);

            self.checkContentArea(contentArea);

            flog('Component is duplicated');

            if (typeof options.onComponentDuplicated === 'function') {
                options.onComponentDuplicated.call(self, component, newComponent, contentArea);
            }

            if (typeof options.onContainerChanged === 'function') {
                options.onContainerChanged.call(self, e, container, contentArea);
            }

            if (typeof options.onContentChanged === 'function') {
                options.onContentChanged.call(self, e, contentArea);
            }
            //hide dropdown
            component.find('.dropdown-toggle').trigger("click");

            self.save();
        });

        body.on('click', '.btn-component-delete', function(e) {
            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-component-delete', btn);


            if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("deleteSnippetModal", null, function(){
              var component = btn.closest('.keditor-component-sortable');
              var container = component.closest('.keditor-container');
              var contentArea = component.closest('.keditor-content-area');

              if (typeof options.onBeforeComponentDeleted === 'function') {
                  options.onBeforeComponentDeleted.call(self, e, component, contentArea);
              }

              if (self.getSettingComponent().is(component)) {
                  self.hideSettingPanel();
              }

              // clone body
              var clonedBody = body.clone(true);
              // delete current component
              clonedBody.find("#"+component.attr('id')).remove();

              var _imgs = component.find("img[data-wps-edit]"),
                  _allimgs = clonedBody.find("img[data-wps-edit]"),
                  _allimgs_arr = [];


              var _id;
              $.each(_allimgs, function(index, el) {
                  _id = $(el).attr("data-id");
                  if (_id) _allimgs_arr.push(_id);
              })

              $.each(_imgs, function(index, el) {
                  var _id = $(el).attr("data-id");
              });

              self.deleteComponent(component);


              self.save();

              if (typeof options.onComponentDeleted === 'function') {
                  options.onComponentDeleted.call(self, e, component, contentArea);
              }

              if (typeof options.onContainerChanged === 'function') {
                  options.onContainerChanged.call(self, e, container, contentArea);
              }

              if (typeof options.onContentChanged === 'function') {
                  options.onContentChanged.call(self, e, contentArea);
              }
            });


        });
        body.on('click', '.btn-component-hide', function(e) {
            e.preventDefault();
            var btn = $(this);
            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();
            if(component.hasClass("webmag_hide")) component.removeClass("webmag_hide");
            else component.addClass("webmag_hide");
            //hide dropdown
            component.find('.dropdown-toggle').trigger("click");
            self.save();
        });
        body.on('click', '.btn-component-lock', function(e) {
            e.preventDefault();
            var btn = $(this);
            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();
            if(component.hasClass("webmag_lock")) component.removeClass("webmag_lock");
            else component.addClass("webmag_lock");
            //hide dropdown
            component.find('.dropdown-toggle').trigger("click");
            self.save();
        });
        body.on('click', '.btn-component-copy', function(e) {
            e.preventDefault();
            var btn = $(this);
            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();


            _ls = [
              {
                clone:component.clone(true, true).wrap("<div />").parent().html()
              }
            ]

            window.localStorage.setItem('clonedElements', JSON.stringify(_ls));


            if (typeof self.options.callFromKeditor === 'function') self.options.callFromKeditor("makeToast", {
                message: self.labels.copySnippet

            }, function(data) {

            })


            //hide dropdown
            component.find('.dropdown-toggle').trigger("click");

        });
        body.on('click', '.btn-component-paste', function(e) {
            e.preventDefault();
            var btn = $(this);

            var _clone = JSON.parse(window.localStorage.getItem('clonedElements'));
            if(!_clone && _clone.length==0){
                return;
            }


            var item = btn.closest('.keditor-component-sortable');
            var container = item.closest('.keditor-container');
            var contentArea = container.parent();
            var isContainer = false;

            $.each(_clone, function(index, val) {
                //iterate through array or object
                _clone = $(val.clone);
                //console.log("_clone", _clone[0].outerHTML);
                _clone = self.cleanFroalaContent(_clone);

                isContainer = _clone.hasClass("keditor-sub-container");
                //console.log("isContainer",isContainer, _clone);
                //console.log("item", item);


                if(isContainer){
                    //var newItem = $(self.getContainerContent(_clone , true));
                    var newContainer = $(self.cleanContainerContent(_clone, true));
                    var snippetContent = newContainer.html();
                    if(newContainer.find("> .keditor-component-content").length==0){
                        console.log("add comnpnent content")
                        newContainer.html('<div class="keditor-ui keditor-component-content">' + snippetContent + '</div>');
                    }
                   // console.log("newItem", newItem);
                   // if item is in a container (closest .keditor-sub-container)
                   console.log("item", item);
                    if(item.parent().closest('section').hasClass('keditor-sub-container')){
                        if (typeof self.options.callFromKeditor === 'function') self.options.callFromKeditor("makeToast", {
                            message: self.labels.pasteSnippetDenial
                        }, function(data) {

                        })
                    }else{
                        item.before(newContainer);
                    }



                }else{
                    var newItem = $(self.getComponentContent(_clone));
                    //console.log("newItem2", newItem);
                    item.before(newItem);
                }

                self.addImageHandler(newItem);
                if(isContainer){

                    self.convertToContainer(contentArea, newContainer);

                }else{
                    self.convertToComponent(contentArea, container, newItem);
                }

                self.checkContentArea(contentArea);

            });
            //hide dropdown
            item.find('.dropdown-toggle').trigger("click");

            self.save();
        });


        body.on('click', '.btn-component-paddingTop', function(e) {
            e.preventDefault();
            var btn = $(this);
            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();
            var _spacing = component.find('[data-wps-edit-name2="spacing"]');

            if(_spacing.length>0 ){
                if(btn.find("i").hasClass("fa-arrow-up")){
                    _spacing.eq(0).removeClass("pt-0i")
                    btn.find("i").removeClass("fa-arrow-up").addClass("fa-arrow-down");
                }
                else if(btn.find("i").hasClass("fa-arrow-down")){
                    _spacing.eq(0).addClass("pt-0i")
                    btn.find("i").removeClass("fa-arrow-down").addClass("fa-arrow-up");
                }
                self.save();
            }
        })

        body.on('click', '.btn-component-paddingBottom', function(e) {
            e.preventDefault();
            var btn = $(this);
            var component = btn.closest('.keditor-component-sortable');
            var container = component.closest('.keditor-container');
            var contentArea = container.parent();

            var _spacing = component.find('[data-wps-edit-name2="spacing"]')
            //console.log("_spacing",_spacing);
            if(_spacing.length>0 ){
                if(btn.find("i").hasClass("fa-arrow-up")){
                    _spacing.eq(0).addClass("pb-0i")
                    btn.find("i").removeClass("fa-arrow-up").addClass("fa-arrow-down");
                }
                else if(btn.find("i").hasClass("fa-arrow-down")){
                    _spacing.eq(0).removeClass("pb-0i")
                    btn.find("i").removeClass("fa-arrow-down").addClass("fa-arrow-up");
                }
                self.save();
            }
        })




        body.on('click', '.btn-component-update', function(e) {
            return;
            e.preventDefault();

            var btn = $(this);
            flog('Click on .btn-component-update', btn);

            if (confirm('Are you sure that you want to update this component? This action can not be undo!')) {
                var component = btn.closest('.keditor-component-sortable');
                var container = component.closest('.keditor-container');
                var contentArea = component.closest('.keditor-content-area');

                if (typeof options.onBeforeComponentUpdated === 'function') {
                    options.onBeforeComponentUpdated.call(self, e, component, contentArea);
                }

                if (self.getSettingComponent().is(component)) {
                    self.hideSettingPanel();
                }

                self.updateComponent(contentArea, container, component);

                if (typeof options.onComponentUpdated === 'function') {
                    options.onComponentUpdated.call(self, e, component, contentArea);
                }

                if (typeof options.onContainerChanged === 'function') {
                    options.onContainerChanged.call(self, e, container, contentArea);
                }

                if (typeof options.onContentChanged === 'function') {
                    options.onContentChanged.call(self, e, contentArea);
                }
            }
        });
    };

    KEditor.prototype.initSelectionWidget = function() {
        var self = this;
        var selectionWidget = $(
            '<div class="keditor-selection-widget" style="display: none;">' +
                '<span class="selection-count">' + self.labels.selected + ': <span class="count">0</span></span>' +
                '<button class="selection-btn btn-delete">' + self.labels.delete + '</button>' +
                '<button class="selection-btn btn-copy">' + self.labels.copy + '</button>' +
                '<button class="selection-btn btn-done">' + self.labels.done + '</button>' +
            '</div>'
        );

        console.log("initSelectionWidget", self);
        self.body.append(selectionWidget);

        self.body.find('.webmag_selected').length > 0 && self.updateSelectionWidget();

        // Handle selection widget buttons
        selectionWidget.on('click', '.btn-delete', function() {
            if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("deleteSnippetModal", null, function(){
                self.body.find('.webmag_selected').each(function() {
                    $(this).remove();
                });
                self.hideSelectionWidget();
                self.save();
            });

        });

        selectionWidget.on('click', '.btn-copy', function() {
            var selectedElements = [];


            self.body.find('.webmag_selected').each(function() {
                $(this).removeClass('webmag_selected');
                selectedElements.push({
                    clone: $(this).clone(true, true).wrap("<div />").parent().html()
                });
            });

            window.localStorage.setItem('clonedElements', JSON.stringify(selectedElements));
            if (typeof self.options.callFromKeditor === 'function') self.options.callFromKeditor("makeToast", {
                message: self.labels.copySnippet

            }, function(data) {

            })
            self.hideSelectionWidget();
        });

        selectionWidget.on('click', '.btn-done', function() {
            self.body.find('.webmag_selected').removeClass('webmag_selected');
            self.hideSelectionWidget();
        });
    };

    // Show selection widget when elements are selected
    KEditor.prototype.updateSelectionWidget = function() {
        var self = this;
        var selectedCount = self.body.find('.webmag_selected').length;

        if (selectedCount > 0) {
            var widget = self.body.find('.keditor-selection-widget');
            widget.find('.count').text(selectedCount);
            widget.show();
        } else {
            self.hideSelectionWidget();
        }
    };

    // Hide selection widget
    KEditor.prototype.hideSelectionWidget = function() {
        var self = this;
        self.body.find('.keditor-selection-widget').hide();
    };

    KEditor.prototype.deleteComponent = function(component) {
        flog('deleteComponent', component);

        var self = this;

        var componentType = self.getComponentType(component);
        if (componentType != "blank") {
            var componentData = KEditor.components["text"];
        } else {
            var componentData = KEditor.components[componentType];
        }
        if (typeof componentData.destroy === 'function') {
            componentData.destroy.call(componentData, component, self);
        } else {
            flog('"destroy" function of component type "' + componentType + '" does not exist');
        }

        component.remove();
    };

    KEditor.prototype.updateComponent = function(contentArea, container, component) {
        flog('updateComponent', component);

        //console.log("updateComponent")

        var self = this;
        var options = self.options;
        var body = self.body;
        var target = options.iframeMode ? self.body : self.element;

        var componentType = self.getComponentType(component);
        if (componentType != "blank") {
            var componentData = KEditor.components["text"];
        } else {
            var componentData = KEditor.components[componentType];
        }
        if (typeof componentData.update === 'function') {
            componentData.update.call(componentData, component, self);
        } else {
            flog('"update" function of component type "' + componentType + '" does not exist');
        }


        version = component.attr("data-wps-version");
        var name = component.attr("data-wps-name");

        var snippetsArticle = {
            "name": name,
            "version": version,
            "element": self.cleanFroalaContent(component.find(".keditor-component-content").children())[0]
        };




        var result = self.snippetsTemplate.filter(function(obj) {
            return obj.name == name;
        });






        if (result.length > 0) {
            if (result[0].version > version) {
                self.updateSnippet( snippetsArticle, result[0] );
                component.attr("data-wps-version", result[0].version);
                // show update button in component
                $(component).find(".keditor-toolbar .btn-component-update").css("display", "none");

                self.reinitComponent(contentArea, container, component);
            }
        } else {

        }
    };

    // update a snippet (chcked by version code)
    KEditor.prototype.updateSnippet = function(oldSnippet, newSnippet) {

        //console.log("updateSnippet", oldSnippet, newSnippet)

        oldSnippet = $(oldSnippet.element);
        newSnippet = $(newSnippet.element);


        // clone snippet
        newSnippet = newSnippet.clone(true, true);
        //console.log("newSnippet0",newSnippet.html())

        templateDivs = [];

        // prepare <template> and template divs
        prepareTemplates(oldSnippet, newSnippet);
        //console.log("newSnippet1",newSnippet.html())



        // update snippet without template divs
        // everything else gets updated
        updateSnippet(oldSnippet, newSnippet);
        //console.log("newSnippet2",newSnippet.html())

        // update and add templateDivs from <template>
        addTemplates();


       // console.log("newSnippet3",newSnippet.html())

        // replace old snippet with new snippet
        $(oldSnippet).replaceWith(newSnippet);


        function prepareTemplates(oldSnippet, newSnippet) {
            /*
                1. delete template divs from new snippet
                    - Save where to insert
                2. get template Divs from old template
                    - Save content
                3. update old template divs with template from new snippet
                4. Whole Update of remaining snippet
                5. insert  updated template divs into new snippet

                Pretty complicated stuff...this could be done much easier!
            */

            var templateDivs_new,
                templateDivs_old;


            // get template IDs
            var templates_new = newSnippet.find("template").addBack("template"),
                pgs_template;

            // iterate <template> of new snippet
            $.each(templates_new, function(index, _template_new) {



                // get content of <template> documentFragment
                _template_new = $($(_template_new).html());

                //console.log("_template_new", _template_new)

                // get template Divs of new Snippet (to get container aka where to put divs later)
                var _id = $(_template_new).attr("pgs-ams-template");
                templateDivs_new = newSnippet.find("[pgs-ams-template='" + _id + "']").addBack("[pgs-ams-template='" + _id + "']");

                // get template Divs of old Snippet
                templateDivs_old = oldSnippet.find("[pgs-ams-template='" + _id + "']").addBack("[pgs-ams-template='" + _id + "']").not("template");
                 //console.log("templateDivs_old", templateDivs_old)

                templateDivs.push({
                    el: templateDivs_old,
                    el_new: [],
                    container: templateDivs_old.parent(),
                    container_new: templateDivs_new.parent(),
                    id: templateDivs_old.attr("pgs-ams-template")
                });





                // iterate through old templateDivs and clone new <template> script each time
                $.each(templateDivs_old, function(index2, _templateDivs_old) {

                    var clone = _template_new.clone(true, true);
                    var editable = clone.find("[data-wps-edit]"),
                        _attr,
                        _name,
                        timestamp;

                    // change data-wps-edit="{i}" to data-wps-edit="1"
                    $.each(editable, function(index, _el) {
                        _el = $(_el);
                        _attr = _el.attr("data-wps-edit").replace("{i}", index2 + 1);
                        _name = _el.attr("data-wps-edit-name").replace("{i}", index2 + 1);
                        timestamp = Math.random().toString().slice(2, 11);
                        _el.attr("data-wps-edit", _attr)
                        _el.attr("data-wps-edit-name", _name)
                    })
                    // save in array
                    templateDivs[index].el_new.push(clone[0])
                })

                // remove template Divs
                $(templateDivs_new).remove();
                $(templateDivs_old).remove();

            })
        }

        function addTemplates() {
            var _templateEl_new;

            // iterate through <template>
            //console.log("templateDivs",templateDivs)
            $.each(templateDivs, function(index, _templateDivs) {

                // iterate through each templateDiv (old Snippet) of a <template>
                $.each(_templateDivs.el, function(index2, _templateEl) {
                    // console.log("_templateDivs",_templateDivs)
                    //console.log("_templateEl",_templateEl)

                    // get cloned templateDiv from <template> (new Snippet)
                    _templateEl_new = _templateDivs.el_new[index2];

                    //console.error("_templateEl_new",_templateEl_new)

                    // update content
                    updateSnippet(_templateEl, _templateEl_new);



                    // append updated template divs to newSnippet in new container
                    _templateDivs.container_new.append(_templateEl_new)

                })
            })
        }

        function updateSnippet(oldSnippet, newSnippet) {

            var self = this;
            var options = self.options;

            oldSnippet = $(oldSnippet)
            newSnippet = $(newSnippet)

            //get all editable tags from article snippet
            oldEditable = $(oldSnippet).find("[data-wps-edit]").addBack("[data-wps-edit]").not("template [data-wps-edit]");

            // put old snippet content into new snippet
            $.each(oldEditable, function(index, _elOld) {




                _elOld = $(_elOld);
                // get editable value
                _attr = _elOld.attr("data-wps-edit");

                //find same value in snippet code
                _elNew = newSnippet.find("[data-wps-edit=" + _attr + "]").addBack("[data-wps-edit=" + _attr + "]");
                //console.log(newSnippet)
                //console.log(_attr)

                //console.log(_elNew)

                // change elements
                if (_elNew.length > 0) {
                    updateEditable(_elOld, _elNew);
                }
            });
        }

        function updateEditable(_elOld, _elNew) {

                        var _src,
                _pb,
                _focus,
                _size,
                _href,
                _classChange = $(_elOld).attr('data-wps-class-change'),
                _classOptions;


            //console.log("ChangeEL", _elOld[0], _elNew[0]);
            // image
            if ($(_elOld).is("img")) {
                // get src from old snippet
                _src = $(_elOld).attr("src");
                _elNew.attr("src", _src);
                //get padding-bottom from old snippet
                _pb = _elOld.parent().css("padding-bottom");
                _elNew.parent().css("padding-bottom", _pb);
                //get focus from old snippet
                _focus = _elOld.parent().attr("data-focus");
                _elNew.parent().attr("data-focus", _focus);
                //get size from old snippet
                _size = _elOld.parent().attr("data-size");
                _elNew.parent().attr("data-size", _size);

                if(_elOld.parent().hasClass('auto-fit')){
                    _elNew.parent().addClass('auto-fit')
                }

            }
            // link
            else if ($(_elOld).is("a")) {
                //get href from old snippet
                _href = $(_elOld).attr("href");
                _elNew.attr("href", _href);
            }

            //class change
            else if(_classChange) {

                // set classchange option
                 _classOptions=_classChange.split('|');
                 var _val;

                 $.each(_classOptions, function(index, val) {
                       _val = val.split('=');
                      //add set class to new snippet
                      if($(_elOld).hasClass(_val[1])){
                          $(_elNew).addClass(_val[1]);
                      }
                 });
            }

            // normal text
            else {
                //get html from old snippet
                _elNew.html(_elOld.html());
            }
        }
    }

    // check if index.html template was updated
    KEditor.prototype.checkTemplateUpdate = function() {
        var self = this;
        var options = self.options;
        var body = self.body;


        _templateVersion = $(self.template).attr("data-wps-version");


        var _dom = self.window.document.createElement("div");
        self.getContent().then(function(content) {
            _dom.innerHTML = content.trim();

            _currentVersion = $(_dom).attr("data-wps-version");


            if(_templateVersion > _currentVersion) self.addTemplateUpdate();
            else $(body).find("#templateUpdate").remove();
          })

    }

    // update current content with default template
    KEditor.prototype.addTemplateUpdate = function() {
        var self = this;
        var options = self.options;
        var body = self.body;


        if($(body).find("#templateUpdate").length==0){
            var _button = $(
                '<button id="templateUpdate">' +
                    '<i class="fa fa-refresh"></i>' +
                '</button>'
            )

            $(body).append(_button)

            _button.on('click', function(e) {
                if (confirm('Are you sure that you want to update the template? It will revert the template to the default style. Your content will stay. This action can not be undo!')) {

                    var _template = $(self.template);
                    self.getContent().then(function(content) {
                        var _content = $(content)
                        // set template to default first
                        // could be that a default element was appended somewhere else
                        if(typeof self.options.template.default == 'function') self.options.template.default(_content);

                        var snippets_t = _template.find('[data-wps-type="container-content"]');
                        var snippets_c = _content.find('[data-wps-type="container-content"]');

                        $.each(snippets_t, function(index, val) {

                            $(val).html("");
                            $.each($(snippets_c[index]).children(), function(index2, val2) {

                                // insert all children
                                // if()
                                $(val).append( val2 )
                            });


                        });

                        self.setContent(_template.prop("outerHTML"));

                        _button.remove();
                    })



                 }

            })
        }
    }




    KEditor.prototype.initDynamicContent = function(dynamicElement) {
        flog('initDynamicContent', dynamicElement);

        var self = this;
        var options = self.options;
        var component = dynamicElement.closest('.keditor-component-sortable');
        var contentArea = dynamicElement.closest('.keditor-content-area');

        dynamicElement.attr('id', self.generateId('dynamic-element'));

        if (typeof options.onBeforeDynamicContentLoad === 'function') {
            options.onBeforeDynamicContentLoad.call(self, dynamicElement, component, contentArea);
        }

        var dynamicHref = dynamicElement.attr('data-dynamic-href');
        var data = self.getDataAttributes(component, ['data-wps-type', 'data-dynamic-href'], false);
        data = $.param(data);
        flog('Dynamic href: ' + dynamicHref, 'Data: ' + data);

        return $.ajax({
            url: dynamicHref,
            data: data,
            type: 'GET',
            dataType: 'HTML',
            success: function(response, status, xhr) {
                flog('Dynamic content is loaded', dynamicElement, response, status, xhr);
                dynamicElement.html(response);

                if (typeof options.onDynamicContentLoaded === 'function') {
                    options.onDynamicContentLoaded.call(self, dynamicElement, response, status, xhr, contentArea);
                }
            },
            error: function(response, status, xhr) {
                flog('Error when loading dynamic content', dynamicElement, response, status, xhr);

                if (typeof options.onDynamicContentError === 'function') {
                    options.onDynamicContentError.call(self, dynamicElement, response, status, xhr, contentArea);
                }
            }
        });
    };

    KEditor.prototype.getComponentContent = function(component) {
        flog('getComponentContent', component);



        var self = this;
        component = component.clone();



        var componentType = self.getComponentType(component);
        if (componentType != "blank") {
            var componentData = KEditor.components["text"];
        } else {
            var componentData = KEditor.components[componentType];
        }




        var _classNames = component[0].classList;
        _classNames.remove("keditor-component", "existing-component", "keditor-initialized-component");


        var _cl="";
        for(var i=0; i<=_classNames.length; i++){
            if(_classNames[i]!="undefined") _cl += _classNames[i] + " ";

        }

        var _data_class = component.attr('data-wps-class');



        _cl += _data_class;

        //var componentData = KEditor.components[componentType];
        var dataAttributes = self.getDataAttributes(component, null, true);

        var content;


        if(!componentData) return;
        if (typeof componentData.getContent === 'function') {
            content = componentData.getContent.call(componentData, component, self);
        } else {
            //never gets called!
            flog('"getContent" function of component type "' + componentType + '" does not exist. Using default getContent method');
            var componentContent = component.children('.keditor-container-inner');
            content = componentContent.html();

            console.log("content", content);
            console.log("componentContent", componentContent);
        }

         // get dom with vanilla JS so it shows no error (scope has to be in iframe!)
        var _dom = self.window.document.createElement("div");
        if(!content){
            return '';
        }
        _dom.innerHTML = content.trim();
        _dom = $(_dom);
        _dom.find('[data-dynamic-href]').each(function() {
            $(this).html('');
        });
        content = _dom.html();
        /*var tempDiv = $('<div />').html(content);
        tempDiv.find('[data-dynamic-href]').each(function() {
            $(this).html('');
        });
        content = tempDiv.html();*/
        var $section = $('<section />'); // Replace with your div's ID or another selector

        // Iterate over the object and add each attribute to the div
        $.each(dataAttributes, function(key, value) {
            $section.attr(key, value);
        });
        $section.addClass(_cl);
        $section.html(content);


        return $section;

        return '<section  class="'+_cl+'" ' + dataAttributes.join(' ') + '>' + content + '</section>';
    };


    KEditor.prototype.cleanFroalaContent = function(container, all) {
        var containerContent;




        container.find("[data-wps-edit]").each(function() {
           // console.log("cleanFroalaContent 1", this)
           // console.log("cleanFroalaContent 1", this.innerHTML)

            // dont clean when value is false (ClassChange i.e.)
            if(!all){
                if($(this).attr("data-wps-edit").indexOf("false") != -1) return;
                if($(this).attr("data-wps-edit")=="false") return;
                if($(this).attr("data-wps-edit-disable")) return;

            }

            containerContent = $(this);

            if (!$(containerContent).is("img")
                && !$(containerContent).is("a")
                && !$(containerContent).is("button")
                && !containerContent[0].hasAttribute("data-wps-edit-disable")) {


                var _el = $(containerContent).find(".fr-element");


                if(_el.length>0){
                   var inner = _el.last().html();
                  // console.warn("i",_el, _el.html());

                  // console.warn("o",containerContent, containerContent.html());
                   if(inner==undefined || inner==null){
                     console.error("An error occured. Please reload the page and contact support.");
                     return;
                   }else{
                     containerContent.html(inner);
                   }
                   $(this).removeClass('fr-box fr-inline fr-ltr');
                   $(this).removeAttr('role');
                }

             }
             //console.error("cleanFroalaContent 2", this.innerHTML);
             //console.log("cleanFroalaContent 2", this)
        })


        //console.log(" return cleanFroalaContent container", container.html())

        return container;
    }



    KEditor.prototype.cleanContainerContent = function(container, isNested, min) {
        flog('getContainerContent - isNested=' + isNested, container);
        var self = this;
        var containerInner = container.clone(true, true);
        containerInner = self.cleanFroalaContent(containerInner);
        containerInner.find('[data-wps-type]').each(function() {
            var component = this;
            var componentType = self.getComponentType($(component));
            var multipleTypes = componentType.split(',');

            $(multipleTypes).each(function(index, el) {
                if (self.window.p$.snippet && self.window.p$.snippet[el]) { //if this snippet has a js file
                    snip = new self.window.p$.snippet[el](); // create snippet
                    if(typeof snip.destroy === 'function') snip.destroy(component);
                }
            });
        })




        console.log("containerInner", containerInner.attr('data-wps-container'));
        if (containerInner.attr('data-wps-container')) {
            // search for classname keditor-component-content
            var _containerInner = containerInner.find('> .keditor-container-inner > .keditor-component-content > div');
            if(_containerInner.length>0){
                containerInner.html(_containerInner.clone(true, true))
            }
            console.log("containerInner", containerInner[0].outerHTML);
        }



        // console.log("containerInner", containerInner)
        // console.log("containerInner", containerInner[0].outerHTML);
        // console.log("dsa",containerInner.find(".keditor-container-inner"));
        // console.log("dsa",containerInner.find(".keditor-container-inner  .keditor-component-content"));
        // console.log("dsa",containerInner.find(".keditor-container-inner  .keditor-component-content > div"));
        // //containerInner.replaceWith(containerInner.find(".keditor-container-inner  .keditor-component-content > div"));
        // containerInner.find(".keditor-container-inner").each(function() {

        //     var containerContent = $(this);
        //     console.log("containerContent", containerContent.parent()[0].outerHTML);
        //      console.log("containerContent > div", containerContent.find(".keditor-component-content > div")[0].outerHTML);
        //     //containerContent.parent().html(containerContent.find(".keditor-component-content > div"));
        //    console.log("containerContent2", containerContent[0].outerHTML);
        // })



        //




        containerInner.find('main[data-wps-type=container-content]').not(isNested ? '' : '.keditor-sub-container-content').each(function() {
            var containerContent = $(this);
            containerContent.removeClass('keditor-container-content keditor-highlighted-dropzone keditor-sub-container-content ui-droppable ui-sortable').removeAttr('id');
            containerContent.children().each(function() {
                var child = $(this);
                if (child.is('.keditor-component')) {
                    //console.error("!!keditor-component");
                    child.replaceWith(self.getComponentContent(child));
                } else if (child.is('.keditor-sub-container')) {
                    child.find(".keditor-ui.keditor-container-inner").replaceWith(child.find(".keditor-container-inner").last().children());
                }
            });
        });

        var containerContent;



        // delete id of keditor-component-content class
        containerInner.find('.keditor-component-content').each(function() {
            containerContent = $(this);
            containerContent.removeAttr('id');
        });


        // clean all contenteditable
        containerInner.find('[contenteditable]').each(function() {
            containerContent = $(this);
            if(!containerContent.hasClass('wbmg_variable') && !containerContent.hasClass('wbmg_form') && !containerContent.hasClass('wbmg_editable')){
                containerContent.removeAttr('contenteditable');
            }
        })

        // clean all editables
        containerInner.find('.cke_editable').each(function() {
            containerContent = $(this);
            containerContent.removeClass('cke_editable cke_editable_inline cke_contents_ltr cke_show_borders');
        })

        containerInner.find('.keditor-toolbar').each(function() {
            containerContent = $(this);
            containerContent.remove();

        })
        containerInner.find('.btn-component-paddingBottom').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })
        containerInner.find('.btn-component-paddingTop').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })
        containerInner.find('.btn-component-paddingRight').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })
        containerInner.find('.btn-component-paddingLeft').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })

        containerInner.find('.keditor-ui').each(function() {
            containerContent = $(this);
            containerContent.removeClass('keditor-ui');
        })

        containerInner.find('.showed-keditor-toolbar').each(function() {
            containerContent = $(this);
            containerContent.removeClass('showed-keditor-toolbar');
        })

        containerInner.find('.divToolImg').each(function() {
            containerContent = $(this);
            containerContent.remove();

        });

        return containerInner[0].outerHTML;
    };



    KEditor.prototype.getContainerContent = function(container, isNested, min) {
        flog('getContainerContent - isNested=' + isNested, container);



        var self = this;



        // deletes dynamic snippet input!

        // Function to check and remove empty elements from live editor data
        function removeIfEmpty(element) {
            // Check if the element is one of the specified tags, is empty, and has no classname
            if (!$(element).attr('class') && $(element).is('span, div, strong, em, u, s, sub, sup, p, h1, h2, h3, h4, h5, h6, table, li, ul') && $.trim($(element).text()) === '' && $(element).children().length === 0) {
                $(element).remove();
                return true; // Indicates the element was removed
            }
            return false; // Indicates the element was not removed
        }
        // Iterate over the specified elements, starting from the deepest child
        // container.children('.keditor-container-inner').find('span:not([class]), div:not([class]), strong:not([class]), em:not([class]), u:not([class]), s:not([class]), sub:not([class]), sup:not([class]), p:not([class]), h1:not([class]), h2:not([class]), h3:not([class]), h4:not([class]), h5:not([class]), h6:not([class]), table:not([class]), li:not([class]), ul:not([class])').get().reverse().forEach(function(el) {
        //     // If the current element is removed, check its parent
        //     if (removeIfEmpty(el) && el.parentNode) {
        //         removeIfEmpty(el.parentNode);
        //     }
        // });


        //console.log(container.children('.keditor-container-inner').clone().html());


        //var containerInner = container.children('.keditor-container-inner').clone();

        //console.log(containerInner.html())


        //return containerInner.html();


        var containerInner = container.children('.keditor-container-inner').clone(true, true);




        // this scritp deletes all froala additional elements (there is no getCleanContents() function)
        // start this before all other because of crazy errors!
       /* containerInner.find("[data-wps-edit]").each(function() {
            containerContent = $(this);
            $(this).removeClass('fr-box fr-inline');
            $(this).removeAttr('role');
             if (!$(containerContent).is("img") && !$(containerContent).is("a") && !$(containerContent).is("button")) {
                var _el = $(containerContent).find(".fr-element").addBack(".fr-element");
                if(_el.length>0){
                   var inner = $(containerContent).find(".fr-element").addBack(".fr-element")[0].innerHTML;
                   $(this)[0].innerHTML=inner;
                }

             }
        })*/



        containerInner = self.cleanFroalaContent(containerInner);





        //container.children('.keditor-container-inner').find('[data-wps-type]').each(function() {

        containerInner.find('[data-wps-type]').each(function() {
            var component = this;

            var componentType = self.getComponentType($(component));

            //var componentType = $(this).attr('data-wps-type');

            var multipleTypes = componentType.split(',');

            $(multipleTypes).each(function(index, el) {

               /* console.error("DESTROY");
                console.log($(component).find(".flickity-enabled"))
                console.log($(component).find(".flickity-enabled").data("flickity"))*/
                if (self.window.p$.snippet && self.window.p$.snippet[el]) { //if this snippet has a js file
                    snip = new self.window.p$.snippet[el](); // create snippet
                    if(typeof snip.destroy === 'function') snip.destroy(component);
                }
            });
        })







        containerInner.find('main[data-wps-type=container-content]').not(isNested ? '' : '.keditor-sub-container-content').each(function() {
            var containerContent = $(this);
            containerContent.removeClass('keditor-container-content keditor-highlighted-dropzone keditor-sub-container-content ui-droppable ui-sortable').removeAttr('id');

         //   console.log(containerContent.html())

            containerContent.children().each(function() {
                var child = $(this);

                if (child.is('.keditor-component')) {
                    //console.error("!!keditor-component");
                    child.replaceWith(self.getComponentContent(child));

                } else if (child.is('.keditor-sub-container')) {

                    child.find(".keditor-ui.keditor-container-inner").replaceWith(child.find(".keditor-container-inner").last().children());

                    //console.error("!!keditor-sub-container");
                //     var _classNames = child[0].classList;
                //    // _classNames.remove("keditor-container", "existing-container", "keditor-initialized-container", "keditor-component-sortable", "undefined");
                //     child.find('.cke_editable').each(function() {
                //         containerContent = $(this);
                //         containerContent.removeClass('cke_editable cke_editable_inline cke_contents_ltr cke_show_borders');
                //     })
                //     child.removeAttr('id');
                //     console.log(child);
                //     console.log(child.find(".keditor-ui.keditor-container-inner"))
                    //child.replaceWith(child.find(".keditor-container-inner").children[0]);
                    //child.replaceWith(self.getComponentContent(child));
                    //child.replaceWith(self.getContainerContent(child, true));
                }
            });
        });

        //console.error(containerInner.html())


        var containerContent;

        // clean all contenteditable
        containerInner.find('[contenteditable]').each(function() {
            containerContent = $(this);
            if(!containerContent.hasClass('wbmg_variable') && !containerContent.hasClass('wbmg_form')){
                containerContent.removeAttr('contenteditable');
            }
        })


        // clean all editables
        containerInner.find('.cke_editable').each(function() {
            containerContent = $(this);
            containerContent.removeClass('cke_editable cke_editable_inline cke_contents_ltr cke_show_borders');

        })

        containerInner.find('.keditor-toolbar').each(function() {
            containerContent = $(this);
            containerContent.remove();

        })
        containerInner.find('.btn-component-paddingBottom').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })
        containerInner.find('.btn-component-paddingTop').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })
        containerInner.find('.btn-component-paddingRight').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })
        containerInner.find('.btn-component-paddingLeft').each(function() {
            containerContent = $(this);
            containerContent.remove();
        })

        containerInner.find('.keditor-ui').each(function() {
            containerContent = $(this);
            containerContent.removeClass('keditor-ui');

        })

        containerInner.find('.showed-keditor-toolbar').each(function() {
            containerContent = $(this);
            containerContent.removeClass('showed-keditor-toolbar');
        })

        containerInner.find('.divToolImg').each(function() {
            containerContent = $(this);
            containerContent.remove();

        });



        // Function to check and remove empty elements
        function removeIfEmpty(element) {
            // Check if the element is one of the specified tags, is empty, and has no classname
            if (!$(element).attr('class') && $(element).is('span, div, strong, em, u, s, sub, sup, h1, h2, h3, h4, h5, h6, table, li, ul') && $.trim($(element).text()) === '' && $(element).children().length === 0) {
                $(element).remove();
                return true; // Indicates the element was removed
            }
            return false; // Indicates the element was not removed
        }

        // // Iterate over the specified elements, starting from the deepest child
        // containerInner.find('span:not([class]), div:not([class]), strong:not([class]), em:not([class]), u:not([class]), s:not([class]), sub:not([class]), sup:not([class]), h1:not([class]), h2:not([class]), h3:not([class]), h4:not([class]), h5:not([class]), h6:not([class]), table:not([class]), li:not([class]), ul:not([class])').get().reverse().forEach(function(el) {
        //     // If the current element is removed, check its parent
        //     if (removeIfEmpty(el) && el.parentNode) {
        //         removeIfEmpty(el.parentNode);
        //     }
        // });

        function removeEmptyElements(containerInner) {
            // Define the list of target elements to check
            const targetTags = [
                'span', 'div', 'strong', 'em', 'u', 's', 'sub', 'sup',
                'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'table', 'li', 'ul'
            ];

            // Define the list of removable class names
            const removableClassNames = [
                'btn', 'btn-outline-dark', 'btn-outline-white', 'btn-outline-secondary',
                'btn-outline-primary', 'btn-dark', 'btn-white', 'btn-primary',
                'btn-secondary', 'btn-tertiary', 'btn-quaternary', 'text-dark',
                'text-white', 'text-primary', 'text-secondary', 'text-tertiary',
                'text-quaternary', 'text-decoration-underline', 'text-decoration-none',
                'small', 'font-weight-normal', 'font-weight-light', 'rounded', 'border-2',
                'shadow', 'shadow-sm', 'mt-2', 'ml-2', 'mr-2', 'mb-2', 'align-middle',
                'hidden-sm-down', 'float-sm-left', 'mb-3', 'mr-sm-3', 'mt-0',
                'float-sm-right', 'ml-sm-3', 'rounded-circle', 'stretched-link',
                'mt-auto', 'w-100', 'customTypo1', 'customTypo2', 'customTypo3',
                'display-1', 'display-2', 'display-3', 'display-4', 'wbmg-mark',
                'pb-0', 'wmag_collapse', 'wmag_footnote', 'text-uppercase',
                'align-top', 'align-bottom', 'no-hyphen', 'wps-aos-fade-in',
                'wps-aos-fade-up', 'wps-aos-fade-right', 'wps-aos-fade-left',
                'wps-aos-fade-down', 'wps-aos-zoom-in', 'wps-aos-zoom-out', 'mb-0'
            ];

            // Define the list of removable IDs
            const removableIds = ['isPasted'];

            // Get all target elements within the containerInner
            const elements = Array.from($(containerInner).find(targetTags.join(',')));

            // Reverse the array to process elements bottom-up
            elements.reverse().forEach(element => {
                if (isEmpty(element)) {
                    console.log('Removing empty element:', element); // Optional: log removed elements for debugging
                    $(element).remove();
                }
            });

            function isEmpty(element) {
                // Check for &nbsp; entities
                if (element.innerHTML.includes('&nbsp;') || element.innerHTML.includes(' ')) {
                    return false;
                }

                // Check if element has meaningful style attributes
                const style = element.style;
                if (style.padding || style.paddingTop || style.paddingBottom ||
                    style.paddingLeft || style.paddingRight ||
                    style.margin || style.marginTop || style.marginBottom ||
                    style.marginLeft || style.marginRight) {
                    return false;
                }

                // Check if element contains any <br> tags
                if (element.getElementsByTagName('br').length > 0) return false;

                const hasProtectedAttrs = Array.from(element.attributes).some(attr => {
                    const name = attr.name.toLowerCase();
                    return name.startsWith('data-');
                });
                if (hasProtectedAttrs) return false;

                const elementClasses = Array.from(element.classList);
                const hasOnlyRemovableClasses = elementClasses.length === 0 ||
                    elementClasses.every(cls => removableClassNames.includes(cls));
                if (!hasOnlyRemovableClasses) return false;

                const id = element.getAttribute('id');
                if (id && !removableIds.includes(id)) return false;

                const hasOnlyAllowedAttrs = Array.from(element.attributes).every(attr => {
                    const name = attr.name.toLowerCase();
                    return ['class', 'style', 'id', 'title', 'aria-label', 'role'].includes(name);
                });
                if (!hasOnlyAllowedAttrs) return false;

                // Modified text content check to preserve &nbsp;
                const textContent = element.textContent;
                if (textContent && textContent.trim() && textContent !== ' ') return false;

                if (element.childNodes.length === 0) return true;

                return Array.from(element.childNodes).every(child => {
                    if (child.nodeType === Node.TEXT_NODE) {
                        const text = child.textContent;
                        // Preserve if text contains &nbsp;
                        return !text.trim() && text !== ' ';
                    }
                    if (child.nodeType === Node.ELEMENT_NODE) {
                        if (child.tagName.toLowerCase() === 'br') return false;

                        const childClasses = Array.from(child.classList);
                        const childHasOnlyRemovableClasses = childClasses.length === 0 ||
                            childClasses.every(cls => removableClassNames.includes(cls));
                        return childHasOnlyRemovableClasses && isEmpty(child);
                    }
                    return true;
                });
            }
        }

        // Example usage:
        // Assuming `containerInner` is a jQuery element
        removeEmptyElements(containerInner);



        containerInner.find('.webmag_selected').each(function() {
            containerContent = $(this);
            containerContent.removeClass('webmag_selected');
        })



        // minimal version of article data for the frontend
        if(min){


            var _src;
             //change all imgs src for lazyloading
            containerInner.find('img').each(function() {
                containerContent = $(this);
                if( containerContent[0].hasAttribute('data-no-lazy') ) return;
                //containerContent.addClass("wps-lazy");
                _src = containerContent.attr("src");

                if(!self.checkForCloudImage(_src)){
                    var _w = 600;
                    var _h = 400;
                    // console.error(_w, _h);
                    // console.log("containerContent", containerContent);
                    // console.error("img transform",self.cloudImageFunction(_src, { width: 600, height: 400 }, {width:1200, height:1200 }, 10));
                    _src = self.cloudImageFunction(_src, { width: 600, height: 400 }, {width:1200, height:1200 }, 10);
                }




                _src = _src.replace(/&q=85/g, '&q=10');
                containerContent.attr("src", _src);
                containerContent.attr('data-src', _src);
                containerContent.attr('data-url', _src);
                if(containerContent.attr('srcset')) containerContent.attr('data-srcset', containerContent.attr('srcset').replace(/&q=85/g, '&q=10'));

                var sizes = "(min-width: 1440px) 1920px, (min-width: 1025px) 1440px, (max-width: 1024px) and (min-width: 768px) 1024px, (max-width: 768px) and (min-width: 640px) 640px, 100vw";
                containerContent.attr("sizes", sizes);




                containerContent.removeAttr('srcset');


                // srcset(containerContent[0]); //if sourceset, set large version as source
                // _src = containerContent.attr("src");
                // containerContent.attr('ci-src', _src);
                // containerContent.removeAttr('src');
                // containerContent.removeAttr('data-src');
                // containerContent.removeAttr('data-srcset');
                // containerContent.removeAttr('sizes');
                containerContent.addClass("wps-lazy");

            })

            function srcset(image) {
              if (!image.attributes['data-srcset'] && !image.attributes['srcset']) return false;


              var candidates = image.attributes['data-srcset'] || image.attributes['srcset'];
              if (candidates == "") return false;
              candidates = candidates.nodeValue.split(',');
              if (candidates[0] == "") return false

              for (var i = 0; i < candidates.length; i++) {
                // The following regular expression was created based on the rules
                // in the srcset W3C specification available at:
                // http://www.w3.org/html/wg/drafts/srcset/w3c-srcset/

                var descriptors = candidates[i].match(
                    /^\s*([^\s]+)\s*(\s(\d+)w)?\s*(\s(\d+)h)?\s*(\s(\d+)x)?\s*$/
                  );
                if(descriptors==undefined) return;//safety
                var  filename = descriptors[1],
                  width = descriptors[3] || false,
                  height = descriptors[5] || false,
                  density = descriptors[7] || 1;

                //console.log(filename)
                /*if (width && width < maxWidth) {

                    continue;
                }

                if (height && height < maxHeight) {
                    continue;
                }

                if (density && density > maxDensity) {
                    continue;
                }*/
                filename = filename.replace(/&q=85/g, '&q=10');
                image.src = filename;
                i = candidates.length;
              }
            }

            var _original_embed;
            containerInner.find('.fr-embedly').each(function() {
              //$(this).addClass("wbmg_embedly");
              //_original_embed = $(this).attr("data-original-embed");
              $(this).html("");
            })



            // find links with classname 'inlineCollapse'
            $(containerInner).find("a.inlineCollapse").each(function(index, obj) {
                var _contentId = $(this).attr("data-target");
                $(this).addClass("collapsed").attr("aria-expanded", "false");
                if(_contentId){
                    var _content = $(containerInner).find(_contentId);
                    _content.removeClass("show");
                }
            })



            $(containerInner).find("[class^='wps-aos'],div[class*=' wps-aos']").each(function(index, obj) {
                className = this.className.match(/wps\-aos\-[^\s]*/);
                if (className.length > 0) {
                    $(this).removeClass(className);
                  $(this).attr("data-aos", className[0].replace("wps-aos-", ""));
                }
            })


            // delete all data-wps-attributes from elements with data-wps-edit attribute
            containerInner.find('[data-wps-edit], section').each(function() {
                var attrs = this.attributes;
                var toRemove = [];
                // cache the jquery object containing the element for better performance
                var element = $(this);

                // iterate the attributes
                for (attr in attrs) {
                 if (typeof attrs[attr] === 'object' &&
                    typeof attrs[attr].name === 'string' &&
                    (/(^data-wps)(?!(-type|-modal|-tf-widget|-chart|-context|-dynamic-page|-variable|-countup|-toc|-fade|-laterpay|-edit-variable|-form-id|-form-data|-index|-attr))/).test(attrs[attr].name)) {
                        // Unfortunately, we can not call removeAttr directly in here, since it
                        // hurts the iteration.
                        toRemove.push(attrs[attr].name);
                    }
                }


                for (var i = 0; i < toRemove.length; i++) {
                 element.removeAttr(toRemove[i]);
                }
            })


            //custom aos
            containerInner.find('section.section').each(function() {
                containerContent = $(this);
                containerContent.attr("data-aos","true");
                containerContent.attr("data-aos-id", "scroll-snippet-tracking");
                containerContent.removeAttr('id');
            })




            //Variables
            containerInner.find('.wbmg_variable').each(function() {
                // containerContent = $(this);
                // if(containerContent.html()=="") containerContent.remove()
                //     else{
                //         containerContent.html("{{{"+containerContent.html()+"}}}");
                //     }
            })

            //create Footnotes

            containerInner.find('.wmag_footnote').each(function() {
                containerContent = $(this);
                if(containerContent.html()=="") containerContent.remove()
                    else{
                        containerContent.attr({
                            'data-toggle': "tooltip",
                            'title': containerContent.html()
                        });
                        containerContent.html("");
                    }
            })

            containerInner.find('.wmag_modal').each(function() {
                containerContent = $(this);
                if(containerContent.html()=="") containerContent.remove()
                    else{
                        containerContent.attr({
                            'data-original-title': containerContent.html()
                        });
                        containerContent.html("");
                    }
            })





            containerInner.find('.img-drag-handler').each(function() {
                containerContent = $(this);
                containerContent.remove();
            })

            containerInner.find('.chartjs-size-monitor').each(function() {
                containerContent = $(this);
                containerContent.remove();
            })

            containerInner.find('.webmag_hide').each(function() {
                containerContent = $(this);
                containerContent.remove();

            })
            containerInner.find('[data-wps-version]').each(function() {
                containerContent = $(this);
                containerContent.removeAttr('data-wps-version');
            })

            containerInner.find('[data-wps-type]').each(function() {
                containerContent = $(this);
                //console.error("Found type", containerContent)
                //containerContent.removeAttr('data-wps-type');
            })

            // needed to make snippets interactive!!
            containerInner.find('[data-wps-name]').each(function() {
                containerContent = $(this);
                //containerContent.removeAttr('data-wps-name');
            })

            // needed
            containerInner.find('[data-toolbar]').each(function() {
                containerContent = $(this);
                //containerContent.removeAttr('data-toolbar');
            })

            // needed
            containerInner.find('[data-toolbar-duplicate]').each(function() {
                containerContent = $(this);
                //containerContent.removeAttr('data-toolbar-duplicate');
            })

            // needed
            containerInner.find('[data-toolbar-delete]').each(function() {
                containerContent = $(this);
                //containerContent.removeAttr('data-toolbar-delete');
            })

            containerInner.find('template.pgs-cb-template').each(function() {
                containerContent = $(this);
                containerContent.remove();
            });

            containerInner.find('[data-wps-edit-variable]').each(function() {
                containerContent = $(this);
                //containerContent.removeAttr('data-wps-edit-variable');
            });

            containerInner.find('[spellcheck]').each(function() {
                containerContent = $(this);
                containerContent.removeAttr('spellcheck');
            });

            containerInner.find('[pgs-ams-template]').each(function() {
                containerContent = $(this);
                containerContent.removeAttr('pgs-ams-template');
            });
             // fix handlebars clearing on data-original-title (from data-wps-variable) -> set template again
            //custom aos
            // needed
            containerInner.find('.footnode_modal[data-wps-variable]').each(function() {
                containerContent = $(this);
                var _var = containerContent.attr("data-wps-variable");
                console.log("var", _var);

                if(_var && _var !="Custom Text") containerContent.attr("data-original-title", "{{"+_var.replace(/\s/g, "")+"}}");
                //containerContent.removeAttr('data-toolbar');
            })


            // console.log("containerInner.html()", containerInner.html())

        }








        return containerInner.html();
        //return '<pgs-article class="' + self.options.data.template + '">' + containerInner.html() + '</pgs-article>';
    };

    KEditor.prototype.getContent = function(inArray, min) {
        var deferred = $.Deferred();
        var self = this;
        var options = self.options;
        var result = [];
        var target = options.iframeMode ? self.body : self.element;

        setTimeout(function () {
            if (target.is('textarea')) {
                target = $(target.attr('data-keditor-wrapper'));
            }


            //console.error(target.find('.keditor-content-area').html())


            target.find('.keditor-content-area').each(function() {
                var html = '';
                $(this).children('.keditor-container').each(function() {


                    var container = $(this);

                    html += self.getContainerContent(container, false, min);

                });

                result.push(html);
            });
            deferred.resolve(inArray ? result : result.join('\n'));

        }, 100);




        return deferred.promise();

    };

    KEditor.prototype.setContent = function(content, contentArea) {
        var self = this;
        var options = self.options;
        var body = self.body;
        var target = options.iframeMode ? self.body : self.element;




        if (!contentArea) {
            contentArea = target.children();
            contentArea = $(target).find(".keditor-content-area")
        } else {
            if (!contentArea.jquery) {
                contentArea = target.find(contentArea);
                            }
        }

        if (contentArea.length === 0) {
            error('Content area does not exist!');
        }

        if (content) {
            // so no image errors in console (scope in iframe window)
            var _dom = self.window.document.createElement("div");
            _dom.innerHTML = content.trim();
            _dom = _dom.children[0];

            var isSection = $(_dom).is('section');
            if (!isSection) {
                content = "<section>" + $(_dom).prop("outerHTML") + "</section>";
            }
            originalContent = content;

              target.find("main section").remove();
        }




        var html = self.compileHandlebars(content);


        //$(".opened-keditor-setting").removeClass("opened-keditor-setting");
        self.toggleSettings(false)


        contentArea.html(html);


        self.initContentArea(contentArea);

        self.initSnippets();

        self.checkContentArea(contentArea);



        if (options.nestedContainerEnabled) {
            //self.containerSnippets.draggable('option', 'connectToSortable', self.getConnectedForContainerSnippets());
        }

        self.save();
        //

        //if(self.componentSnippets) self.componentSnippets.draggable('option', 'connectToSortable', body.find('.keditor-container-content'));
    };



    // Also set all Helpers in p$.utils.compileHandlebars!!
    KEditor.prototype.compileHandlebars = function(content) {
      var self = this;



       _siblings = self.options.allPageSiblings;
        var prevNextSiblings = self.options.prevNextSiblings;




        var self = this;


            function getCategory(arr){
                if(!arr) return "";
                for (var x = 0; x < arr.length; x++) {
                    if (arr[x].charAt(0)!="#") return arr[x];
                }
                return "";
            }


            Handlebars.registerHelper('ams_each', function(context, options) {
              var   ret = "",
                    _arr;


              var i = options.hash.start || 0;
              var end;

              // only return articles with menu true
              if(context=='articles'){
                _arr = _siblings;
                //_arr = p$.data.current.modules.content;



                end = options.hash.end || _arr.length;

                for(var i, j=end; i<j; i++) {
                    options.data.index = i+1;
                    options.data.first = i === 0;
                    options.data.last = i === (end - 1);
                    if(_arr[i]){
                        options.data.link = new Handlebars.SafeString(_arr[i].slug);
                        options.data.category = new Handlebars.SafeString(getCategory(_arr[i].keywords)); // get first value in category[] as category
                        if(!_arr[i].isHidden ) ret = ret + options.fn(_arr[i]);
                    }
                }

                return ret;
              }

        });




        Handlebars.registerHelper('times', function(n, block) {
            var accum = '';
            for (var i = 1; i <= n; ++i) {
                block.data.index = i;
                block.data.first = i === 0;
                block.data.last = i === (n - 1);
                accum += block.fn(this);
            }
            return accum;
        });

         Handlebars.registerHelper('ams_ArticleLink', function(n, block) {
                var _m_id = $.grep(_siblings, function(e){ return e.id==n && e.isPublished; });

                //if (!_m_id) _m_id = p$.theme.popup.activePopup.data;
                if(_m_id.length>0){
                    return new Handlebars.SafeString(_m_id[0].slug);
                }else{
                    return new Handlebars.SafeString("");
                }
        });

        Handlebars.registerHelper('ams-nextArticle', function(n, block) {
            var accum = '';
            // not working in V2??
            //if ( window.parent._AMS && window.parent._AMS.project) {
                // var _content = _siblings;

                // var index = _content.map(function(e) {
                //     return e.id;
                // }).indexOf(self.options.data.id); //get index of clicked module
                // //find next article
                // // _m_next == undefined when there is no next article
                // var _m_next;

                // for (var i = index + 1; i < _content.length; i++) {
                //     _m_next = _content[i];
                //     if (!_m_next.isHidden) {
                //         break;
                //     } else {
                //         _m_next = false
                //     }
                // };

                _m_next = prevNextSiblings.next;




                if(_m_next){
                    n = n.split("."); //n could be image.url, so iterate through object
                    var _obj = _m_next;
                    var _obj2 = _obj;
                    $.each(n, function(index, val) {
                        /* iterate through array or object */
                        if (val == "path") val = "slug";
                        if (val == "title") _obj2 = _obj.name;
                        if (val == "description") _obj2 = _obj.description;
                        if (val == "focalpoint") _obj2 = _obj.thumbnails.focalpoint.x + "," +_obj.thumbnails.focalpoint.y;
                        if (val == "width") _obj2 = _obj.thumbnails.width;
                        if (val == "height") _obj2 = _obj.thumbnails.height;
                        if (val == "image") _obj2 = _obj.thumbnails.url;
                        if (_obj[val] || _obj[val]=="") _obj2 = _obj[val];

                        if(_obj2==null || typeof _obj2 === 'object') _obj2="";

                    });
                    //if (n == "path") _obj =_obj;
                    return new Handlebars.SafeString(_obj2);
                }

            //}

        });

        Handlebars.registerHelper('ams-prevArticle', function(n, block) {
            var accum = '';

            //if (window.parent._AMS && window.parent._AMS.project) {
                // var _content = _siblings;

                // var index = _content.map(function(e) {
                //     return e.id;
                // }).indexOf(self.options.data.id); //get index of clicked module


                // //find next article
                // // _m_prev == undefined when there is no next article
                // var _m_prev;
                // for (var j = index-1 ; j >= 0; j--) {
                //     _m_prev = _content[j];
                //     if (!_m_prev.isHidden ) {
                //         break;
                //     } else {
                //         _m_prev = false
                //     }
                // };


                //if (!_m_prev) _m_prev = self.options.data;
                // _m_prev = prevNextSiblings.prev;
                // if(_m_prev){
                //     n = n.split("."); //n could be image.url
                //     var _obj = _m_prev;
                //     $.each(n, function(index, val) {
                //         /* iterate through array or object */
                //         if(_obj[val] || _obj[val]=="") _obj = _obj[val]
                //     });

                //     if (n == "path") _obj = _obj
                //     return new Handlebars.SafeString(_obj);
                // }

                _m_prev = prevNextSiblings.prev;
                if(_m_prev){
                    n = n.split("."); //n could be image.url, so iterate through object
                    var _obj = _m_prev;
                    var _obj2 = _obj;
                    $.each(n, function(index, val) {
                        /* iterate through array or object */
                        if (val == "path") val = "slug";
                        if (val == "title") _obj2 = _obj.name;
                        if (val == "description") _obj2 = _obj.description;
                        if (val == "focalpoint") _obj2 = _obj.thumbnails.focalpoint.x + "," +_obj.thumbnails.focalpoint.y;
                        if (val == "width") _obj2 = _obj.thumbnails.width;
                        if (val == "height") _obj2 = _obj.thumbnails.height;
                        if (val == "image") _obj2 = _obj.thumbnails.url;
                        if (_obj[val] || _obj[val]=="") _obj2 = _obj[val]

                        if(_obj2==null || typeof _obj2 === 'object') _obj2="";

                    });
                    //if (n == "path") _obj =_obj;
                    return new Handlebars.SafeString(_obj2);
                }


            //}

        });


        Handlebars.registerHelper('ams-settings', function(n, block) {
            var accum = '';

            if (false && window.parent._AMS && window.parent._AMS.project) {
                var _content = _siblings;

                n = n.split("."); //n could be image.url, so iterate through object
                var _obj = _content;
                $.each(n, function(index, val) {

                    if(_obj[val]) _obj = _obj[val]
                });

                if (n == "path") _obj =_obj;
                return new Handlebars.SafeString(_obj);
            }

        });



        Handlebars.registerHelper('logo', function(n, block) {
            var _content = self.corporateIdentity.logo ? self.corporateIdentity.logo.image.url : "";
            return new Handlebars.SafeString(_content);
        });




        //var _content = $(content);
        //console.error(_content)

        var _dom = self.window.document.createElement("div");
        _dom.innerHTML = content.trim();
        var _content = $(_dom);



        var _edit,
            _editVar,
            _editTemplate;
        // replace content of data-wps-edit-variable with elements content so the handlebars var will always get updated
        _variables = _content.find("[data-wps-edit-variable]").addBack("[data-wps-edit-variable]");
        //console.error("_variables",_variables)
        if (_variables.length > 0) {

            $.each(_variables, function(index, el) {
                _editVar = $(el).attr("data-wps-edit-variable");
                _edit = "{{" + $(el).attr("data-wps-edit-variable") + "}}";



                //cleaning v1 Templates to v2
                switch (_editVar) {
                  case "ams-nextArticle 'path'":
                    _editVar = "ams-nextArticle 'page.slug'"
                    break;
                case "ams-nextArticle 'slug'":
                    _editVar = "ams-nextArticle 'page.slug'"
                    break;
                  case "ams-nextArticle 'subtitle'":
                    _editVar = "ams-nextArticle 'page.description'"
                    break;
                  case "ams-nextArticle 'image.medium'":
                    _editVar = "ams-nextArticle 'page.image.url'"
                    break;
                  case "ams-prevArticle 'path'":
                    _editVar = "ams-prevArticle 'page.slug'"
                    break;
                    case "ams-prevArticle 'path'":
                        _editVar = "ams-prevArticle 'page.slug'"
                        break;
                  case "ams-prevArticle 'subtitle'":
                    _editVar = "ams-prevArticle 'page.description'"
                    break;
                  case "ams-prevArticle 'image.medium'":
                    _editVar = "ams-prevArticle 'page.image.url'"
                    break;

                }
                $(el).attr("data-wps-edit-variable", _editVar); // add new data-wps-edit-variable attribute to snippet


                if (el.tagName.toLowerCase() == "img") {
                    var defaultImage =  $(el).attr("data-wps-image-default") || $(el).attr("src");
                                        // if no next/previous article, add default src
                    $(el).attr("src", "{{#if (" + _editVar + ")}}{{" + _editVar + "}}{{else}}" + defaultImage + "{{/if}}");
                    $(el).attr("data-src", "{{#if (" + _editVar + ")}}{{" + _editVar + "}}{{else}}" + defaultImage + "{{/if}}");

                    //set focalpoint and width/height
                    $(el).parent().attr("data-focus", "{{#if (" + _editVar + ")}}{{"+_editVar.replace(/(["'])(?:(?=(\\?))\2.)*?\1/g, "'image.focalpoint.x'") +"}} {{"+_editVar.replace(/(["'])(?:(?=(\\?))\2.)*?\1/g, "'image.focalpoint.y'") +"}}{{else}}" + "50 50" + "{{/if}}");
                    $(el).parent().attr("data-size", "{{#if (" + _editVar + ")}}{{"+_editVar.replace(/(["'])(?:(?=(\\?))\2.)*?\1/g, "'image.width'") +"}},{{"+_editVar.replace(/(["'])(?:(?=(\\?))\2.)*?\1/g, "'image.height'") +"}}{{else}}" + "1024,600" + "{{/if}}");





                    //$(el).attr("src", _edit)
                }
                else if(el.tagName.toLowerCase() == "a"){
                    // if no next/previous article, add default href
                   $(el).attr("href", "{{#if (" + _editVar + ")}}{{" + _editVar + "}}{{else}}" + $(el).attr("href") + "{{/if}}")
                }
                else if($(el).css('background-image') != 'none' && $(el).css('background-image') != ''){
                    // change background image
                    _editTemplate = Handlebars.compile(_edit);
                    _edit = _editTemplate();
                    var backgroundImage = el.style.backgroundImage;
                    var url = backgroundImage.slice(4, -1).replace(/["']/g, "")
                   $(el).css('background-image', 'url(' + "{{#if (" + _editVar + ")}}{{" + _editVar + "}}{{else}}" + url + "{{/if}}" + ')')
                }
                else {
                    if ($(el).children().length > 0) {
                        // Has child elements - preserve HTML structure
                        var existingHtml = $(el).html();
                        console.log("existingHtml", existingHtml);

                        // Create a temporary DOM element to parse the HTML
                        var tempDiv = $('<div>').html(existingHtml);
                        var textContent = tempDiv.text();
                        console.log("textContent", textContent);

                        $(el).html(existingHtml.replace(
                          textContent,
                          _edit
                        ));
                    } else {
                    // No child elements - simple replacement
                    $(el).html(_edit);
                    }
                }
            });
        }



        if($(_content).children().length==0){
          _content = $(_content).html();
        }else{
          _content = $(_content).children()[0].outerHTML; //to string again
        }



        //compile and fill template with handlebars
        var template = Handlebars.compile(_content);
        var html = template(self.options.data);




        // Also set all Helpers in p$.utils.compileHandlebars!!
        return html;


    }


    KEditor.prototype.addImageHandler = function(contentArea) {
        var self = this;

        // check priviledge for user
        if(!self.privileges.includes('can_edit_page') && !self.options.isSysAdmin) return;

        var _img = $(contentArea).find("img[data-wps-edit]").not(".fr-image"),
            _src = _img.attr("src")

        var imgTool = $('<div class="divToolImg" style="display: block;"><i id="lnkEditImage" class="fa fa-camera"></i></div>')

        console.log("_img addImageHandler", _img);
        // Add drag handler div after each editable image
        _img.each(function() {
            var $img = $(this);
            var $figure = $img.closest('figure');
            $figure.find('.img-drag-handler').remove();
            // Create drag handler if it doesn't exist
            if ($figure.find('.img-drag-handler').length === 0) {
                var dragHandler = $('<div class="img-drag-handler"></div>');
                var percentageDisplay = $('<span class="percentage-display"></span>');
                dragHandler.append(percentageDisplay);
                $figure.append(dragHandler);

                // Add transition to figure element
                $figure.css({
                    'transition': 'padding-bottom 0.1s ease-out',
                    'will-change': 'padding-bottom' // Optimize performance
                });

                // Add styles for drag handler
                dragHandler.css({
                    'position': 'absolute',
                    'bottom': '0',
                    'left': '0',
                    'right': '0',
                    'height': '20px',
                    'background': 'rgba(0,0,0,0.3)',
                    'cursor': 'ns-resize',
                    'opacity': '0',
                    'transition': 'opacity 0.2s',
                    'z-index': '9'
                });

                percentageDisplay.css({
                    'position': 'absolute',
                    'right': '8px',
                    'bottom': '2px',
                    'color': '#fff',
                    'font-size': '12px',
                    'font-family': 'monospace',
                    'opacity': '0',
                    'transition': 'opacity 0.2s'
                });

                // Show/hide handler on hover
                $figure.hover(
                    function() {
                        dragHandler.css('opacity', '1');
                        if (isDragging) percentageDisplay.css('opacity', '1');
                    },
                    function() {
                        if (!isDragging) {
                            dragHandler.css('opacity', '0');
                            percentageDisplay.css('opacity', '0');
                        }
                    }
                );

                // Initialize drag functionality
                var isDragging = false;
                var startY, startPadding;
                var lastUpdate = 0;
                var rafId = null;

                dragHandler.on('mousedown', function(e) {
                    console.log("mousedown");
                    isDragging = true;
                    startY = e.pageY;
                    startPadding = parseFloat($figure.css('padding-bottom')) / $figure.width() * 100;
                    percentageDisplay.css('opacity', '1');
                    console.log("startPadding", startPadding);

                    // Handle mousemove on both documents
                    function handleMouseMove(e) {
                        if (!isDragging) return;

                        // Use requestAnimationFrame for smooth updates
                        if (rafId) cancelAnimationFrame(rafId);

                        rafId = requestAnimationFrame(function() {
                            var currentTime = performance.now();
                            // Throttle updates to every 16ms (approximately 60fps)
                            if (currentTime - lastUpdate > 16) {
                                var deltaY = e.pageY - startY;
                                // Reduce sensitivity for smoother changes
                                var sensitivity = Math.max(0.5, startPadding / 200);
                                var deltaPercent = (deltaY / $figure.width()) * 100 * sensitivity;
                                var newPadding = Math.max(0, startPadding + deltaPercent);

                                newPadding = Math.round(newPadding * 100) / 100;

                                // Update display and apply new padding
                                percentageDisplay.text(Math.round(newPadding) + '%');
                                $figure.css('padding-bottom', newPadding + '%');

                                lastUpdate = currentTime;
                            }
                        });
                    }

                    function handleMouseUp() {
                        if (isDragging) {
                            isDragging = false;
                            if (rafId) {
                                cancelAnimationFrame(rafId);
                                rafId = null;
                            }
                                    // Get current padding in percentage
                            var currentPadding = parseFloat($figure.css('padding-bottom')) / $figure.width() * 100;
                            var snappedPadding = Math.round(currentPadding);
                            console.log("currentPadding", currentPadding);
                            var snappedPadding = Math.round(currentPadding);
                            console.log("snappedPadding", snappedPadding);

                            // Apply the snapped value with transition
                            $figure.css({
                                'transition': 'padding-bottom 0.15s ease-out',
                                'padding-bottom': snappedPadding + '%'
                            });

                            // Update percentage display one last time
                            percentageDisplay.text(snappedPadding + '%');

                            // Clean up
                            $(self.iframeDoc).off('mousemove', handleMouseMove);
                            $(document).off('mousemove', handleMouseMove);
                            $(self.iframeDoc).off('mouseup', handleMouseUp);
                            $(document).off('mouseup', handleMouseUp);

                            // Fade out the handlers after a brief delay
                            setTimeout(function() {
                                dragHandler.css('opacity', '0');
                                percentageDisplay.css('opacity', '0');
                            }, 200);

                            self.save();
                        }
                    }

                    // Bind events to both documents
                    $(self.iframeDoc).on('mousemove', handleMouseMove);
                    $(document).on('mousemove', handleMouseMove);
                    $(self.iframeDoc).on('mouseup', handleMouseUp);
                    $(document).on('mouseup', handleMouseUp);

                    e.preventDefault();
                });
            }
        });


        _img.off('mouseenter mouseleave').on({ //Change space separation from comma separation.
            mouseenter : function (ev) {
                if($(this).hasClass("fr-image")) return;
                // if it has a clicked class, dont show image changer
                if($(this).closest(".ams-click").length>0) return;
                $(this).parent().addClass("img-hover");
                //$(this).parent().append(imgTool);
                //imgTool.show();
            },
            mouseleave : function (ev) {
                $(this).parent().removeClass("img-hover");
                //if (!$(ev.relatedTarget).is(imgTool) && !$(ev.relatedTarget).parent().is(imgTool)) imgTool.hide();
            }
        });


        /*$(contentArea).off('mouseenter mouseleave').on({ //Change space separation from comma separation.
            mouseenter : function (ev) {
                var x = ev.originalEvent.clientX, y = ev.originalEvent.clientY,
                        elementMouseIsOver = self.iframeDoc[0].elementsFromPoint(x, y),
                        imageDiv;
                console.log(elementMouseIsOver)

                $.each(elementMouseIsOver, function(index, val) {


                     if($(val).is("img")){
                        if(val.hasAttribute("data-wps-edit")){
                            console.error(val);
                            imageDiv = val;
                            return false;
                        }
                     }
                });


                $(imageDiv).parent().append(imgTool);
                imgTool.show();



            },

            mouseleave : function (ev) {
                if (!$(ev.relatedTarget).is(imgTool) && !$(ev.relatedTarget).parent().is(imgTool)) imgTool.hide();
            }

        });*/

        //imgTool.off("click").on("click", function(ev) {
        _img.off("click").on("click", function(ev) {

          var _img = ev.currentTarget,
              _size = ($(_img).parent().attr('data-size')) ? $(_img).parent().attr('data-size').split(",") : [null,null],
              _focus = $(_img).parent().attr('data-focus'),
              _variable = $(_img).attr("data-wps-variable"),
              _personalization = {};

              if(_variable){
                var _variableSplit = _variable.split(".");
                if(_variableSplit.length==2){
                    _personalization = {
                        setId: parseInt(_variableSplit[0]),
                        valueId: _variableSplit[1]
                    }
                }
            }
            console.log("_personalization", _personalization);

          if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
            "src": _img.src,
            "width" : _size[0],
            "height": _size[1],
            "focal" : _focus,
            "personalization": _personalization,
          }, function(data){

            console.error("data", data);

            var _group = $(_img).attr("data-wps-edit-group");
            var _focal = data[0].focalpoint;


            // set image dimensions
            var _w = $(_img).width();
            var _h = $(_img).height();
            data[0].url = self.cloudImageFunction(data[0].url, { width: _w, height: _h }, data[0]);

            if(data[0].personalization){

                $(_img).attr("data-wps-variable", data[0].personalization.setId+"."+data[0].personalization.valueId)
            }else{
                $(_img).removeAttr("data-wps-variable");
            }

            // preload the result image thumbnail
              $(_img).removeAttr('data-wps-edit-variable'); //delete data-wps-edit-variable
              $(_img).removeAttr('srcset'); //delete data-wps-edit-variable
              $(_img).attr("data-url", data[0].url);
              $(_img).parent().attr("data-focus", _focal.x+","+_focal.y);
              if(data[0].width && data[0].height) $(_img).parent().attr("data-size", data[0].width+","+data[0].height);
              $(_img).attr("src", data[0].url)

              if(data[0].width && data[0].height){
                if(!$(_img).parent().hasClass("no_auto_fit")){
                    var _el,
                        _auto_fit = $(_img).attr('data-wps-auto-fit');
                    if(_auto_fit==true){
                        _el = $(_img).parent();
                    }else{
                        _el = $(_img).closest(_auto_fit);
                    }
                    var _percentage = data[0].height/data[0].width*100;
                    _el.css({
                        paddingBottom: _percentage+'%'
                    });
                }
              }

              if (_group) {
                  // find group attributes in component
                  var _groupEl = component.find('[data-wps-edit-group="' + _group + '"]');

                  $.each(_groupEl, function(index, el) {

                      // if it is not the _img itself, change image to new one
                      if (el != image[0]) {
                          $(el).attr("data-srcset", _srcset);
                          //$(el).attr("data-id", data.file.id);
                          $(el).parent().attr("data-focus", _focal.x+","+_focal.y);

                          // if attribute data-wps-edit-size was set in element:
                          if (_size) {
                              $(el).attr("src", data[0].url.replace("/s/", "/" + _size + "/"));
                          } else {
                              $(el).attr("src", data[0].url.replace("/s/", "/l/"));
                          }
                      }
                  });
                  // find group attributes in settings-form
                  var container = _button.closest(".keditor-setting-form");
                  var _groupEl = container.find('[data-wps-edit-group="' + _group + '"]');
                  $.each(_groupEl, function(index, el) {
                      $(el).attr("src", data[0].url);
                  });
              }

              // cloudimage.io
              // first set src to si-src to make it work
              var ciResponsive = new window.parent.CIResponsive({
                token: 'axyqwmwryo'
              });

              self.window.imgCover.update(self.body[0]);
              self.save();


          })
        })
    }


    KEditor.prototype.buildFocalPointHelper = function(image) {

        var self = this;
        var html = "<div id='Frames'>";

        for (var i = 1; i <= 9; i++) {
            html += "<div id='Frame" + i + "' class='focuspoint'> <img-cover class='pgs-image' data-focus='50,50' data-size='1703,614'> <img src='" + image.attr("src") + "'></img-cover></div>"
        }

        html += "</div>";

        /*var popup = self.buildPopup(html);
        $(self.body).append(popup);
        $(self.body).addClass("ams-desktop") // delete later!

        setTimeout(function() {
            popup.addClass('md-show')
        }, 10);
        */




    }

    KEditor.prototype.buildPopup = function(content) {

        var self = this;

        var _modal = $('<div class="pgs_modal pgs_modal_image_upload md-effect-1 " ><div class="md-content">' + content + '<div class="ams-po-buttons"><ul><li><button type="button" class="ams-active"><span>OK</span></button></li></ul></div></div></div><div class="pgs_modal_overlay"></div>');

        _modal.find(".ams-active").addBack(".ams-active").on("click", close);
        _modal.find(".pgs_modal_overlay").addBack(".pgs_modal_overlay").on("click", close);

        function close() {
            _modal.removeClass('md-show');
            setTimeout(function() {
                _modal.remove();
            }, 300);
        };

        return _modal;
    }


    KEditor.prototype.addUploader = function(_button, _dropzone, image, _options, callback) {

        var self = this;
        return;


        console.log("addUploader",_button, _dropzone, image);
        var _img = image,
            _size = $(_img).parent().attr('data-size'),
            _size = (_size) ? _size.split(",") : "",
            _focus = $(_img).parent().attr('data-focus');

        if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
            "src": _img.src,
            "width" : _size[0],
            "height": _size[1],
            "focal" : _focus
          }, function(data){
            console.log("callFromKeditor", data[0]);
            console.log(data[0].url)


            var _group = $(_img).attr("data-wps-edit-group");
            var _focal = data[0].focalpoint;



              // if it is a video file return immediately
                if(data.file.type.indexOf("video/mp4")>=0
                  || data.file.type.indexOf("audio/mp3")>=0
                  || data.file.type.indexOf("audio/mpeg")>=0){
                  callback(data);
                  self.save();
                  return;
                }
                // set image dimensions
                var _w = $(_img).width();
                var _h = $(_img).height();
                data[0].url = self.cloudImageFunction(data[0].url, { width: _w, height: _h }, data[0]);


                // preload the result image thumbnail
              $(_img).removeAttr('data-wps-edit-variable'); //delete data-wps-edit-variable
              $(_img).removeAttr('srcset'); //delete data-wps-edit-variable
              $(_img).attr("data-url", data[0].url);
              $(_img).parent().attr("data-focus", _focal.x+","+_focal.y);
              if(data[0].width && data[0].height) $(_img).parent().attr("data-size", data[0].width+","+data[0].height);
              $(_img).attr("src", data[0].url)

              if(data[0].width && data[0].height){
                if(!$(_img).parent().hasClass("no_auto_fit")){
                    var _el,
                        _auto_fit = $(_img).attr('data-wps-auto-fit');
                    if(_auto_fit==true){
                        _el = $(_img).parent();
                    }else{
                        _el = $(_img).closest(_auto_fit);
                    }
                    var _percentage = data[0].height/data[0].width*100;
                    _el.css({
                        paddingBottom: _percentage+'%'
                    });
                }
              }

              if (_group) {
                  // find group attributes in component
                  var _groupEl = component.find('[data-wps-edit-group="' + _group + '"]');

                  $.each(_groupEl, function(index, el) {

                      // if it is not the _img itself, change image to new one
                      if (el != image[0]) {
                          $(el).attr("data-srcset", _srcset);
                          //$(el).attr("data-id", data.file.id);
                          $(el).parent().attr("data-focus", _focal.x+","+_focal.y);

                          // if attribute data-wps-edit-size was set in element:
                          if (_size) {
                              $(el).attr("src", data[0].url.replace("/s/", "/" + _size + "/"));
                          } else {
                              $(el).attr("src", data[0].url.replace("/s/", "/l/"));
                          }
                      }
                  });
                  // find group attributes in settings-form
                  var container = _button.closest(".keditor-setting-form");
                  var _groupEl = container.find('[data-wps-edit-group="' + _group + '"]');
                  $.each(_groupEl, function(index, el) {
                      $(el).attr("src", data[0].url);
                  });
              }



              self.window.imgCover.update(self.body[0]);
              self.save();

              if(callback) {
                callback(data);
              }


          })

        return;



    }

    KEditor.prototype.absolutePath = function(href) {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") return href;
        var link = document.createElement("a");
        link.href = href;
        return (this.options.env.VUE_APP_PHP_SERVER_ENDPOINT + link.pathname + link.search + link.hash);
    }

    KEditor.prototype.initEditables = function(component, options) {


        var self = this;

        // check priviledge for user
        if(!self.privileges.includes('can_edit_page') && !self.options.isSysAdmin) return;

        options = options ||  "",
        edit_option = "",
        _options = [];


        // search for all editable elements with the attribute data-wps-edit are not a child of an element with the attribute data-wps-container  or that have the attribute data-wps-container
        var editableBlocks = component.find('[data-wps-edit]').filter(function() {
            var closestWpsContainer = $(this).closest('[data-wps-container]');
            return closestWpsContainer.length === 0 || !component.is(closestWpsContainer) && !component.find(closestWpsContainer).length;
        });




        for (var i = 0; i < editableBlocks.length; i++) {
            _editableBlock = $(editableBlocks[i]);
           // console.dir(_editableBlock[0])

            if (!_editableBlock.is("img") && !_editableBlock.hasClass("pgs-video") && _editableBlock.attr("data-wps-edit").indexOf("false") == -1 ) {
                //CKEDITOR.disableAutoInline = true;

                if(_editableBlock.attr("data-wps-edit-disable")){
                    //console.error("return", editableBlocks[i])
                    // dont edit

                }else{
                    if (!_editableBlock.hasClass("fr-box") ) {


                        //console.log(!_editableBlock.hasClass("fr-box"))
                        //_editableBlock.prop('contenteditable', true);

                        // get toolbar options from element
                        //var _options = KEditor.components.text.getToolbarOptions(_editableBlock, options);

                        //_options= (_editableBlock.attr("data-wps-toolbar-items")) ?  _editableBlock.attr("data-wps-toolbar-items").split(',') : ['undo', 'redo'];

                        _options= _editableBlock.attr("data-wps-toolbar-items");

                        if(_options){
                            if(_options.indexOf("toolbarSet")>=0) _options=self.options[_options].split(',');
                            else _options = _options.split(',');
                        }else{
                            _options = ['undo', 'redo'];
                        }

                        _options = self.options['toolbarSet6'].split(',');
                        // add extra toolbar values to editor from element
                        _options_add= _editableBlock.attr("data-wps-toolbar-items-add");
                        if(_options_add){
                            var a = _options.indexOf("undo");
                            _options_add = _options_add.split(',')
                            _options.splice.apply(_options, [a, 0].concat(_options_add));
                        }


                        // internal Link Dropdown
                        var _articles =  self.options.allPageSiblings;
                        var _linkOptions = {};
                        $.each(_articles, function (index, val) {
                            _linkOptions[val.slug] = val.title
                        });
                        self.window.FroalaEditor.DefineIcon('customLinkList', {NAME: 'cog', PATH: 'M20.9 4.2c0-.2-.1-.4-.2-.6-.3-.3-.9-.3-1.2 0l-5.6 5.6v-3c.4 0 .8-.4.8-.8 0-.5-.4-.8-.8-.8H6.3c-1.1 0-2 .9-2 2v11.5c0 1.1.9 2 2 2h11.5c1.1 0 2-.9 2-2v-7.6c0-.5-.4-.8-.8-.8s-.9.2-.9.7V18c0 .2-.1.3-.3.3H6.3c-.2 0-.3-.1-.3-.3V6.5c0-.2.1-.3.3-.3h7.6v3.1l-3.3 3.3V8.8c0-.5-.4-.8-.8-.8s-.9.4-.9.8v5.8c0 .5.4.8.8.8h5.8c.5 0 .8-.4.8-.8s-.4-.8-.8-.8h-3.8l9-9c.2-.2.2-.4.2-.6z'});
                        self.window.FroalaEditor.RegisterCommand('customLinkList', {
                            title: self.labels.linkPopup.internal,
                            type: 'dropdown',
                            focus: false,
                            undo: false,
                            refreshAfterCallback: true,
                            options: _linkOptions,
                            callback: function (cmd, val) {
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                if($(_link).attr("href")==val){
                                    $(_link).attr("href", "");
                                }else{
                                    $(_link).attr("href", val);
                                }

                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                                var _href = $(_link).attr("href");
                                if(_href){
                                    $($btn).parent().find('[data-param1="'+_href+'"]').addClass("fr-active");
                                }
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                                var _href = $(_link).attr("href");
                                if(_href){
                                    $($btn).parent().find('[data-param1="'+_href+'"]').addClass("fr-active");
                                }
                            }
                        });


                        var _modalStyles = {
                            "wbmg-modal-link": self.labels.linkStyles.modal_link,
                            "wbmg-modal-link-sm": self.labels.linkStyles.modal_link_sm,
                            "wbmg-modal-link-xl": self.labels.linkStyles.modal_link_xl,
                            "wbmg-modal-link-full": self.labels.linkStyles.modal_link_full,
                            "wbmg-modal-link-center": self.labels.linkStyles.modal_link_center
                        };
                        self.window.FroalaEditor.DefineIcon('customModalList', {NAME: 'cog', PATH: 'M18.8 2.5c-1.4 0-2.5.9-2.9 2.1H5.7c-1.2 0-2.3 1-2.3 2.3v10c0 1.2 1 2.3 2.3 2.3h12c1.2 0 2.3-1 2.3-2.3V8.2c1.1-.5 1.8-1.5 1.8-2.7 0-1.7-1.3-3-3-3zm-.3 14.4c0 .4-.3.8-.8.8h-12c-.4 0-.8-.3-.8-.8v-6.8h13.5v6.8zm0-8.3H5V6.9c0-.4.3-.8.8-.8H16c.3 1.2 1.3 2.2 2.6 2.3v.2zm1.7-2.2c.1.1.1.2 0 .3s-.2.1-.3 0l-1-1-1 1c-.1.1-.2.1-.3 0s-.1-.2 0-.3l1-1-1-1c-.1-.1-.1-.2 0-.3s.2-.1.3 0l1 1 1-1c.1-.1.2-.1.3 0s.1.2 0 .3l-1 1 1 1z'});
                        self.window.FroalaEditor.RegisterCommand('customModalList', {
                            title: self.labels.linkPopup.modal,
                            type: 'dropdown',
                            focus: false,
                            undo: false,
                            refreshAfterCallback: true,
                            options: _modalStyles,
                            callback: function (cmd, val) {
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                if($(_link).hasClass(val)){
                                    $(_link).removeClass(val);
                                }else{
                                    $(_link).addClass(val);
                                }
                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                                if($(_link).hasClass("wbmg-modal-link")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-sm")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-sm"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-xl")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-xl"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-full")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-full"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-center")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-center"]').addClass("fr-active");
                                }
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                                if($(_link).hasClass("wbmg-modal-link")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-sm")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-sm"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-xl")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-xl"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-full")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-full"]').addClass("fr-active");
                                }
                                if($(_link).hasClass("wbmg-modal-link-center")){
                                    $($btn).parent().find('[data-param1="wbmg-modal-link-center"]').addClass("fr-active");
                                }
                            }
                        });

                        self.window.FroalaEditor.DefineIcon('insertPdf', {NAME: 'insertPdf', SVG_KEY: 'insertFile'});
                        self.window.FroalaEditor.RegisterCommand('insertPdf', {
                            title: self.labels.linkPopup.pdf,
                            focus: true,
                            undo: true,
                            refreshAfterCallback: true,
                            callback: function () {
                                var that = this;
                                console.log("this",this);
                                setTimeout(function () {
                                if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                }, 100);

                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }

                                if (typeof self.options.callFromKeditor === 'function') self.options.callFromKeditor("imageUploadModal", {
                                    "filetype": "application/pdf",
                                    "focal": false
                                }, function (data) {
                                    console.log(data)
                                    $(_link).attr("href", data[0].src);
                                    self.save();
                                })
                            }
                        });

                        // set link options
                        var  linkEditButtons = ['linkEdit', 'linkRemove','linkStyle','-','customLinkList', 'customModalList', 'customReadMoreBtn', 'insertPdf'];
                        edit_option = _editableBlock.attr("data-wps-options");
                        if(edit_option){
                            if(edit_option.indexOf("linkEdit=false")>=0)  linkEditButtons = ['linkRemove','linkStyle'];
                        }


                        self.window.FroalaEditor.DefineIcon('customReadMoreBtnIcon', { NAME: 'star', PATH: 'M3.1,18h6.5v-2H3.1V18z M3.1,11v2h18v-2H3.1z M3.1,6v2h18V6H3.1z M11.9,14l4.6,4.6l4.6-4.6l1.4,1.4l-6,6l-6-6L11.9,14z'})
                        self.window.FroalaEditor.RegisterCommand('customReadMoreBtn', {
                            title: self.labels.readMore,
                            icon: 'customReadMoreBtnIcon',
                            undo: true,
                            focus: true,
                            popup: true,
                            // Callback on refresh.
                            refresh: function ($btn) {
                                console.log("refresh", this);
                                console.log("refresh", this.selection);
                                //console.log("refresh", this.editor.selection.element());
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                var _contentId = $(_link).attr("data-target");
                                    console.log("_contentId",_contentId);
                                if(_contentId){
                                    $($btn).addClass("fr-active");
                                }else{
                                    $($btn).removeClass("fr-active");
                                }

                            },
                            callback: function () {
                                this.customReadMore.showPopup();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customReadMoreBtn_popupClose', {
                            NAME: 'times',
                            SVG_KEY: 'back'
                        });
                        self.window.FroalaEditor.RegisterCommand('customReadMoreBtn_popupClose', {
                            title: self.labels.back,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.customReadMore.hidePopup();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customReadMoreBtn_delete', {
                            NAME: 'times',
                            SVG_KEY: 'remove'
                        });
                        self.window.FroalaEditor.RegisterCommand('customReadMoreBtn_delete', {
                            title: self.labels.delete,
                            undo: false,
                            focus: false,
                            callback: function(e) {
                                this.customReadMore.deleteElement(e);
                            }
                        });
                        // Define popup buttons.
                        Object.assign(self.window.FroalaEditor.DEFAULTS, {
                            customReadMoreBtnPopupButtons: ['customReadMoreBtn_popupClose', 'customReadMoreBtn_delete'],
                        });
                         // Define popup template.
                        self.window.FroalaEditor.POPUP_TEMPLATES["customReadMore.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';
                        self.window.FroalaEditor.PLUGINS.customReadMore = function(editor) {

                            var _link;
                            // Create custom popup.
                            function initPopup(el) {
                                // Popup buttons.
                                var popup_buttons = '';
                                var that = this;

                                // Create the list of buttons.
                                if (editor.opts.customReadMoreBtnPopupButtons.length > 1) {
                                    popup_buttons += '<div class="fr-buttons">';
                                    popup_buttons += editor.button.buildList(editor.opts.customReadMoreBtnPopupButtons);
                                    popup_buttons += '</div>';
                                }

                                // Load popup template.
                                var template = {
                                    buttons: popup_buttons,
                                    custom_layer: '<div style="width:500px" class="fr-link-insert-layer fr-layer fr-active mt-0"><div class="fr-input-line"><input id="fr-link-insert-layer-customReadMore" name="Collapsed Text" type="text" class="fr-link-attr" placeholder="" tabindex="1" dir="ltr"><label for="fr-link-insert-layer-customReadMore">COLLAPSED TEXT</label></div><div class="_froalaEditor pb-1"></div><div class="fr-action-buttons"><button class="fr-command" role="button"  href="#" type="button">Insert</button></div></div>'
                                };

                                // Create popup.
                                var $popup = editor.popups.create('customReadMore.popup', template);
                                var _froalaInput = $popup.find("._froalaEditor");

                                $popup.find(".fr-action-buttons > button").on('click', function() {
                                    // add collapsed text as data-attribute to the link
                                    var _link = $(el);
                                    // if link has another element inside
                                    if(_link.tagName!="a"){
                                        _link = $(_link).closest("a")[0];
                                    }
                                    var _collapsedText = $popup.find("#fr-link-insert-layer-customReadMore").val();
                                    $(_link).attr("data-collapsed-text", _collapsedText.trim());

                                    var _text = $(_link).text();
                                    $(_link).attr("data-collapse-text", _text.trim());

                                    // wrap $popup.element with a bootstrap collapsible and add froala text in it
                                    var _contentId = $(el).attr("data-target");
                                    var _text = _froalaInput[0]["data-froala.editor"].html.get();
                                    if(_contentId){
                                        $(el).closest(".fr-element").find(_contentId).html(_text);
                                    }else{
                                        var timestamp = new Date().getTime();
                                        $(el).addClass("inlineCollapse").attr("data-toggle", "collapse").attr("data-target", "#inlineCollapse"+timestamp).attr("aria-expanded", "false").attr("aria-controls", "inlineCollapse"+timestamp);
                                        console.log("el",$(el));
                                        console.log("$(el).parent()", $(el).parent())
                                        $('<div contenteditable="false" class="collapse show" id="inlineCollapse'+timestamp+'"><div contenteditable="true">'+_text+'</div></div>').insertBefore($(el).parent());
                                    }

                                    self.save();
                                    hidePopup();
                                })

                                return $popup;
                            }

                            // Show the popup
                            function showPopup(el) {

                                _link = editor.selection.element();
                                self.window.FroalaEditor.PLUGINS.customReadMore.link=_link;
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                console.log("_link", _link);

                                var left = $(_link).offset().left + $(_link).outerWidth() / 2;
                                var top = $(_link).offset().top + $(_link).outerHeight();

                                // Get the popup object defined above.
                                var $popup = editor.popups.get('customReadMore.popup');

                                // If popup doesn't exist then create it.
                                // To improve performance it is best to create the popup when it is first needed
                                // and not when the editor is initialized.
                                //if (!$popup)
                                $popup = initPopup(_link);

                                // Set the editor toolbar as the popup's container.
                                editor.popups.setContainer('customReadMore.popup', editor.$tb);

                                // This will trigger the refresh event assigned to the popup.
                                // editor.popups.refresh('customReadMore.popup');

                                // This custom popup is opened by pressing a button from the editor's toolbar.
                                // Get the button's object in order to place the popup relative to it.
                                var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');


                                // Show the custom popup.
                                // The button's outerHeight is required in case the popup needs to be displayed above it.
                                editor.popups.show('customReadMore.popup', left, top, $btn.outerHeight());


                                var _froalaInput = $popup.find("._froalaEditor");
                                $(_froalaInput).empty();

                                var _options = $.extend(true,{
                                    height: 200
                                },self.options.keditorOptions);
                                delete _options.toolbarInline;
                                delete _options.initOnClick;
                                delete _options.toolbarContainer;
                                var fEditor = new self.window.FroalaEditor(_froalaInput[0],
                                    _options
                                , function () {
                                    // Call the method inside the initialized event.
                                   //if link is a collapsible, get the content of the collapsible with the id in data-target
                                    var _contentId = $(_link).attr("data-target");
                                    console.log("_contentId",_contentId);
                                    if(_contentId){
                                        var _content = $(_link).closest(".fr-element").find(_contentId).html();
                                        fEditor.html.set(decodeURIComponent(_content));
                                    }
                                });
                                // add link text to input field
                                var _collapsedText = $(_link).attr("data-collapsed-text");
                                var _text = $(_link).text();
                                if(_collapsedText){
                                    $popup.find("#fr-link-insert-layer-customReadMore").val(_collapsedText);
                                }else{
                                    $popup.find("#fr-link-insert-layer-customReadMore").val(_text);
                                }

                            }

                            // Hide the custom popup.
                            function hidePopup() {
                                editor.popups.hide('customReadMore.popup');
                            }

                            // Hide the custom popup.
                            function deleteElement(e) {

                                _link = self.window.FroalaEditor.PLUGINS.customReadMore.link;
                                console.log("_link", _link);

                                var _contentId = $(_link).attr("data-target");
                                console.log("_contentId",_contentId);
                                if(_contentId){
                                    var _content = $(_link).closest(".fr-element").find(_contentId);
                                    console.log("_content",_content);
                                    _content.remove();
                                    $(_link).removeAttr("data-toggle").removeAttr("data-target").removeAttr("aria-expanded").removeAttr("aria-controls").removeAttr("data-collapsed-text").removeAttr("data-collapse-text").removeClass("collapse show collapsed inlineCollapse");
                                    hidePopup();
                                    self.save();
                                }
                            }

                            // Methods visible outside the plugin.
                            return {
                                showPopup: showPopup,
                                hidePopup: hidePopup,
                                deleteElement: deleteElement
                            }
                        }







                        // can user add custom Colors
                        var _customColor = false;
                        var _customFonts = false;
                        var _customFontsObj = {};


                        if(self.corporateIdentity && self.corporateIdentity.defaultFonts){
                            var _defaultFonts = self.corporateIdentity.defaultFonts;
                            Object.keys(_defaultFonts).forEach(fontKey => {
                                _customFontsObj[_defaultFonts[fontKey].font] = _defaultFonts[fontKey].font;
                            });
                        }else{
                            if (self.corporateIdentity._customFonts && self.corporateIdentity._customFonts.google && self.corporateIdentity._customFonts.google.length > 0) {
                                _customFonts = self.corporateIdentity._customFonts.google
                            }
                            if(_customFonts){
                                $.each(_customFonts, function(index, el) {
                                    if(el!="") _customFontsObj[el] = el;
                                });
                            }
                        }

                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.customFonts){
                            $.extend( _customFontsObj, self.groupSettings.editorSettings.customFonts );
                        }


                        var _colorsText = [
                            '#000',
                            '#444',
                            '#666',
                            '#999',
                            '#ccc',
                            '#eee',
                            '#f3f3f3',
                            '#fff',
                            'REMOVE',
                        ];
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.defaultColors){
                            _colorsText = self.groupSettings.editorSettings.defaultColors;
                        }

                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings._customColor){
                            var _customColor = self.groupSettings.editorSettings._customColor
                            $.each(_customColor, function(index, el) {
                                if(el!="" && !_colorsText.includes(el)) _colorsText.unshift(el);
                            });
                        }

                        var _color = self.corporateIdentity._customColor;
                        $.each(_color, function(index, el) {

                            if(el!="" && !_colorsText.includes(el)) _colorsText.unshift(el);
                        });


                        var _colorsHEXInput = true;
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.colorsHEXInput){
                            _colorsHEXInput = self.groupSettings.editorSettings.colorsHEXInput;
                        }


                        var _pluginsEnabled = [
                          'fontFamily',
                          'link',
                          'lists',
                          'paragraphFormat',
                          'paragraphStyle',
                          'inlineClass',
                          'fontAwesome',
                          'colors',
                          'align',
                          'table',
                          'help',
                          'draggable',
                          'wordPaste',
                          'emoticons',
                          'embedly',
                          'lineHeight',
                          'specialCharacters',
                          'table',
                          'fontSize',
                          'customFontAwesomePlugin',
                          'customTefFootnodePlugin',
                          'customAdditionalText',
                          'customReadMore',
                          'customPersonalizationPlugin',
                          'forms'
                          //'oembed'
                        ]; //


                        if(self.options.isSysAdmin){
                            _pluginsEnabled.push('codeView');
                        }



                        if(true ){
                              _pluginsEnabled.push('image');
                              self.window.FroalaEditor.DefineIcon('insertImage', {NAME: 'insertImage', SVG_KEY: 'insertImage'});
                              self.window.FroalaEditor.RegisterCommand('insertImage', {
                                title: self.labels.imageAdd,
                                focus: true,
                                undo: true,
                                refreshAfterCallback: true,
                                callback: function () {
                                  var that = this;
                                    console.log("this",this);
                                  setTimeout(function () {

                                    if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                  }, 100);

                                  var _img = $(this).parent().find("img[data-wps-edit]");


                                  _size = $(_img).parent().attr('data-size'),
                                  _focus = $(_img).parent().attr('data-focus');
                                  if(_size){
                                    _size = _size.split(",")
                                  }else{
                                      _size="";
                                  }

                                    //console.log("imageUploadModal",_size,_focus  );
                                  if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                                    "src": _img.src,
                                    "width": _size[0] || $(window).width(),
                                    "height": _size[1] || $(window).height(),
                                    "focal": _focus
                                  }, function(data){
                                    // set image dimensions
                                    var _w = _size[0] || $(window).width();
                                    var _h = _size[1] || $(window).height();
                                    var _perso="";
                                    data[0].url = self.cloudImageFunction(data[0].url, { width: _w, height: _h }, data[0]);

                                    if(data[0].personalization){
                                        _perso = 'data-wps-variable="'+ data[0].personalization.setId+'.'+data[0].personalization.valueId+'"';
                                    }
                                    // preload the result image thumbnail
                                    that.html.insert('<img alt="" '+_perso+' style="width:100%" class="fr-image wbmg-i-img" src="'+data[0].url+'" >');
                                    self.window.imgCover.update(self.body[0]);
                                      self.save();
                                  })

                                }
                              });

                            self.window.FroalaEditor.DefineIcon('customImageReplace', {NAME: 'Replace Image', SVG_KEY: 'replaceImage'});
                            self.window.FroalaEditor.RegisterCommand('customImageReplace', {
                                    title: self.labels.imageReplace,
                                    focus: false,
                                    undo: false,
                                    refreshAfterCallback: false,
                                    callback: function () {
                                        var that = this;

                                        setTimeout(function () {
                                            if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                        }, 100);
                                        var _img = this.image.get(),
                                            _variable = $(_img).attr("data-wps-variable"),
                                            _personalization = {};

                                        if(_variable){
                                            var _variableSplit = _variable.split(".");
                                            if(_variableSplit.length==2){
                                                _personalization = {
                                                    setId: parseInt(_variableSplit[0]),
                                                    valueId: _variableSplit[1]
                                                }
                                            }
                                        }
                                        //var _img = $(this).parent().find("img[data-wps-edit]");

                                        // var _src = _img.attr("src")
                                        // var _id = _img.attr("data-id");


                                            //console.log("imageUploadModal",_size,_focus  );
                                        if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                                            "src": _img.attr("src"),
                                            "personalization": _personalization,
                                        }, function(data){

                                            // set image dimensions
                                            var _w = $(window).width();
                                            var _h = $(window).height();
                                            data[0].url = self.cloudImageFunction(data[0].url, { width: _w, height: _h }, data[0]);

                                            // preload the result image thumbnail
                                            _img.attr("src",data[0].url);
                                            if(data[0].personalization){
                                                _img.attr("data-wps-variable", data[0].personalization.setId+"."+data[0].personalization.valueId)
                                            }else{
                                                $(_img).removeAttr("data-wps-variable");
                                            }
                                            //(that.html.insert('<img alt="" class="fr-image" src="'+data[0].url+'" >');
                                            self.window.imgCover.update(self.body[0]);
                                            self.save();
                                        })
                                    }
                            });
                        }





                        if(true){
                            _pluginsEnabled.push('customImageAudioPlugin');
                        }

                        // Define an icon and command for the button that opens the custom popup.
                        self.window.FroalaEditor.DefineIcon('openImageAudioPopup', {NAME: 'insertAudio', PATH: 'M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z'});
                        self.window.FroalaEditor.RegisterCommand('openImageAudioPopup', {
                            title: self.labels.audioImagePopup,
                            undo: false,
                            focus: false,
                            popup: true,
                            // Buttons which are included in the editor toolbar should have the plugin property set.
                            //plugin: 'customPlugin',
                            callback: function () {
                                if (!this.popups.isVisible('imageAudioPopup.popup')) {
                                this.customImageAudioPlugin.showPopup(this);
                                }
                                else {
                                if (this.$el.find('.fr-marker')) {
                                    this.events.disableBlur();
                                    this.selection.restore();
                                }
                                this.customImageAudioPlugin.hide('imageAudioPopup.popup');
                                }
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customImageAudioPlugin_popupClose', {
                            NAME: 'times',
                            SVG_KEY: 'back'
                        });
                        self.window.FroalaEditor.RegisterCommand('customImageAudioPlugin_popupClose', {
                            title: self.labels.back,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.customImageAudioPlugin.hidePopup();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customImageAudioPlugin_popupDelete', {
                            NAME: 'times',
                            SVG_KEY: 'remove'
                        });
                        self.window.FroalaEditor.RegisterCommand('customImageAudioPlugin_popupDelete', {
                            title: self.labels.audioImageDelete,
                            undo: false,
                            focus: false,
                            callback: function() {
                                var _img = this.image.get();
                                this.customImageAudioPlugin.hidePopup();
                                _img.parent().find("audio").remove();
                                _img.unwrap();
                                _img.removeAttr("data-wps-audio-image");
                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                var _img = this.image.get();
                                var _audio = _img.parent().find("audio");
                                if(_audio.length>0){
                                    $($btn).removeClass("fr-disabled");
                                }else{
                                    $($btn).addClass("fr-disabled");
                                }
                            }
                        });
                        // self.window.FroalaEditor.DefineIcon('customImageAudioPlugin_dropdown', {NAME: 'cog', SVG_KEY: 'fontSize'});
                        // self.window.FroalaEditor.RegisterCommand('customImageAudioPlugin_dropdown', {
                        //     title: self.labels.fontSize,
                        //     type: 'dropdown',
                        //     focus: false,
                        //     undo: true,
                        //     refreshAfterCallback: true,
                        //     options: {
                        //         "image-hover-audio" : "Play on hover",
                        //         "image-click-audio" : "Play on click"
                        //     },
                        //     callback: function (cmd, val) {
                        //         console.log("customImageAudioPlugin_dropdown", this);
                        //         var _img = this.image.get();
                        //         if(_img.length>0){
                        //             _img.parent().find("audio").removeClass("image-hover-audio").removeClass("image-click-audio");
                        //             _img.parent().find("audio").addClass(val);
                        //             self.save();
                        //         }
                        //         console.log("_img", _img);

                        //     },
                        //     // Callback on refresh.
                        //     refresh: function ($btn) {
                        //         var _img = this.image.get();
                        //         var _audio = _img.parent().find("audio");
                        //         console.log("$btn",$btn, _audio);
                        //         if(_audio.length>0){
                        //             $($btn).removeClass("fr-disabled");
                        //             $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                        //             if(_audio.hasClass("image-hover-audio")){
                        //                 $($btn).parent().find('[data-param1="image-hover-audio"]').addClass("fr-active");
                        //             }
                        //             if(_audio.hasClass("image-click-audio")){
                        //                 $($btn).parent().find('[data-param1="image-click-audio"]').addClass("fr-active");
                        //             }
                        //         }else{
                        //             $($btn).addClass("fr-disabled");
                        //         }
                        //     },
                        //     // Callback on dropdown show.
                        //     refreshOnShow: function ($btn, $dropdown) {
                        //         var _img = this.image.get();
                        //         var _audio = _img.parent().find("audio");
                        //         if(_audio.length>0){

                        //             console.log("$btn",$btn);
                        //             $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                        //             if(_audio.hasClass("image-hover-audio")){
                        //                 $($btn).parent().find('[data-param1="image-hover-audio"]').addClass("fr-active");
                        //             }
                        //             if(_audio.hasClass("image-click-audio")){
                        //                 $($btn).parent().find('[data-param1="image-click-audio"]').addClass("fr-active");
                        //             }
                        //         }
                        //     }
                        // });

                        // Define popup template.
                        self.window.FroalaEditor.POPUP_TEMPLATES["customImageAudioPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

                        // Define popup buttons.
                        Object.assign(self.window.FroalaEditor.DEFAULTS, {
                            popupButtonsImageAudio: ['customImageAudioPlugin_popupClose', '|', 'audioCustomUpload', 'audioImageCustomUpload','customImageAudioPlugin_popupDelete'],
                        });

                        // The custom popup is defined inside a plugin (new or existing).
                        self.window.FroalaEditor.PLUGINS.customImageAudioPlugin = function(editor) {
                            // Create custom popup.
                            function initPopup() {
                                // Popup buttons.
                                var popup_buttons = '';

                                // Create the list of buttons.
                                if (editor.opts.popupButtonsImageAudio.length > 1) {
                                popup_buttons += '<div class="fr-buttons">';
                                popup_buttons += editor.button.buildList(editor.opts.popupButtonsImageAudio);
                                popup_buttons += '</div>';
                                }

                                // Load popup template.
                                var template = {
                                    buttons: popup_buttons,
                                    custom_layer: '<div class="custom-layer px-2 pb-2">'+self.labels.audioPopupText+'</div>'
                                };

                                // Create popup.
                                var $popup = editor.popups.create('customImageAudioPlugin.popup', template);

                                return $popup;
                            }

                            // Show the popup
                            function showPopup(that) {
                                // Get the popup object defined above.
                                var $popup = editor.popups.get('customImageAudioPlugin.popup');

                                // If popup doesn't exist then create it.
                                // To improve performance it is best to create the popup when it is first needed
                                // and not when the editor is initialized.
                                if (!$popup) $popup = initPopup();

                                // Set the editor toolbar as the popup's container.
                                editor.popups.setContainer('customImageAudioPlugin.popup', editor.$tb);

                                // This will trigger the refresh event assigned to the popup.
                                // editor.popups.refresh('customImageAudioPlugin.popup');

                                // This custom popup is opened by pressing a button from the editor's toolbar.
                                // Get the button's object in order to place the popup relative to it.
                                var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');
                                var _img = that.image.get();

                                var $el = $(editor.selection.element());
                                // Set the popup's position.
                                var left = _img.offset().left + _img.outerWidth() / 2;
                                var top = _img.offset().top;
                                //var top = _img.offset().top + _img.outerHeight(); // Enhancement 2950


                                // Show the custom popup.
                                // The button's outerHeight is required in case the popup needs to be displayed above it.
                                editor.popups.show('customImageAudioPlugin.popup', left, top, $btn.outerHeight());

                            }

                            // Hide the custom popup.
                            function hidePopup() {
                                editor.popups.hide('customImageAudioPlugin.popup');
                            }

                            // Methods visible outside the plugin.
                            return {
                                showPopup: showPopup,
                                hidePopup: hidePopup
                            }
                        }

                        //Audio Upload
                        self.window.FroalaEditor.DefineIcon('audioImageCustomUpload', {NAME: 'insertAudio', SVG_KEY: 'insertImage'});
                        self.window.FroalaEditor.RegisterCommand('audioImageCustomUpload', {
                        title: self.labels.audioImageAdd,
                        focus: true,
                        undo: true,
                        refreshAfterCallback: true,
                        callback: function () {
                            var that = this;
                            setTimeout(function () {
                                if( that.popups)  that.popups.hideAll();
                            if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                            }, 100);
                            var _img = this.image.get();
                            //console.log("imageUploadModal",_size,_focus  );
                            if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                            "src": '',
                            "focal": false
                            }, function(data){

                                _img.attr("data-wps-audio-image", data[0].url);

                                self.save();
                            })
                        },// Callback on refresh.
                        refresh: function ($btn) {
                            var _img = this.image.get();
                            var _audio = _img.parent().find("audio");
                            if(_audio.length>0){
                                $($btn).removeClass("fr-disabled");
                            }else{
                                $($btn).addClass("fr-disabled");
                            }
                        }
                        });

                        //Audio Upload
                        self.window.FroalaEditor.DefineIcon('audioCustomUpload', {NAME: 'insertAudio', PATH: 'M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z'});
                        self.window.FroalaEditor.RegisterCommand('audioCustomUpload', {
                        title: self.labels.audioAdd,
                        focus: true,
                        undo: true,
                        refreshAfterCallback: true,
                        callback: function () {
                            var that = this;
                            setTimeout(function () {
                                if( that.popups)  that.popups.hideAll();
                            if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                            }, 100);
                            var _img = this.image.get();
                            //console.log("imageUploadModal",_size,_focus  );
                            if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                            "src": '',
                            "filetype": "audio/mp3",
                            "focal": false
                            }, function(data){

                                if(_img.parent().hasClass("image-audio-wrap")){
                                    _img.parent().find("audio").remove();
                                    _img.unwrap();
                                }else{
                                    _img.wrap("<span class='image-audio-wrap'></span>");
                                    _img.parent().append('<audio class="d-none image-click-audio" preload="true" controls="" src="'+data[0].src+'"  ></audio>')
                                }
                                self.save();
                            })

                        }
                        });







                        // Video Upload
                        self.window.FroalaEditor.DefineIcon('videoCustomUpload', {NAME: 'insertVideo', SVG_KEY: 'insertVideo'});
                        self.window.FroalaEditor.RegisterCommand('videoCustomUpload', {
                          title: self.labels.videoAdd,
                          focus: true,
                          undo: true,
                          refreshAfterCallback: true,
                          callback: function () {
                            var that = this;
                              console.log("this",this);
                            setTimeout(function () {
                                if( that.popups)  that.popups.hideAll();
                              if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                            }, 100);

                              //console.log("imageUploadModal",_size,_focus  );
                            if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                              "src": '',
                              "filetype": "video/mp4",
                              "focal": false
                            }, function(data){
                                console.log("data", data);
                                that.html.insert('<span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable" style=""><video  style="width:100%;"><source src="'+data[0].src+'" type="video/mp4">Your browser does not support HTML5 video.</video></span>');
                                self.save();
                            })

                          }
                        });


                        var _videoModalStyles = {
                            "wbmg-video-loop": self.labels.videoPopup.loop,
                            "wbmg-video-autoplay": self.labels.videoPopup.autoplay,
                            "wbmg-video-controls": self.labels.videoPopup.controls,
                            "wbmg-video-playsinline": self.labels.videoPopup.playsinline,
                            "wbmg-video-transparent": self.labels.videoPopup.transparent,
                            'rounded': self.labels.imageStyles.rounded,
                            'shadow': self.labels.imageStyles.shadow,
                            'shadow-sm': self.labels.imageStyles.shadow_sm,
                            'mt-2': self.labels.imageStyles.mt_2,
                            'ml-2': self.labels.imageStyles.ml_2,
                            'mr-2': self.labels.imageStyles.mr_2,
                            'mb-2': self.labels.imageStyles.mb_2,
                            'align-middle': self.labels.imageStyles.align_middle,
                            'hidden-sm-down': self.labels.imageStyles.hidden_sm_down,
                            'float-sm-left mb-3 mr-sm-3 mt-0': self.labels.imageStyles.wrap_text_left,
                            'float-sm-right mb-3 ml-sm-3 mt-0': self.labels.imageStyles.wrap_text_right
                        };
                        self.window.FroalaEditor.DefineIcon('customVideoList', {NAME: 'cog', PATH: 'M18.8 2.5c-1.4 0-2.5.9-2.9 2.1H5.7c-1.2 0-2.3 1-2.3 2.3v10c0 1.2 1 2.3 2.3 2.3h12c1.2 0 2.3-1 2.3-2.3V8.2c1.1-.5 1.8-1.5 1.8-2.7 0-1.7-1.3-3-3-3zm-.3 14.4c0 .4-.3.8-.8.8h-12c-.4 0-.8-.3-.8-.8v-6.8h13.5v6.8zm0-8.3H5V6.9c0-.4.3-.8.8-.8H16c.3 1.2 1.3 2.2 2.6 2.3v.2zm1.7-2.2c.1.1.1.2 0 .3s-.2.1-.3 0l-1-1-1 1c-.1.1-.2.1-.3 0s-.1-.2 0-.3l1-1-1-1c-.1-.1-.1-.2 0-.3s.2-.1.3 0l1 1 1-1c.1-.1.2-.1.3 0s.1.2 0 .3l-1 1 1 1z'});
                        self.window.FroalaEditor.RegisterCommand('customVideoList', {
                            title: self.labels.videoPopup.modal,
                            type: 'dropdown',
                            focus: false,
                            undo: false,
                            refreshAfterCallback: true,
                            options: _videoModalStyles,
                            callback: function (cmd, val) {
                                var _vid = this.video.get();
                                console.log("_vid", _vid);
                                var _video = _vid.find("video");
                                // is mp4 video
                                if(_video.length>0) _vid = _video;

                                console.log("_video", _video);

                                if(val == "wbmg-video-autoplay"){
                                    if(_video.attr("autoplay") == "true"){
                                        _video.removeAttr("muted");
                                        _video.removeAttr("autoplay");
                                    }else{
                                        _video.attr("muted", "true");
                                        _video.attr("autoplay", "true");
                                    }
                                } else if(val == "wbmg-video-loop"){
                                    console.log("attr",_video.attr("loop"))
                                    if(_video.attr("loop") == "true"){
                                        _video.removeAttr("loop");
                                    }else{
                                        _video.attr("loop", "true");
                                    }
                                } else if(val == "wbmg-video-controls"){
                                    if(_video.attr("controls") == "true"){
                                        _video.removeAttr("controls");
                                    }else{
                                        _video.attr("controls", "true");
                                    }
                                }else if(val == "wbmg-video-transparent"){
                                    if(_video.attr("type") === 'video/mp4;codecs="hvc1"'){
                                        _video.attr("type", 'video/mp4');
                                    }else{
                                        _video.attr("type", 'video/mp4;codecs="hvc1"');
                                    }
                                }else if(val == "wbmg-video-playsinline"){
                                    if(_video.attr("playsinline") == "true"){
                                        _video.removeAttr("playsinline");
                                    }else{
                                        _video.attr("playsinline", "true");
                                    }
                                }else{
                                    _vid.toggleClass(val);
                                    //oder _video.parent().toggleClass(val); ?
                                }
                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                var _vid = this.video.get();
                                var _video = _vid.find("video");
                                $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                                if(_video.length>0){
                                    $($btn).parent().find('[data-param1="wbmg-video-loop"]').show();
                                    $($btn).parent().find('[data-param1="wbmg-video-autoplay"]').show();
                                    $($btn).parent().find('[data-param1="wbmg-video-controls"]').show();
                                    $($btn).parent().find('[data-param1="wbmg-video-transparent"]').show();
                                    $($btn).parent().find('[data-param1="wbmg-video-playsinline"]').show();
                                    if(_video.attr("loop") == "true"){
                                        $($btn).parent().find('[data-param1="wbmg-video-loop"]').addClass("fr-active");
                                    }
                                    if(_video.attr("autoplay") == "true"){
                                        $($btn).parent().find('[data-param1="wbmg-video-autoplay"]').addClass("fr-active");
                                    }
                                    if(_video.attr("controls") == "true"){
                                        $($btn).parent().find('[data-param1="wbmg-video-controls"]').addClass("fr-active");
                                    }
                                    if(_video.attr("playsinline") == "true"){
                                        $($btn).parent().find('[data-param1="wbmg-video-playsinline"]').addClass("fr-active");
                                    }
                                    if(_video.attr("type") === 'video/mp4;codecs="hvc1"'){
                                        $($btn).parent().find('[data-param1="wbmg-video-transparent"]').addClass("fr-active");
                                    }
                                    _vid = _video;
                                } else{
                                    $($btn).parent().find('[data-param1="wbmg-video-loop"]').hide();
                                    $($btn).parent().find('[data-param1="wbmg-video-autoplay"]').hide();
                                    $($btn).parent().find('[data-param1="wbmg-video-controls"]').hide();
                                    $($btn).parent().find('[data-param1="wbmg-video-playsinline"]').hide();
                                    $($btn).parent().find('[data-param1="wbmg-video-transparent"]').hide();
                                }
                                //var _vid = $(this).parent().find("img[data-wps-edit]");
                                console.log("_video", _video, $btn);

                                if(_vid.hasClass("rounded")){
                                    $($btn).parent().find('[data-param1="rounded"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("shadow")){
                                    $($btn).parent().find('[data-param1="shadow"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("shadow-sm")){
                                    $($btn).parent().find('[data-param1="shadow-sm"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("mt-2")){
                                    $($btn).parent().find('[data-param1="mt-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("ml-2")){
                                    $($btn).parent().find('[data-param1="ml-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("mr-2")){
                                    $($btn).parent().find('[data-param1="mr-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("mb-2")){
                                    $($btn).parent().find('[data-param1="mb-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("align-middle")){
                                    $($btn).parent().find('[data-param1="align-middle"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("hidden-sm-down")){
                                    $($btn).parent().find('[data-param1="hidden-sm-down"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("float-sm-left mb-3 mr-sm-3 mt-0")){
                                    $($btn).parent().find('[data-param1="float-sm-left mb-3 mr-sm-3 mt-0"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("float-sm-right mb-3 ml-sm-3 mt-0")){
                                    $($btn).parent().find('[data-param1="float-sm-right mb-3 ml-sm-3 mt-0"]').addClass("fr-active");
                                }
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                console.log("this", this);
                                var _vid = this.video.get();
                                var _video = _vid.find("video");
                                //var _vid = $(this).parent().find("img[data-wps-edit]");
                                // is mp4 video
                                if(_video.length>0) _vid = _video;
                                $($btn).parent().find("#dropdown-menu-"+$btn[0].id+" li a").removeClass("fr-active");
                                if(_video.attr("loop") == "true"){
                                    $($btn).parent().find('[data-param1="wbmg-video-loop"]').addClass("fr-active");
                                }
                                if(_video.attr("autoplay") == "true"){
                                    $($btn).parent().find('[data-param1="wbmg-video-autoplay"]').addClass("fr-active");
                                }
                                if(_video.attr("controls") == "true"){
                                    $($btn).parent().find('[data-param1="wbmg-video-controls"]').addClass("fr-active");
                                }
                                if(_video.attr("playsinline") == "true"){
                                    $($btn).parent().find('[data-param1="wbmg-video-playsinline"]').addClass("fr-active");
                                }
                                if(_video.attr("type") === 'video/mp4;codecs="hvc1"'){
                                    $($btn).parent().find('[data-param1="wbmg-video-transparent"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("rounded")){
                                    $($btn).parent().find('[data-param1="rounded"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("shadow")){
                                    $($btn).parent().find('[data-param1="shadow"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("shadow-sm")){
                                    $($btn).parent().find('[data-param1="shadow-sm"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("mt-2")){
                                    $($btn).parent().find('[data-param1="mt-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("ml-2")){
                                    $($btn).parent().find('[data-param1="ml-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("mr-2")){
                                    $($btn).parent().find('[data-param1="mr-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("mb-2")){
                                    $($btn).parent().find('[data-param1="mb-2"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("align-middle")){
                                    $($btn).parent().find('[data-param1="align-middle"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("hidden-sm-down")){
                                    $($btn).parent().find('[data-param1="hidden-sm-down"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("float-sm-left mb-3 mr-sm-3 mt-0")){
                                    $($btn).parent().find('[data-param1="float-sm-left mb-3 mr-sm-3 mt-0"]').addClass("fr-active");
                                }
                                if(_vid.hasClass("float-sm-right mb-3 ml-sm-3 mt-0")){
                                    $($btn).parent().find('[data-param1="float-sm-right mb-3 ml-sm-3 mt-0"]').addClass("fr-active");
                                }
                            }
                        });


                        self.window.FroalaEditor.DefineIcon('customVideoAutoplay', {NAME: 'AUTO PLAY', SVG_KEY: 'autoplay'});
                        self.window.FroalaEditor.RegisterCommand('customVideoAutoplay', {
                                title: self.labels.videoAutoplay,
                                focus: false,
                                undo: false,
                                refreshAfterCallback: false,
                                callback: function () {
                                    var that = this;

                                    setTimeout(function () {
                                        if( that.popups)  that.popups.hideAll();
                                        if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                    }, 100);
                                    console.log("this", this);
                                    var _vid = this.video.get();
                                    var _video = _vid.find("video");
                                    //var _vid = $(this).parent().find("img[data-wps-edit]");
                                    console.log("_video", _video);
                                    // var _src = _vid.attr("src")
                                    // var _id = _vid.attr("data-id");
                                    _video.attr("muted", "true");
                                    _video.attr("autoplay", "true");
                                    _video.attr("controls", "false");

                                    self.save();
                                }
                        });

                        self.window.FroalaEditor.DefineIcon('customVideoLoop', {NAME: 'AUTO LOOP', PATH: 'M10.5 7.1c-.2 0-.4-.1-.5-.1h-.7s-.1 0-.1-.1V5l-.1-.1L5.5 8v.1l.8.7L5 10.3l-1-.9h-.1l-.2.2c-.8 1-1.3 2.3-1.3 3.6 0 2.6 1.7 4.8 4.2 5.5h.1c.1 0 .3.1.4.1h.7s.1 0 .1.1v1.9l.1.2 3.6-3.1v-.1L8 14.7s-.1 0-.1.1v2.1s0 .1-.1.1-.2 0-.3-.1c-.1 0-.3-.1-.4-.1-.1 0-.2-.1-.2-.1-.2-.1-.3-.1-.5-.2h-.1s-.1 0-.1-.1c-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5v-.1c-.1-.2-.1-.3-.2-.6v-.2c-.1-.2-.1-.5-.1-.7 0-.8.3-1.6.7-2.2l.2-.2v-.1l-.6-.6 1.3-1.4 2.8 2.4s.1 0 .1-.1V9.1s0-.1.1-.1.2 0 .3.1c.1 0 .3.1.4.1.1 0 .2.1.2.1.2.1.3.1.5.2.1 0 .1.1.2.1.1.1.2.2.4.3.1.1.3.3.4.5.1.2.2.3.3.6.1.2.1.4.2.6v.2c.1.2.1.5.1.7 0 .8-.3 1.6-.7 2.2l.1.3c-.1.1-.1.2 0 .3l1.3 1.2h.3l.1-.2c.8-1 1.3-2.3 1.3-3.6 0-2.6-1.7-4.8-4.2-5.6z'});
                        self.window.FroalaEditor.RegisterCommand('customVideoLoop', {
                                title: self.labels.videoLoop,
                                focus: false,
                                undo: false,
                                refreshAfterCallback: false,
                                callback: function () {
                                    var that = this;

                                    setTimeout(function () {
                                        if( that.popups)  that.popups.hideAll();
                                        if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                    }, 100);
                                    console.log("this", this);
                                    var _vid = this.video.get();
                                    var _video = _vid.find("video");
                                    //var _vid = $(this).parent().find("img[data-wps-edit]");
                                    console.log("_video", _video);
                                    // var _src = _vid.attr("src")
                                    // var _id = _vid.attr("data-id");
                                    _video.attr("loop", "true");

                                    self.save();
                                }
                        });

                        self.window.FroalaEditor.DefineIcon('customVideoResponsive', {NAME: 'Responsive Video', PATH: 'm20 5h-16c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h7.2l-0.3-0.6 1-0.9h-2.4v-2.5h5.4 1.1v-4.5h4.5v3h-4.6v1.5h6.1v-8c0-1.1-0.9-2-2-2zm-16.5 2c0-0.3 0.2-0.5 0.4-0.5h0.1 4v2.5h-4.5v-2zm0 3.5h4.5v3h-4.5v-3zm0.5 7c-0.3 0-0.5-0.2-0.5-0.4v-0.1-2h4.5v2.5h-4zm10.5-4h-5v-3h5v3zm0-4.5h-5v-2.5h5v2.5zm6 0h-4.5v-2.5h4c0.3 0 0.5 0.2 0.5 0.4v0.1 2zm2 10.2v0c0-0.1 0.1-0.1 0.1-0.1v-0.1-0.1-0.3-0.1-0.1-0.1-0.1l-2.3-2.3c-0.3-0.3-0.8-0.2-1 0.1s-0.2 0.7 0 0.9l0.9 0.9h-4.4l0.9-0.9c0.3-0.3 0.2-0.8-0.1-1s-0.7-0.2-0.9 0l-2.3 2.3v0.1 0.1 0.1 0.1 0.3 0.1 0.1 0.1 0.1l2.3 2.3c0.3 0.3 0.8 0.2 1-0.1s0.2-0.7 0-0.9l-0.9-0.9h4.4l-0.9 0.9c-0.3 0.3-0.3 0.7-0.1 1 0.3 0.3 0.7 0.3 1 0.1l2.3-2.3c-0.1-0.2-0.1-0.2 0-0.2z'});
                        self.window.FroalaEditor.RegisterCommand('customVideoResponsive', {
                                title: self.labels.videoResponsive,
                                focus: false,
                                undo: false,
                                refreshAfterCallback: false,
                                refresh: function ($btn) {

                                    console.log ("refresh", this.video.get());
                                    console.log("$btn", $btn);
                                    var _vid = this.video.get();
                                    var _video = _vid.find("video");
                                    // is mp4 video
                                    if(_video.length>0){
                                        $btn.addClass("fr-disabled");
                                    }else{
                                        $btn.removeClass("fr-disabled");
                                        if(this.video.get().hasClass("fr-rv")){
                                            $btn.addClass("fr-active");
                                        }else{
                                            $btn.removeClass("fr-active");
                                        }
                                    }
                                },
                                callback: function () {
                                    var that = this;

                                    setTimeout(function () {
                                        if( that.popups)  that.popups.hideAll();
                                        if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                    }, 100);
                                    console.log("this", this);
                                    var _vid = this.video.get();
                                    //var _vid = $(this).parent().find("img[data-wps-edit]");
                                    console.log("_vid", _vid);
                                    // var _src = _vid.attr("src")
                                    // var _id = _vid.attr("data-id");
                                    if(_vid.hasClass("fr-rv")){
                                        _vid.removeClass("fr-rv");
                                    }else{
                                        _vid.addClass("fr-rv");
                                    }

                                }
                        });

                        self.window.FroalaEditor.DefineIcon('customVideoReplace', {NAME: 'Replace Image', SVG_KEY: 'replaceImage'});
                        self.window.FroalaEditor.RegisterCommand('customVideoReplace', {
                                title: self.labels.videoReplace,
                                focus: false,
                                undo: false,
                                refreshAfterCallback: false,
                                refresh: function ($btn) {
                                    console.log ("refresh", this.video.get());
                                    console.log("$btn", $btn);
                                    var _vid = this.video.get();
                                    var _video = _vid.find("video");
                                    // is mp4 video
                                    if(_video.length>0){
                                        $btn.removeClass("fr-disabled");
                                    }else{
                                        $btn.addClass("fr-disabled");
                                    }
                                },
                                callback: function () {
                                    var that = this;

                                    setTimeout(function () {
                                        if( that.popups)  that.popups.hideAll();
                                        if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                    }, 100);
                                    console.log("this", this);
                                    var _vid = this.video.get();
                                    //var _vid = $(this).parent().find("img[data-wps-edit]");
                                    console.log("_vid", _vid);
                                    // var _src = _vid.attr("src")
                                    // var _id = _vid.attr("data-id");


                                        //console.log("imageUploadModal",_size,_focus  );
                                    if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                                        "src": _vid.find("video").attr("src"),
                                        "filetype": "video/mp4",
                                        "focal": false
                                    }, function(data){
                                        console.log("data", data);
                                        var targetType = "video/mp4";
                                        var source = _vid.find("source[type='" + targetType + "']");
                                        if (source.length > 0) {
                                            source.attr("src", data[0].src);
                                        } else {
                                            // remove src from video element
                                            _vid.find('video').removeAttr("src");
                                            // add a source element with the new format
                                            _vid.find('video').append('<source src="' + data[0].src + '" type="' + targetType + '">');
                                        }
                                        //_vid.find("video").attr("src",data[0].src);
                                        self.save();
                                    })
                                }
                        });


                        self.window.FroalaEditor.DefineIcon('customVideoAlternativeFormat', {NAME: 'Replace Image', PATH: 'M20.6 7.6c-.3 0-.5.1-.7.3l-2.4 2.4V6.7c0-.6-.5-1-1-1H3.9c-.6 0-1 .5-1 1v10.4c0 .6.5 1 1 1h12.5c.6 0 1-.5 1-1v-3.6l2.4 2.4c.4.4 1.1.4 1.5 0 .2-.2.3-.5.3-.7V8.7c0-.6-.5-1.1-1-1.1zm-9.5.1V16H9V7.7h2.1zM5 7.7h2V16H5V7.7zM15.4 16h-2.3V7.7h2.3V16z'});
                        self.window.FroalaEditor.RegisterCommand('customVideoAlternativeFormat', {
                                title: self.labels.videoAlternativeFormat,
                                focus: false,
                                undo: false,
                                refreshAfterCallback: false,
                                refresh: function ($btn) {
                                    console.log ("refresh", this.video.get());
                                    console.log("$btn", $btn);
                                    var _vid = this.video.get();
                                    var targetType = "video/webm";
                                    var _source = _vid.find("source[type='" + targetType + "']");
                                    // is mp4 video
                                    if(_source.length>0 || _vid.find("video").length == 0){
                                        $btn.addClass("d-none");
                                    }else{
                                        $btn.removeClass("d-none");
                                    }
                                },
                                callback: function () {
                                    var that = this;

                                    setTimeout(function () {
                                        if( that.popups)  that.popups.hideAll();
                                        if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                    }, 100);
                                    console.log("this", this);
                                    var _vid = this.video.get();
                                    //var _vid = $(this).parent().find("img[data-wps-edit]");
                                    console.log("_vid", _vid);
                                    // var _src = _vid.attr("src")
                                    // var _id = _vid.attr("data-id");

                                    var targetType = "video/webm";
                                    var source = _vid.find("source[type='" + targetType + "']");
                                    var src = "";
                                    if (source.length > 0) {
                                        //get src from source element
                                        src = source.attr("src");
                                    }
                                        //console.log("imageUploadModal",_size,_focus  );
                                    if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                                        "src": src,
                                        "filetype": "video/webm",
                                        "focal": false
                                    }, function(data){
                                        console.log("data", data);
                                        var targetType = "video/webm";
                                        var source = _vid.find("source[type='" + targetType + "']");
                                        if (source.length > 0) {
                                            source.attr("src", data[0].src);
                                        } else {

                                            // add a source element with the new format
                                            _vid.find('video').append('<source src="' + data[0].src + '" type="' + targetType + '">');
                                        }
                                        //_vid.find("video").attr("src",data[0].src);
                                        self.save();
                                    })
                                }
                        });

                        self.window.FroalaEditor.DefineIcon('customVideoAlternativeFormatUnlink', {NAME: 'insertVideoLink', PATH: 'M20.6 7.6c-.3 0-.5.1-.7.3l-2.4 2.4V6.7c0-.6-.5-1-1-1H8l2.1 2.1h1v1l1.9 1.9v-3h2.3V13l2.1 2.1v-1.6l2.4 2.4c.4.4 1.1.4 1.5 0 .2-.2.3-.5.3-.7V8.7c0-.6-.5-1.1-1-1.1zM11.1 16H9v-4l-2-1.9v6H5V8L3.1 6.1c-.1.2-.2.4-.2.6v10.4c0 .6.5 1 1 1h11.2l-4-4V16zM2.563 4.214 3.836 2.94l16.617 16.617-1.273 1.273z'});
                        self.window.FroalaEditor.RegisterCommand('customVideoAlternativeFormatUnlink', {
                            title: self.labels.videoAlternativeFormatUnlink,
                            undo: false,
                            focus: false,
                            popup: true,
                            // Buttons which are included in the editor toolbar should have the plugin property set.
                            //plugin: 'customPlugin',
                            refresh: function ($btn) {
                                console.log ("refresh", this.video.get());
                                console.log("$btn", $btn);
                                var _vid = this.video.get();
                                var targetType = "video/webm";
                                var _video = _vid.find("source[type='" + targetType + "']");
                                // is mp4 video
                                if(_video.length>0){
                                    $btn.removeClass("d-none");
                                }else{
                                    $btn.addClass("d-none");
                                }
                            },
                            callback: function () {
                                var _video = $(this.video.get());
                                var targetType = "video/webm";
                                var source = _video.find("source[type='" + targetType + "']");
                                if (source.length > 0) {
                                    source.remove();
                                }
                                self.save();
                                this.events.focus();
                            }
                        });


                        self.window.FroalaEditor.DefineIcon('customVideoPoster', {NAME: 'Replace Image', SVG_KEY: 'insertImage'});
                        self.window.FroalaEditor.RegisterCommand('customVideoPoster', {
                                title: self.labels.videoPoster,
                                focus: false,
                                undo: false,
                                refreshAfterCallback: false,
                                refresh: function ($btn) {
                                    var _vid = this.video.get();
                                    // check if video element has a poster attribute
                                    if(_vid.find("video").attr("poster") != undefined || _vid.find("video").length == 0){
                                        $btn.addClass("d-none");
                                    }else{
                                        $btn.removeClass("d-none");
                                    }
                                },
                                callback: function () {
                                    var that = this;
                                    setTimeout(function () {
                                        if( that.popups)  that.popups.hideAll();
                                        if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                    }, 100);
                                    var _vid = this.video.get();

                                   // get poster url from video element
                                    var _poster = _vid.find("video").attr("poster");
                                    var src = "";
                                    //if poster attribute is set and not empty
                                    if (_poster != undefined && _poster.length > 0) {
                                        src = _poster;
                                    }

                                    if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                                        "src": src,
                                        "focal": false
                                    }, function(data){
                                        console.log("data", data);
                                        //add poster attribute to video element
                                        _vid.find("video").attr("poster",data[0].url);
                                        self.save();
                                    })
                                }
                        });

                        self.window.FroalaEditor.DefineIcon('videoPosterUnlink', {NAME: 'insertVideoLink', PATH: 'M19.9 3.6H7.3l1.3 1.3H7.3L4.9 2.5 3.6 3.8 4.8 5H3.2l-.6-.7-.1-.1c-.4.3-.6.9-.6 1.4v12c0 1.1.9 2 2 2H18l-2-2H3.9v-12H4L3.3 5h1.5l4.6 4.6H7.9l-.9-1c-.1.2-.1.4-.1.5 0 .8.7 1.5 1.5 1.5.2 0 .3 0 .5-.1l-1-.9h1.5l5 5H13l-1.2-1.2-.8 1-2.1-2.7-3 3.9H14l-1-1h1.5l5.7 5.7 1.3-1.3L7.4 5h1.3l.6.6h10.6v10.6l1.9 1.9c0-.2.1-.3.1-.5v-12c0-1.1-.9-2-2-2z'});
                        self.window.FroalaEditor.RegisterCommand('videoPosterUnlink', {
                            title: self.labels.videoPosterUnlink,
                            undo: false,
                            focus: false,
                            popup: true,
                            // Buttons which are included in the editor toolbar should have the plugin property set.
                            //plugin: 'customPlugin',
                            refresh: function ($btn) {
                                var _vid = this.video.get();
                                // check if video element has a poster attribute
                                if(_vid.find("video").attr("poster") != undefined){
                                    $btn.removeClass("d-none");
                                }else{
                                    $btn.addClass("d-none");
                                }
                            },
                            callback: function () {
                                var _vid = $(this.video.get());
                                // delete poster attribut
                                _vid.find("video").removeAttr("poster");
                                self.save();
                                this.events.focus();
                            }
                        });



                        /* CUSTOM VIDEO LINK PLUGIN */
                            _pluginsEnabled.push('customVideoLinkPlugin');
                            // Define an icon and command for the button that opens the custom popup.
                            self.window.FroalaEditor.DefineIcon('customVideoLink', {NAME: 'insertVideoLink', SVG_KEY: 'insertLink'});
                            self.window.FroalaEditor.RegisterCommand('customVideoLink', {
                                title: self.labels.insertVideoLink,
                                undo: false,
                                focus: false,
                                popup: true,
                                // Buttons which are included in the editor toolbar should have the plugin property set.
                                //plugin: 'customPlugin',
                                refresh: function ($btn) {

                                    console.log ("refresh", this.video.get());
                                    console.log("$btn", $btn);
                                    var _vid = this.video.get();
                                    console.log("_vid.closest", _vid.closest("a"))
                                    if(_vid.closest("a").length == 0){
                                        $btn.removeClass("d-none");
                                    }else{
                                        $btn.addClass("d-none");
                                    }
                                },
                                callback: function () {
                                    if (!this.popups.isVisible('imageVideoLinkPopup.popup')) {
                                    this.customVideoLinkPlugin.showPopup(this);
                                    }
                                    else {
                                    if (this.$el.find('.fr-marker')) {
                                        this.events.disableBlur();
                                        this.selection.restore();
                                    }
                                    this.customVideoLinkPlugin.hide('imageVideoLinkPopup.popup');
                                    }
                                }
                            });
                            self.window.FroalaEditor.DefineIcon('customVideoLinkEdit', {NAME: 'insertVideoLink', SVG_KEY: 'edit'});
                            self.window.FroalaEditor.RegisterCommand('customVideoLinkEdit', {
                                title: self.labels.editVideoLink,
                                undo: false,
                                focus: false,
                                popup: true,
                                // Buttons which are included in the editor toolbar should have the plugin property set.
                                //plugin: 'customPlugin',
                                refresh: function ($btn) {
                                    console.log ("refresh", this.video.get());
                                    console.log("$btn", $btn);
                                    var _vid = this.video.get();
                                    console.log("_vid.closest", _vid.closest("a"))
                                    if(_vid.closest("a").length == 0){
                                        $btn.addClass("d-none");
                                    }else{
                                        $btn.removeClass("d-none");
                                    }
                                },
                                callback: function () {
                                    if (!this.popups.isVisible('imageVideoLinkPopup.popup')) {
                                    this.customVideoLinkPlugin.showPopup(this);
                                    }
                                    else {
                                    if (this.$el.find('.fr-marker')) {
                                        this.events.disableBlur();
                                        this.selection.restore();
                                    }
                                    this.customVideoLinkPlugin.hide('imageVideoLinkPopup.popup');
                                    }
                                }
                            });
                            self.window.FroalaEditor.DefineIcon('customVideoUnlink', {NAME: 'insertVideoLink', SVG_KEY: 'unlink'});
                            self.window.FroalaEditor.RegisterCommand('customVideoUnlink', {
                                title: self.labels.unlinkVideoLink,
                                undo: false,
                                focus: false,
                                popup: true,
                                // Buttons which are included in the editor toolbar should have the plugin property set.
                                //plugin: 'customPlugin',
                                refresh: function ($btn) {

                                    console.log ("refresh", this.video.get());
                                    console.log("$btn", $btn);
                                    var _vid = this.video.get();
                                    console.log("_vid.closest", _vid.closest("a"))
                                    if(_vid.closest("a").length == 0){
                                        $btn.addClass("d-none");
                                    }else{
                                        $btn.removeClass("d-none");
                                    }
                                },
                                callback: function () {
                                    var _video = $(this.video.get());
                                    _video.unwrap();
                                    self.save();
                                    this.events.focus();
                                    console.log(this, this.video.get());
                                }
                            });
                            self.window.FroalaEditor.DefineIcon('customVideoLinkPlugin_popupClose', {
                                NAME: 'times',
                                SVG_KEY: 'back'
                            });
                            self.window.FroalaEditor.RegisterCommand('customVideoLinkPlugin_popupClose', {
                                title: self.labels.back,
                                undo: false,
                                focus: false,
                                callback: function() {
                                    this.customVideoLinkPlugin.hidePopup();
                                }
                            });
                            // Define popup template.
                            self.window.FroalaEditor.POPUP_TEMPLATES["customVideoLinkPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';
                            // Define popup buttons.
                            Object.assign(self.window.FroalaEditor.DEFAULTS, {
                                popupButtonsVideoLink: ['customVideoLinkPlugin_popupClose','|'],
                            });
                            // The custom popup is defined inside a plugin (new or existing).
                            self.window.FroalaEditor.PLUGINS.customVideoLinkPlugin = function(editor) {
                                // Create custom popup.
                                function initPopup() {
                                    // Popup buttons.
                                    var popup_buttons = '';
                                    var that = this;
                                    console.log("that", that);

                                    // Create the list of buttons.
                                    if (editor.opts.popupButtonsVideoLink.length > 1) {
                                    popup_buttons += '<div class="fr-buttons">';
                                    popup_buttons += editor.button.buildList(editor.opts.popupButtonsVideoLink);
                                    popup_buttons += '</div>';
                                    }


                                    var modal_content =
                                    '<div class="fr-link-insert-layer fr-layer fr-active" id="fr-link-insert-layer-6" data-mouseenter-event-set="true">'+
                                        '<div class="fr-input-line"><input id="fr-link-insert-layer-url-6" name="href" type="text" class="fr-link-attr" placeholder="" tabindex="1" dir="auto"><label for="fr-link-insert-layer-url-6">URL</label></div>'+
                                            '<div class="fr-input-line" style="display: none;">'+
                                                '<input id="fr-link-insert-layer-text-6" name="text" type="text" class="fr-link-attr" placeholder="" tabindex="2" dir="auto">'+
                                                '<label for="fr-link-insert-layer-text-6">Text</label>'+
                                            '</div>'+
                                        '<div class="fr-checkbox-line">'+
                                            '<span class="fr-checkbox">'+
                                                '<input name="target" class="fr-link-attr fr-not-empty" data-checked="_blank" type="checkbox" id="fr-link-target-6" tabindex="3" dir="auto" >'+
                                                '<span>'+
                                                    ' <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32"><path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path></svg>'+
                                                '</span>'+
                                            '</span>'+
                                            '<label id="fr-label-target-6">Open in new tab</label>'+
                                        '</div>'+
                                        '<div class="fr-action-buttons">'+
                                            '<button class="fr-command fr-submit" role="button" href="#" tabindex="4" type="button">Insert</button>'+
                                        '</div>'+
                                    '</div>';


                                    // Load popup template.
                                    var template = {
                                        buttons: popup_buttons,
                                        custom_layer: modal_content
                                    };

                                    // Create popup.
                                    var $popup = editor.popups.create('customVideoLinkPlugin.popup', template);



                                    $popup.find(".fr-submit").on('click', function() {

                                        var _link = $popup.find('input.fr-link-attr[type="text"]').val();
                                        var isChecked = ($popup.find('input.fr-link-attr[type="checkbox"]').is(':checked')) ? "_blank" : "";

                                        console.log(_link, isChecked);

                                        console.log("_video", $popup.video );

                                        if(_link && _link!=""){
                                            if($popup.video.closest("a").length != 0){
                                                $popup.video.unwrap();
                                            }
                                            $popup.video.wrap('<a href="'+_link+'" target="'+isChecked+'"></a>')
                                        }

                                        self.save();
                                        hidePopup()
                                        // var _val = _select.val();
                                        // $popup.element.attr("data-wps-variable", _val);
                                        // //console.log("change", editor.element);
                                        // if(_select.val()=="Custom Text"){
                                        //     console.log("_froalaInput", _froalaInput);
                                        //     console.dir(_froalaInput[0]["data-froala.editor"]);
                                        //     console.dir(_froalaInput[0]["data-froala.editor"].html.get());
                                        //     $popup.element.attr("data-original-title", encodeURIComponent(_froalaInput[0]["data-froala.editor"].html.get()));
                                        // }
                                        // else{
                                        //     $popup.element.attr("data-original-title", "{{{"+_val+"}}}");
                                        // }
                                        // self.save();
                                        // hidePopup();

                                    })

                                    return $popup;
                                }

                                // Show the popup
                                function showPopup(that) {
                                    // Get the popup object defined above.
                                    var $popup = editor.popups.get('customVideoLinkPlugin.popup');

                                    // If popup doesn't exist then create it.
                                    // To improve performance it is best to create the popup when it is first needed
                                    // and not when the editor is initialized.
                                    if (!$popup) $popup = initPopup();

                                    // Set the editor toolbar as the popup's container.
                                    editor.popups.setContainer('customVideoLinkPlugin.popup', editor.$tb);

                                    // This will trigger the refresh event assigned to the popup.
                                    // editor.popups.refresh('customVideoLinkPlugin.popup');

                                    // This custom popup is opened by pressing a button from the editor's toolbar.
                                    // Get the button's object in order to place the popup relative to it.
                                    var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');
                                    console.log("that", that);
                                    var _video = that.video.get();
                                    $popup.video = _video;
                                    var $el = $(editor.selection.element());
                                    // Set the popup's position.
                                    var left = _video.offset().left + _video.outerWidth() / 2;
                                    var top = _video.offset().top + (editor.opts.toolbarBottom ? 10 : _video.outerHeight() - 10);
                                    //var top = _img.offset().top + _img.outerHeight(); // Enhancement 2950

                                    var _link= "",
                                        _checked = "",
                                        _a = $popup.video.closest("a");
                                    if(_a.length != 0){
                                        $popup.find('input.fr-link-attr[type="text"]').val(_a.attr("href"));
                                        $popup.find('input.fr-link-attr[type="checkbox"]').attr('checked="true"');
                                    }

                                    // Show the custom popup.
                                    // The button's outerHeight is required in case the popup needs to be displayed above it.
                                    editor.popups.show('customVideoLinkPlugin.popup', left, top, $btn.outerHeight());

                                }

                                // Hide the custom popup.
                                function hidePopup() {
                                    editor.popups.hide('customVideoLinkPlugin.popup');
                                }

                                // Methods visible outside the plugin.
                                return {
                                    showPopup: showPopup,
                                    hidePopup: hidePopup
                                }
                            }
                        /* END CUSTOM VIDEO LINK PLUGIN */




                        if(true){
                            _pluginsEnabled.push('customCountUpPlugin');
                            //if customForms is not in the toolbarSet7, add it
                                if(self.options['toolbarSet7'].moreRich.buttons.indexOf('customCountUp') == -1){
                                    self.options['toolbarSet7'].moreRich.buttons.push('customCountUp');
                                }
                        }
                        // Define icons and commands
self.window.FroalaEditor.DefineIcon('customCountUp', {NAME: 'Counter', PATH: 'M20.9 3.8H3c-1 0-1.7.8-1.7 1.7v13.9c0 1 .8 1.7 1.7 1.7h17.9c1 0 1.7-.8 1.7-1.7V5.5c0-1-.7-1.7-1.7-1.7zm.7 15.5c0 .4-.3.7-.7.7h-2.4l1.5-3.2c0-.1.1-.1.1-.2v-.8h-2.3v-3c.3 0 .5-.1.7-.2.3-.1.5-.3.7-.5.2-.2.4-.4.5-.7.1-.3.2-.5.2-.9 0-.3-.1-.6-.2-.9-.1-.2-.2-.4-.4-.6-.2-.2-.4-.3-.6-.4s-.5-.1-.7-.1c-.2 0-.3 0-.5.1-.1 0-.3.1-.4.1l.1-.1.1-.1 1.9-2.4h-1c-.1 0-.3 0-.4.1-.1.1-.2.1-.2.2L16 8.5c-.3.3-.5.7-.6 1-.1.3-.2.6-.2.9h-1.7c-.1-.3-.3-.6-.4-.9-.2-.3-.5-.5-.8-.6-.3-.1-.6-.2-.9-.2-.3 0-.7.1-.9.2-.3.1-.5.3-.8.6-.2.3-.4.6-.5 1-.1.4-.2.9-.2 1.3H8c0-.5-.1-.9-.2-1.3s-.3-.8-.5-1c-.2-.2-.4-.4-.7-.5-.3-.2-.6-.2-1-.2-.3 0-.6 0-.9.2-.3.1-.6.3-.8.6-.2.3-.4.6-.5 1-.1.4-.2.9-.2 1.5s.1 1.1.2 1.5c.1.4.3.8.5 1 .2.3.5.5.8.6.3.1.6.2.9.2.3 0 .7-.1.9-.2.3-.1.5-.3.8-.6.2-.3.4-.6.5-1 .2-.4.2-.9.2-1.5v-.2h1.3v.2c0 .6.1 1.1.2 1.5.1.4.3.8.5 1 .2.3.5.5.8.6.3.1.6.2.9.2.3 0 .7-.1.9-.2.3-.1.5-.3.8-.6.2-.3.4-.6.5-1 .1-.4.2-.9.2-1.5s-.1-1.1-.2-1.5c0-.1 0-.1-.1-.2h1.7v.1c0 .3.1.7.2.9.1.3.3.5.4.7.2.2.4.4.7.5.3.1.6.2.9.2h.2v3h-2.2v.8c0 .1 0 .1.1.2s.1.1.2.1h3.1c-.1.1-.2.3-.4.5l-1.3 2.7H3c-.4 0-.7-.3-.7-.7V5.5c0-.4.3-.7.7-.7h17.9c.4 0 .7.3.7.7v13.8zm-3.9-7.4c-.2 0-.4 0-.5-.1-.1-.1-.3-.1-.4-.2s-.2-.2-.2-.4-.1-.3-.1-.5 0-.3.1-.5.1-.3.2-.4.2-.2.4-.2c.1-.1.3-.1.5-.1s.3 0 .5.1c.1.1.3.1.4.2.1.1.2.2.2.4.1.1.1.3.1.5s0 .3-.1.5c-.1.1-.1.3-.2.4-.1.1-.2.2-.4.3h-.5zm-4.8.2c0 .5 0 .9-.1 1.2-.1.3-.2.6-.3.7-.1.2-.2.3-.4.4-.2.1-.3.1-.5.1s-.3 0-.5-.1c-.1-.1-.3-.2-.4-.4s-.2-.4-.3-.7-.1-.7-.1-1.2 0-.9.1-1.2.2-.6.3-.7c.1-.2.2-.3.4-.4.1-.1.3-.1.5-.1s.3 0 .5.1c.1.1.3.2.4.4.1.2.2.4.3.7.1.3.1.7.1 1.2zm-6 0c0 .5 0 .9-.1 1.2-.1.3-.2.6-.3.7-.1.2-.2.3-.4.4-.1.1-.3.1-.5.1s-.3 0-.5-.1c-.1-.1-.2-.2-.3-.4-.1-.2-.2-.4-.3-.7s-.1-.7-.1-1.2 0-.9.1-1.2.2-.6.3-.7c.1-.2.2-.3.4-.4.1-.1.3-.1.5-.1s.3 0 .5.1c.1.1.3.2.4.4.1.2.2.4.3.7-.1.3 0 .7 0 1.2z'});

self.window.FroalaEditor.RegisterCommand('customCountUp', {
    title: self.labels.addCounter,
    icon: 'customCountUp',
    undo: true,
    focus: false,
    plugin: 'customCountUpPlugin',
    callback: function () {
        this.customCountUpPlugin.showPopup();
    }
});

self.window.FroalaEditor.DefineIcon('customCountUpPlugin_popupClose', {
    NAME: 'times',
    SVG_KEY: 'back'
});

self.window.FroalaEditor.RegisterCommand('customCountUpPlugin_popupClose', {
    title: self.labels.back,
    undo: false,
    focus: false,
    callback: function() {
        this.customCountUpPlugin.hidePopup();
    }
});

self.window.FroalaEditor.DefineIcon('customCountUpPlugin_remove', {
    NAME: 'Remove',
    SVG_KEY: 'remove'
});

self.window.FroalaEditor.RegisterCommand('customCountUpPlugin_remove', {
    title: self.labels.delete,
    undo: true,
    focus: false,
    callback: function() {
        var $span = this.customCountUpPlugin.$target;
        console.log("$span", $span, this);
        if ($span) {
            $span.remove();
            this.customCountUpPlugin.hidePopup();
            self.save();
        }
    }
});

// Define popup template
self.window.FroalaEditor.POPUP_TEMPLATES["customCountUpPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

// Define popup buttons
Object.assign(self.window.FroalaEditor.DEFAULTS, {
    popupButtonsCountUp: ['customCountUpPlugin_popupClose', '|', 'customCountUpPlugin_remove'],
});

// Create the plugin
self.window.FroalaEditor.PLUGINS.customCountUpPlugin = function(editor) {
    var $popup;
    var $target;

    function initPopup() {
        // Popup buttons
        var popup_buttons = '';
        if (editor.opts.popupButtonsCountUp.length > 1) {
            popup_buttons += '<div class="fr-buttons">';
            popup_buttons += editor.button.buildList(editor.opts.popupButtonsCountUp);
            popup_buttons += '</div>';
        }
        console.log("target", $target);
        // Create the form HTML
        var template = {
            buttons: popup_buttons,
            custom_layer: '<div class="fr-counter-form p-2">' +
                '<div class="fr-input-line">' +
                    '<input type="number" id="valueInput" name="value" class="fr-counter-value fr-not-empty">' +
                    '<label for="valueInput">' + self.labels.countUpValue + '</label>' +
                '</div>' +
                '<div style="display: flex; gap: 10px;">' +
                    '<div class="fr-input-line" style="flex: 1;">' +
                        '<input type="text" id="prefixInput" name="prefix" placeholder="Prefix" class="fr-counter-prefix fr-not-empty">' +
                        '<label for="prefixInput">' + self.labels.countUpPrefix + '</label>' +
                    '</div>' +
                    '<div class="fr-input-line" style="flex: 1;">' +
                        '<input type="text" id="suffixInput" name="suffix" placeholder="Suffix" class="fr-counter-suffix fr-not-empty">' +
                        '<label for="suffixInput">' + self.labels.countUpSuffix + '</label>' +
                    '</div>' +
                '</div>' +
                '<div class="fr-input-line">' +
                        '<input type="number" id="startValInput" name="startVal" placeholder="Start Value" value="0" class="fr-counter-startVal fr-not-empty">' +
                        '<label for="startValInput">' + self.labels.countUpStartValue + '</label>' +
                    '</div>' +
                '<div style="display: flex; gap: 10px;">' +
                    '<div class="fr-input-line" style="flex: 1;">' +
                        '<input type="number" id="scrollSpyDelayInput" name="scrollSpyDelay" placeholder="Scroll Spy Delay (ms)" value="0" class="fr-counter-scrollSpyDelay fr-not-empty">' +
                        '<label for="scrollSpyDelayInput">' + self.labels.countUpScrollSpyDelay + '</label>' +
                    '</div>' +
                    '<div class="fr-input-line" style="flex: 1;">' +
                        '<input type="number" id="durationInput" name="duration" placeholder="Duration (seconds)" value="2" class="fr-counter-duration fr-not-empty">' +
                        '<label for="durationInput">' + self.labels.countUpDuration + '</label>' +
                    '</div>' +
                '</div>' +
                '<div style="display: flex; gap: 10px;">' +
                    '<div class="fr-input-line" style="flex: 1;">' +
                        '<input type="text" id="decimalInput" name="decimal" placeholder="Decimal separator" value="." class="fr-counter-decimal fr-not-empty">' +
                        '<label for="decimalInput">' + self.labels.countUpDecimal + '</label>' +
                    '</div>' +
                    '<div class="fr-input-line" style="flex: 1;">' +
                        '<input type="number" id="decimalPlacesInput" name="decimalPlaces" placeholder="Decimal Places" value="0" class="fr-counter-decimalPlaces fr-not-empty">' +
                        '<label for="decimalPlacesInput">' + self.labels.countUpDecimalPlaces + '</label>' +
                    '</div>' +
                '</div>' +
                '<div style="display: flex; gap: 10px;">' +
                    '<div class="fr-checkbox-line" style="flex: 1;">' +
                        '<span class="fr-checkbox">' +
                            '<input name="useGrouping" class="fr-counter-useGrouping fr-not-empty" type="checkbox" id="useGroupingInput" checked tabindex="3" dir="auto">' +
                            '<span>' +
                                '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32"><path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path></svg>' +
                            '</span>' +
                        '</span>' +
                        '<label for="useGroupingInput">' + self.labels.countUpUseGrouping + '</label>' +
                    '</div>' +
                    '<div class="fr-input-line separator-input" style="display: none;" style="flex: 1;">' +
                        '<input type="text" id="separatorInput" name="separator" placeholder="Grouping separator" value="," class="fr-counter-separator fr-not-empty">' +
                        '<label for="separatorInput">' + self.labels.countUpSeparator + '</label>' +
                    '</div>' +
                '</div>' +
                '<div class="fr-action-buttons">' +
                    '<button class="fr-command fr-submit" role="button">' + ($target ? self.labels.updateCounter : self.labels.insertCounter) + '</button>' +
                '</div>' +
            '</div>'
        };



        // Create popup
        var $popup = editor.popups.create('customCountUpPlugin.popup', template);

         // Add event handler for useGrouping checkbox
         $popup.find('.fr-counter-useGrouping').on('change', function() {
            var isChecked = $(this).is(':checked');
            $($popup.find('.separator-input')[0]).css('display', isChecked ? 'block' : 'none');
        });

        // Handle form submission
        $popup.find('.fr-submit').on('click', function() {
            var options = {
                value: parseFloat($popup.find('.fr-counter-value').val()) || 0,
                startVal: parseFloat($popup.find('.fr-counter-startVal').val()) || 0,
                duration: parseFloat($popup.find('.fr-counter-duration').val()) || 2,
                decimalPlaces: parseInt($popup.find('.fr-counter-decimalPlaces').val()) || 0,
                useGrouping: $($popup.find('.fr-counter-useGrouping')[0]).is(':checked'),
                separator: $popup.find('.fr-counter-separator').val() || ',',
                decimal: $popup.find('.fr-counter-decimal').val() || '.',
                prefix: $popup.find('.fr-counter-prefix').val() || '',
                suffix: $popup.find('.fr-counter-suffix').val() || '',
                scrollSpyDelay: parseInt($popup.find('.fr-counter-scrollSpyDelay').val()) || 0

            };

            insertCounter(options);
        });

        return $popup;
    }

    function insertCounter(options) {
        // Create the counter span
        var displayValue = options.prefix + options.value + options.suffix;
        var $span = $(`<span class="wbmg_countup" data-wps-countup='${JSON.stringify(options)}'>${displayValue}</span>`);

        if ($target) {
            console.log("updating existing counter", $target, options);
            $target.attr('data-wps-countup', JSON.stringify(options));
            // Find the deepest element that can contain text
            $target.find('.fr-marker').remove();

            var $textElement = $target.find('*').filter(function() {
                return $(this).clone().children().remove().end().text().trim().length > 0;
            }).first();
            console.log("$deepestElement", $textElement);
            var $elementToUpdate = $textElement.length ? $textElement : $target;

            // If no nested elements found, use the target itself
            console.log("$elementToUpdate", options.prefix + options.value + options.suffix);
            $elementToUpdate.text(options.prefix + options.value + options.suffix);
        } else {
            // Create new counter
            var displayValue = options.prefix + options.value + options.suffix;
            var $span = $(`<span class="wbmg_countup" data-wps-countup='${JSON.stringify(options)}'>${displayValue}</span>`);

            // Check for fr-marker
            var $marker = editor.$el.find('.fr-marker');
            if ($marker.length) {
                $marker.replaceWith($span);
            } else {
                // Fallback: Insert at current cursor position
                editor.selection.restore();
                editor.html.insertNode($span[0]);
            }
        }



        hidePopup();
        self.save();
    }

    function showPopup(existingTarget) {

        hidePopup();
        $target = existingTarget;
        $popup = initPopup();

        console.log("$target", $target);
        // If editing existing counter, populate form
        if ($target) {
            try {
                var options = JSON.parse($target.attr('data-wps-countup') || '{}');
                console.log("options", options);
                console.log("$popup", $popup);
                console.log("$popup.find('.fr-counter-value')", $popup.find('.fr-counter-value'));
                $popup.find('.fr-counter-value').val(options.value || '').addClass('fr-not-empty');
                $popup.find('.fr-counter-startVal').val(options.startVal || 0).addClass('fr-not-empty');
                $popup.find('.fr-counter-duration').val(options.duration || 2).addClass('fr-not-empty');
                $popup.find('.fr-counter-decimalPlaces').val(options.decimalPlaces || 0).addClass('fr-not-empty');
                var useGrouping = options.useGrouping !== false;
                $($popup.find('.fr-counter-useGrouping')[0]).prop('checked', useGrouping);
                $popup.find('.fr-counter-separator').val(options.separator || ',').addClass('fr-not-empty');
                $($popup.find('.separator-input')[0]).css('display', useGrouping ? 'block' : 'none'); // Show/hide based on useGrouping
                $popup.find('.fr-counter-decimal').val(options.decimal || '.').addClass('fr-not-empty');
                $popup.find('.fr-counter-prefix').val(options.prefix || '').addClass('fr-not-empty');
                $popup.find('.fr-counter-suffix').val(options.suffix || '').addClass('fr-not-empty');
                $popup.find('.fr-counter-scrollSpyDelay').val(options.scrollSpyDelay || 0).addClass('fr-not-empty');

                //$popup.find('.fr-counter-useGrouping').prop('checked', options.useGrouping !== false);

                // Show delete button when editing
                $popup.find('.fr-command[data-cmd="customCountUpPlugin_remove"]').removeClass('fr-hidden');

                // Position popup relative to the clicked counter
                var left = $target.offset().left + $target.outerWidth() / 2;
                var top = $target.offset().top + (editor.opts.toolbarBottom ? 10 : $target.outerHeight() - 10);
                editor.popups.show('customCountUpPlugin.popup', left, top, $target.outerHeight());
            } catch (e) {
                console.error('Error parsing counter options:', e);
            }
        } else {
            // Reset form for new counter
            $popup.find('input[type="number"]').val('');
            $popup.find('input[type="text"]').val('');
            $popup.find('.fr-counter-duration').val('2');
            $popup.find('.fr-counter-startVal').val('0');
            $popup.find('.fr-counter-decimalPlaces').val('0');
            $popup.find('.fr-counter-decimal').val('.');
            $($popup.find('.fr-counter-useGrouping')[0]).prop('checked', true);
            $($popup.find('.separator-input')[0]).css('display', 'block'); // Show for new counters since useGrouping defaults to true
            $popup.find('.fr-counter-scrollSpyDelay').val(0);



            // Hide delete button for new counter
            $popup.find('.fr-command[data-cmd="customCountUpPlugin_remove"]').addClass('fr-hidden');

            // Position popup relative to toolbar button
            editor.popups.setContainer('customCountUpPlugin.popup', editor.$tb);
            var $btn = editor.$tb.find('.fr-command[data-cmd="customCountUp"]');
            var left = $btn.offset().left;
            var top = $btn.offset().top + $btn.outerHeight();
            editor.popups.show('customCountUpPlugin.popup', left, top, $btn.outerHeight());
        }
    }

    function hidePopup() {

            editor.popups.hide('customCountUpPlugin.popup');
            //editor.popups.remove('customCountUpPlugin.popup');
            $popup = null;

    }

    return {
        showPopup: showPopup,
        hidePopup: hidePopup,
        get $target() { // Add getter for $target
            return $target;
        }
    };
};



/* CUSTOM Forms Popup PLUGIN */
if(self.options.isSysAdmin){
    _pluginsEnabled.push('customFormsPlugin');
    //if customForms is not in the toolbarSet7, add it
    if(self.options['toolbarSet7'].moreRich.buttons.indexOf('customForms') == -1){
        self.options['toolbarSet7'].moreRich.buttons.push('customForms');
    }
}






// Define an icon and command for the button that opens the custom popup.
self.window.FroalaEditor.DefineIcon('customForms', {NAME: 'insertForms', PATH: 'M9.7 3.7c.1-.2.3-.4.5-.4h4.6c.3 0 .6.3.6.6v.2l-2 6.1h4.4c.3 0 .6.3.6.6 0 .1 0 .3-.1.4l-8 10.3c-.2.3-.6.3-.8.1-.2-.1-.3-.4-.2-.6l2.1-6.7H7.3c-.3 0-.6-.3-.6-.6v-.2c.1-.1 3-9.8 3-9.8z'});
self.window.FroalaEditor.RegisterCommand('customForms', {
    title: self.labels.addForms,
    undo: false,
    focus: false,
    popup: true,
    // Buttons which are included in the editor toolbar should have the plugin property set.
    //plugin: 'customPlugin',
    refresh: function ($btn) {

        var el;
            image = this.image.get(),
            video = this.video.get(),
            selection = this.selection.get();
        if(image) el = $(image);
        else if(video) el = $(video);
        else if(selection && selection.focusNode) el = selection;



        // // if it is a text node wrap the text node with a span
        // if(el.focusNode.nodeType==3){
        //     $($(el.baseNode.parentNode).find('.fr-marker')[0].nextSibling).wrap("<span data-aos='"+_animation+"' data-aos-duration='"+_duration+"' data-aos-delay='"+_delay+"'></span>")
        // }else{
        //     if(el.focusNode) el = $(el.focusNode);
        //     el.attr("data-aos", _animation);
        //     el.attr("data-aos-duration", _duration);
        //     el.attr("data-aos-delay", _delay);
        // }

    },
    callback: function () {
        if (!this.popups.isVisible('customFormsPopup.popup')) {
        this.customFormsPlugin.showPopup(this);
        }
        else {
        if (this.$el.find('.fr-marker')) {
            this.events.disableBlur();
            this.selection.restore();
        }
        this.customFormsPlugin.hide('customFormsPopup.popup');
        }
    }
});
self.window.FroalaEditor.DefineIcon('customFormsEdit', {NAME: 'insertForms', SVG_KEY: 'M9.7 3.7c.1-.2.3-.4.5-.4h4.6c.3 0 .6.3.6.6v.2l-2 6.1h4.4c.3 0 .6.3.6.6 0 .1 0 .3-.1.4l-8 10.3c-.2.3-.6.3-.8.1-.2-.1-.3-.4-.2-.6l2.1-6.7H7.3c-.3 0-.6-.3-.6-.6v-.2c.1-.1 3-9.8 3-9.8z'});
self.window.FroalaEditor.RegisterCommand('customFormsEdit', {
    title: self.labels.addForms,
    undo: false,
    focus: false,
    popup: true,
    // Buttons which are included in the editor toolbar should have the plugin property set.
    //plugin: 'customPlugin',
    refresh: function ($btn) {
        console.log ("refresh", this.video.get());
        console.log("$btn", $btn);
        var _vid = this.video.get();
        console.log("_vid.closest", _vid.closest("a"))
        if(_vid.closest("a").length == 0){
            $btn.addClass("d-none");
        }else{
            $btn.removeClass("d-none");
        }
    },
    callback: function () {
        if (!this.popups.isVisible('customFormsPopup.popup')) {
        this.customFormsPlugin.showPopup(this);
        }
        else {
        if (this.$el.find('.fr-marker')) {
            this.events.disableBlur();
            this.selection.restore();
        }
        this.customFormsPlugin.hide('customFormsPopup.popup');
        }
    }
});

self.window.FroalaEditor.DefineIcon('customFormsPlugin_popupClose', {
    NAME: 'times',
    SVG_KEY: 'back'
});
self.window.FroalaEditor.RegisterCommand('customFormsPlugin_popupClose', {
    title: self.labels.back,
    undo: false,
    focus: false,
    callback: function() {
        this.customFormsPlugin.hidePopup();
    }
});


self.window.FroalaEditor.DefineIcon('customFormsPlugin_popupDelete', {
    NAME: 'times',
    SVG_KEY: 'remove'
});
self.window.FroalaEditor.RegisterCommand('customFormsPlugin_popupDelete', {
    title: self.labels.audioImageDelete,
    undo: false,
    focus: false,
    callback: function() {
        var _img = this.image.get();
        this.customImageAudioPlugin.hidePopup();
        _img.parent().find("audio").remove();
        _img.unwrap();
        _img.removeAttr("data-wps-audio-image");
        self.save();
    },
    // Callback on refresh.
    refresh: function ($btn) {
        var _img = this.image.get();
        var _audio = _img.parent().find("audio");
        if(_audio.length>0){
            $($btn).removeClass("fr-disabled");
        }else{
            $($btn).addClass("fr-disabled");
        }
    }
});

// Define popup template.
self.window.FroalaEditor.POPUP_TEMPLATES["customFormsPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';
// Define popup buttons.
Object.assign(self.window.FroalaEditor.DEFAULTS, {
    popupButtonsVideoLink: ['customFormsPlugin_popupClose','|','customFormsPlugin_popupDelete'],
});
// The custom popup is defined inside a plugin (new or existing).
self.window.FroalaEditor.PLUGINS.customFormsPlugin = function(editor) {
    // Create custom popup.
    function initPopup() {
        // Popup buttons.
        var popup_buttons = '';
        var that = this;
        console.log("that", that);

        // Create the list of buttons.
        if (editor.opts.popupButtonsVideoLink.length > 1) {
        popup_buttons += '<div class="fr-buttons">';
        popup_buttons += editor.button.buildList(editor.opts.popupButtonsVideoLink);
        popup_buttons += '</div>';
        }


        console.log("_sets", _sets);
        var _select = "<select class='fr-select form_select' style='float:none'>";
        $.each(self.options.formsData, function(index, val) {
            _select+='<option value="'+val.form_id+'">'+val.name+'</option>';
        })
        _select += "</select>";
        // Load popup template.
        var template = {
            buttons: popup_buttons,
            custom_layer: '<div style="width:200px" class="fr-link-insert-layer fr-layer fr-active mt-0"><div>'+_select+'</div>'+
            '<div class="fr-action-buttons"><button class="fr-command" role="button"  href="#" type="button">Insert</button></div></div>'
        };

        // Create popup.
         var $popup = editor.popups.create('customFormsPlugin.popup', template);
        // var _setsSelect = $popup.find("select.personalization_set");
        // var _valuesSelect = $popup.find("select.personalization_value");
        // var _default = $popup.find("#fr-link-insert-layer-personalization-default");
        $popup.find(".fr-action-buttons > button").on('click', function() {
            var _form_id = $popup.find(".form_select").val();
            //get the form with the id==form_id from self.options.formsData
            var form = $.grep(self.options.formsData, function(element) {
                return element.form_id == _form_id;
            })[0];

            console.log("form", form);
            console.log($popup);
            var jsonString = JSON.stringify(form.form_data);
            var escapedJsonString = jsonString.replace(/"/g, '&quot;');
            var form_html = '<div contenteditable="false" class="wbmg_form" data-wps-form-id="'+form.form_id+'">'+form.name+'</div>';

            $popup.html.insert(form_html);

            // traverse up till section.section
            console.log("$popup", $popup);
            console.log("$popup.html", $popup.element[0].$el);
            var section = $popup.element[0].$el.closest("section.section");
            // get data-wps-type
            console.log("section", section);
            var componentType = self.getComponentType(section);
            console.log("componentType", componentType);
            // init component+
            var snip;
            var multipleTypes = componentType.split(',');
            $(multipleTypes).each(function(index, el) {
                console.log("self.window.p$.snippet", self.window.p$.snippet, el)
                if (self.window.p$.snippet && self.window.p$.snippet[el]) { //if this snippet has a js file
                    snip = new self.window.p$.snippet[el](); // create snippet
                    if(typeof snip.clear === 'function') snip.clear(component);
                    snip.init(component); // init snippet
                }
            });

            self.save();
            hidePopup();

            return;

        })


        return $popup;
    }

    // Show the popup
    function showPopup(el) {
        console.log("el", el);
        // Get the popup object defined above.
        var $popup = editor.popups.get('customFormsPlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container.
        editor.popups.setContainer('customFormsPlugin.popup', editor.$tb);

        // This will trigger the refresh event assigned to the popup.
        // editor.popups.refresh('customFormsPlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');
        // console.log("el", el);

        var $el = $btn;

       console.log("el", el);

       if(el){
           $el = $(el);
           $popup.element = $el;
       }
       editor.element = $el;
       $popup.html = editor.html;
       var left = 0;
        var top=0;
       if(!el.$el){
        left = $el.offset().left + $el.outerWidth() / 2;
        top = $el.offset().top ; // Enhancement 2950
       }



        // Show the custom popup.
        // The button's outerHeight is required in case the popup needs to be displayed above it.
        console.log("$btn.outerHeight()", $btn.outerHeight(), top, left);
        editor.popups.show('customFormsPlugin.popup', left, top, $btn.outerHeight());

    }

    // Hide the custom popup.
    function hidePopup() {
        editor.popups.hide('customFormsPlugin.popup');
    }

    // Methods visible outside the plugin.
    return {
        showPopup: showPopup,
        hidePopup: hidePopup
    }
}
/* END CUSTOM VIDEO LINK PLUGIN */






/* CUSTOM Animation Popup PLUGIN */
if(self.options.isSysAdmin ){
    _pluginsEnabled.push('customAnimationPlugin');
    //if customForms is not in the toolbarSet7, add it
    if(self.options['toolbarSet7'].moreText.buttons.indexOf('customAnimation') == -1){
        self.options['toolbarSet7'].moreText.buttons.push('customAnimation');
    }
}



// Define an icon and command for the button that opens the custom popup.
self.window.FroalaEditor.DefineIcon('customAnimation', {NAME: 'insertAnimation', PATH: 'M9.7 3.7c.1-.2.3-.4.5-.4h4.6c.3 0 .6.3.6.6v.2l-2 6.1h4.4c.3 0 .6.3.6.6 0 .1 0 .3-.1.4l-8 10.3c-.2.3-.6.3-.8.1-.2-.1-.3-.4-.2-.6l2.1-6.7H7.3c-.3 0-.6-.3-.6-.6v-.2c.1-.1 3-9.8 3-9.8z'});
self.window.FroalaEditor.RegisterCommand('customAnimation', {
    title: self.labels.addAnimation,
    undo: false,
    focus: false,
    popup: true,
    // Buttons which are included in the editor toolbar should have the plugin property set.
    //plugin: 'customPlugin',
    refresh: function ($btn) {

        var el;
            image = this.image.get(),
            video = this.video.get(),
            selection = this.selection.get();
        if(image) el = $(image);
        else if(video) el = $(video);
        else if(selection && selection.focusNode) el = selection;



        // // if it is a text node wrap the text node with a span
        // if(el.focusNode.nodeType==3){
        //     $($(el.baseNode.parentNode).find('.fr-marker')[0].nextSibling).wrap("<span data-aos='"+_animation+"' data-aos-duration='"+_duration+"' data-aos-delay='"+_delay+"'></span>")
        // }else{
        //     if(el.focusNode) el = $(el.focusNode);
        //     el.attr("data-aos", _animation);
        //     el.attr("data-aos-duration", _duration);
        //     el.attr("data-aos-delay", _delay);
        // }

    },
    callback: function () {
        if (!this.popups.isVisible('customAnimationPopup.popup')) {
        this.customAnimationPlugin.showPopup(this);
        }
        else {
        if (this.$el.find('.fr-marker')) {
            this.events.disableBlur();
            this.selection.restore();
        }
        this.customAnimationPlugin.hide('customAnimationPopup.popup');
        }
    }
});
self.window.FroalaEditor.DefineIcon('customAnimationEdit', {NAME: 'insertAnimation', SVG_KEY: 'M9.7 3.7c.1-.2.3-.4.5-.4h4.6c.3 0 .6.3.6.6v.2l-2 6.1h4.4c.3 0 .6.3.6.6 0 .1 0 .3-.1.4l-8 10.3c-.2.3-.6.3-.8.1-.2-.1-.3-.4-.2-.6l2.1-6.7H7.3c-.3 0-.6-.3-.6-.6v-.2c.1-.1 3-9.8 3-9.8z'});
self.window.FroalaEditor.RegisterCommand('customAnimationEdit', {
    title: self.labels.addAnimation,
    undo: false,
    focus: false,
    popup: true,
    // Buttons which are included in the editor toolbar should have the plugin property set.
    //plugin: 'customPlugin',
    refresh: function ($btn) {
        console.log ("refresh", this.video.get());
        console.log("$btn", $btn);
        var _vid = this.video.get();
        console.log("_vid.closest", _vid.closest("a"))
        if(_vid.closest("a").length == 0){
            $btn.addClass("d-none");
        }else{
            $btn.removeClass("d-none");
        }
    },
    callback: function () {
        if (!this.popups.isVisible('customAnimationPopup.popup')) {
        this.customAnimationPlugin.showPopup(this);
        }
        else {
        if (this.$el.find('.fr-marker')) {
            this.events.disableBlur();
            this.selection.restore();
        }
        this.customAnimationPlugin.hide('customAnimationPopup.popup');
        }
    }
});

self.window.FroalaEditor.DefineIcon('customAnimationPlugin_popupClose', {
    NAME: 'times',
    SVG_KEY: 'back'
});
self.window.FroalaEditor.RegisterCommand('customAnimationPlugin_popupClose', {
    title: self.labels.back,
    undo: false,
    focus: false,
    callback: function() {
        this.customAnimationPlugin.hidePopup();
    }
});
// Define popup template.
self.window.FroalaEditor.POPUP_TEMPLATES["customAnimationPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';
// Define popup buttons.
Object.assign(self.window.FroalaEditor.DEFAULTS, {
    popupButtonsVideoLink: ['customAnimationPlugin_popupClose','|'],
});
// The custom popup is defined inside a plugin (new or existing).
self.window.FroalaEditor.PLUGINS.customAnimationPlugin = function(editor) {
    // Create custom popup.
    function initPopup() {
        // Popup buttons.
        var popup_buttons = '';
        var that = this;
        console.log("that", that);

        // Create the list of buttons.
        if (editor.opts.popupButtonsVideoLink.length > 1) {
        popup_buttons += '<div class="fr-buttons">';
        popup_buttons += editor.button.buildList(editor.opts.popupButtonsVideoLink);
        popup_buttons += '</div>';
        }
        var animationList = {
            "no":           "No animation",
            "fade-in":      "Fade in",
            "fade-up":      "Fade up",
            "fade-down":    "Fade down",
            "fade-left":    "Fade left",
            "fade-right":   "Fade right",
            "shake":        "Shake",
            "bounce":       "Bounce",
            "zoom-in":      "Zoom in",
            "zoom-out":     "Zoom out",
            "reveal-left":  "Reveal left",
            "reveal-right": "Reveal right"
        }

        console.log("_sets", _sets);
        var _select = "<select class='fr-select animation_select' style='float:none'>";
        $.each(animationList, function(index, val) {
            _select+='<option value="'+index+'">'+val+'</option>';
        })
        _select += "</select>";
        // Load popup template.
        var template = {
            buttons: popup_buttons,
            custom_layer: '<div style="width:400px" class="fr-link-insert-layer fr-layer fr-active mt-0"><div>'+_select+'</div>'+
            '<div class="fr-input-line"><input id="fr-link-insert-layer-animation-default1" name="default value" type="text" class="fr-link-attr fr-not-empty" placeholder="400" tabindex="1" dir="auto" value="400"><label for="fr-link-insert-layer-animation-default1">Duration</label></div>'+
            '<div class="fr-input-line"><input id="fr-link-insert-layer-animation-default2" name="default value" type="text" class="fr-link-attr" placeholder="" tabindex="1" dir="auto"><label for="fr-link-insert-layer-animation-default2">Delay</label></div>'+
            '<div class="fr-action-buttons"><button class="fr-command" role="button"  href="#" type="button">Insert</button></div></div>'
        };

        //

        // Create popup.
         var $popup = editor.popups.create('customAnimationPlugin.popup', template);
        // var _setsSelect = $popup.find("select.personalization_set");
        // var _valuesSelect = $popup.find("select.personalization_value");
        // var _default = $popup.find("#fr-link-insert-layer-personalization-default");
        $popup.find(".fr-action-buttons > button").on('click', function() {
            var _animation = $popup.find(".animation_select").val();
            var _duration =  $popup.find("#fr-link-insert-layer-animation-default1").val();
            var _delay =  $popup.find("#fr-link-insert-layer-animation-default2").val();

            // if it is a text node wrap the text node with a span
            if($popup.element.focusNode.nodeType==3){
                $($($popup.element.baseNode.parentNode).find('.fr-marker')[0].nextSibling).wrap("<span data-aos='"+_animation+"' data-aos-duration='"+_duration+"' data-aos-delay='"+_delay+"'></span>");
                $($($popup.element.baseNode.parentNode).find('.fr-marker')[0].nextSibling).append("<span class='inline-aos'></span>");

            }else{
                if($popup.element.focusNode) $popup.element = $($popup.element.focusNode);
                $popup.element.attr("data-aos", _animation);
                $popup.element.attr("data-aos-duration", _duration);
                $popup.element.attr("data-aos-delay", _delay);
                $popup.element.append("<span class='inline-aos'></span>")
            }

            self.save();
            hidePopup();
        })
        // _setsSelect.on('change', function() {
        //     console.log(this.value);
        //     console.log();
        //     _valuesSelect = $popup.find("select.personalization_value");
        //     console.log(_valuesSelect);
        //     _valuesSelect.empty(); // remove old options
        //     $.each(_sets[this.value].schema, function(index, val) {
        //         if(val.type!="MediaField"){
        //             if(index==0) _valuesSelect.append('<option selected value="'+index+'">'+val.name+'</option>');
        //             else _valuesSelect.append('<option value="'+index+'">'+val.name+'</option>');
        //         }
        //     });
        // });
        // _valuesSelect.on('change', function() {
        //     console.log(this.value);
        // });

        return $popup;
    }

    // Show the popup
    function showPopup(el) {
        // Get the popup object defined above.
        var $popup = editor.popups.get('customAnimationPlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container.
        editor.popups.setContainer('customAnimationPlugin.popup', editor.$tb);

        // This will trigger the refresh event assigned to the popup.
        // editor.popups.refresh('customAnimationPlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');
        // console.log("el", el);

        var $el = $btn;



        if($(el).hasClass("inline-aos")){
            $popup.element = $(el).parent();
            $btn = $(el).parent();
            // console.log("$popup", $popup);
            var _animation = $popup.element.attr("data-aos");
            var _duration =  $popup.element.attr("data-aos-duration");
            var _delay =  $popup.element.attr("data-aos-delay");
            $popup.find(".animation_select").val(_animation);
            $popup.find("#fr-link-insert-layer-animation-default1").val(_duration);
            $popup.find("#fr-link-insert-layer-animation-default2").val(_delay);
        }else if(el){
            // console.log("image", el.image.get())
            // console.log("video", el.video.get())
            // console.log("selected", el.selection.get())
            // console.log("selected", el.selection.get()) // focusNode
            var image = el.image.get(),
                video = el.video.get(),
                selection = el.selection.get();
            if(image) $popup.element = $(image);
            else if(video) $popup.element = $(video);
            else if(selection && selection.focusNode) $popup.element = selection;
        }

        // var _video = that.video.get();
        // $popup.video = _video;
        // var $el = $(editor.selection.element());
        // // Set the popup's position.
        // var left = _video.offset().left + _video.outerWidth() / 2;
        // var top = _video.offset().top + (editor.opts.toolbarBottom ? 10 : _video.outerHeight() - 10);
        //var top = _img.offset().top + _img.outerHeight(); // Enhancement 2950

       //console.log("el", el);

    //    if(el){
    //        $el = $(el);
    //        $popup.element = $el;
    //    }
       //editor.element = $el;
       $popup.html = editor.html;

       var left = 0;
       var top=0;

       // Set the popup's position.
    //    var left = $el.offset().left + $el.outerWidth() / 2;
    //    var top = $el.offset().top + $el.outerHeight(); // Enhancement 2950

        // Show the custom popup.
        // The button's outerHeight is required in case the popup needs to be displayed above it.
        console.log("$btn.outerHeight()", $btn.outerHeight(), top, left);
        editor.popups.show('customAnimationPlugin.popup', left, top, $btn.outerHeight());

    }

    // Hide the custom popup.
    function hidePopup() {
        editor.popups.hide('customAnimationPlugin.popup');
    }

    // Methods visible outside the plugin.
    return {
        showPopup: showPopup,
        hidePopup: hidePopup
    }
}
/* END CUSTOM VIDEO LINK PLUGIN */






                        if(true ){
                            _pluginsEnabled.push('video');
                        }


                        self.window.FroalaEditor.DefineIcon('line', {NAME: 'line', SVG_KEY: 'horizontalLine'});
                        self.window.FroalaEditor.RegisterCommand('line', {
                          title: self.labels.horizontalLine,
                          focus: true,
                          undo: true,
                          refreshAfterCallback: true,
                          callback: function () {
                            var that = this;

                            setTimeout(function () {
                              if( that.toolbar)  that.toolbar.hide();
                            }, 100);
                            that.html.insert('<span><hr class="my-0 border-light" data-wps-edit="1-inline line" data-wps-edit-hide="true" data-wps-edit-disable="true" data-wps-bordercolor="true"  style="padding-bottom:15px;margin-top:0px;width:60px;"  data-wps-edit-disable="true" data-wps-edit-name="Change hr" data-wps-slider="margin-top;0;100;px"  data-wps-slider2="padding-bottom;0;100;px"  data-wps-slider3="width;0;1000;px" data-wps-class-change2="normal=border-top-1|thick=border-top-2|bold=border-top-4|bolder=border-top-8" data-wps-class-change3="left=mr-auto|center=mx-auto|right=ml-auto"></span>');
                          }
                        });


                        self.window.FroalaEditor.DefineIcon('br', {NAME: 'br', SVG_KEY: 'horizontalLine'});
                        self.window.FroalaEditor.RegisterCommand('br', {
                          title: self.labels.breakLine,
                          icon: '<i class="fa fa-level-down" aria-hidden="true"></i>',
                          focus: true,
                          undo: true,
                          refreshAfterCallback: true,
                          callback: function (e) {
                            var that = this;

                            setTimeout(function () {
                              if( that.toolbar)  that.toolbar.hide();
                            }, 100);
                            that.html.insert('<br>');
                          }
                        });
                        //  self.window.FroalaEditor.RegisterShortcut(self.window.FroalaEditor.KEYCODE.ENTER, 'br', 'br', 'BR', false, false);

                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.dashList){
                           self.window.FroalaEditor.DefineIcon('DashedList', {NAME: 'info',
                                PATH: 'M5.02 12v-2h14v2zM0 12v-2h3.03v2zm5.02-5V5h14v2zM0 7V5h3.03v2zm5.02-5V0h14v2zM0 2V0h3.03v2z'
                            });
                           self.window.FroalaEditor.RegisterCommand('DashedList', {
                                title: self.labels.dash,
                                class: 'dashedList',
                                focus: false,
                                undo: false,
                                refreshAfterCallback: false,
                                callback: function () {
                                    this.html.insert(`<ul class="dash"><br></ul>`)
                                    this.events.focus()
                                }
                            });
                        }


                        var _fontsize = ['4', '5', '6', '7', '8','9','10','11','12','13','14','15','16','17','18','19','20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100', '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112', '113', '114', '115', '116', '117', '118', '119', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132', '133', '134', '135', '136', '137', '138', '139', '140', '141', '142', '143', '144', '145', '146', '147', '148', '149', '150'];




                        var _options = {};
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.telefonica_footnode)  _options.tef_footnode = "Telefonica Footnode";
                        if(false/*&self.groupSettings.editorSettings & self.groupSettings.editorSettings.telefonica_footnode*/)  _options.additional_text = "Add Text as Modal/Collapsible";
                        _options.v0 = "Button";
                        _options.v0a = "2 Buttons";
                        _options.v0b = "2 Text columns";
                        _options.author01 = "Author";
                        //_options.v0c = "Box";

                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.cnhi_nh_roundBox) _options.v1 = 'New Holland: Round box';
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.cnhi_case_table) _options.v2 = 'Case IH: Catalog table';
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.telefonica_2_buttons) _options.tef1 = 'Telefonica: 2 Buttons';





                        self.window.FroalaEditor.DefineIcon('inline_snippets', {NAME: 'cog', PATH: 'M20.7 12.5c0-.2-.1-.4-.3-.5l-1.6-.8 1.6-.8c.2-.1.3-.3.3-.5s-.1-.4-.3-.5L12.1 5c-.2-.1-.4-.1-.5 0L3.2 9.2c-.2.1-.3.3-.3.5s.1.4.3.5l1.6.8-1.6 1c-.2.1-.3.3-.3.5s.1.4.3.5l1.5.8-1.5.8c-.2.1-.3.3-.3.5s.1.4.3.5l8.3 4.4c.1 0 .2.1.3.1s.2 0 .3-.1l8.3-4.4c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-1.5-.8 1.5-.8c.2-.1.3-.3.3-.5zM11.8 6l7.1 3.8-7.1 3.8-7.1-3.9L11.8 6zm-5.9 5.9c.1 0 .1-.1.1-.1l5.5 2.9c.1 0 .2.1.3.1.1 0 .2 0 .3-.1l5.5-2.9 1.3.7-7.1 3.8-7.1-3.8 1.2-.6zm13 3.2-7.1 3.8-7.1-3.8 1.1-.6h.1l5.6 3c.1 0 .2.1.3.1s.2 0 .3-.1l5.6-3 1.2.6z'});
                        self.window.FroalaEditor.RegisterCommand('inline_snippets', {
                            title: self.labels.inline,
                            type: 'dropdown',
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            options: _options,
                            callback: function (cmd, val) {
                                if(val=="v0") this.html.insert('<a href="#" target="_blank" rel="noopener noreferrer" class="btn btn-primary mt-2">Button</a>');
                                if(val=="v0a") this.html.insert('<p><a class="btn btn-primary mr-2 mt-2" href="#" >Button 1</a><a class="btn btn-default btn-dark mt-2" href="#" >Button 2</a></p>');
                                if(val=="v0b") this.html.insert('<div class="row"><div class="col-md-6 mt-2 mt-md-0 mb-2 mb-md-0 wbmg-editor-dash"><h3>Feature 1</h3><p>Use our drag & drop editor with preconfigured snippets to create beautiful pages in minutes.</p></div><div class="col-md-6 mt-2 mt-md-0 mb-2 mb-md-0 wbmg-editor-dash"><h3>Feature 2</h3><p>Use our drag & drop editor with preconfigured snippets to create beautiful pages in minutes.</p></div></div>');
                                if(val=="author01") this.html.insert('<div class="align-items-start d-sm-flex mb align-items-center"><img alt="" class="fr-image m-0 fr-fic fr-dii mr-3 my-2" src="https://cdn.webmag.io/webmag/snippets/default/man.jpg" style="width: 160px;"><div class="media-body"><p class="m-0" style="text-align: left;"><strong>Lorem ipsum</strong><br>sea takimata sanctus est<br><a href="#">Email</a></p></div></div>');
                                if(val=="v1") this.html.insert('<table class="table fr-no-borders3 mx-auto mb-2 w-100" style="width: 100%; border-radius: 30px; text-align: center; margin: 0px calc(22%) 0px calc(24%);"><tbody><tr><td style="width: 100%;text-align: center;background-color: rgb(0, 82, 156);border-radius-top: 30px 0px;border-top-left-radius: 30px;border-top-right-radius: 30px;border-bottom: 4px solid white!important;"><strong><span style="color: rgb(255, 255, 255);">Headline</span></strong><strong></strong></td></tr><tr><td data-del-cell="true" style="width: 100%;background-color: rgb(255, 208, 0);text-align: center;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;" class="fr-selected-cell"><br><span style="color: rgb(0, 82, 156);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi alias ea aut dolorum dignissimos. Consequuntur, eos!</span><br><span>&ZeroWidthSpace;</span></td></tr></tbody></table>');
                                if(val=="v2") this.html.insert('<table class="table fr-no-borders2 mx-auto" style="width: 100%;"><tbody><tr><td style="width: 100%;border-top-width: 2px !important;text-align: center;border-color: rgb(111 111 111);"><strong>47940053</strong><br></td></tr><tr><td style="width: 100%;text-align: center;border-color: rgb(111 111 111);"><span style="color: rgb(68, 68, 68);">(deluxe fabric)</span><br></td></tr></tbody></table>');

                                if(val=="tef1") this.html.insert('<p><a class="btn mr-2 mt-2 btn-white" href="#" >Jetzt kaufen</a><a class="btn btn-outline-white mt-2 " href="#" >Termin vereinbaren</a></p>');
                                if(val=="tef2") this.html.insert('<ul class="o2-list"><li>Liste</li></ul>');

                                if(val=="tef_table1") this.html.insert('<p>Flatrate für Minuten/SMS<br><strong><img style="width:20px" src="https://s3.eu-central-1.amazonaws.com/media.webmag.io/telefonica/default/check.svg" alt=""></strong></p>');
                                if(val=="tef_table2") this.html.insert('<p><strong>Mtl. Grundgebühr<br>für Junge Leute</strong><br><small>(Bei 24 Monaten Mindestlaufzeit)</small></p><h2><strong>29,99 €</strong></h2>');
                                if(val=="tef_footnode") this.html.insert('<span class="footnode_modal" data-original-title="" data-wps-variable="Custom Text" style="font-size: 21px;vertical-align:super;"><i></i></span>');
                                if(val=="additional_text") this.html.insert('<span class="additional_text" data-original-title="" data-wps-variable="Custom Text" style="font-size: 21px;vertical-align:super;"><i></i></span>');

                                this.events.focus();
                                self.save();
                            }
                        });

                        var _optionsCustomFormatUL = {
                            "dash": "Dash",
                            "minus": "Minus",
                            "plus": "Plus",
                            "check": "Check",
                            "star": "Star",
                            "arrow-right": "Arrow right"
                        };
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.customFormatUL){
                            $.extend( _optionsCustomFormatUL, self.groupSettings.editorSettings.customFormatUL );
                        }
                        self.window.FroalaEditor.DefineIcon('customFormatUL', {NAME: 'cog', PATH: 'M4.3 15.4c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5v-.1h2-2c0-.8-.7-1.4-1.5-1.4zm0-7c.8 0 1.5-.7 1.5-1.5h2v1h14v-2h-14v1h-2c0-.8-.7-1.4-1.5-1.5-.8 0-1.5.7-1.5 1.5.1.8.7 1.5 1.5 1.5zm1.5 3.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5.7 1.5 1.5 1.5c.9 0 1.5-.7 1.5-1.5h2-2zM23 14.7h-3.7l-1-2.5h1.2v.7h2.3v-2h-2.3v1.3h-1.2l-.4-1-.4 1h-1.4v-1.3H7.8v2h8.3v-.7h1.4l-1.2 2.6h-3.8l1.3 1.3-.6.1v-.3H7.8v2h5.4v-1.7l.6-.1 1.6 1.6-.9 3.6 3.2-2 3.2 1.9-.9-3.6z'});
                        self.window.FroalaEditor.RegisterCommand('customFormatUL', {
                            title: self.labels.customFormatUL,
                            type: 'dropdown',
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            options: _optionsCustomFormatUL,
                            callback: function (cmd, val) {
                                console.log("this.selection.element()", this.selection.element())
                                console.log("this.element", this.element);
                                console.log("this", this);
                                var _ul = $(this.selection.element()).closest("ul");
                                if(_ul){
                                    console.log(_ul);
                                    $.each(_optionsCustomFormatUL, function(index, el) {
                                        if(index!=val) _ul.removeClass(index);
                                    })
                                    _ul.toggleClass(val);
                                    this.events.focus();
                                    self.save();
                                }
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                var _ul = $(this.selection.element()).closest("ul");
                                if(_ul){
                                    $.each(_optionsCustomFormatUL, function(index, el) {
                                        if(_ul.hasClass(index)){
                                            $($btn).parent().find('[data-param1="'+index+'"]').addClass("fr-active");
                                        }
                                    })
                                }

                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                var _ul = $(this.selection.element()).closest("ul");
                                if(_ul){
                                    $.each(_optionsCustomFormatUL, function(index, el) {
                                        if(_ul.hasClass(index)){
                                            $($btn).parent().find('[data-param1="'+index+'"]').addClass("fr-active");
                                        }
                                    })
                                }
                            }
                        });



                        /* Font Awesome Popup */
                        self.window.FroalaEditor.DefineIcon('customFontAwesomePlugin_popupClose', {
                            NAME: 'times',
                            SVG_KEY: 'back'
                        });
                        self.window.FroalaEditor.RegisterCommand('customFontAwesomePlugin_popupClose', {
                            title: self.labels.back,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.customFontAwesomePlugin.hidePopup();
                            }
                        });

                        self.window.FroalaEditor.DefineIcon('customFontAwesomePlugin_dropdown', {NAME: 'cog', SVG_KEY: 'fontSize'});

                        var _fontsizeOptions = {};
                        $.each(_fontsize, function (index, val) {
                            _fontsizeOptions[val] = val
                        });
                        self.window.FroalaEditor.RegisterCommand('customFontAwesomePlugin_dropdown', {
                            title: self.labels.fontSize,
                            type: 'dropdown',
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            options: _fontsizeOptions,
                            callback: function (cmd, val) {
                                $(this.selection.element()).css("font-size",val)
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                //console.log ('do refresh');
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                //console.log ('do refresh when show');
                            }
                        });

                        // Define popup template.
                        self.window.FroalaEditor.POPUP_TEMPLATES["customFontAwesomePlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

                        // Define popup buttons.
                        Object.assign(self.window.FroalaEditor.DEFAULTS, {
                            popupButtonsFontAwesomePlugin: ['customFontAwesomePlugin_popupClose', 'customFontAwesomePlugin_dropdown'],
                            //popupButtons: ['customFontAwesomePlugin_popupClose'],
                        });

                        // The custom popup is defined inside a plugin (new or existing).
                        self.window.FroalaEditor.PLUGINS.customFontAwesomePlugin = function(editor) {
                            // Create custom popup.
                            function initPopup() {
                                // Popup buttons.
                                var popup_buttons = '';

                                // Create the list of buttons.
                                if (editor.opts.popupButtonsFontAwesomePlugin.length > 1) {
                                popup_buttons += '<div class="fr-buttons">';
                                popup_buttons += editor.button.buildList(editor.opts.popupButtonsFontAwesomePlugin);
                                popup_buttons += '</div>';
                                }

                                // Load popup template.
                                var template = {
                                    buttons: popup_buttons,
                                    custom_layer: '<div class="_textcolor px-2 pb-1"></div>'
                                };

                                // Create popup.
                                var $popup = editor.popups.create('customFontAwesomePlugin.popup', template);

                                return $popup;
                            }

                            // Show the popup
                            function showPopup() {
                                // Get the popup object defined above.
                                var $popup = editor.popups.get('customFontAwesomePlugin.popup');

                                // If popup doesn't exist then create it.
                                // To improve performance it is best to create the popup when it is first needed
                                // and not when the editor is initialized.
                                if (!$popup) $popup = initPopup();

                                // Set the editor toolbar as the popup's container.
                                editor.popups.setContainer('customFontAwesomePlugin.popup', editor.$tb);

                                // This will trigger the refresh event assigned to the popup.
                                // editor.popups.refresh('customFontAwesomePlugin.popup');

                                // This custom popup is opened by pressing a button from the editor's toolbar.
                                // Get the button's object in order to place the popup relative to it.
                                var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');

                                var $el = $(editor.selection.element());
                                // Set the popup's position.
                                var left = $el.offset().left + $el.outerWidth() / 2;
                                var top = $el.offset().top + $el.outerHeight(); // Enhancement 2950

                                // Show the custom popup.
                                // The button's outerHeight is required in case the popup needs to be displayed above it.
                                editor.popups.show('customFontAwesomePlugin.popup', left, top, $btn.outerHeight());

                                var _default = $el[0].style.color.replace(/!important/g, '').trim() || getComputedStyle($el[0], null)["color"];
                                var _prevcolors = ['rgba(0,0,0,0)'];
                                _prevcolors = _prevcolors.concat(_colorsText);
                                //_prevcolors = _prevcolors.concat(["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"]);
                                console.log("$popup",$popup);
                                var xncolorpicker = new self.window.XNColorPicker({
                                    color: _default,
                                    selector: $popup.find("._textcolor"),
                                    showprecolor: true,
                                    prevcolors: _prevcolors,
                                    showhistorycolor: true,
                                    historycolornum: 16,
                                    format: 'hex',
                                    showPalette: true,
                                    show: false,
                                    lang: 'en',
                                    //colorTypeOption: 'single',
                                    colorTypeOption:'single',
                                    canMove: true,
                                    alwaysShow: false,
                                    autoConfirm: false,
                                    onError: function (e) {

                                    },
                                    onCancel: function (color) {
                                    console.log("cancel", color)
                                    },
                                    onChange: function (color) {
                                    console.log("change", color)
                                    },
                                    onConfirm: function (color) {

                                    console.log("confirm", color);

                                    var _color,
                                        el =$el[0];
                                    if (color) {
                                        if(color.color.hex === '#00000000'){
                                            el.style.setProperty("color", "");
                                        } else {
                                            _color = color.color.rgba;
                                            el.style.setProperty("color", _color, "important");
                                        }
                                    } else {
                                        //el.style.setProperty("background-color", "");
                                        el.style.setProperty("color", "");
                                    }
                                    self.save();
                                    }
                                })
                            }

                            // Hide the custom popup.
                            function hidePopup() {
                                editor.popups.hide('customFontAwesomePlugin.popup');
                            }

                            // Methods visible outside the plugin.
                            return {
                                showPopup: showPopup,
                                hidePopup: hidePopup
                            }
                        }

                        /* END:  Font Awesome Popup  */



                       /* Custom Telefonica Footnode Popup */
                        self.window.FroalaEditor.DefineIcon('customTefFootnodePlugin_popupClose', {
                            NAME: 'times',
                            SVG_KEY: 'back'
                        });
                        self.window.FroalaEditor.RegisterCommand('customTefFootnodePlugin_popupClose', {
                            title: self.labels.back,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.customTefFootnodePlugin.hidePopup();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customTefFootnodePlugin_delete', {
                            NAME: 'times',
                            SVG_KEY: 'remove'
                        });
                        self.window.FroalaEditor.RegisterCommand('customTefFootnodePlugin_delete', {
                            title: self.labels.delete,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.element.remove();
                                console.log(this.element);
                                console.log(this);
                                self.save();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customTefFootnodePlugin_dropdown', {NAME: 'cog', SVG_KEY: 'fontSize'});

                        var _fontsizeOptions = {};
                        $.each(_fontsize, function (index, val) {
                            _fontsizeOptions[val] = val
                        });
                        self.window.FroalaEditor.RegisterCommand('customTefFootnodePlugin_dropdown', {
                            title: self.labels.fontSize,
                            type: 'dropdown',
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            options: _fontsizeOptions,
                            callback: function (cmd, val) {
                                this.element.css("font-size",val)
                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                //console.log ('do refresh');
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                //console.log ('do refresh when show');
                            }
                        });

                        self.window.FroalaEditor.DefineIcon('customTefFootnodePlugin_dropdown_vertical', {NAME: 'cog', SVG_KEY: 'star'});
                        self.window.FroalaEditor.RegisterCommand('customTefFootnodePlugin_dropdown_vertical', {
                            title: self.labels.verticalAlign,
                            type: 'dropdown',
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            options: {
                                "top" :"vertical align top",
                                "super": "vertical align super",
                                "bottom": "vertical align bottom"
                            },
                            callback: function (cmd, val) {
                                console.log(val);
                                this.element.css("vertical-align",val)
                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                //console.log ('do refresh');
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                //console.log ('do refresh when show');
                            }
                        });

                        //self.window.FroalaEditor.DefineIcon('customTefFootnodePlugin_dropdown2', {NAME: 'cog', SVG_KEY: 'fontSize'});

                        self.window.FroalaEditor.RegisterCommand('customTefFootnodePlugin_dropdown2', {
                            title: "Test",
                            displaySelection: function displaySelection(editor) {
                                console.log("displaySelection",editor);
                                return editor.opts.paragraphFormatSelection;
                            },
                            defaultSelection: function defaultSelection(editor) {
                                return 'Custom Test';
                            },
                            displaySelectionWidth: 130,
                            type: 'dropdown',
                            html: function html() {
                                var c = '<ul class="fr-dropdown-list" role="presentation">';
                                 for (var val in _list) {
                                    console.log(val);
                                    c += '<li role="presentation"><div style="padding: 0 !important; margin: 0 !important;" role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="paragraphFormat" data-param1="' + val + '" title="' + val + '">' + val + '</a></div></li>';
                                }
                                c += '</ul>';
                                return c;
                            },
                            callback: function (cmd, val) {
                                console.log("callback", cmd, val)
                                // var $popup = editor.popups.get('customTefFootnodePlugin.popup');
                                // if(val=="custom"){
                                //     $popup.find("._froalaEditor").show();
                                // }else{
                                //     $popup.find("._froalaEditor").hide();
                                // }

                            },
                            // Callback on refresh.
                            refresh: function ($btn, $dropdown) {
                                console.log ('do refresh',$btn, $dropdown);
                                console.log("this.element", this.element);
                                var $popup = editor.popups.get('customTefFootnodePlugin.popup');


                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                console.log ('do refresh when show');
                            }
                        });

                        // Define popup template.
                        self.window.FroalaEditor.POPUP_TEMPLATES["customTefFootnodePlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

                        // Define popup buttons.
                        Object.assign(self.window.FroalaEditor.DEFAULTS, {
                            popupButtonsFootnodePlugin: ['customTefFootnodePlugin_popupClose', 'customTefFootnodePlugin_dropdown', 'customTefFootnodePlugin_dropdown_vertical', 'customTefFootnodePlugin_delete'],
                            //popupButtons: ['customTefFootnodePlugin_popupClose'],
                        });

                        // The custom popup is defined inside a plugin (new or existing).
                        var _list = {};
                        // change array to abject
                        $.each(self.options.variablesPlugin, function(index, item) {
                            _list[item.var] =  item.text;
                        });

                        //if(self.options.variablesPlugin && self.options.variablesPlugin.json) _list = $.extend({}, _list, JSON.parse(self.options.variablesPlugin.json));
                        self.window.FroalaEditor.PLUGINS.customTefFootnodePlugin = function(editor) {
                            // Create custom popup.
                            function initPopup(el) {
                                // Popup buttons.
                                var popup_buttons = '';
                                var that = this;

                                // Create the list of buttons.
                                if (editor.opts.popupButtonsFootnodePlugin.length > 1) {
                                    popup_buttons += '<div class="fr-buttons">';
                                    popup_buttons += editor.button.buildList(editor.opts.popupButtonsFootnodePlugin);
                                    popup_buttons += '</div>';
                                }

                                var _select = "<select class='fr-select' style='float:none'>";
                                _select += '<option value="Custom Text">Custom Text</option>'; // add custom text as default
                                for (var val in _list) {
                                    _select += '<option value="'+val+'">'+val+'</option>';
                                }
                                _select += '</select>';



                                // Load popup template.
                                var template = {
                                    buttons: popup_buttons,
                                    custom_layer: '<div class="_textcolor px-2 pb-1"></div><div style="width:400px" class="fr-link-insert-layer fr-layer fr-active mt-0"><div>'+_select+'</div><div class="_textEditor px-2 border-1 py-2"></div><div class="_froalaEditor pb-1"></div><div class="fr-action-buttons"><button class="fr-command" role="button"  href="#" type="button">Insert</button></div></div>'
                                };

                                // Create popup.
                                var $popup = editor.popups.create('customTefFootnodePlugin.popup', template);
                                var _froalaInput = $popup.find("._froalaEditor");
                                var _textEditor = $popup.find("._textEditor");
                                var _select = $popup.find("select.fr-select");
                                $popup.find(".fr-action-buttons > button").on('click', function() {
                                    var _val = _select.val();
                                    $popup.element.attr("data-wps-variable", _val);
                                    //console.log("change", editor.element);
                                    if(_select.val()=="Custom Text"){
                                        console.log("_froalaInput", _froalaInput);
                                        console.dir(_froalaInput[0]["data-froala.editor"]);
                                        console.dir(_froalaInput[0]["data-froala.editor"].html.get());
                                        $popup.element.attr("data-original-title", encodeURIComponent(_froalaInput[0]["data-froala.editor"].html.get()));
                                    }
                                    else{
                                        $popup.element.attr("data-original-title", "{{{"+_val+"}}}");
                                    }
                                    self.save();
                                    hidePopup();
                                })
                                _select.on('change', function() {
                                    console.log(this.value);
                                    //editor.element.attr("data-wps-variable", this.value);
                                    if(this.value=="Custom Text"){
                                        _froalaInput.show();
                                        _textEditor.hide();
                                    } else {
                                        _froalaInput.hide();
                                        _textEditor.show();
                                        _textEditor.html(_list[this.value]);
                                    }
                                    self.save();
                                });



                                return $popup;
                            }

                            // Show the popup
                            function showPopup(el) {
                                // Get the popup object defined above.
                                var $popup = editor.popups.get('customTefFootnodePlugin.popup');

                                // If popup doesn't exist then create it.
                                // To improve performance it is best to create the popup when it is first needed
                                // and not when the editor is initialized.
                                if (!$popup) $popup = initPopup(el);

                                // Set the editor toolbar as the popup's container.
                                editor.popups.setContainer('customTefFootnodePlugin.popup', editor.$tb);

                                // This will trigger the refresh event assigned to the popup.
                                // editor.popups.refresh('customTefFootnodePlugin.popup');

                                // This custom popup is opened by pressing a button from the editor's toolbar.
                                // Get the button's object in order to place the popup relative to it.
                                var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');

                                console.log("el", el);
                                var $el = $(el);
                                editor.element = $el;
                                $popup.element = $el;

                                // Set the popup's position.
                                var left = $el.offset().left + $el.outerWidth() / 2;
                                var top = $el.offset().top + $el.outerHeight(); // Enhancement 2950

                                // Show the custom popup.
                                // The button's outerHeight is required in case the popup needs to be displayed above it.
                                editor.popups.show('customTefFootnodePlugin.popup', left, top, $btn.outerHeight());

                                var variable = $(editor.element).attr("data-wps-variable");
                                console.log("variable", variable);
                                if(variable!="") $popup.find("select.fr-select").val(variable)
                                else {
                                    $popup.find("select.fr-select")[0].selectedIndex = 0;
                                }

                                var _froalaInput = $popup.find("._froalaEditor");
                                $(_froalaInput).empty();

                                var _options = $.extend(true,{},self.options.keditorOptions);
                                delete _options.toolbarInline;
                                delete _options.initOnClick;
                                delete _options.toolbarContainer;
                                var fEditor = new self.window.FroalaEditor(_froalaInput[0], /*{

                                    // key: 'oc1F2vB2A1H2B4C1B6mEZXQUVJb1EZf1IWIAUKLJZMBQuD3E2D1E1C4G1G4F1A10C6',
                                    // toolbarButtons: {
                                    //     'moreText': {
                                    //       'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor','clearFormatting']
                                    //     },
                                    //     'moreParagraph': {
                                    //       'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight'],
                                    //       'buttonsVisible': 0
                                    //     },
                                    //     'moreRich': {
                                    //       'buttons': ['insertLink', 'emoticons', 'fontAwesome', 'specialCharacters'],
                                    //       'buttonsVisible': 0
                                    //     },
                                    // },
                                }*/
                                _options
                                , function () {
                                    // Call the method inside the initialized event.
                                    fEditor.html.set(decodeURIComponent(editor.element.attr("data-original-title")));
                                  });



                                var _textEditor = $popup.find("._textEditor");
                                if(variable=="Custom Text"){
                                    _froalaInput.show();
                                    _textEditor.hide();
                                    console.log("fEditor", fEditor);
                                    //fEditor.html.set();
                                } else {
                                    _froalaInput.hide();
                                    _textEditor.show();
                                    _textEditor.html(_list[variable]);
                                }

                                // Color change
                                var _default = $el[0].style.color.replace(/!important/g, '').trim() || getComputedStyle($el[0], null)["color"];
                                var _prevcolors = ['rgba(0,0,0,0)'];
                                _prevcolors = _prevcolors.concat(_colorsText);
                                //_prevcolors = _prevcolors.concat(["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"]);
                                console.log("$popup",$popup);
                                var xncolorpicker = new self.window.XNColorPicker({
                                    color: _default,
                                    selector: $popup.find("._textcolor"),
                                    showprecolor: true,
                                    prevcolors: _prevcolors,
                                    showhistorycolor: true,
                                    historycolornum: 16,
                                    format: 'hex',
                                    showPalette: true,
                                    show: false,
                                    lang: 'en',
                                    //colorTypeOption: 'single',
                                    colorTypeOption:'single',
                                    canMove: true,
                                    alwaysShow: false,
                                    autoConfirm: false,
                                    onError: function (e) {

                                    },
                                    onCancel: function (color) {
                                    console.log("cancel", color)
                                    },
                                    onChange: function (color) {
                                    console.log("change", color)
                                    },
                                    onConfirm: function (color) {

                                    console.log("confirm", color);

                                    var _color,
                                        el =$el[0];
                                    if (color) {
                                        if(color.color.hex === '#00000000'){
                                            el.style.setProperty("color", "");
                                        } else {
                                            _color = color.color.rgba;
                                            el.style.setProperty("color", _color, "important");
                                        }
                                    } else {
                                        //el.style.setProperty("background-color", "");
                                        el.style.setProperty("color", "");
                                    }
                                    self.save();
                                    }
                                })

                                //console.log("$popup",$popup);

                            }

                            // Hide the custom popup.
                            function hidePopup() {
                                editor.popups.hide('customTefFootnodePlugin.popup');
                            }

                            // Methods visible outside the plugin.
                            return {
                                showPopup: showPopup,
                                hidePopup: hidePopup
                            }
                        }

                        /* END:  Custom Telefonica Footnode Popup  */

                        /* Custom Personalization Popup */

                //if(val=="personalization") this.html.insert('<span class="wbmg_variable" data-wps-variable="{{#if method}}{{method}}{{else}}POST{{/if}}">value</span>');

                    if(self.options.personalizedData.length>0){

                        // Define popup template.
                        self.window.FroalaEditor.POPUP_TEMPLATES["customPersonalizationPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

                        // Define popup buttons.
                        Object.assign(self.window.FroalaEditor.DEFAULTS, {
                            popupButtons: ['customPersonalizationPlugin_popupClose', 'customPersonalizationPlugin_delete'],
                        });

                        // The custom popup is defined inside a plugin (new or existing).
                        var _sets = self.options.personalizedData;
                        console.log("_sets", _sets);

                        // create a set with all schema values without duplicates
                        _sets["*"] = {};
                        let uniqueSchemas = _sets
                            .map(item => item.schema) // Extract all schema arrays
                            .reduce((acc, schemaArray) => acc.concat(schemaArray), []) // Flatten the array of arrays
                            .filter((schema, index, array) => {
                                if (schema === null) {
                                    console.log(`Null value encountered at index ${index} in filter.`);
                                    return false; // Exclude null values
                                }
                                if (!schema.id) {
                                    console.log(`Missing 'id' for schema:`, schema, `at index ${index}`);
                                    return false; // Optionally exclude items without 'id'
                                }
                                return array.findIndex(s => s && s.id === schema.id) === index;
                            });

                        // console.log("Unique Schemas:", uniqueSchemas);
                        // var uniqueSchemas = _sets
                        //     .map(item => item.schema) // Extract all schema arrays
                        //     .reduce((acc, schemaArray) => acc.concat(schemaArray), []) // Flatten the array of arrays
                        //     .filter((schema, index, array) =>
                        //         array.findIndex(s => s.id === schema.id) === index); // Filter out duplicates

                        _sets["*"].name = "*";
                        _sets["*"].personalized_variable_set_id = "*";
                        _sets["*"].schema =uniqueSchemas;

                        // change array to abject
                        // $.each(self.options.personalizedData, function(index, set) {
                        //     _sets.push({
                        //         "name": set.name,
                        //         "index": index,
                        //         "schema":[]
                        //     })

                        //     $.each(self.options.personalizedData[index].schema, function(index2, value) {
                        //         if(value.type!="MediaField"){
                        //             _sets[index].schema.push ({
                        //                 "name": value.name,
                        //                 "id": value.id,
                        //                 "type": value.type
                        //             })
                        //         }
                        //     });

                        // });


                        // The custom popup is defined inside a plugin (new or existing).
                        // var _list2 = [];
                        // // change array to abject

                        // console.log("_list2", _list2);

                        // The custom popup is defined inside a plugin (new or existing).
                        self.window.FroalaEditor.PLUGINS.customPersonalizationPlugin = function (editor) {
                            // Create custom popup.
                            function initPopup(el) {
                                // Popup buttons.
                                var popup_buttons = '';
                                var that = this;




                                // Create the list of buttons.
                                if (editor.opts.popupButtons.length > 1) {
                                    popup_buttons += '<div class="fr-buttons">';
                                    popup_buttons += editor.button.buildList(editor.opts.popupButtons);
                                    popup_buttons += '</div>';
                                }
                                console.log("_sets", _sets);
                                var _select = "<select class='fr-select personalization_set' style='float:none'></select>";
                                var _select2 = "<select  class='fr-select personalization_value' style='float:none'></select>";


                                var _custom_layer = '<div style="width:400px" class="fr-link-insert-layer fr-layer fr-active mt-0"><div>'+_select+'</div><div>'+_select2+'</div>';
                                _custom_layer += '<div class="_froalaEditor pb-1"></div>';
                                _custom_layer += '<div class="fr-input-line"><input id="fr-link-insert-layer-personalization-default" name="default value" type="text" class="fr-link-attr" placeholder="" tabindex="1" dir="auto"><label for="fr-link-insert-layer-personalization-default">DEFAULT VALUE</label></div>';
                                _custom_layer += '<div class="fr-action-buttons"><button class="fr-command" role="button"  href="#" type="button">Insert</button></div></div>';
                                // Load popup template.
                                var template = {
                                    buttons: popup_buttons,
                                    custom_layer: _custom_layer
                                };

                                //

                                // Create popup.
                                var $popup = editor.popups.create('customPersonalizationPlugin.popup', template);
                                var _setsSelect = $popup.find("select.personalization_set");
                                var _valuesSelect = $popup.find("select.personalization_value");

                                var _default = $popup.find("#fr-link-insert-layer-personalization-default");

                                $popup.find(".fr-action-buttons > button").on('click', function() {
                                    var _set = _setsSelect.val();
                                    var _value = _valuesSelect.val();
                                    var _defaultVal;
                                    // when _froalaEditor is visible, get the froala editor instance
                                    var _froalaInput = $popup.find("._froalaEditor");
                                    if (_froalaInput.css('display') !== 'none') {
                                        _defaultVal = encodeURIComponent(_froalaInput[0]["data-froala.editor"].html.get());
                                    } else {
                                        _defaultVal = _default.val();
                                    }
                                    console.log("_set", _set);
                                    _setName = _sets[_set].name;
                                    _setId = _sets[_set].personalized_variable_set_id;
                                    _valueName = _sets[_set].schema[_value].name;
                                    _valueId = _sets[_set].schema[_value].id;
                                    if($popup.element) {
                                        $popup.element.replaceWith('<span contenteditable="false" class="wbmg_variable" data-wps-variable="'+_setId+'.'+_valueId+'" data-wps-default="'+_defaultVal+'">'+_setName+'.'+_valueName+'</span>');
                                    }else{
                                        editor.$el.find(".fr-marker").replaceWith('<span contenteditable="false" class="wbmg_variable" data-wps-variable="'+_setId+'.'+_valueId+'" data-wps-default="'+_defaultVal+'">'+_setName+'.'+_valueName+'</span>');
                                        //$popup.html.insert('<span contenteditable="false" class="wbmg_variable" data-wps-variable="'+_val+'" data-wps-default="'+_defaultVal+'">'+_val+'</span>');
                                    }
                                    // $popup.element.attr("data-wps-variable", _val);
                                    // $popup.element.attr("data-original-title", "{{{"+_val+"}}}");
                                    self.save();
                                    hidePopup();
                                })
                                _setsSelect.on('change', function() {
                                    console.log(this.value);
                                    console.log();
                                    _valuesSelect = $popup.find("select.personalization_value");
                                    console.log(_valuesSelect);
                                    _valuesSelect.empty(); // remove old options
                                    $.each(_sets[this.value].schema, function(index, val) {
                                        if(val.type!="MediaField"){
                                            if(index==0) _valuesSelect.append('<option selected value="'+index+'">'+val.name+'</option>');
                                            else _valuesSelect.append('<option value="'+index+'">'+val.name+'</option>');
                                        }
                                    });
                                });
                                _valuesSelect.on('change', function() {
                                    console.log(this.value);
                                    // Get the selected option's type
                                    var _type = $(this).find('option:selected').attr('type');
                                    var _default = $popup.find("#fr-link-insert-layer-personalization-default");
                                    var _froalaInput = $popup.find("._froalaEditor");

                                    if (_type == "RichTextField") {

                                        // Hide default input and show Froala editor
                                        _default.closest('.fr-input-line').hide();

                                        // Initialize Froala editor if not already initialized
                                        if (_froalaInput.is(':empty')) {
                                            var _options = $.extend(true, {}, self.options.keditorOptions);
                                            delete _options.toolbarInline;
                                            delete _options.initOnClick;
                                            delete _options.toolbarContainer;
                                            _options.enter = self.window.FroalaEditor.ENTER_BR;

                                            var fEditor = new self.window.FroalaEditor(_froalaInput[0], _options, function() {
                                                fEditor.html.set(''); // Initialize empty
                                            });
                                        }
                                        _froalaInput.show();
                                    } else {
                                        // Hide Froala editor and show default input
                                        _froalaInput.hide();
                                        _default.closest('.fr-input-line').show();
                                    }



                                //     // check if selected option has type RichTextField
                                // var _type = _sets[_index].schema[_id].type;
                                // if(_type=="RichTextField"){
                                //     var _froalaInputRich = $popup.find("._froalaEditor");
                                //     $popup.find(".fr-input-line").remove();
                                //     $(_froalaInputRich).empty();

                                //     var _options = $.extend(true,{},self.options.keditorOptions);
                                //     delete _options.toolbarInline;
                                //     delete _options.initOnClick;
                                //     delete _options.toolbarContainer;
                                //     _options.enter = self.window.FroalaEditor.ENTER_BR;
                                //     var fEditor = new self.window.FroalaEditor(_froalaInputRich[0],
                                //     _options
                                //     , function () {
                                //         // Call the method inside the initialized event.
                                //         fEditor.html.set(decodeURIComponent(_default));
                                //     });
                                // }else{
                                //     $popup.find("._froalaEditor").hide();
                                // }
                                 });



                                return $popup;
                            }

                            // Show the popup
                            function showPopup(el) {
                                console.log("el", el);
                                // Get the popup object defined above.
                                var $popup = editor.popups.get('customPersonalizationPlugin.popup');
                                editor.$el.find(".fr-marker").remove();
                                editor.markers.insert();
                                // If popup doesn't exist then create it.
                                // To improve performance it is best to create the popup when it is first needed
                                // and not when the editor is initialized.
                                if ($popup) editor.popups.hide('customPersonalizationPlugin.popup');

                                $popup = initPopup(el);
                                if($popup.element) delete $popup.element;

                                // Set the editor toolbar as the popup's container.
                                editor.popups.setContainer('customPersonalizationPlugin.popup', editor.$tb);

                                // This will trigger the refresh event assigned to the popup.
                                // editor.popups.refresh('customPersonalizationPlugin.popup');

                                // This custom popup is opened by pressing a button from the editor's toolbar.
                                // Get the button's object in order to place the popup relative to it.
                                var $btn = editor.$tb.find('.fr-command[data-cmd="personalizationBtn"]');

                                //console.log("el", el);
                                var $el = $btn;
                                if(el){
                                    $el = $(el);
                                    $popup.element = $el;
                                }
                                editor.element = $el;
                                $popup.html = editor.html;


                                // Set the popup's position.
                                var left = $el.offset().left + $el.outerWidth() / 2;
                                var top = $el.offset().top + $el.outerHeight(); // Enhancement 2950


                                // Show the custom popup.
                                // The button's outerHeight is required in case the popup needs to be displayed above it.
                                editor.popups.show('customPersonalizationPlugin.popup', left, top, $btn.outerHeight());


                                var _index = 0;
                                var _id = 0;

                                // get the indexes from current variable
                                if(el){
                                    var variable = $(el).attr("data-wps-variable");
                                    console.log("variable", variable);
                                    var values = variable.split(".");
                                    $.each(_sets, function(index, set) {
                                        console.log("set", set, values[0]);
                                        if(set.personalized_variable_set_id == values[0]){
                                            _index = index
                                            $.each(set.schema, function(index2, val) {
                                                if(val.id == values[1]){
                                                    _id = index2;
                                                    return;
                                                }
                                            })
                                        }
                                    })
                                }
                                // add the right options and selected options
                                var _setsSelect = $popup.find("select.personalization_set");
                                var _valuesSelect = $popup.find("select.personalization_value");
                                _setsSelect.empty(); // remove old options
                                _valuesSelect.empty(); // remove old options
                                var _options1 = '<option selected value="*">*</option>',
                                    _options2;
                                $.each(_sets, function(index, val) {
                                    if(index==_index) _options1 += '<option selected value="'+index+'">'+val.name+'</option>';
                                    else _options1 += '<option value="'+index+'">'+val.name+'</option>';
                                });
                                _setsSelect.append(_options1);

                                $.each(_sets[_index].schema, function(index2, val2) {
                                    if(val2.type!="MediaField"){ // dont add media Fields
                                        if(index2==_id) _options2 += '<option selected type="'+val2.type+'" value="'+index2+'">'+val2.name+'</option>';
                                        else _options2 += '<option type="'+val2.type+'" value="'+index2+'">'+val2.name+'</option>';
                                    }
                                });
                                _valuesSelect.append(_options2);

                                var _default = $(editor.element).attr("data-wps-default") || "";
                                var _input = $popup.find("#fr-link-insert-layer-personalization-default");
                                _input.addClass("fr-not-empty").val(_default);


                                // check if selected option has type RichTextField
                                var _type = _sets[_index].schema[_id].type;
                                if(_type=="RichTextField"){
                                    var _froalaInputRich = $popup.find("._froalaEditor");
                                    $popup.find(".fr-input-line").hide();
                                    $(_froalaInputRich).empty();

                                    var _options = $.extend(true,{},self.options.keditorOptions);
                                    delete _options.toolbarInline;
                                    delete _options.initOnClick;
                                    delete _options.toolbarContainer;
                                    _options.enter = self.window.FroalaEditor.ENTER_BR;
                                    var fEditor = new self.window.FroalaEditor(_froalaInputRich[0],
                                    _options
                                    , function () {
                                        // Call the method inside the initialized event.
                                        fEditor.html.set(decodeURIComponent(_default));
                                    });
                                }else{
                                    $popup.find("._froalaEditor").hide();
                                }

                            }

                            // Hide the custom popup.
                            function hidePopup () {
                                editor.popups.hide('customPersonalizationPlugin.popup');
                            }

                            // Methods visible outside the plugin.
                            return {
                                showPopup: showPopup,
                                hidePopup: hidePopup
                            }
                        }
                       // Define an icon and command for the button that opens the custom popup.
                        self.window.FroalaEditor.DefineIcon('buttonIcon', { NAME: 'help', PATH: 'M23.5 8.2c-.3-.3-.7-.3-.9 0l-3.5 3.5-1.5-1.5c-.3-.3-.7-.3-.9 0l-2.2-1.5c0-.2.1-.5.1-.8 0-2.2-1.8-4-4-4s-4 1.8-4 4c0 2.1 1.7 3.9 3.8 4v1.4c-6.5.1-7.8 4-7.8 5.3C2.4 20 3.7 20 3.7 20H17s1.3 0 1.3-1.3-1.3-5.3-8-5.3h-.2V12h.2c1.9 0 3.6-1.4 3.9-3.2l2.2 1.5c-.2.3-.2.7 0 .9l2 2c.3.3.7.3.9 0l4-4c.5-.4.5-.8.2-1z'})
                        self.window.FroalaEditor.RegisterCommand('personalizationBtn', {
                            title: self.labels.personalization,
                            icon: 'buttonIcon',
                            undo: true,
                            focus: true,
                            plugin: 'customPersonalizationPlugin',
                            callback: function () {
                                this.customPersonalizationPlugin.showPopup();
                            }
                        });


                        self.window.FroalaEditor.DefineIcon('customPersonalizationPlugin_popupClose', {
                            NAME: 'times',
                            SVG_KEY: 'back'
                        });
                        self.window.FroalaEditor.RegisterCommand('customPersonalizationPlugin_popupClose', {
                            title: self.labels.back,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.customPersonalizationPlugin.hidePopup();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customPersonalizationPlugin_delete', {
                            NAME: 'times',
                            SVG_KEY: 'remove'
                        });
                        self.window.FroalaEditor.RegisterCommand('customPersonalizationPlugin_delete', {
                            title: self.labels.delete,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.element.remove();
                                editor.markers.remove();
                                this.customPersonalizationPlugin.hidePopup();
                                console.log(this.element);
                                console.log(this);
                                self.save();
                            }
                        });
                    }
                    /* END:  Custom Personalization Popup  */




if(false){

                        /* Custom Modal/Collapseible Popup */
                        self.window.FroalaEditor.DefineIcon('customAdditionalText_popupClose', {
                            NAME: 'times',
                            SVG_KEY: 'back'
                        });
                        self.window.FroalaEditor.RegisterCommand('customAdditionalText_popupClose', {
                            title: self.labels.back,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.customAdditionalText.hidePopup();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customAdditionalText_delete', {
                            NAME: 'times',
                            SVG_KEY: 'remove'
                        });
                        self.window.FroalaEditor.RegisterCommand('customAdditionalText_delete', {
                            title: self.labels.delete,
                            undo: false,
                            focus: false,
                            callback: function() {
                                this.element.remove();
                                console.log(this.element);
                                console.log(this);
                                self.save();
                            }
                        });
                        self.window.FroalaEditor.DefineIcon('customAdditionalText_dropdown', {NAME: 'cog', SVG_KEY: 'fontSize'});

                        var _fontsizeOptions = {};
                        $.each(_fontsize, function (index, val) {
                            _fontsizeOptions[val] = val
                        });
                        self.window.FroalaEditor.RegisterCommand('customAdditionalText_dropdown', {
                            title: self.labels.fontSize,
                            type: 'dropdown',
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            options: _fontsizeOptions,
                            callback: function (cmd, val) {
                                this.element.css("font-size",val)
                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                //console.log ('do refresh');
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                //console.log ('do refresh when show');
                            }
                        });

                        self.window.FroalaEditor.DefineIcon('customAdditionalText_dropdown_vertical', {NAME: 'cog', SVG_KEY: 'star'});
                        self.window.FroalaEditor.RegisterCommand('customAdditionalText_dropdown_vertical', {
                            title: self.labels.verticalAlign,
                            type: 'dropdown',
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            options: {
                                "top" :"vertical align top",
                                "super": "vertical align super",
                                "bottom": "vertical align bottom"
                            },
                            callback: function (cmd, val) {
                                console.log(val);
                                this.element.css("vertical-align",val)
                                self.save();
                            },
                            // Callback on refresh.
                            refresh: function ($btn) {
                                //console.log ('do refresh');
                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                //console.log ('do refresh when show');
                            }
                        });

                        //self.window.FroalaEditor.DefineIcon('customAdditionalText_dropdown2', {NAME: 'cog', SVG_KEY: 'fontSize'});

                        self.window.FroalaEditor.RegisterCommand('customAdditionalText_dropdown2', {
                            title: "Test",
                            displaySelection: function displaySelection(editor) {
                                console.log("displaySelection",editor);
                                return editor.opts.paragraphFormatSelection;
                            },
                            defaultSelection: function defaultSelection(editor) {
                                return 'Custom Test';
                            },
                            displaySelectionWidth: 130,
                            type: 'dropdown',
                            html: function html() {
                                var c = '<ul class="fr-dropdown-list" role="presentation">';
                                 for (var val in _list) {
                                    console.log(val);
                                    c += '<li role="presentation"><div style="padding: 0 !important; margin: 0 !important;" role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="paragraphFormat" data-param1="' + val + '" title="' + val + '">' + val + '</a></div></li>';
                                }
                                c += '</ul>';
                                return c;
                            },
                            callback: function (cmd, val) {
                                console.log("callback", cmd, val)
                                // var $popup = editor.popups.get('customAdditionalText.popup');
                                // if(val=="custom"){
                                //     $popup.find("._froalaEditor").show();
                                // }else{
                                //     $popup.find("._froalaEditor").hide();
                                // }

                            },
                            // Callback on refresh.
                            refresh: function ($btn, $dropdown) {
                                console.log ('do refresh',$btn, $dropdown);
                                console.log("this.element", this.element);
                                var $popup = editor.popups.get('customAdditionalText.popup');


                            },
                            // Callback on dropdown show.
                            refreshOnShow: function ($btn, $dropdown) {
                                console.log ('do refresh when show');
                            }
                        });

                        // Define popup template.
                        self.window.FroalaEditor.POPUP_TEMPLATES["customAdditionalText.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

                        // Define popup buttons.
                        Object.assign(self.window.FroalaEditor.DEFAULTS, {
                            popupButtons: ['customAdditionalText_popupClose', 'customAdditionalText_dropdown', 'customAdditionalText_dropdown_vertical', 'customAdditionalText_delete'],
                            //popupButtons: ['customAdditionalText_popupClose'],
                        });

                        self.window.FroalaEditor.DefineIcon('buttonIcon', { NAME: 'star', SVG_KEY: 'star'})
                        self.window.FroalaEditor.RegisterCommand('customAdditionalTextBtn', {
                            title: 'customAdditionalText',
                            icon: 'buttonIcon',
                            undo: true,
                            focus: true,
                            plugin: 'customAdditionalText',
                            callback: function () {
                                this.customAdditionalText.showPopup();
                            }
                        });

                        // The custom popup is defined inside a plugin (new or existing).
                        var _list = {};
                        // change array to abject
                        $.each(self.options.variablesPlugin, function(index, item) {
                            _list[item.var] =  item.text;
                        });
                        console.log("_list", _list);
                        //if(self.options.variablesPlugin && self.options.variablesPlugin.json) _list = $.extend({}, _list, JSON.parse(self.options.variablesPlugin.json));
                        self.window.FroalaEditor.PLUGINS.customAdditionalText = function(editor) {
                            // Create custom popup.
                            function initPopup(el) {
                                // Popup buttons.
                                var popup_buttons = '';
                                var that = this;

                                // Create the list of buttons.
                                if (editor.opts.popupButtons.length > 1) {
                                    popup_buttons += '<div class="fr-buttons">';
                                    popup_buttons += editor.button.buildList(editor.opts.popupButtons);
                                    popup_buttons += '</div>';
                                }

                                var _select = "<select class='fr-select' style='float:none'>";
                                _select += '<option value="Custom Text">Custom Text</option>'; // add custom text as default
                                for (var val in _list) {
                                    _select += '<option value="'+val+'">'+val+'</option>';
                                }
                                _select += '</select>';



                                // Load popup template.
                                var template = {
                                    buttons: popup_buttons,
                                    custom_layer: '<div class="_textcolor px-2 pb-1"></div><div style="width:400px" class="fr-link-insert-layer fr-layer fr-active mt-0"><div>'+_select+'</div><div class="_textEditor px-2 border-1 py-2"></div><div class="_froalaEditor pb-1"></div><div class="fr-action-buttons"><button class="fr-command" role="button"  href="#" type="button">Insert</button></div></div>'
                                };

                                // Create popup.
                                var $popup = editor.popups.create('customAdditionalText.popup', template);
                                var _froalaInput = $popup.find("._froalaEditor");
                                var _textEditor = $popup.find("._textEditor");
                                var _select = $popup.find("select.fr-select");
                                $popup.find(".fr-action-buttons > button").on('click', function() {
                                    var _val = _select.val();
                                    $popup.element.attr("data-wps-variable", _val);
                                    //console.log("change", editor.element);
                                    if(_select.val()=="Custom Text"){
                                        console.log("_froalaInput", _froalaInput);
                                        console.dir(_froalaInput[0]["data-froala.editor"]);
                                        console.dir(_froalaInput[0]["data-froala.editor"].html.get());
                                        $popup.element.attr("data-original-title", encodeURIComponent(_froalaInput[0]["data-froala.editor"].html.get()));
                                    }
                                    else{
                                        $popup.element.attr("data-original-title", "{{{"+_val+"}}}");
                                    }
                                    self.save();
                                    hidePopup();
                                })
                                _select.on('change', function() {
                                    console.log(this.value);
                                    //editor.element.attr("data-wps-variable", this.value);
                                    if(this.value=="Custom Text"){
                                        _froalaInput.show();
                                        _textEditor.hide();
                                    } else {
                                        _froalaInput.hide();
                                        _textEditor.show();
                                        _textEditor.html(_list[this.value]);
                                    }
                                    self.save();
                                });



                                return $popup;
                            }

                            // Show the popup
                            function showPopup(el) {

                                console.log("this", this);
                                var _link = this.selection.element();
                                // if link has another element inside
                                if(_link.tagName!="a"){
                                    _link = $(_link).closest("a")[0];
                                }
                                console.log("_link", _link);

                                var _img = that.image.get();
                                var $el = $(editor.selection.element());
                                // Set the popup's position.
                                var left = _img.offset().left + _img.outerWidth() / 2;
                                var top = _img.offset().top + (editor.opts.toolbarBottom ? 10 : _img.outerHeight() - 10);

                                console.log("_img", _img)
                                console.log("$el", $el);







                                // Get the popup object defined above.
                                var $popup = editor.popups.get('customAdditionalText.popup');

                                // If popup doesn't exist then create it.
                                // To improve performance it is best to create the popup when it is first needed
                                // and not when the editor is initialized.
                                if (!$popup) $popup = initPopup(el);

                                // Set the editor toolbar as the popup's container.
                                editor.popups.setContainer('customAdditionalText.popup', editor.$tb);

                                // This will trigger the refresh event assigned to the popup.
                                // editor.popups.refresh('customAdditionalText.popup');

                                // This custom popup is opened by pressing a button from the editor's toolbar.
                                // Get the button's object in order to place the popup relative to it.
                                var $btn = editor.$tb.find('.fr-command[data-cmd="bold"]');

                                console.log("el", el);
                                var $el = $(el);
                                editor.element = $el;
                                $popup.element = $el;

                                // Set the popup's position.
                                var left = $el.offset().left + $el.outerWidth() / 2;
                                var top = $el.offset().top + $el.outerHeight(); // Enhancement 2950

                                // Show the custom popup.
                                // The button's outerHeight is required in case the popup needs to be displayed above it.
                                editor.popups.show('customAdditionalText.popup', left, top, $btn.outerHeight());

                                var variable = $(editor.element).attr("data-wps-variable");
                                console.log("variable", variable);
                                if(variable!="") $popup.find("select.fr-select").val(variable)
                                else {
                                    $popup.find("select.fr-select")[0].selectedIndex = 0;
                                }

                                var _froalaInput = $popup.find("._froalaEditor");
                                $(_froalaInput).empty();

                                var _options = $.extend(true,{},self.options.keditorOptions);
                                delete _options.toolbarInline;
                                delete _options.initOnClick;
                                delete _options.toolbarContainer;
                                var fEditor = new self.window.FroalaEditor(_froalaInput[0], /*{

                                    // key: 'oc1F2vB2A1H2B4C1B6mEZXQUVJb1EZf1IWIAUKLJZMBQuD3E2D1E1C4G1G4F1A10C6',
                                    // toolbarButtons: {
                                    //     'moreText': {
                                    //       'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor','clearFormatting']
                                    //     },
                                    //     'moreParagraph': {
                                    //       'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight'],
                                    //       'buttonsVisible': 0
                                    //     },
                                    //     'moreRich': {
                                    //       'buttons': ['insertLink', 'emoticons', 'fontAwesome', 'specialCharacters'],
                                    //       'buttonsVisible': 0
                                    //     },
                                    // },
                                }*/
                                _options
                                , function () {
                                    // Call the method inside the initialized event.
                                    fEditor.html.set(decodeURIComponent(editor.element.attr("data-original-title")));
                                  });



                                var _textEditor = $popup.find("._textEditor");
                                if(variable=="Custom Text"){
                                    _froalaInput.show();
                                    _textEditor.hide();
                                    console.log("fEditor", fEditor);
                                    //fEditor.html.set();
                                } else {
                                    _froalaInput.hide();
                                    _textEditor.show();
                                    _textEditor.html(_list[variable]);
                                }

                                // Color change
                                var _default = $el[0].style.color.replace(/!important/g, '').trim() || getComputedStyle($el[0], null)["color"];
                                var _prevcolors = ['rgba(0,0,0,0)'];
                                _prevcolors = _prevcolors.concat(_colorsText);
                                //_prevcolors = _prevcolors.concat(["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"]);
                                console.log("$popup",$popup);
                                var xncolorpicker = new self.window.XNColorPicker({
                                    color: _default,
                                    selector: $popup.find("._textcolor"),
                                    showprecolor: true,
                                    prevcolors: _prevcolors,
                                    showhistorycolor: true,
                                    historycolornum: 16,
                                    format: 'hex',
                                    showPalette: true,
                                    show: false,
                                    lang: 'en',
                                    //colorTypeOption: 'single',
                                    colorTypeOption:'single',
                                    canMove: true,
                                    alwaysShow: false,
                                    autoConfirm: false,
                                    onError: function (e) {

                                    },
                                    onCancel: function (color) {
                                    console.log("cancel", color)
                                    },
                                    onChange: function (color) {
                                    console.log("change", color)
                                    },
                                    onConfirm: function (color) {

                                    console.log("confirm", color);

                                    var _color,
                                        el =$el[0];
                                    if (color) {
                                        if(color.color.hex === '#00000000'){
                                            el.style.setProperty("color", "");
                                        } else {
                                            _color = color.color.rgba;
                                            el.style.setProperty("color", _color, "important");
                                        }
                                    } else {
                                        //el.style.setProperty("background-color", "");
                                        el.style.setProperty("color", "");
                                    }
                                    self.save();
                                    }
                                })

                                //console.log("$popup",$popup);

                            }

                            // Hide the custom popup.
                            function hidePopup() {
                                editor.popups.hide('customAdditionalText.popup');
                            }

                            // Methods visible outside the plugin.
                            return {
                                showPopup: showPopup,
                                hidePopup: hidePopup
                            }
                        }

                        /* END:  Custom Telefonica Footnode Popup  */

}












                        /* ChatGPT */
                        if(self.options.chatGPTPlugin){
                            console.log("self.options.chatGPTPlugin", self.options.chatGPTPlugin);
                            self.window.FroalaEditor.DefineIcon('chatGPT', {NAME: 'help', PATH: 'M20.3 10.4c.4-1.3.3-2.7-.4-3.9-1-1.8-3.2-2.8-5.2-2.3-.9-1-2.2-1.6-3.6-1.6C9 2.6 7.1 4 6.5 6c-1.4.1-2.6 1-3.2 2.2-1.1 1.8-.8 4.1.6 5.7-.4 1.3-.3 2.7.4 3.9 1 1.8 3.2 2.8 5.2 2.3.9 1 2.2 1.6 3.6 1.6 2.1 0 4-1.4 4.6-3.4 1.4-.3 2.5-1.1 3.2-2.3 1.1-1.8.9-4.1-.6-5.6zm-1.5-3.3c.4.7.6 1.6.4 2.4 0 0-.1 0-.1-.1l-3.8-2.2c-.2-.1-.4-.1-.6 0L10 9.9V8l3.9-2.2c1.7-1 3.9-.4 4.9 1.3zm-6.7 2.6 2.1 1.2v2.4l-2.1 1.2-2.1-1.2v-2.4l2.1-1.2zM7.5 7.4c0-2 1.6-3.6 3.6-3.6.8 0 1.7.3 2.3.8 0 0-.1 0-.1.1L9.5 6.9c-.2.1-.4.3-.4.5v5.4l-1.6-.9V7.4zM4.4 8.8c.4-.7 1.1-1.3 1.9-1.6v4.5c0 .2.1.4.3.5l4.7 2.7L9.6 16h-.1l-3.9-2.2c-1.6-1.1-2.2-3.3-1.2-5zm1 8.4c-.4-.7-.6-1.6-.4-2.4 0 0 .1 0 .1.1L8.9 17c.2.1.4.1.6 0l4.7-2.7v1.9l-3.9 2.2c-1.7 1.1-3.9.5-4.9-1.2zm11.3-.3c0 2-1.6 3.6-3.6 3.6-.8 0-1.7-.3-2.3-.8 0 0 .1 0 .1-.1l3.8-2.2c.2-.1.3-.3.3-.5v-5.4l1.6.9v4.5zm3.1-1.5c-.4.7-1.1 1.3-1.9 1.6v-4.5c0-.2-.1-.4-.3-.5L13 9.2l1.6-.9h.1l3.9 2.2c1.7 1 2.2 3.2 1.2 4.9z'});
                            self.window.FroalaEditor.RegisterCommand('chatGPT', {
                                title: "Ask ChatGPT",
                                focus: false,
                                undo: true,
                                refreshAfterCallback: false,

                                callback: function (cmd, val) {
                                   var api_key = self.options.chatGPTPlugin.api_key;
                                   selection = this.selection.text();
                                   var data = {
                                    model: self.options.chatGPTPlugin.model || 'text-davinci-003',
                                    prompt: selection,
                                    max_tokens: parseInt(self.options.chatGPTPlugin.max_tokens) || 256,
                                    temperature: parseInt(self.options.chatGPTPlugin.temperature) || 0,
                                   }
                                   fetch('https://api.openai.com/v1/completions', {
                                    method: 'POST',
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: 'Bearer '+api_key,
                                    },
                                    body: JSON.stringify(data),
                                   })
                                   .then((response) => {
                                        return response.json();
                                   })
                                   .then((data) => {
                                    console.log("data", data);
                                    var to_display = data.choices[0].text;
                                    this.html.insert(to_display);
                                    self.save();
                                   })
                                }
                            });
                        }




                        var _imageStyles = {
                            'rounded': self.labels.imageStyles.rounded,
                            'border-2': self.labels.imageStyles.border,
                            'shadow': self.labels.imageStyles.shadow,
                            'shadow-sm': self.labels.imageStyles.shadow_sm,
                            'mt-2': self.labels.imageStyles.mt_2,
                            'ml-2': self.labels.imageStyles.ml_2,
                            'mr-2': self.labels.imageStyles.mr_2,
                            'mb-2': self.labels.imageStyles.mb_2,
                            'align-middle': self.labels.imageStyles.align_middle,
                            'hidden-sm-down': self.labels.imageStyles.hidden_sm_down,
                            'float-sm-left mb-3 mr-sm-3 mt-0': self.labels.imageStyles.wrap_text_left,
                            'float-sm-right mb-3 ml-sm-3 mt-0': self.labels.imageStyles.wrap_text_right,
                            "rounded-circle" : self.labels.imageStyles.rounded_circle
                        };
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.imageStyles){
                        $.extend( _imageStyles, self.groupSettings.editorSettings.imageStyles );
                        }



                        var _linkStyles = {
                            'btn': self.labels.linkStyles.btn,
                            'btn-outline-dark': self.labels.linkStyles.outline_dark,
                            'btn-outline-white': self.labels.linkStyles.outline_white,
                            'btn-outline-secondary': self.labels.linkStyles.outline_secondary,
                            'btn-outline-primary': self.labels.linkStyles.outline_primary,
                            'btn-dark': self.labels.linkStyles.dark,
                            'btn-white': self.labels.linkStyles.white,
                            'btn-primary': self.labels.linkStyles.primary,
                            'btn-secondary': self.labels.linkStyles.secondary,
                            'btn-tertiary': self.labels.linkStyles.tertiary,
                            'btn-quaternary': self.labels.linkStyles.quaternary,
                            'text-dark': self.labels.linkStyles.text_dark,
                            'text-white text-btn-white': self.labels.linkStyles.text_white,
                            'text-primary': self.labels.linkStyles.text_primary,
                            'text-secondary': self.labels.linkStyles.text_secondary,
                            'text-tertiary': self.labels.linkStyles.text_tertiary,
                            'text-quaternary': self.labels.linkStyles.text_quaternary,
                            'text-decoration-underline': self.labels.linkStyles.underline,
                            'text-decoration-none': self.labels.linkStyles.no_underline,
                            'small': self.labels.linkStyles.small,
                            'font-weight-normal': self.labels.linkStyles.normal,
                            'font-weight-light': self.labels.linkStyles.light,
                            'stretched-link': self.labels.linkStyles.stretched,
                            'mt-auto': self.labels.linkStyles.mt_auto,
                            'w-100': self.labels.linkStyles.w100
                        };
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.linkStyles){
                          $.extend( _linkStyles, self.groupSettings.editorSettings.linkStyles );
                        }

                        var _inlineClasses = {
                            'customTypo1': self.labels.inlineClasses.customTypo1,
                            'customTypo2': self.labels.inlineClasses.customTypo2,
                            'customTypo3': self.labels.inlineClasses.customTypo3,
                            'display-1': self.labels.inlineClasses.display_1,
                            'display-2': self.labels.inlineClasses.display_2,
                            'display-3': self.labels.inlineClasses.display_3,
                            'display-4': self.labels.inlineClasses.display_4,
                            'small': self.labels.inlineClasses.small,
                            'font-weight-light': self.labels.inlineClasses.font_weight_light,
                            'font-weight-normal': self.labels.inlineClasses.font_weight_normal,
                            'wbmg-mark': self.labels.inlineClasses.wbmg_mark,
                            'pb-0': self.labels.inlineClasses.pb_0,
                            'wmag_collapse': self.labels.inlineClasses.wmag_collapse,
                            'wmag_footnote': self.labels.inlineClasses.wmag_footnote,
                            'text-uppercase': self.labels.inlineClasses.text_uppercase,
                            'align-top': self.labels.inlineClasses.align_top,
                            'align-middle': self.labels.inlineClasses.align_middle,
                            'align-bottom': self.labels.inlineClasses.align_bottom,
                            'no-hyphen': self.labels.inlineClasses.no_hyphen,
                            'wbmg_variable': self.labels.inlineClasses.wbmg_variable
                        };
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.inlineClasses){
                          $.extend( _inlineClasses, self.groupSettings.editorSettings.inlineClasses );
                        }

                        var _lineHeights = {
                            Default: '',
                            '0.5': '0.5',
                            '0.6': '0.6',
                            '0.7': '0.7',
                            '0.8': '0.8',
                            '0.9': '0.9',
                            '1': '1',
                            '1.15': '1.15',
                            '1.2': '1.2',
                            '1.3': '1.3',
                            '1.4': '1.4',
                            '1.5': '1.5',
                            '1.6': '1.6',
                            '1.7': '1.7',
                            '1.8': '1.8',
                            '1.9': '1.9',
                            '2': '2',
                            '2.2': '2.2',
                            '2.4': '2.4',
                            '2.6': '2.6',
                            '2.8': '2.8',
                            '3': '3'
                        };
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.lineHeights){
                            $.extend( _lineHeights, self.groupSettings.editorSettings.lineHeights );
                        }

                        var _paragraphFormat= {
                          H6: 'H6',
                          H5: 'H5',
                          H4: 'H4',
                          H3: 'H3',
                          H2: 'H2',
                          H1: 'H1',
                          N: 'Normal'
                        };
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.paragraphFormat){
                          $.extend( _inlineClasses, self.groupSettings.editorSettings.paragraphFormat );
                        }


                        self.window.FroalaEditor.DefineIcon('customTableResponsive', {NAME: 'cog', PATH: 'm20 5h-16c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h7.2l-0.3-0.6 1-0.9h-2.4v-2.5h5.4 1.1v-4.5h4.5v3h-4.6v1.5h6.1v-8c0-1.1-0.9-2-2-2zm-16.5 2c0-0.3 0.2-0.5 0.4-0.5h0.1 4v2.5h-4.5v-2zm0 3.5h4.5v3h-4.5v-3zm0.5 7c-0.3 0-0.5-0.2-0.5-0.4v-0.1-2h4.5v2.5h-4zm10.5-4h-5v-3h5v3zm0-4.5h-5v-2.5h5v2.5zm6 0h-4.5v-2.5h4c0.3 0 0.5 0.2 0.5 0.4v0.1 2zm2 10.2v0c0-0.1 0.1-0.1 0.1-0.1v-0.1-0.1-0.3-0.1-0.1-0.1-0.1l-2.3-2.3c-0.3-0.3-0.8-0.2-1 0.1s-0.2 0.7 0 0.9l0.9 0.9h-4.4l0.9-0.9c0.3-0.3 0.2-0.8-0.1-1s-0.7-0.2-0.9 0l-2.3 2.3v0.1 0.1 0.1 0.1 0.3 0.1 0.1 0.1 0.1l2.3 2.3c0.3 0.3 0.8 0.2 1-0.1s0.2-0.7 0-0.9l-0.9-0.9h4.4l-0.9 0.9c-0.3 0.3-0.3 0.7-0.1 1 0.3 0.3 0.7 0.3 1 0.1l2.3-2.3c-0.1-0.2-0.1-0.2 0-0.2z'});
                        // self.window.FroalaEditor.DefineIcon('customTableResponsive', {NAME: 'Responsive Table', SVG_KEY: 'replaceImage'});
                        self.window.FroalaEditor.RegisterCommand('customTableResponsive', {
                            title: self.labels.responsiveTable,
                            focus: false,
                            undo: true,
                            refreshAfterCallback: true,
                            callback: function (a) {
                                var that = this;

                                setTimeout(function () {
                                    if( that.toolbar && that.opts.toolbarInline)  that.toolbar.hide();
                                }, 100);

                                console.log(this);
                                console.log(a);
                                var _table = (this.table.selectedTable().length>0) ? this.table.selectedTable()[0] : false;
                                if(_table){
                                    if($(_table).parent().hasClass("table-responsive")){
                                        $(_table).unwrap();
                                        // $($btn).addClass("fr-active");
                                    }else{
                                        $(_table).wrap("<div class='table-responsive'></div>");
                                        // $($btn).removeClass("fr-active");
                                    }
                                }
                                this.events.focus();
                                self.save();
                            },// Callback on refresh.
                            refresh: function ($btn) {
                                console.log ('do refresh', $btn);

                                var _table = (this.table.selectedTable().length>0) ? this.table.selectedTable()[0] : false;
                                if(_table){
                                    if($(_table).parent().hasClass("table-responsive")){
                                        $($btn).addClass("fr-active");
                                    }else{
                                        $($btn).removeClass("fr-active");
                                    }
                                }
                            }
                        });

                        var _tableStyles= {
                            'table': self.labels.tableStyles.table,
                            'table table-sm': self.labels.tableStyles.table_sm,
                            "table-horizontal": self.labels.tableStyles.table_horizontal,
                            'mx-auto': self.labels.tableStyles.mx_auto,
                            'ml-auto': self.labels.tableStyles.ml_auto,
                            'mb-0': self.labels.tableStyles.mb_0,
                            'w-100': self.labels.tableStyles.w_100,
                            "w-sm-100-up": self.labels.tableStyles.w_sm_100_up,
                            'fr-dashed-borders': self.labels.tableStyles.fr_dashed_borders,
                            'fr-no-borders1': self.labels.tableStyles.fr_no_borders1,
                            'fr-no-borders2': self.labels.tableStyles.fr_no_borders2,
                            'fr-no-borders3': self.labels.tableStyles.fr_no_borders3,
                            'fr-alternate-rows': self.labels.tableStyles.fr_alternate_rows,
                            'table-hover': self.labels.tableStyles.table_hover,
                            'table-sticky': self.labels.tableStyles.table_sticky
                        };

                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.tableStyles){
                          $.extend( _tableStyles, self.groupSettings.editorSettings.tableStyles );
                        }
                        var _tableCellStyles= {
                            'fr-highlighted': self.labels.tableCellStyles.fr_highlighted,
                            'fr-thick': self.labels.tableCellStyles.fr_thick,
                            "p-3": self.labels.tableCellStyles.p_3,
                            "p-4": self.labels.tableCellStyles.p_4,
                            "fr-round-bottom": self.labels.tableCellStyles.fr_round_bottom,
                            "fr-round-top": self.labels.tableCellStyles.fr_round_top,
                            "border-bottom-0": self.labels.tableCellStyles.fr_border_bottom,
                            "border-top-0": self.labels.tableCellStyles.fr_border_top,
                          };
                          if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.tableCellStyles){
                            $.extend( _tableCellStyles, self.groupSettings.editorSettings.tableCellStyles );
                          }

                        var _paragraphStyles = {
                          "wps-aos-fade-in": self.labels.paragraphStyles.wps_aos_fade_in,
                          "wps-aos-fade-up": self.labels.paragraphStyles.wps_aos_fade_up,
                          "wps-aos-fade-right": self.labels.paragraphStyles.wps_aos_fade_right,
                          "wps-aos-fade-left": self.labels.paragraphStyles.wps_aos_fade_left,
                          "wps-aos-fade-down": self.labels.paragraphStyles.wps_aos_fade_down,
                          "wps-aos-zoom-in": self.labels.paragraphStyles.wps_aos_zoom_in,
                          "wps-aos-zoom-out": self.labels.paragraphStyles.wps_aos_zoom_out,
                          'mb-0': self.labels.paragraphStyles.mb_0
                        }
                        if(self.groupSettings.editorSettings && self.groupSettings.editorSettings.paragraphStyles){
                          $.extend( _paragraphStyles, self.groupSettings.editorSettings.paragraphStyles);
                        }


                        //console.dir(_editableBlock[0])

                        //var editor = new self.window.FroalaEditor(_editableBlock[0]);
                        //editor.destroy();

                        // Version 3 example.





                    var _options =  {
                          // Pass options.
                          key: 'GPD2tA9A4A5C2B2F2F1lFe1a1PVWEc1Fd1XHTHc1THMMe1NCb1tA1A1A1B1H4A1D2B1C7B4==',
                          //embedlyKey: 'c8ad5986b1a740d69e596399b41dd2aa',
                          //embedlyScriptPath: 'https://cdn.embedly.com/widgets/platform.js',
                          toolbarInline: true,
                          scrollableContainer: $(editableBlocks[0]).closest("body")[0],
                          //toolbarContainer: $(editableBlocks[0]).closest("body").find("#keditor-toolbarContainer"),
                          pluginsEnabled: _pluginsEnabled,
                          toolbarButtons: self.options['toolbarSet7'],
                          //toolbarButtons: ['bold','fontAwesome'],
                          //htmlDoNotWrapTags: ['h1', 'h2'],
                          htmlAllowedEmptyTags: ['span','.footnode_modal','textarea', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'p','strong','i','br'],
                          htmlUntouched: false, // if set to true, cant set colors on text - before: delete after tables bug https://jsfiddle.net/ilyasfroaal/2q49gk8n/2/
                          dragInline: false,
                          keepFormatOnDelete: true,
                          fontSizeSelection: true,
                          fontSize: _fontsize,
                          //toolbarVisibleWithoutSelection: true,
                          //pastePlain: true,
                          //pasteAllowedStyleProps: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'],
                          //pasteDeniedAttrs: ['class', 'id','style'],
                          imageEditButtons: ['imageAlign', 'imageCaption', 'imageRemove', 'customImageReplace', '|', 'imageLink', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize','openImageAudioPopup', 'customAdditionalTextBtn'/*, 'customAnimation'*/],
                          tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle', 'customTableResponsive','-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle'],
                          imageMove:false,
                          videoEditButtons: ['videoAlign', 'videoRemove', 'customVideoReplace', 'customVideoLink', 'customVideoLinkEdit', 'customVideoUnlink', '|','-','customVideoList','customVideoStyle', 'videoSize', 'customVideoResponsive', 'customVideoAlternativeFormat', 'customVideoAlternativeFormatUnlink', 'customVideoPoster', 'videoPosterUnlink'],
                          videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed','videoCustomUpload'],
                          videoResponsive: false,
                          videoResize: false,
                          videoDefaultWidth:0,
                          imagePaste: false,
                          imageResizeWithPercent: true,
                          imageRoundPercent: true,
                          imageStyles: _imageStyles,
                          imageUpload: false,
                          wordPasteKeepFormatting: false,
                          wordPasteModal: false,
                          pasteAllowedStyleProps:['wps-*','color'],
                          pasteDeniedTags:['iframe','div','figure', 'blockquote', 'embed', 'form', 'input', 'label', 'nav', 'noscript', 'object',/*'style',*/ 'optgroup', 'option', 'script', 'select', 'source'],
                          pasteDeniedAttrs:['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', /*'class'*/, 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high',  'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', /*'span',*/ 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck',  'tabindex', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap'],
                         // wordDeniedAttrs: ['style'],
                          //wordAllowedStyleProps: [],
                          linkEditButtons: linkEditButtons,
                          linkText: true,
                          linkConvertEmailAddress: true,
                          linkAutoPrefix: '',
                          paragraphFormatSelection: true,
                          //linkInsertButtons:['linkBack','|', 'linkList'],
                          linkInsertButtons:['linkBack'],
                          linkMultipleStyles: true,
                          linkStyles: _linkStyles,
                          attribution: false,
                          /*linkList: [
                            {
                              displayText: 'Google',
                              href: '1',
                              'data-test': 'datatest'
                            },
                            {
                              displayText: 'Froala',
                              href: '2',
                            }
                          ],*/

                          initOnClick: true,
                          tableColors: _colorsText,
                          colorsText: _colorsText,
                          colorsBackground: _colorsText,
                          align: 'right',
                          colorsStep: 6,
                          tableColorsStep: 6,
                          colorsHEXInput: _colorsHEXInput,
                          entities: "&shy;",
                          language: self.options.language || "en",
                          fontAwesomeTemplate: '<i class="fa fa-[NAME] fr-deletable" aria-hidden="true"></i>',
                          //enter: self.window.FroalaEditor.ENTER_BR,
                          lineHeights: _lineHeights,
                          paragraphFormat: _paragraphFormat,
                          paragraphStyles: _paragraphStyles,
                          inlineClasses: _inlineClasses,
                          fontFamily: _customFontsObj,
                          fontFamilySelection: true,
                          tableStyles:_tableStyles,
                          tableCellStyles: _tableCellStyles,
                          //paragraphFormatSelection: true,

                          // Bind events
                          events: {
                              contentChanged: function () {
                                // Get editor instance.
                                var editor = this;

                                var _content = editor.html.get(true);

                                $(editor).trigger('datachange');


                                self.save();

                                // Callback code here.
                              },
                              'mouseup': function (evt) {

                                console.log("mouseup", evt.currentTarget);

                                var that = this;
                                if($(evt.currentTarget).is( "i" ) && $(evt.currentTarget).hasClass("fa")){
                                    that.customFontAwesomePlugin.showPopup();
                                }
                                if($(evt.currentTarget).is( "span" ) && $(evt.currentTarget).hasClass("footnode_modal")){
                                    that.customTefFootnodePlugin.showPopup(evt.currentTarget);
                                }
                                // if($(evt.currentTarget).is( "span" ) && $(evt.currentTarget).hasClass("additional_text")){
                                //     that.customAdditionalTextPlugin.showPopup(evt.currentTarget);
                                // }
                                if($(evt.currentTarget).is( "span" ) && $(evt.currentTarget).hasClass("inline-aos")){
                                    that.customAnimationPlugin.showPopup(evt.currentTarget);
                                }
                                if($(evt.currentTarget).is( "span" ) && $(evt.currentTarget).hasClass("wbmg_variable")){
                                    that.customPersonalizationPlugin.showPopup(evt.currentTarget);
                                }
                                if($(evt.currentTarget).hasClass("wbmg_countup")){
                                    that.customCountUpPlugin.showPopup($(evt.currentTarget));
                                }

                                if($(evt.currentTarget).hasClass("wbmg_form")){
                                    console.log("showPopup", evt.currentTarget);
                                    that.customFormsPlugin.showPopup(evt.currentTarget);
                                }



                              },
                              'paste.after': function () {
                                // Do something here.
                                // this is the editor instance.
                                // console.log('paste.after',this);
                              },
                              'paste.afterCleanup': function (clipboard_html) {
                                // Do something here.
                                // this is the editor instance.
                                // console.log('paste.afterCleanup',this, clipboard_html);

                                var tempDiv = document.createElement('div');
                                tempDiv.innerHTML = clipboard_html;

                                // Select the table element and remove the 'border' attribute
                                // froala bug -> does not remove inline attributes on pasting
                                var table = tempDiv.querySelector('table');
                                if (table) {
                                    table.removeAttribute('border');
                                }

                                // Serialize the DOM object back to an HTML string
                                var newHtmlString = tempDiv.innerHTML;

                                return newHtmlString;


                              },
                              'paste.before': function (original_event) {
                                // Do something here.
                                // this is the editor instance.
                                // console.log('paste.before',this, original_event);
                              },
                              'paste.beforeCleanup': function (clipboard_html) {
                                // Do something here.
                                // this is the editor instance.
                                // console.log('paste.beforeCleanup',this, clipboard_html);
                              },
                              'paste.wordPaste': function (clipboard_html) {
                                // Do something here.
                                // this is the editor instance.
                                // console.log('paste.wordPaste',this, clipboard_html);
                              },
                              'image.beforePasteUpload': function (img) {
                                // Do something here.
                                // this is the editor instance.
                                // console.log("this",this);
                                // console.log("img",img);
                                //return false;
                              },
                              'image.beforeUpload': function (images) {
                                // Do something here.
                                // this is the editor instance.
                                // console.log("image.beforeUpload", this, images);
                                //return false;
                                // if(typeof self.options.callFromKeditor === 'function' ) self.options.callFromKeditor("imageUploadModal", {
                                //     "src": images[0],
                                //     // "width" : _size[0],
                                //     // "height": _size[1]
                                //   }, function(data){
                                //     console.error("data", data);
                                //   })
                              }
                            //   'mousedown': function (mousedownEvent) {
                            //     // Do something here.
                            //     // this is the editor instance.
                            //     console.error("mousedown",this.customFontAwesomePlugin.showPopup());
                            //     console.error("mousedownEvent", mousedownEvent.currentTarget.className)
                            //     console.error(self.window.FroalaEditor.PLUGINS)
                            //     console.error(editor)

                            //     if($(mousedownEvent.currentTarget).hasClass("fa")){
                            //         //editor.customFontAwesomePlugin.showPopup();
                            //     }
                            //   }
                          }
                      };

                      if(_editableBlock.hasClass("wps-br")){
                        _options.enter = self.window.FroalaEditor.ENTER_BR;
                      }
                      if(_editableBlock[0].tagName.toLowerCase() == "a"){
                        _options.enter = self.window.FroalaEditor.ENTER_BR;
                      }

                      self.options.keditorOptions = _options;




                      editor = new self.window.FroalaEditor(_editableBlock[0], _options);

                    //   console.warn("_editableBlock", _editableBlock);
                    //   console.log(_editableBlock.find(".fa"))
                    //   _editableBlock.find(".fa").click(function(evt){
                    //       console.log("click", evt);
                    //     editor.customFontAwesomePlugin.showPopup();

                    //   })




                    }
                }


            }
        }



    }


    KEditor.prototype.getOptions = function() {
        var keditor = $(this).data('keditor');

        return $.extend(true, {}, keditor.options);
    };


    var absolutePath = function(href) {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") return href;
        var link = document.createElement("a");
        link.href = href;
        return (link.protocol + "//" + link.host + link.pathname + link.search + link.hash);
    }



    // KEditor plugins
    $.fn.keditor = function(options) {
        var element = $(this)
        var data = element.data('keditor');


        if (!data) {
            element.data('keditor', (data = new KEditor(element, options)));
        } else {




            // closed editor and open another again
            // keditor is still available
            // so just set new content
            data.options.data = options.data;

            //clean modals
            data.element.find("iframe").contents().find('.pgs_modal').remove();
            data.element.find("iframe").contents().find(".pgs_modal_overlay").remove();


            if(data.element.find("iframe").contents().find('.keditor-ui').length==0){
              if (window.confirm("There was an error. Please reload the page. [7gsze]")) {
                window.parent.location.reload();
              }
              return;
            }

            if (options.content) {

                data.setContent(options.content);
               // if(self.options.newArticle) self.save();
            } else {
                // if there is not content saved, use template
                originalContent = data.template;
                data.setContent(originalContent);

                data.save();
            }
        }

        if (typeof options == 'string') {
            return data[options].apply(data, Array.prototype.slice.call(arguments, 1));
        } else {
            return data;
        }
    };

    $.fn.keditor.constructor = KEditor;

    // Export KEditor
    $.keditor = KEditor;


    function getScrollbarWidth() {

      // Creating invisible container
      var outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);

      // Creating inner element and placing it in the container
      var inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      var scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);

      return scrollbarWidth;

    }

})(jQuery);
