<template>
  <div class="d-flex">
    <div>
      <b-icon
        v-if="item.published && !statusChanged"
        :id="`tooltip-icon-published-${id}-${item.id}`"
        icon="cloud-check-fill"
        aria-hidden="true"
        class="mx-015"
      ></b-icon>
      <b-tooltip
        v-if="item.published && !statusChanged"
        :target="`tooltip-icon-published-${id}-${item.id}`"
      >
        <small v-if="item.type === 'page'">{{ $t('webmag.publishedPage') }}</small>
        <small v-else>{{ $t('webmag.publishedProject') }}</small>
      </b-tooltip>
      <b-icon
        v-if="!item.published && !statusChanged"
        :id="`tooltip-icon-not-published-${id}-${item.id}`"
        icon="cloud"
        aria-hidden="true"
        class="mx-015"
      ></b-icon>
      <b-tooltip
        v-if="!item.published && !statusChanged"
        :target="`tooltip-icon-not-published-${id}-${item.id}`"
      >
        <small v-if="item.type === 'page'">{{ $t('webmag.unpublishedPage') }}</small>
        <small v-else>{{ $t('webmag.unpublishedProject') }}</small>
      </b-tooltip>
      <b-icon
        v-if="item.published && statusChanged"
        :id="`tooltip-icon-changed-${id}-${item.id}`"
        class="icon-status-changed mx-015"
        icon="cloud-upload-fill"
        aria-hidden="true"
      ></b-icon>
      <b-tooltip
        v-if="item.published && statusChanged"
        :target="`tooltip-icon-changed-${id}-${item.id}`"
      >
        <small>{{ $t('webmag.publishedChanged') }}</small>
      </b-tooltip>
    </div>
    <div class="d-flex">
      <!-- <b-button
        v-if="!item.published && isProjectOrPage"
        class="inline-block eye-button border-0 p-0 m-0"
      >
        <b-icon
          :id="`tooltip-eye-changed-${id}-${item.id}`"
          icon="link"
          aria-hidden="true"
        ></b-icon>
      </b-button> -->
      <b-button
        v-if="item.published && isProjectOrPage"
        class="inline-block eye-button border-0 p-0 m-0 mx-015"
        @click="showUrlModal"
      >
        <b-icon
          :id="`tooltip-eye-fill-changed-${id}-${item.id}`"
          icon="link"
          aria-hidden="true"
          class="mx-1 top--1"
        ></b-icon>
      </b-button>
      <b-tooltip
        v-if="!item.published && isProjectOrPage"
        :target="`tooltip-eye-changed-${id}-${item.id}`"
      >
        <small v-if="item.type === 'project'">{{ $t('webmag.publishProjectChangesFirst') }}</small>
        <small v-if="item.type === 'page'">{{ $t('webmag.publishPageChangesFirst') }}</small>
      </b-tooltip>
      <b-tooltip
        v-if="item.published && isProjectOrPage"
        :target="`tooltip-eye-fill-changed-${id}-${item.id}`"
      >
        <small>{{ $t('webmag.showItemUrl') }}</small>
      </b-tooltip>
    </div>
  </div>
</template>

<script>

import SetItemUrlWindowFromCache from '@/graphQlQueries/mutations/setItemUrlWindowFromCache';

export default {
  name: 'PublishStatusIcons',
  props: {
    item: Object,
    id: String,
  },
  computed: {
    statusOfPageVersions() {
      let publishStatus = true;
      if (this.item.type === 'project') {
        this.item.childPages.forEach((childPage) => {
          if (childPage.published
            && childPage.latest_page_version_id !== childPage.latest_published_page_version_id) {
            publishStatus = false;
          }
        });
      }
      if (this.item.type === 'page') {
        if (this.item.latestPageVersionId !== this.item.latestPublishedPageVersionId) {
          publishStatus = false;
        }
      }
      return publishStatus;
    },
    statusChanged() {
      return (this.item.published && !this.statusOfPageVersions);
    },
    isProjectOrPage() {
      return this.item.type === 'project' || this.item.type === 'page';
    },
  },
  methods: {
    showUrlModal() {
      this.$apollo.mutate({
        mutation: SetItemUrlWindowFromCache,
        variables: {
          openItemUrlWindow: true,
          itemPath: this.item.item_path,
        },
      });
    },
  },
};
</script>

<style scoped>
.icon-status-changed {
  color: #53dc86;
}
.eye-button {
  line-height: 10.3px;
  border: 0;
  background: transparent
}

</style>
