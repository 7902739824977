import gql from 'graphql-tag';

export default gql`
mutation setPublishStateOfProject(
  $id: Int!,
  $published: Boolean!
) {
  update_projects_by_pk(
    pk_columns: {project_id: $id},
    _set: {published: $published}
  ) {
    project_id
    updated_at
  }
}
`;
