import gql from 'graphql-tag';

export default gql`
mutation UpdatePageVersionsPublishState(
  $pageVersionIds: [Int!],
  $publishStatus: Boolean!,
  $userId: Int
) {
  update_page_versions(
    where: {
      page_version_id: {_in: $pageVersionIds}
    },
    _set: {
      published: $publishStatus,
      user_id: $userId
    }
  ) {
    affected_rows
    returning {
      page_version_id
      page_id
      published_at
    }
  }
}
`;
