var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showItem
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "mx-015",
              attrs: { id: _vm.item.slug + "-listed-" + _vm.id }
            },
            [
              _vm.hasAccessToSettings
                ? _c(
                    "a",
                    { attrs: { href: _vm.pathOfItemSettings } },
                    [
                      _c(
                        "b-iconstack",
                        {
                          staticStyle: { color: "#fff" },
                          attrs: { "font-scale": "1" }
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "exclamation-circle",
                              scale: ".9"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-iconstack",
                    {
                      staticStyle: { color: "#fff" },
                      attrs: { "font-scale": "1" }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          stacked: "",
                          icon: "exclamation-circle",
                          scale: ".9"
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "b-tooltip",
            { attrs: { target: _vm.item.slug + "-listed-" + _vm.id } },
            [
              _vm.item.searchable === false &&
              (_vm.item.type === "project" ||
                (this.item.type === "page" && this.parentItemType === "folder"))
                ? _c("div", [
                    _c("small", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("webmag.hiddenFromSearchEngines"))
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.item.listed === false && _vm.item.type === "project"
                ? _c("div", [
                    _c("small", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("webmag.listedOnOverviewPage"))
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.item.listed === false && _vm.item.type === "page"
                ? _c("div", [
                    _c("small", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("webmag.pageHiddenFromProject"))
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.item.isSharedTemplate === true
                ? _c("div", [
                    _c("small", [
                      _vm._v(" " + _vm._s(_vm.$t("webmag.isSharedTemplate")))
                    ])
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }