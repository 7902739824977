var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _c(
      "div",
      [
        _vm.item.published && !_vm.statusChanged
          ? _c("b-icon", {
              staticClass: "mx-015",
              attrs: {
                id: `tooltip-icon-published-${_vm.id}-${_vm.item.id}`,
                icon: "cloud-check-fill",
                "aria-hidden": "true"
              }
            })
          : _vm._e(),
        _vm.item.published && !_vm.statusChanged
          ? _c(
              "b-tooltip",
              {
                attrs: {
                  target: `tooltip-icon-published-${_vm.id}-${_vm.item.id}`
                }
              },
              [
                _vm.item.type === "page"
                  ? _c("small", [
                      _vm._v(_vm._s(_vm.$t("webmag.publishedPage")))
                    ])
                  : _c("small", [
                      _vm._v(_vm._s(_vm.$t("webmag.publishedProject")))
                    ])
              ]
            )
          : _vm._e(),
        !_vm.item.published && !_vm.statusChanged
          ? _c("b-icon", {
              staticClass: "mx-015",
              attrs: {
                id: `tooltip-icon-not-published-${_vm.id}-${_vm.item.id}`,
                icon: "cloud",
                "aria-hidden": "true"
              }
            })
          : _vm._e(),
        !_vm.item.published && !_vm.statusChanged
          ? _c(
              "b-tooltip",
              {
                attrs: {
                  target: `tooltip-icon-not-published-${_vm.id}-${_vm.item.id}`
                }
              },
              [
                _vm.item.type === "page"
                  ? _c("small", [
                      _vm._v(_vm._s(_vm.$t("webmag.unpublishedPage")))
                    ])
                  : _c("small", [
                      _vm._v(_vm._s(_vm.$t("webmag.unpublishedProject")))
                    ])
              ]
            )
          : _vm._e(),
        _vm.item.published && _vm.statusChanged
          ? _c("b-icon", {
              staticClass: "icon-status-changed mx-015",
              attrs: {
                id: `tooltip-icon-changed-${_vm.id}-${_vm.item.id}`,
                icon: "cloud-upload-fill",
                "aria-hidden": "true"
              }
            })
          : _vm._e(),
        _vm.item.published && _vm.statusChanged
          ? _c(
              "b-tooltip",
              {
                attrs: {
                  target: `tooltip-icon-changed-${_vm.id}-${_vm.item.id}`
                }
              },
              [_c("small", [_vm._v(_vm._s(_vm.$t("webmag.publishedChanged")))])]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _vm.item.published && _vm.isProjectOrPage
          ? _c(
              "b-button",
              {
                staticClass: "inline-block eye-button border-0 p-0 m-0 mx-015",
                on: { click: _vm.showUrlModal }
              },
              [
                _c("b-icon", {
                  staticClass: "mx-1 top--1",
                  attrs: {
                    id: `tooltip-eye-fill-changed-${_vm.id}-${_vm.item.id}`,
                    icon: "link",
                    "aria-hidden": "true"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        !_vm.item.published && _vm.isProjectOrPage
          ? _c(
              "b-tooltip",
              {
                attrs: {
                  target: `tooltip-eye-changed-${_vm.id}-${_vm.item.id}`
                }
              },
              [
                _vm.item.type === "project"
                  ? _c("small", [
                      _vm._v(
                        _vm._s(_vm.$t("webmag.publishProjectChangesFirst"))
                      )
                    ])
                  : _vm._e(),
                _vm.item.type === "page"
                  ? _c("small", [
                      _vm._v(_vm._s(_vm.$t("webmag.publishPageChangesFirst")))
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm.item.published && _vm.isProjectOrPage
          ? _c(
              "b-tooltip",
              {
                attrs: {
                  target: `tooltip-eye-fill-changed-${_vm.id}-${_vm.item.id}`
                }
              },
              [_c("small", [_vm._v(_vm._s(_vm.$t("webmag.showItemUrl")))])]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }