var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mr-4" }, [
    _c("div", { staticClass: "item-card", class: _vm.item.type }, [
      _c(
        "div",
        { staticClass: "item-card-header" },
        [
          _vm.item.breadcrumbs
            ? _c(
                "div",
                [
                  _c(
                    "p",
                    { attrs: { id: `tooltip-card-breadcrumb-${_vm.item.id}` } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("sliceStingPos28")(_vm.item.breadcrumbs)
                          ) +
                          " "
                      )
                    ]
                  ),
                  _vm.item.breadcrumbs.length > 19
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            target: `tooltip-card-breadcrumb-${_vm.item.id}`
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.item.breadcrumbs) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _c("div", [_vm._m(0)]),
          _vm.item.type === "page"
            ? _c("p", [
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("formatOrder")(_vm.item.order)) + ".")
                ])
              ])
            : _vm._e(),
          _c("h3", { attrs: { id: `tooltip-button-variant-${_vm.item.id}` } }, [
            _vm._v(_vm._s(_vm.item.name))
          ]),
          _vm.item.name.length > 19
            ? _c(
                "b-tooltip",
                { attrs: { target: `tooltip-button-variant-${_vm.item.id}` } },
                [_c("div", [_vm._v(_vm._s(_vm.item.name))])]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "item-card-wrapper" }, [
        _c(
          "div",
          {
            staticClass:
              "item-card-body d-flex justify-content-center align-items-center",
            style: {
              backgroundImage: _vm.backgroundImage,
              backgroundPosition: _vm.backgroundPosition
            },
            on: { click: _vm.navigateToClick }
          },
          [
            _vm.item.type === "folder"
              ? _c("b-icon", {
                  attrs: {
                    icon: "folder",
                    "aria-hidden": "true",
                    color: "#bbb",
                    scale: "4.5"
                  }
                })
              : _vm._e(),
            _vm.item.type === "project" && !this.item.image
              ? _c("b-icon", {
                  attrs: {
                    icon: "files",
                    "aria-hidden": "true",
                    color: "#bbb",
                    scale: "4.5"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "item-card-footer d-flex flex-row-reverse justify-content-between"
          },
          [
            (!_vm.item.incompatibleItem || _vm.isSysAdmin) && _vm.showDotMenu
              ? _c("dot-dropdown", {
                  attrs: {
                    item: _vm.item,
                    "is-team-view": _vm.isTeamView,
                    "group-slug": _vm.groupSlug,
                    "parent-item": _vm.parentItem,
                    "is-project-published": _vm.isProjectPublished,
                    "is-group-user": _vm.isGroupUser,
                    "is-sys-admin": _vm.isSysAdmin,
                    showDots: false
                  },
                  on: { "item-action": _vm.itemAction }
                })
              : _c("div", {
                  staticClass: "empty-placeholder",
                  staticStyle: { height: "34px" }
                }),
            _vm.item.incompatibleItem
              ? _c(
                  "div",
                  {
                    staticClass: "pb-1 pr-2",
                    staticStyle: { "padding-top": "6px" }
                  },
                  [
                    _c("incompatible-icon", {
                      attrs: { item: _vm.item, id: "card-item" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.item.type !== "folder" &&
            (!_vm.item.incompatibleItem || _vm.isSysAdmin)
              ? _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("publish-status-icons", {
                      staticClass: "publish-status-icons",
                      attrs: { id: "card-item", item: _vm.item }
                    }),
                    _c("status-icons", {
                      attrs: {
                        item: _vm.item,
                        "has-access-to-settings": _vm.hasAccessToSettings,
                        "parent-item-type": _vm.parentItem
                          ? _vm.parentItem.type
                          : null,
                        id: "card-item"
                      }
                    })
                  ],
                  1
                )
              : _vm.item.type === "folder"
              ? _c(
                  "div",
                  [
                    _c("listed-status-icons", {
                      attrs: {
                        item: _vm.item,
                        "has-access-to-settings": _vm.hasAccessToSettings,
                        id: "card-item"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      !_vm.item.incompatibleItem || _vm.isSysAdmin
        ? _c("div", { staticClass: "card-below mb-3 text-center px-2 pt-2" }, [
            _vm.projectOrPageUnPublished
              ? _c("p", [
                  _vm.item.type === "page"
                    ? _c("small", [
                        _vm._v(_vm._s(_vm.$t("webmag.unpublishedPage")))
                      ])
                    : _c("small", [
                        _vm._v(_vm._s(_vm.$t("webmag.unpublishedProject")))
                      ])
                ])
              : _vm._e(),
            _vm.projectOrPagePublishedAndStatusChanged
              ? _c("p", { staticClass: "status-changed" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("webmag.publishedChanged")))
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }