<template>
  <div class="mr-4">
    <div class="item-card" :class="item.type">
      <div class="item-card-header">
        <div v-if="item.breadcrumbs">
          <p
            :id="`tooltip-card-breadcrumb-${item.id}`">
            {{ item.breadcrumbs | sliceStingPos28 }}
          </p>
          <b-tooltip
            v-if="item.breadcrumbs.length > 19"
            :target="`tooltip-card-breadcrumb-${item.id}`"
          >
            {{ item.breadcrumbs }}
          </b-tooltip>
        </div>
        <div v-else><p><br></p></div>
        <p v-if="item.type === 'page'"><strong>{{ item.order | formatOrder }}.</strong></p>
        <h3 :id="`tooltip-button-variant-${item.id}`"
            v-html="item.name"></h3>
        <b-tooltip
          v-if="item.name.length > 19"
          :target="`tooltip-button-variant-${item.id}`"
        >
          <div v-html="item.name"></div>
        </b-tooltip>
      </div>
      <div class="item-card-wrapper">
        <div
          class="item-card-body d-flex justify-content-center align-items-center"
          :style="{ backgroundImage: backgroundImage, backgroundPosition: backgroundPosition }"
          @click="navigateToClick"
        >
          <b-icon
            v-if="item.type === 'folder'"
            icon="folder"
            aria-hidden="true"
            color="#bbb"
            scale="4.5"
          ></b-icon>
          <b-icon
            v-if="item.type === 'project' && !this.item.image"
            icon="files"
            aria-hidden="true"
            color="#bbb"
            scale="4.5"
          ></b-icon>
        </div>
        <div class="item-card-footer d-flex flex-row-reverse justify-content-between">
          <dot-dropdown
            v-if="(!item.incompatibleItem || isSysAdmin) && showDotMenu"
            :item="item"
            :is-team-view="isTeamView"
            :group-slug="groupSlug"
            :parent-item="parentItem"
            :is-project-published="isProjectPublished"
            :is-group-user="isGroupUser"
            :is-sys-admin="isSysAdmin"
            :showDots="false"
            @item-action="itemAction"
          />
          <div v-else style="height: 34px" class="empty-placeholder"></div>
          <div v-if="item.incompatibleItem" class="pb-1 pr-2" style="padding-top: 6px;">
            <incompatible-icon
              :item="item"
              id="card-item"
            ></incompatible-icon>
          </div>
          <div
            v-if="item.type !== 'folder' && (!item.incompatibleItem || isSysAdmin)"
            class="d-flex align-items-center">
            <publish-status-icons
              class="publish-status-icons"
              id="card-item"
              :item="item"
            ></publish-status-icons>
            <status-icons
              :item="item"
              :has-access-to-settings="hasAccessToSettings"
              :parent-item-type="(parentItem) ? parentItem.type : null"
              id="card-item"
            ></status-icons>
          </div>
          <div v-else-if="item.type === 'folder'">
            <listed-status-icons
              :item="item"
              :has-access-to-settings="hasAccessToSettings"
              id="card-item"
            ></listed-status-icons>
          </div>
        </div>
      </div>
      <div
        v-if="!item.incompatibleItem || isSysAdmin"
        class="card-below mb-3 text-center px-2 pt-2"
      >
        <p v-if="projectOrPageUnPublished">
          <small v-if="item.type === 'page'">{{ $t('webmag.unpublishedPage') }}</small>
          <small v-else>{{ $t('webmag.unpublishedProject') }}</small>
        </p>
        <p v-if="projectOrPagePublishedAndStatusChanged" class="status-changed">
          <small>{{ $t('webmag.publishedChanged') }}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import graphQL queries
import GetPublishOfParentProject from '@/graphQlQueries/queries/getPublishOfParentProject';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';

export default {
  name: 'DashboardItem',
  mixins: [SendPublishedDataToServer],
  components: {
    DotDropdown: () => import('@/components/dashboard/DotDropdown.vue'),
    PublishStatusIcons: () => import('@/components/dashboard/PublishStatusIcons.vue'),
    StatusIcons: () => import('@/components/dashboard/StatusIcons.vue'),
    IncompatibleIcon: () => import('@/components/dashboard/IncompatipleIcon.vue'),
    ListedStatusIcons: () => import('@/components/dashboard/ListedStatusIcons.vue'),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    parentItem: {
      type: Object,
      default: null,
    },
    isProjectPublished: {
      type: Boolean,
      default: null,
    },
    groupSlug: {
      type: String,
      default: null,
    },
    slugPath: {
      type: String,
      default: null,
    },
    isTeamView: {
      type: Boolean,
      default: false,
    },
    isGroupUser: {
      type: Boolean,
      default: true,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
    hasAccessToSettings: {
      type: Boolean,
      default: false,
    },
    showDotMenu: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    hasParentProject: false,
    parentProjectPublished: false,
  }),
  computed: {
    statusOfPageVersions() {
      let publishStatus = true;
      if (this.item.type === 'project') {
        this.item.childPages.forEach((childPage) => {
          if (childPage.published
            && childPage.latest_page_version_id !== childPage.latest_published_page_version_id) {
            publishStatus = false;
          }
        });
      }
      if (this.item.type === 'page') {
        if (this.item.latestPageVersionId !== this.item.latestPublishedPageVersionId) {
          publishStatus = false;
        }
      }
      return publishStatus;
    },
    backgroundImage() {
      return (this.item.image)
        ? this.item.image
        : 'none';
    },
    backgroundPosition() {
      return (this.item.focalpoint)
        ? this.item.focalpoint
        : 'center';
    },
    showPublishButton() {
      if (
        (this.item.type !== 'folder' && !this.item.published)
        || this.projectOrPagePublishedAndStatusChanged
      ) {
        if (this.hasParentProject) {
          return this.parentProjectPublished;
        }
        return true;
      }
      return false;
    },
    projectOrPagePublishedAndStatusChanged() {
      return (this.item.type !== 'folder' && this.item.published && !this.statusOfPageVersions);
    },
    projectOrPageUnPublished() {
      return (this.item.type !== 'folder' && !this.item.published);
    },
  },
  apollo: {
    getPublishOfParentProject: {
      query: GetPublishOfParentProject,
      variables() {
        return {
          pageId: this.item.id,
        };
      },
      update(data) {
        this.hasParentProject = data.pages_by_pk.parent_project !== null;
        if (data.pages_by_pk.parent_project !== null) {
          this.parentProjectIsPublished = data.pages_by_pk.parent_project.published;
        }
      },
      skip() {
        return !this.item.id || this.item.type !== 'page';
      },
      fetchPolicy: 'network-only',
    },
  },
  filters: {
    formatOrder(value) {
      return (value < 10) ? `0${value}` : value;
    },
    sliceStingPos28(value) {
      return (value.length > 28) ? `${value.slice(0, 28)}...` : value;
    },
  },
  methods: {
    itemAction(actionType, item) {
      this.$emit('item-action', actionType, item);
    },
    navigateToClick() {
      if (this.slugPath) {
        this.$router.push(this.slugPath);
      } else {
        const newPath = (this.$route.fullPath !== '/')
          ? `${this.$route.fullPath}/${this.item.slug}`
          : `/${this.item.slug}`;
        this.$router.push(newPath);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

h3 {
  font-size: 15px;
}

.item-card {
  max-width: 210px;
  width: 210px;
  transition: all .2s;

  .item-card-wrapper {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
  }

  &.project .item-card-wrapper:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0px;
    left: 0;
    background-color: #5a5c60;
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
    will-change: transform;
    z-index: -1;
    -webkit-transform: translate(5px, 5px);
    transform: translate(6px, 6px);
    border: 1px solid #44484c;
    opacity: 0.8;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  &:hover.project .item-card-wrapper:before {
    // transform: translate(10px, 10px);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.30);
  }

  .item-card-header {
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      color: #777c7f;
      font-size: 12px;
      margin: 0;
    }
  }

  .item-card-body {
    cursor: pointer;
    min-height: 150px;
    position: relative;
    background-color: #b9b9b9;
    background-size: cover;
    background-position: center center;
    transition: opacity .2s ease-in-out;

    h4 {
      margin: 0;
      text-transform: capitalize;
    }
  }

  .item-card-footer {
    background: #53575b;
  }

  &.folder .item-card-body:before {
    content: "";
    border-left: 15px solid rgba(83, 220, 134, 0.7);
    border-top: 20px solid #53575b;
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 20px;
  }

  &.folder svg {
    fill: white;
  }

  &.folder, &.project {
    .item-card-body {
      background-color: rgba(119, 124, 127, 1);
    }
  }

  &.folder {
    .item-card-body {
      background-color: rgba(83, 220, 134, 0.7);
    }
  }

  &.project .item-card-body,
  &.page .item-card-body {
    opacity: 1;
  }

  &:hover {
    h3, h4 {
      color: #fff;
    }

    .item-card-wrapper {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    }

    &.folder, &.project {
      .item-card-body {
        background-color: rgba(119, 124, 127, 1);
      }
    }

    &.folder {
      .item-card-body {
        //background-color: rgba(83, 220, 134, 0.45);
        background-color: rgba(83, 220, 134, 0.7);
      }
    }

    &.folder .item-card-body:before {
      border-left: 15px solid rgba(83, 220, 134, 0.45);
    }

    &.project .item-card-body,
    &.page .item-card-body,
    &.folder .item-card-body {
      opacity: .8;
    }
  }
}

.status-changed {
  color: #53dc86;
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.publish-status-icons {
  margin: 0 0 0 11px
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}
</style>
