var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.searchable === false && _vm.item.published && false
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "mx-015",
              attrs: { id: _vm.item.slug + "-searchable-" + _vm.id }
            },
            [
              _c(
                "svg",
                {
                  staticClass:
                    "bi-cloud-upload-fill icon-status-changed b-icon bi",
                  staticStyle: { fill: "#53dc86", transform: "scale(.85)" },
                  attrs: {
                    "data-name": "Group 1",
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "1em",
                    height: "1em"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "data-name": "Path 1",
                      d:
                        "M6.5 12A5.5 5.5 0 101 6.5 5.5 5.5 0 006.5 12zM13 6.5A6.5 6.5 0 116.5 0 6.5 6.5 0 0113 6.5z",
                      "fill-rule": "evenodd"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      "data-name": "Path 2",
                      d:
                        "M10.344 11.742a1.17 1.17 0 00.1.115l3.85 3.85a1 1 0 001.415-1.414l-3.85-3.85a1.007 1.007 0 00-.115-.1 6.538 6.538 0 01-1.4 1.4z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      "data-name": "Path 3",
                      d:
                        "M8.975 4.026a.5.5 0 010 .707L7.207 6.5l1.768 1.768a.5.5 0 01-.707.707L6.5 7.207 4.732 8.975a.5.5 0 01-.707-.707L5.793 6.5 4.025 4.733a.5.5 0 01.707-.707L6.5 5.793l1.768-1.767a.5.5 0 01.707 0z",
                      "fill-rule": "evenodd"
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "b-tooltip",
            { attrs: { target: _vm.item.slug + "-searchable-" + _vm.id } },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("webmag.hiddenFromSearchEngines")) + " "
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }